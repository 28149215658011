import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-resumes';

import {Paper, Grid, IconButton, InputLabel, RadioGroup, FormControlLabel, Link, Dialog, Fade, Collapse} from '@material-ui/core';
import Input from '../../components/UI/Input/Input';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import Radio from '../../components/UI/RadioButton/RadioButton';
import DatepickerInput from '../../components/UI/Datepicker/DatepickerInput';
import Autocomplete from '../../components/UI/Autocomplete/Autocomplete';
import Button from '../../components/UI/Button/Button';
import DocumentIcon from '../../components/UI/Icons/DocumentIcon';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

/* import Datepicker from '../../components/UI/Datepicker/Datepicker'; */

import DatePicker from "react-datepicker";

import styles from './ResumeBuilder.module.scss';

import countries from '../../shared/countriesJson';
import {apiUrl} from '../../config';
import { getTransformedResume, checkValidity } from '../../shared/utility';

//Driving License Types
const licenseTypes = [ 'A', 'B', 'C', 'D', 'E' ];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function AdditionalSection(props) {

    const [additionalPanelOpen, setAdditionalPanelOpen] = useState(true);

    const [additionalFieldsOpen, setAdditionalFieldsOpen] = useState(false);

    const [validationValues, setValidationValues] = useState({
        phoneNumber: {
            validation: {
                required: false,
                isPhoneNumber: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
    });

    const [additionalFields, setAdditionalFields] = useState(
        { jobPosition: '', phoneNumber: '', country: '', city: '', adress: '', postalCode: '', nationality: '', birthPlace: '', birthDate: null, drivingLicense: 1, drivingLicenseType: [] }
    );

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const handleDateChange = (content) => {
        let date;
        if (content) {
            date = new Date(content.getTime() - (content.getTimezoneOffset() * 60000));
        } else {
            date = null;
        }
        setAdditionalFields({...additionalFields, birthDate: date});
        setChanged(true);
    };

    const handleCountryChange = (country) => {
        setAdditionalFields({...additionalFields, country: country});
        
        if (country) {
            let currentCountry = countriesCitiesArray.find(el => el.country === country);
            if (currentCountry) {
                setCitiesArray(currentCountry.cities);
            } else {
                setCitiesArray([]);
            }
        }
        setChanged(true);
    };

    const handleCityChange = (city) => {
        setAdditionalFields({...additionalFields, city: city});
        setChanged(true);
    };

    const handleLicenseTypeChange = (content) => {
        setAdditionalFields({...additionalFields, drivingLicenseType: content});
        setChanged(true);
    };

    const handleJobTitleChange = (content) => {
        setAdditionalFields({...additionalFields, jobPosition: content});
        setChanged(true);
    };

    //Autocomplete State
    /*     const countriesOptions = JSON.parse(countries); */
    const [countriesCitiesArray, setCountriesCitiesArray] =  React.useState([]);

    const [countriesArray, setCountriesArray] =  React.useState([]);

    const [citiesArray, setCitiesArray] =  React.useState([]);

    useEffect(() => {
        axios.get( '/jobs', { headers: {
            'Authorization': `Bearer ${props.token}`
        }})
        .then( response => {
            let jobs = response.data.jobs;
            if (jobs) {
                setResumeSamples(jobs);
            }
        } )
        .catch( err => {
            console.log(err);
        });

        if (props.resume) {
            console.log('Additional Info Mounted');
            Object.keys(additionalFields).forEach((key) => { if (props.resume[key]) { additionalFields[key] = props.resume[key] } });
            setAdditionalFields({ ...additionalFields });
            setDataLoaded(true);
        }

        let countriesArr = [];
        for ( let key in countries ) {
            countriesArr.push(key);
        }
        setCountriesArray(countriesArr);

        let countriesCitiesArr = [];
        for ( let key in countries ) {
            countriesCitiesArr.push( {
                country: key,
                cities: countries[key]
            } );
        }
        setCountriesCitiesArray(countriesCitiesArr);

    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            console.log('Additional Info Reload');
            Object.keys(additionalFields).forEach((key) => { if (props.resume[key]) { additionalFields[key] = props.resume[key] } });
            setAdditionalFields({ ...additionalFields });
        }
    }, [props.exampleLoaded]);

    /* useEffect(() => {
        if (dataLoaded) {
            
            if (additionalFields.country) {
                let currentCountry = countriesCitiesArray.find(el => el.country == additionalFields.country);
                if (currentCountry) {
                    setCitiesArray(currentCountry.cities);
                } else {
                    setCitiesArray([]);
                }
            }
            
            props.onChange(additionalFields);
        }
    }, [additionalFields]); */

    useEffect(() => {
        //let delayDebounceFn = null;
        if (dataLoaded) {
            if (additionalFields.country) {
                let currentCountry = countriesCitiesArray.find(el => el.country === additionalFields.country);
                if (currentCountry) {
                    setCitiesArray(currentCountry.cities);
                } else {
                    setCitiesArray([]);
                }
            }
            if (changed) {
                //delayDebounceFn = setTimeout(() => {
                    props.onChange(additionalFields);
                //}, 3000);
            }
        }
        
        //return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, additionalFields]);

    const handleChange = (fieldKey, content) => (event) => {
        let value = event.target.value;
        if (fieldKey === 'phoneNumber') {
            const updatedControls = {
                ...validationValues,
                [fieldKey]: {
                    ...validationValues[fieldKey],
                    valid: checkValidity( event.target.value, validationValues[fieldKey].validation ).valid,
                    validationMessage: checkValidity( event.target.value, validationValues[fieldKey].validation ).validationMessage,
                    touched: true
                }
            };
            setValidationValues(updatedControls);
        }
        setAdditionalFields({...additionalFields, [fieldKey]: value});
        setChanged(true);
    };
    
    
    const [resumeSamples, setResumeSamples] = useState([]);
    const [suggestionOpen, setSuggestionOpen] = useState(false);
    const [suggestedValue, setSuggestedValue] = useState({});

    const [openDialog, setOpenDialog] = React.useState(false);

    
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSelectSuggestedResume = () => {
        const updatedResume = {
            ...suggestedValue,
            userId: props.userId,
            firstName: props.resume.firstName,
            lastName: props.resume.lastName,
            email: props.resume.email,
            resumeImage: null,
        }
        console.log('SUGGESTED RESUME');
        console.log(updatedResume);
        props.onChange(updatedResume);
        props.loadResumeSampleHandler();
        setOpenDialog(false);
        setSuggestionOpen(false);
    }

    const setResumeSuggestionOpen = (newValue) => {
        let suggestedResume = resumeSamples.find(resume => resume.name === newValue);
        if (suggestedResume && suggestedResume.example_url) {
            /* if (matchPath(suggestedResume.example_url, '/r/:idHash')) {
                console.log('idHash');
            } */
            const idHash = suggestedResume.example_url.substring(suggestedResume.example_url.lastIndexOf('/') + 1);
            const resumeId = atob(idHash);

            axios.get( `/example/resume/${resumeId}` )
            .then( response => {
                let resumeSample = getTransformedResume(response.data.resume);
                if (resumeSample) {
                    setSuggestionOpen(true);
                    setSuggestedValue({ ...resumeSample, title: suggestedResume.name, id: props.resume.id });
                }
            } )
            .catch( err => {
                console.log(err);
            } );
        }
    };

    const setResumeSuggestionClose = () => {
        setSuggestionOpen(false);
    };

    return (                       
        <Paper className={`${styles.FormSection} ${styles.AdditionalInfo}`}>
            <IconButton onClick={() => setAdditionalPanelOpen(!additionalPanelOpen)} className={styles.PanelToggle}>
                {additionalPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <h4>Profile</h4>
                <Tooltip tooltipContent="You can provide additional information to the employer. 
                Choose what you think it's relevant for a potential employer." />
            </div>
            
            {additionalPanelOpen ? (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Autocomplete
                            freeSolo
                            inputValue={additionalFields.jobPosition || ''}
                            changed={(event, newValue) => {
                                setResumeSuggestionOpen(newValue && newValue.name);
                            }}
                            inputChanged={(event, newValue) => {
                                handleJobTitleChange(newValue);
                            }}
                            id="jobPosition"
                            //options={resumeSamples.map((option) => `${option.name} ${option.example_url ? (<span>Sample available</span>) : ''}`)}
                            options={resumeSamples/* .map((option) => option.name) */}
                            filterOptions={createFilterOptions({
                                limit: 500,
                            })}
                            renderOption={(option) => (
                            <span style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', width: '100%', }}>
                                <span style={{flex: '1 0 auto', textTransform: 'capitalize', }}>{option.name}</span>
                                {option.example_url ? (<span style={{display: 'flex', alignItems: 'center', color: '#5C6783', fontSize: '.87rem'}}><DocumentIcon viewBox="0 0 12 15" style={{maxHeight: '15px'}} /><span>Sample available</span></span>) : ''}
                            </span>
                            )}
                            getOptionLabel={(option) => option.name}
                            label="Job Title"
                        />
                        {suggestedValue ? (
                            <Collapse in={suggestionOpen}>
                                <div className={`${styles.ResumeSuggestion} `}>
                                    <StarBorderIcon style={{fill: '#5C6783', marginRight: '.5em'}} />
                                    <div>
                                        <h5>We have a {suggestedValue ? suggestedValue.title : ''} resume template! </h5>
                                        <p>Would you like to have a quick look at the 
                                        example before starting to build your resume?</p>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    clicked={handleClickOpenDialog}
                                                    className={styles.Button}
                                                >
                                                    Yes, show me
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Link onClick={setResumeSuggestionClose} className={styles.Link}>No, I don't need a template</Link>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Collapse>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            variant="outlined"
                            key="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            name="Phone"
                            label="Phone"
                            value={additionalFields.phoneNumber || ''}
                            invalid={!validationValues.phoneNumber.valid}
                            shouldValidate={validationValues.phoneNumber.validation}
                            validationMessage={validationValues.phoneNumber.validationMessage}
                            touched={validationValues.phoneNumber.touched}
                            changed={handleChange("phoneNumber")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            freeSolo
                            inputValue={additionalFields.country || ''}
                            inputChanged={(event, newValue) => {
                                handleCountryChange(newValue);
                            }}
                            id="country-select"
                            options={countriesArray} 
                            /* getOptionLabel={(option) => option.country} */
                            label="Country"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            freeSolo
                            inputValue={additionalFields.city || ''}
                            inputChanged={(event, newValue) => {
                                handleCityChange(newValue);
                            }}
                            id="city-select"
                            options={citiesArray}
                            label="City"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={styles.Label}>Driving license</InputLabel>
                        <RadioGroup 
                            row
                            aria-label="drivingLicense" 
                            name="drivingLicense" 
                            value={String(additionalFields.drivingLicense)} 
                            onChange={handleChange("drivingLicense")}
                            style={{marginTop: '1rem'}}
                        >
                            <FormControlLabel value='1' control={<Radio />} label="Yes" />
                            <FormControlLabel value='0' control={<Radio />} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            multiple
                            id="driving-license-type"
                            options={licenseTypes}
                            disabled={!Boolean(Number(additionalFields.drivingLicense))}
                            value={additionalFields.drivingLicenseType}
                            changed={(event, newValue) => {
                                handleLicenseTypeChange(newValue);
                            }}
                            getOptionLabel={(option) => option}
                            label="Type of driving license"
                        />
                    </Grid>
                </Grid>
                <Collapse in={additionalFieldsOpen}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Input
                                variant="outlined"
                                key="adress"
                                id="adress"
                                type="text"
                                label="Address"
                                value={additionalFields.adress}
                                changed={handleChange("adress")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                        
                            <Input
                                variant="outlined"
                                key="postalCode"
                                id="postalCode"
                                type="text"
                                label="Postal code"
                                value={additionalFields.postalCode}
                                changed={handleChange("postalCode")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                variant="outlined"
                                key="nationality"
                                id="nationality"
                                type="text"
                                label="Nationality"
                                value={additionalFields.nationality}
                                changed={handleChange("nationality")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                variant="outlined"
                                key="birthPlace"
                                id="birthPlace"
                                type="text"
                                label="Place of birth"
                                value={additionalFields.birthPlace}
                                changed={handleChange("birthPlace")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className={styles.Label}>Date of birth</InputLabel>
                            <DatePicker
                                showPopperArrow={false}
                                selected={additionalFields.birthDate ? new Date(additionalFields.birthDate) : additionalFields.birthDate}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                calendarClassName={styles.BithdayCalendar}
                                customInput={<DatepickerInput />}
                                adjustDateOnChange
                                isClearable
                                closeOnScroll={true}
                                maxDate={new Date()}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <span onClick={() => setAdditionalFieldsOpen(!additionalFieldsOpen)} className={styles.ToggleLink}>
                    {additionalFieldsOpen ? 'Hide' : 'Show' } additional details
                    {additionalFieldsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                </span>
            </>
            ) : null}
            {
                suggestedValue ? (
                    <Dialog 
                        onClose={handleCloseDialog} 
                        aria-labelledby="resume-dialog-title" 
                        open={openDialog}
                        disableBackdropClick={true}
                        maxWidth={false}
                        TransitionComponent={Transition}
                        PaperProps={{
                            style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            position: 'static',
                            color: '#fff',
                            margin: '1rem',
                            },
                        }}
                        BackdropProps= {{
                            style: {
                                backgroundColor: '#17181B',
                                opacity: '.75',
                            },
                        }}
                        className={styles.Dialog}
                    >
                        <>
                            <IconButton aria-label="close" className={styles.CloseDialogButton} onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                            <h2 className={styles.DialogTitle}>{suggestedValue.title} Resume Template <span>Preview</span></h2>
                            <div className={styles.DialogImageWrap} /* style={{backgroundImage: `url(${suggestedValue.img})`}} */>
                                {/* <img src={`http://resumeapi.justfortest-staging1.com/storage${suggestedValue.resumeImage}`} alt={suggestedValue.title} /> */}
                                <div style={{backgroundImage: `url(${apiUrl}/storage${suggestedValue.resumeImage})`}} className={styles.ResumeSuggestionImage}></div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    clicked={handleSelectSuggestedResume}
                                    className={styles.Button}
                                >
                                    Use template
                                </Button>
                            </div>
                            <Link onClick={handleCloseDialog} className={styles.Link}>Cancel</Link>
                        </>
                    </Dialog>  
                ) : null
            }      
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        email: state.auth.userEmail,
        firstName: state.auth.userFirstName,
        lastName: state.auth.userLastName,
        created: state.resume.created,
        resumes: state.resume.resumes,
    };
};

export default connect( mapStateToProps )( AdditionalSection );