import React, { useState, useEffect, useRef } from 'react';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import WebsiteSocialItem from './WebsiteSocialItem';
import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import Input from '../../components/UI/Input/Input';
import styles from './ResumeBuilder.module.scss';

function WebsiteSocialSection(props) {
    const [websiteSocialPanelOpen, setWebsiteSocialPanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('Website & Social profiles');

    const [socials, setSocials] = useState({
        facebook: '',
        linkedin: '',
        twitter: '',
        instagram: '',
        github: '',
        youtube: '',
    });

    const [websiteSocialFields, setWebsiteSocialFields] = useState([
        { name: '', link: '' }
    ]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) {
                console.log('WebsiteSocial Info Mounted');
                setWebsiteSocialFields(props.resume.websiteSocialFields);
            }
            if (props.resume.socialSectionTitle) {
                setSectionTitle(props.resume.socialSectionTitle);
            }
            if (props.resume.socials) {
                setSocials(props.resume.socials);
            }
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) {
                console.log('WebsiteSocial Info Reload');
                setWebsiteSocialFields(props.resume.websiteSocialFields);
            }
            if (props.resume.socialSectionTitle) {
                setSectionTitle(props.resume.socialSectionTitle);
            }
            if (props.resume.socials) {
                setSocials(props.resume.socials);
            }
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ websiteSocialFields: websiteSocialFields });
        }
    }, [websiteSocialFields]);

    /* useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ websiteSocialFields: websiteSocialFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, websiteSocialFields]); */

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ socialSectionTitle: sectionTitle});
        }
    }, [sectionTitle]);

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ socials: socials});
        }
    }, [socials]);

    /* useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ socials: socials });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [socials]); */

    const handleAddWebsiteSocialFields = () => {
        const values = [...websiteSocialFields];
        values.push({ name: '', link: '' });
        setWebsiteSocialFields(values);
        setChanged(true);
    };

    const handleRemoveWebsiteSocialFields = index => {
        const values = [...websiteSocialFields];
        values.splice(index, 1);
        setWebsiteSocialFields(values);
        handleCloseDeleteEntryDialog();
        setChanged(true);
    };

    const handleWebsiteSocialInputChange = (index, fieldKey) => (event) => {
        const values = [...websiteSocialFields];
        values[index][fieldKey] = event.target.value;
        setWebsiteSocialFields(values);
        setChanged(true);
    };
    
    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    const handleSocialChange = (key) => (event) => {
        const socialValues = {...socials};
        socialValues[key] = event.target.value;
        setSocials(socialValues);
        setChanged(true);
    }

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setWebsiteSocialPanelOpen(!websiteSocialPanelOpen)} className={styles.PanelToggle}>
                {websiteSocialPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="social-section-title"
                    value={sectionTitle}
                    placeholder="Website & Social profiles"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
            </div>
            { websiteSocialPanelOpen ? (
                <>
                    <p style={{marginBottom: '1rem'}}>Here you can add links to your portfolio, linkedin profile, personal websites or other relevant social media.</p>

                    <Input
                        variant="outlined"
                        key="facebookLink"
                        id="facebookLink"
                        type="text"
                        placeholder="https://www.facebook.com/id"
                        label={(<><FacebookIcon />Facebook</>)}
                        value={socials.facebook}
                        changed={handleSocialChange('facebook')}
                    />
                    <Input
                        variant="outlined"
                        key="linkedinLink"
                        id="linkedinLink"
                        type="text"
                        placeholder="https://www.linkedin.com/id"
                        label={(<><LinkedInIcon />Linkedin</>)}
                        value={socials.linkedin}
                        changed={handleSocialChange('linkedin')}
                    />
                    <Input
                        variant="outlined"
                        key="twitterLink"
                        id="twitterLink"
                        type="text"
                        placeholder="https://www.twitter.com/id"
                        label={(<><TwitterIcon />Twitter</>)}
                        value={socials.twitter}
                        changed={handleSocialChange('twitter')}
                    />
                    <Input
                        variant="outlined"
                        key="instagramLink"
                        id="instagramLink"
                        type="text"
                        placeholder="https://www.instagram.com/id"
                        label={(<><InstagramIcon />Instagram</>)}
                        value={socials.instagram}
                        changed={handleSocialChange('instagram')}
                    />
                    <Input
                        variant="outlined"
                        key="githubLink"
                        id="githubLink"
                        type="text"
                        placeholder="https://www.github.com/id"
                        label={(<><GitHubIcon />Github</>)}
                        value={socials.github}
                        changed={handleSocialChange('github')}
                    />
                    <Input
                        variant="outlined"
                        key="youtubeLink"
                        id="youtubeLink"
                        type="text"
                        placeholder="https://www.youtube.com/id"
                        label={(<><YouTubeIcon />Youtube</>)}
                        value={socials.youtube}
                        changed={handleSocialChange('youtube')}
                    />

                    {websiteSocialFields.map((inputField, index) => (
                        <WebsiteSocialItem 
                            key={`${inputField}~${index}`} 
                            values={websiteSocialFields[index]} 
                            changed={handleWebsiteSocialInputChange}
                            deleted={() => handleOpenDeleteEntryDialog(index)}
                            index={index}
                        />
                    ))}
                    <Link
                        component="a"
                        variant="body2"
                        className={styles.FormLink}
                        onClick={() => handleAddWebsiteSocialFields()}
                    >
                        <AddCircleIcon /> Add a new link
                    </Link>
                </>
            ) : null }
            <Dialog 
                open={deleteEntryDialog.open}
                closed={handleCloseDeleteEntryDialog}
            >
                <h2>Delete Entry</h2>
                <p>Are you sure you want to delete this entry?</p>   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={() => handleRemoveWebsiteSocialFields(deleteEntryDialog.deletedEntry)}
                    className={styles.Button}
                >
                    Delete
                </Button>
                <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
            </Dialog>
        </Paper>
    )
}

export default WebsiteSocialSection;