import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DesignIcon(props) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0)">
                <path d="M12.7964 5.64554C12.7833 5.63743 12.7698 5.62994 12.7558 5.62311L11.8333 5.16455L6.7173 7.66483C6.58139 7.73128 6.41865 7.73128 6.28274 7.66483L1.1668 5.16455L0.244247 5.62311C0.0200294 5.73298 -0.065283 5.98982 0.0537354 6.19679C0.061135 6.20964 0.0692418 6.22215 0.0780288 6.23425L6.50002 9.37267L12.922 6.23425C13.0634 6.03966 13.0072 5.77609 12.7964 5.64554Z"/>
                <path d="M12.9444 8.36846C12.9014 8.29491 12.8361 8.23457 12.7564 8.19489L11.8339 7.73633L6.7179 10.2366C6.58199 10.3031 6.41926 10.3031 6.28334 10.2366L1.16737 7.73633L0.244822 8.19489C0.0191628 8.30732 -0.0650071 8.5673 0.0567863 8.7756C0.0997961 8.84915 0.165168 8.90949 0.244822 8.94917L6.28054 11.9492C6.41792 12.0175 6.5833 12.0175 6.72068 11.9492L12.7564 8.94917C12.982 8.83674 13.0662 8.57676 12.9444 8.36846Z"/>
                <path d="M12.7964 3.07364C12.7833 3.06553 12.7698 3.05804 12.7558 3.05121L6.7201 0.0512088C6.58272 -0.0170696 6.41735 -0.0170696 6.27997 0.0512088L0.244247 3.05121C0.0200294 3.16108 -0.065283 3.41792 0.0537354 3.62489C0.061135 3.63775 0.0692418 3.65025 0.0780288 3.66236L6.50002 6.80078L12.922 3.66236C13.0634 3.46777 13.0072 3.20419 12.7964 3.07364Z"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="13" height="12" fill="white"/>
                </clipPath>
            </defs>
       </SvgIcon>
    );
}