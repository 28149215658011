import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
  
import {Container, Snackbar, Paper, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';

import styles from './Account.module.scss';
import { checkValidity } from '../../shared/utility';

import * as actions from '../../store/actions/index';

function CreatePassword(props) {
    const [email, setEmail] = useState({
        value: '',
        validation: {
            required: true,
            isEmail: true,
            message: '',
        },
        valid: false,
        touched: false,
        validationMessage: '',
    });

    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);

    useEffect(() => {
        props.onAuthInit();
    }, [] );

    useEffect(() => {
        if (props.successMessage) {
            setSuccessMessageOpen(true);
        }
    }, [props.successMessage] );

    useEffect(() => {
        if (props.error) {
            setErrorMessageOpen(true);
        } else {
            setErrorMessageOpen(false);
        }
    }, [props.error] );

    const handleEmailChange = (event) => {
        const updatedEmail = {
            ...email,
            value: event.target.value,
            valid: checkValidity( event.target.value, email.validation ).valid,
            validationMessage: checkValidity( event.target.value, email.validation ).validationMessage,
            touched: true
        };
        setEmail({ ...updatedEmail });
    };

    // Form Validation
    const validateEmail = () => {
        const updatedEmail = {
            ...email,
            touched: true
        };
        setEmail({ ...updatedEmail });

        return email.valid;
    }

    const submitHandler = ( event ) => {
        event.preventDefault();

        if (validateEmail()) {
            props.onForgotPassword(email.value);
        } else {
            console.log('Invalid Password');
        }
    }

    return (
        <Container component="main" maxWidth="md" className={styles.AccountContainer}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={successMessageOpen}
                autoHideDuration={5000}
                severity="success"
                onClose={() => setSuccessMessageOpen(false)}
            >
                <Alert severity="success">{props.successMessage}</Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={errorMessageOpen}
                autoHideDuration={5000}
                onClose={() => setErrorMessageOpen(false)}
            >
                <Alert severity="error">{props.error}</Alert>
            </Snackbar>
            <Paper className={styles.Paper}>
                <h3>Forgot your password?</h3>
                <p>Please enter the email address registred on your account</p>
                <Grid container spacing={1} style={{marginBottom: '1rem'}}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            variant="outlined"
                            id="email"
                            type="email"
                            label="E-mail (your login email)"
                            name="E-mail"
                            value={email.value}
                            invalid={!email.valid}
                            shouldValidate={email.validation}
                            validationMessage={email.validationMessage}
                            touched={email.touched}
                            classes={styles.Input}
                            changed={handleEmailChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={styles.Button}
                    clicked={submitHandler}
                >
                    Password Recovery
                </Button>
            </Paper>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        successMessage: state.auth.successMessage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onForgotPassword: ( email ) => dispatch( actions.forgotPassword( email ) ),
        onAuthInit: () => dispatch(actions.authInit()),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( CreatePassword );