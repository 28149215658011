import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import WorkExperienceItem from './WorkExperienceItem';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import styles from './ResumeBuilder.module.scss';

import { shallowEqual } from '../../shared/utility';


function WorkExperienceSection(props) {
    
    const [workExperiencePanelOpen, setWorkExperiencePanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('Work experience');

    const [workExperienceFields, setWorkExperienceFields] = useState([
        { jobTitle: '', company: '', startDate: null, endDate: null, presentDate: false, city: '', description: '' }
    ]);

    const  [workExperienceItemOpen, setWorkExperienceItemOpen]  = useState(0);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.workExperienceItems && props.resume.workExperienceItems.length) {
                console.log('Work Info Mounted');
                setWorkExperienceFields(props.resume.workExperienceItems);
            }
            if (props.resume.experienceSectionTitle) {
                setSectionTitle(props.resume.experienceSectionTitle);
            }
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.workExperienceItems && props.resume.workExperienceItems.length) {
                console.log('Work Info Reload');
                setWorkExperienceFields(props.resume.workExperienceItems);
            }
            if (props.resume.experienceSectionTitle) {
                setSectionTitle(props.resume.experienceSectionTitle);
            }
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ workExperienceItems: workExperienceFields });
        }
    }, [workExperienceFields]);

    /* useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ workExperienceItems: workExperienceFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, workExperienceFields]); */

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ experienceSectionTitle: sectionTitle});
        }
    }, [sectionTitle]);

    const handleAddWorkExperienceFields = () => {
        const values = [...workExperienceFields];
        values.push({ jobTitle: '', company: '', startDate: null, endDate: null, presentDate: false, city: '', description: '' });
        setWorkExperienceFields(values);
        setWorkExperienceItemOpen(values.length - 1);
        setChanged(true);
    };

    const handleRemoveWorkExperienceFields = index => {
        const values = [...workExperienceFields];
        values.splice(index, 1);
        setWorkExperienceFields(values);
        setChanged(true);

        if (workExperienceItemOpen == index) {
            setWorkExperienceItemOpen(null);
        }

        handleCloseDeleteEntryDialog();
    };

    const handleToggleWorkExperienceFields = index => {
        if (workExperienceItemOpen == index) {
            setWorkExperienceItemOpen(null);
        } else {
            setWorkExperienceItemOpen(index);
        }
    }

    /* const handleWorkExperienceInputChange = (index, fieldKey, content) => (event) => {
        const values = [...workExperienceFields];
        if ((fieldKey === 'description') || (fieldKey === 'presentDate') || (fieldKey === 'startDate') || (fieldKey === 'endDate')) {
            values[index][fieldKey] = content;
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setWorkExperienceFields(values);
        setChanged(true);
    }; */

    const handleWorkExperienceInputChange = (index, fieldKey, content) => (event) => {
        const values = [...workExperienceFields];
        if ((fieldKey === 'description') || (fieldKey === 'presentDate') || (fieldKey === 'startDateMonthHidden') || (fieldKey === 'endDateMonthHidden')) {
            values[index][fieldKey] = content;
        } else if ((fieldKey === 'startDate') || (fieldKey === 'endDate')) {
            //Fix One day off JS issue
            if (moment(content).isValid()) {
                const date = new Date(content.getTime() - (content.getTimezoneOffset() * 60000));
                values[index][fieldKey] = date;
            } else {
                values[index][fieldKey] = content;
            }
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setWorkExperienceFields(values);
        setChanged(true);
    };

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        
        console.log('workExperienceItemOpen ' + workExperienceItemOpen + '  source.index' + source.index + ' destination.index ' + destination.index);

        const values = [...workExperienceFields];
        let draggableEl = values.splice(source.index, 1);
        values.splice(destination.index, 0, draggableEl[0]);

        if (!shallowEqual(workExperienceFields[workExperienceItemOpen], values[workExperienceItemOpen])) {
            if (workExperienceItemOpen === source.index) {
                setWorkExperienceItemOpen(destination.index);
            } else if (workExperienceItemOpen > destination.index) {
                setWorkExperienceItemOpen(workExperienceItemOpen + 1);
            } else if (workExperienceItemOpen < destination.index) {
                setWorkExperienceItemOpen(workExperienceItemOpen - 1);
            } else if (workExperienceItemOpen === destination.index) {
                setWorkExperienceItemOpen(source.index);
            }
        }
        
        setWorkExperienceFields(values);
        setChanged(true);
    };
    
    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    return (   
        <DragDropContext onDragEnd={onDragEnd}>                   
            <Paper className={`${styles.FormSection}`}>
                <IconButton onClick={() => setWorkExperiencePanelOpen(!workExperiencePanelOpen)} className={styles.PanelToggle}>
                    {workExperiencePanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                </IconButton>

                <div className={styles.PanelTitle}>
                    <AutosizeInput
                        name="experience-section-title"
                        value={sectionTitle}
                        placeholder="Work experience"
                        onChange={handleSectionTitleChange}
                        className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                        ref={sectionTitleInput}
                        data-lpignore="true"
                    />
                    <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
                </div>
                { workExperiencePanelOpen ? (
                    <>
                        <p>Include your latest most relevant experience. List your most recent positions first.</p>
                        <Droppable droppableId="workExperienceSection">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {workExperienceFields.map((inputField, index) => (
                                        <WorkExperienceItem 
                                            key={`workExperience~${index}`}
                                            id={`workExperience~${index}`}
                                            index={index} 
                                            values={workExperienceFields[index]} 
                                            changed={handleWorkExperienceInputChange}
                                            deleted={() => handleOpenDeleteEntryDialog(index)}
                                            toggled={() => handleToggleWorkExperienceFields(index)}
                                            open={workExperienceItemOpen == index}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        
                        <Link
                            component="a"
                            variant="body2"
                            className={styles.FormLink}
                            onClick={() => handleAddWorkExperienceFields()}
                        >
                            <AddCircleIcon /> Add another job
                        </Link>
                    </>
                ) : null }
                <Dialog 
                    open={deleteEntryDialog.open}
                    closed={handleCloseDeleteEntryDialog}
                >
                    <h2>Delete Entry</h2>
                    <p>Are you sure you want to delete this entry?</p>   
                    <Button
                        variant="contained"
                        color="primary"
                        clicked={() => handleRemoveWorkExperienceFields(deleteEntryDialog.deletedEntry)}
                        className={styles.Button}
                    >
                        Delete
                    </Button>
                    <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
                </Dialog>
            </Paper>
        </DragDropContext>        
    )
}

export default WorkExperienceSection;