import React from 'react';
import { Page, Text, Document, StyleSheet, Font, View, Link } from '@react-pdf/renderer';

import {toJSON} from '../../../shared/domToJson';
import {JsonToPdfComponent} from '../../../shared/jsonToPdf';
import {createFragment} from '../../../shared/createFragment';
import { addPdfPattern } from '../../../shared/addPdfPattern';


// Create Document Component
const DifferentColorsPdf = (props) => {
    
    Font.registerHyphenationCallback(word => {
        return [word];
    });
    
    const mainColor = props.coverLetterConfiguration.primary_color || '#c7ecc7';
    const fontFamily = props.coverLetterConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.coverLetterConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 11.5 * multiplier;
    const headerTitleSize = 24 * multiplier;
    

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
            position: 'relative',
        },
        table: {
            fontSize: mainSize,
            lineHeight: 1.3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        headerBefore: {
            position: 'absolute',
            top: 0,
            height: 21,
            width: '100%',
            backgroundColor: mainColor,
        },
        header: {
            position: 'relative',
            textAlign: 'center',
            paddingTop: 20,
            paddingBottom: 40,
            paddingHorizontal: 40,
            marginBottom: 20,
            backgroundColor: mainColor,
        },
        title: {
            fontSize: headerTitleSize,
            fontWeight: 700,
            marginBottom: 0,
        },
        main: {
            paddingTop: 0,
            paddingHorizontal: 40,
            paddingBottom: 5,
        },
        link: {
            color: mainColor, 
            textDecoration: 'none',
        },
        jobPosition: {
            maxWidth: '48%',
            width: '48%',
        },
        contactInfo: {
            maxWidth: '48%',
            width: '48%',
            textAlign: 'right',
        },
        contactLink: {
            color: 'inherit',
            textDecoration: 'none',
        },
        employerInfo: {
            marginVertical: 20,
        },
        description: {
            maxWidth: '98%',
        },
    });

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'dash'));
        }
    }

    return (
    <Document>
        <Page wrap size="A4" style={styles.body}>
            {addPdfPattern(props.coverLetterConfiguration.pattern)}
            <View style={styles.headerBefore}></View>
            <View style={styles.table}>
                <View style={styles.header}>
                    <Text style={styles.title}>{props.coverLetter.firstName} {props.coverLetter.lastName}</Text>
                </View>

                <View style={styles.main}>
                    <View style={styles.row}>
                        <Text style={styles.jobPosition}>{props.coverLetter.jobPosition}</Text>
                        {(props.coverLetter.email || props.coverLetter.phoneNumber) ? (
                            <View style={styles.contactInfo}>
                                {props.coverLetter.phoneNumber ? (<View style={styles.contactItem}>
                                    <Text style={styles.contactValue}>{props.coverLetter.phoneNumber}</Text>
                                </View>) : <></>}
                                {props.coverLetter.email ? (<View style={styles.contactItem}>
                                    <Link src={`mailto:${props.coverLetter.email}`} style={styles.contactLink}>{props.coverLetter.email}</Link>
                                </View>) : <></>}
                            </View>
                        ) : <></>}
                    </View>
                    <View style={styles.employerInfo}>
                        {props.coverLetter.companyName ? (<Text style={styles.companyName}>{props.coverLetter.companyName}</Text>) : <></>}
                        {props.coverLetter.managerName ? (<Text style={styles.managerName}>{props.coverLetter.managerName}</Text>) : <></>}
                    </View>
                    {props.coverLetter.letterDetails ? (<View style={styles.description}>{generateTinyMceElements(props.coverLetter.letterDetails)}</View>) : <></>}
                </View>
            </View>
        </Page>
    </Document>
    )
};

export default DifferentColorsPdf;