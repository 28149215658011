import React from 'react';

import styles from './MobileToggle.module.scss';

const MobileToggle = (props) => {
    let attachedClasses = [styles.MobileToggle];
    if (props.open) {
        attachedClasses = [styles.MobileToggle, styles.Open];
    }

    return (
        <div className={attachedClasses.join(' ')} onClick={props.clicked}>
            <span></span>
        </div>
    )
};

export default MobileToggle;