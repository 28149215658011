import React from 'react';
import {createUseStyles} from 'react-jss';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import ComputerIcon from '@material-ui/icons/Computer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RateReviewIcon from '@material-ui/icons/RateReview';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

import styles from './Woodlawn.module.scss';

function Woodlawn (props) {
    const mainColor = props.resumeConfiguration.primary_color || '#000';
    const additionalColor = props.resumeConfiguration.secondary_color || '#000';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    const fontSize = props.resumeConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 12 * multiplier;
    const headerTitleSize = 24 * multiplier;
    const headerSubtitleSize = 14 * multiplier;
    const sideTitleSize = 15 * multiplier;
    const contactLinkSize = 11 * multiplier;
    const sectionTitleSize = 15 * multiplier;
    const repeatedTitleSize = 14 * multiplier;
    const repeatedSubtitleSize = 13 * multiplier;

    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, sideTitleSize, contactLinkSize, sectionTitleSize, repeatedTitleSize, repeatedSubtitleSize};

    const templateStyles = createUseStyles({
        header: {
            padding: '25pt 20pt 20pt',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            textAlign: 'center',
            lineHeight: 1.5,
            zIndex: 1,
            '& $description': {
                textAlign: 'left',
                lineHeight: 1.5,
            }
        },
        headerRow: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'left',
            margin: '0 auto',
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            textAlign: 'center',
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            fontWeight: 700,
            color: (prop) => prop.mainColor,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            color: (prop) => prop.mainColor,
        },
        userImage: {
            width: '25mm',
            height: '25mm',
            objectFit: 'cover',
            borderRadius: '12.5mm',
            flexGrow: 0,
            marginLeft: '15px',
        },
        sectionTitle: {
            display: 'block',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            fontWeight: 700,
            color: (prop) => prop.mainColor,
            flexBasis: `calc(100% - 25pt)`,
            order: 1,
            textTransform: 'uppercase',
            letterSpacing: '1pt',
        },
        description: {
            marginTop: '3pt',
            '& ol': {
                margin: 0,
                /* margin: '.5rem 0', */
                paddingLeft: '10pt',
                lineHeight: 1.4,
            },
            '& ul': {
                margin: 0,
                /* margin: '.5rem 0', */
                padding: 0,
                lineHeight: 1.4,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: (prop) => prop.mainColor,
                        fontSize: (prop) => '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
            '& p': {
                lineHeight: 1.3,
                marginBottom: 0,
            },
            '& a': {
                color: (prop) => prop.mainColor,
                textDecoration: 'none',
            },
        },
        main: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'stretch',
            flexWrap: 'nowrap',
            flex: '1 1 100%',
            padding: '0 20pt 25pt',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 1,
            '& a': {
                color: (prop) => prop.mainColor,
                textDecoration: 'none',
            },
            '& p': {
                marginBottom: 0,
            }
        },
        link: {
            color: (prop) => prop.mainColor,
            textDecoration: 'none',
        },
        detailsContentCell: {
            maxWidth: '25%',
            width: '25%',
            paddingLeft: '15pt',
            borderLeft: '1px solid #e4e4e4',
            '& $link': {
                wordWrap: 'break-word',
                fontSize: (prop) => `${prop.contactLinkSize}pt`,
                color: (prop) => prop.additionalColor,
            },
            '& $sectionTitle': {
                borderBottom: '2px solid #e4e4e4',
                marginBottom: '10pt',
                paddingBottom: '3pt',
            }
        },
        repeatedItem: {
            marginBottom: '10pt',
            '&:not(:last-child)': {
                paddingBottom: '10pt',
                borderBottom: '1px solid #e4e4e4',
            }
        },
        repeatedItemTitle: {
            fontWeight: 700,
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            marginBottom: '5pt',
        },
        repeatedItemSubtitle: {
            color: '#8190AA',
            fontSize: (prop) => `${prop.repeatedSubtitleSize}pt`,
        },
        contactItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5pt',
            wordBreak: 'break-all',
            '& svg': {
                marginRight: '0.5rem',
            },
        },
        contactLine: {
            //fontSize: '13pt',
            '& span': {
                display: 'inline-block',
                '&:not(:last-of-type):after': {
                    content: '","',
                    display: 'inline-block',
                    marginRight: '0.5em',
                },
            },
        },
        phoneNumber: {
            //fontSize: '13pt',
        },
    });

    let classes = templateStyles({ mainColor, additionalColor, fontFamily, ...fontSizes });
    
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 13,
            borderWidth: '1px',
            borderColor: additionalColor,
            borderStyle: 'solid',
            borderRadius: '5px',
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: additionalColor,
        },
    }))(LinearProgress);

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const languagesPercentages = {
        'Native Speaker': 100, 
        'Highly proficient': 80, 
        'Very good command': 60, 
        'Good working knowledge': 40,
        'Working knowledge': 20, 
        'C2': 100, 
        'C1': 100, 
        'B2': 80, 
        'B1': 60, 
        'A2': 40, 
        'A1': 20
    }

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="fb-link"><FacebookIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.facebook}>{showUrl(socials.facebook)}</a></p>);
        }
        if (socials.linkedin) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="li-link"><LinkedInIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.linkedin}>{showUrl(socials.linkedin)}</a></p>);
        }
        if (socials.twitter) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="tw-link"><TwitterIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.twitter}>{showUrl(socials.twitter)}</a></p>);
        }
        if (socials.instagram) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="in-link"><InstagramIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.instagram}>{showUrl(socials.instagram)}</a></p>);
        }
        if (socials.github) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="gh-link"><GitHubIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.github}>{showUrl(socials.github)}</a></p>);
        }
        if (socials.youtube) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="yt-link"><YouTubeIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.youtube}>{showUrl(socials.youtube)}</a></p>);
        }

        return socialsList;
    }

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerRow}>
                    {props.resume.userImage ? (
                        <img 
                            className={classes.userImage} 
                            src={props.resume.userImage}
                            alt="user avatar"
                        />
                    ) : <></>}
                
                    <div className={classes.headerText}>
                        <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                        <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                    </div>
                </div>
                {props.resume.careerSummary ? (<div className={classes.description}>{generateTinyMceElements(props.resume.careerSummary)}</div>) : <></>}
            </div>
            <div className={classes.main}>
                <div className={styles.MainContentCell}>

                    {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <ComputerIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.experienceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.experienceSectionTitle}</p>) : (<p className={classes.sectionTitle}>Work Experience</p>)}    
                            </div>
                            {props.resume.workExperienceItems.map(workItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={workItem.id}>
                                    <p className={classes.repeatedItemTitle}>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ', ' : ''}{workItem.company}</p>
                                    <p className={classes.repeatedItemSubtitle}>{workItem.city}{(workItem.city && (workItem.startDate || workItem.endDate || workItem.presentDate)) ? ' • ' : ''}{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMMM YYYY")}</p>
                                    {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.educationFields && props.resume.educationFields.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <AccountBalanceIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.educationSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.educationSectionTitle}</p>) : (<p className={classes.sectionTitle}>Education</p>)}    
                            </div>
                            {props.resume.educationFields.map(educationItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={educationItem.id}>
                                        <p className={classes.repeatedItemTitle}>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ',' : ''}{educationItem.institution}</p>
                                        <p className={classes.repeatedItemSubtitle}>{educationItem.city}{(educationItem.city && (educationItem.startDate || educationItem.endDate || educationItem.presentDate)) ? ' • ' : ''}{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMMM YYYY")}</p>
                                        {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                        </div>
                    ) : <></>}

                    {props.resume.internshipFields && props.resume.internshipFields.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <ComputerIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.internshipSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.internshipSectionTitle}</p>) : (<p className={classes.sectionTitle}>Internship</p>)}
                            </div>
                            {props.resume.internshipFields.map(internshipItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={internshipItem.id}>
                                    <p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ', ' : ''}{internshipItem.employer}</p>
                                    <p className={classes.repeatedItemSubtitle}>{internshipItem.city}{(internshipItem.city && (internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate)) ? ' • ' : ''}{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMMM YYYY")}</p>
                                    {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.coursesFields && props.resume.coursesFields.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <AccountBalanceIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.courseSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.courseSectionTitle}</p>) : (<p className={classes.sectionTitle}>Courses</p>)}
                            </div>
                            {props.resume.coursesFields.map(courseItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={courseItem.id}>
                                    <p className={classes.repeatedItemTitle}>{courseItem.course}{(courseItem.course && courseItem.institution) ? ', ' : ''}{courseItem.institution}</p>
                                    <p className={classes.repeatedItemSubtitle}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMMM YYYY")}</p>
                                    {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.referencesFields && props.resume.referencesFields.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <RateReviewIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.referenceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.referenceSectionTitle}</p>) : (<p className={`movable ${classes.sectionTitle}`}>References</p>)}
                            </div>   
                            {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                props.resume.referencesFields.map(referenceItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={referenceItem.id}>
                                        <p className={classes.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ', ' : ''}{referenceItem.company}</p>
                                        <p className={classes.repeatedItemSubtitle}><a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}</p>
                                        {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>) : <></> }
                                    </div>
                                )) : (<p>References available upon request</p>)}
                        </div>
                    ) : <></>}

                    {props.resume.customFields && props.resume.customFields.length ? (
                        <div className={styles.SectionWrap}>
                            <div className={`movable ${styles.SectionTitleWrap}`}>
                                <AddCircleIcon style={{ color: mainColor }} className={styles.SectionIcon}/>
                                {props.resume.customSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.customSectionTitle}</p>) : (<></>)}
                            </div>
                            {props.resume.customFields.map(customItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={customItem.id}>
                                    <p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>
                                    <p className={classes.repeatedItemSubtitle}>{customItem.city}{(customItem.city && (customItem.startDate || customItem.endDate || customItem.presentDate)) ? ' • ' : ''}{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMMM YYYY")}</p>
                                    {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}    
                    
                </div>

                <div className={classes.detailsContentCell} /* fixed */>
                    <div className={`side-movable ${styles.SideItem}`}>
                        <p className={classes.contactLine}>
                            {generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}
                        </p>
                        {props.resume.phoneNumber ? (<p className={classes.phoneNumber}>{props.resume.phoneNumber}</p>) : <></>}
                        <a className={classes.link} href={`mailto:${props.resume.email}`}>{props.resume.email}</a>

                        {(props.resume.birthPlace || props.resume.birthDate) ? (
                            <>
                                <p className={styles.SideSubtitle}>{props.resume.birthDate ? 'Date' : ''}{props.resume.birthDate && props.resume.birthPlace ? ' / ' : ''}{props.resume.birthPlace ? 'Place' : ''} of birth</p>
                                {props.resume.birthDate ? (<p>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</p>) : ''}
                                {props.resume.birthPlace ? (<p>{props.resume.birthPlace}</p>) : ''}
                            </>
                        ) : (<></>)}

                        {props.resume.nationality ? (
                            <>
                                <p className={styles.SideSubtitle}>Nationality</p>
                                <p>{props.resume.nationality}</p>
                            </>
                        ) : (<></>)}
                        
                        {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (
                            <>
                                <p className={styles.SideSubtitle}>Driving license</p>
                                <p>{props.resume.drivingLicenseType.join(', ')}</p>
                            </>
                        ) : (<></>)}
                    </div>

                    {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                        <div className={styles.SideItem}>
                            {props.resume.websiteSocialFields.map(socialItem => (
                                (socialItem.link || socialItem.name) ? (
                                    <p key={socialItem.id}><a className={`side-movable ${classes.link}`} href={socialItem.link}>{socialItem.name}</a></p>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                        </div>
                    ) : <></>}

                    {props.resume.skillsFields && props.resume.skillsFields.length ? (
                        <div className={styles.SideItem}>                            
                            {props.resume.skillSectionTitle ? (<p className={`side-movable ${classes.sectionTitle}`}>{props.resume.skillSectionTitle}</p>) : (<p className={classes.sectionTitle}>Skills</p>)}
                            {props.resume.skillsFields.map(skillItem => 
                                (skillItem.skill) ? (<div className={`side-movable ${styles.SideRepeatedItem}`} key={skillItem.id}>
                                    <p>{skillItem.skill}</p>
                                    {skillItem.level && Boolean(Number(props.resume.skillsVisibility)) ? (
                                        <div className={styles.Level}><BorderLinearProgress 
                                            variant="determinate" 
                                            value={skillsPercentages[skillItem.level]}
                                        /></div>
                                    ) : (<></>)}
                                </div>)  : (<></>)
                            )}
                        </div>
                    ) : <></>}
                    

                    {props.resume.languagesFields && props.resume.languagesFields.length ? (
                        <div className={styles.SideItem}>                            
                            {props.resume.languageSectionTitle ? (<p className={`side-movable ${classes.sectionTitle}`}>{props.resume.languageSectionTitle}</p>) : (<p className={classes.sectionTitle}>Languages</p>)}
                            {props.resume.languagesFields.map(languageItem => 
                                (languageItem.language) ? (<div className={`side-movable ${styles.SideRepeatedItem}`} key={languageItem.id}>
                                    <p>{languageItem.language}</p>
                                    {languageItem.level ? (
                                        <div className={styles.Level}><BorderLinearProgress 
                                            variant="determinate" 
                                            value={languagesPercentages[languageItem.level]}
                                        /></div>) : null}
                                </div>)  : (<></>)
                            )}
                        </div>
                    ) : <></>} 

                </div>
                
            </div>
        </>
    )
}

export default Woodlawn;