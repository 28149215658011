import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-resumes';

import {Paper, Grid, Avatar} from '@material-ui/core';

import Input from '../../components/UI/Input/Input';

import styles from './ResumeBuilder.module.scss';
import {apiUrl} from '../../config';

import { checkValidity } from '../../shared/utility';

function GeneralInfoSection(props) {

    const [values, setValues] = useState({
        resumeForm: {
            firstName: {
                elementConfig: {
                    type: 'text',
                    name: 'First Name',
                    label: 'First Name'
                },
                value: '',
                validation: {
                    required: true,
                    isAlphabeticCharacters: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
            lastName: {
                elementConfig: {
                    type: 'text',
                    name: 'Last Name',
                    label: 'Last Name'
                },
                value: '',
                validation: {
                    required: true,
                    isAlphabeticCharacters: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
            email: {
                elementConfig: {
                    type: 'email',
                    name: 'E-mail',
                    label: 'E-mail'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
        },
    });

    const [avatar, setUserAvatar] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const avatarInput = useRef(null);

    useEffect(() => {   
        if (props.resume) {
            let updatedControls = {
                ...values.resumeForm,
            };
            
            console.log('General Info Mounted');
            Object.keys(updatedControls).forEach(
                (key) => {
                    updatedControls[key] = {
                        ...values.resumeForm[key],
                        value: props.resume[key],
                        valid: true
                    };
                }
            );
            setValues({ ...values, resumeForm: updatedControls });
            if (props.resume.userImage) {
                setUserAvatar(props.resume.userImage);
            }
            setDataLoaded(true);
        }
    } , [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            let updatedControls = {
                ...values.resumeForm,
            };
            
            console.log('General Info Reload');
            Object.keys(updatedControls).forEach(
                (key) => {
                    updatedControls[key] = {
                        ...values.resumeForm[key],
                        value: props.resume[key],
                        valid: true
                    };
                }
            );
            setValues({ ...values, resumeForm: updatedControls });
            setUserAvatar(props.resume.userImage);
        }
    }, [props.exampleLoaded]);

/*     useEffect(() => {
        const formData = {};
        for (let formElementIdentifier in values.resumeForm) {
            formData[formElementIdentifier] = values.resumeForm[formElementIdentifier].value;
        }
        if (dataLoaded) {
            props.onChange(formData);
        }
    }, [values.resumeForm, dataLoaded]); */

    useEffect(() => {
        const formData = {};
        for (let formElementIdentifier in values.resumeForm) {
            formData[formElementIdentifier] = values.resumeForm[formElementIdentifier].value;
        }
        //const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange(formData);
            }
        //}, 3000);
        //return () => clearTimeout(delayDebounceFn);
    }, [values.resumeForm, dataLoaded]);

    useEffect(() => {
        if (dataLoaded && changed) {
            console.log('// Send Avatar request here');
            props.onChange({userImage: avatar});
        }
    }, [avatar, dataLoaded]);

    //let timeout = null;

    const handleChange = (prop) => (event) => {

        const updatedControls = {
            ...values.resumeForm,
            [prop]: {
                ...values.resumeForm[prop],
                value: event.target.value,
                valid: checkValidity( event.target.value, values.resumeForm[prop].validation ).valid,
                validationMessage: checkValidity( event.target.value, values.resumeForm[prop].validation ).validationMessage,
                touched: true
            }
        };
        setValues({ ...values, resumeForm: updatedControls });
        setChanged(true);
        
    /*  if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setValues({ ...values, resumeForm: updatedControls });
        }, 300); */
    };

    /* const handleChange = (prop) => (event) => {
        const updatedControls = {
            ...values.resumeForm,
            [prop]: {
                ...values.resumeForm[prop],
                value: event.target.value,
                valid: checkValidity( event.target.value, values.resumeForm[prop].validation ).valid,
                validationMessage: checkValidity( event.target.value, values.resumeForm[prop].validation ).validationMessage,
                touched: true
            }
        };
        
        setValues({ ...values, resumeForm: updatedControls });
    }; */


    const uploadAvatar = (image) => {
        if (image) {
            const formData = new FormData();
            formData.append('id', props.resumeId);
            formData.append('image', image);
            axios.post( '/resume/upload/image', formData, { headers: {
                'Authorization': `Bearer ${props.token}`
            }})
            .then( response => {
                if (response.data.image_path) {
                    let avatarUrl = `${apiUrl}/storage` + response.data.image_path;
                    setChanged(true);
                    setUserAvatar(avatarUrl);
                }
            } )
            .catch( err => {
                console.log(err);
            } );
        }
    }

    /* const deleteAvatar = () => {
        axios.delete( `/resume/user/image/${props.resumeId}`, { headers: {
            'Authorization': `Bearer ${props.token}`
        }})
        .then( response => {
            setUserAvatar(null);
            avatarInput.current.value = '';
        } )
        .catch( err => { 
            console.log(err);
        } );
    } */

    return (                       
        <Paper className={`${styles.FormSection} ${styles.GeneralInfo}`} >
            <div className={styles.AvatarUploader}>
                <Avatar 
                    src={avatar ? avatar : '/images/example.jpg'}
                    className={styles.AvatarImage}
                />
                <input
                    accept="image/*"
                    className={styles.AvatarInput}
                    id="resume-avatar"
                    type="file"
                    onChange={(event) => uploadAvatar(event.target.files[0])}
                    ref={avatarInput}
                />
                <label htmlFor="resume-avatar" className={styles.AvatarLabel}>
                    {avatar ? 'Change photo' : 'Upload photo'}
                </label>
            </div>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="firstName"
                        id="firstName"
                        type={values.resumeForm.firstName.elementConfig.type}
                        name={values.resumeForm.firstName.elementConfig.name}
                        label={values.resumeForm.firstName.elementConfig.label}
                        value={values.resumeForm.firstName.value}
                        invalid={!values.resumeForm.firstName.valid}
                        shouldValidate={values.resumeForm.firstName.validation}
                        validationMessage={values.resumeForm.firstName.validationMessage}
                        touched={values.resumeForm.firstName.touched}
                        changed={handleChange("firstName")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="lastName"
                        id="lastName"
                        type={values.resumeForm.lastName.elementConfig.type}
                        name={values.resumeForm.lastName.elementConfig.name}
                        label={values.resumeForm.lastName.elementConfig.label}
                        value={values.resumeForm.lastName.value}
                        invalid={!values.resumeForm.lastName.valid}
                        shouldValidate={values.resumeForm.lastName.validation}
                        validationMessage={values.resumeForm.lastName.validationMessage}
                        touched={values.resumeForm.lastName.touched}
                        changed={handleChange("lastName")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        variant="outlined"
                        key="email"
                        id="email"
                        type={values.resumeForm.email.elementConfig.type}
                        name={values.resumeForm.email.elementConfig.name}
                        label={values.resumeForm.email.elementConfig.label}
                        value={values.resumeForm.email.value}
                        invalid={!values.resumeForm.email.valid}
                        shouldValidate={values.resumeForm.email.validation}
                        validationMessage={values.resumeForm.email.validationMessage}
                        touched={values.resumeForm.email.touched}
                        changed={handleChange("email")}
                    />
                </Grid>
            </Grid>    
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        created: state.resume.created,
        resumes: state.resume.resumes
    };
};

export default connect( mapStateToProps )( GeneralInfoSection );