import React, {useRef} from 'react';
import { connect } from 'react-redux';
import styles from './BuilderToolbar.module.scss';
import Switch from '../UI/Switch/Switch';
import * as actions from '../../store/actions/index';

const ShareItem = (props) => {
    const sharedLink = btoa(props.documentId);
    const sharedLinkRef = useRef(null);
    const copyToClipboard = (e) => {
        sharedLinkRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };

    return (
        <>
            <div className={styles.FlexRow}>
                <h3>Share Link</h3>
                <Switch
                    checked={Boolean(Number(props.resumeVisibility))}
                    swichChangeHandler={(event) => props.onChangeResumeVisibility(props.token, Number(event.target.checked))}
                    name="resumeVisibility"
                />
            </div>
            <p>Create a public link to your {!props.isCoverLetter ? 'resume' : 'cover letter'} you can share with your friends and colleagues</p>
            {Boolean(Number(props.resumeVisibility)) && (
                <div className={styles.ShareLinkWrap}>
                    <p>Your {!props.isCoverLetter ? 'Resume' : 'Cover Letter'} Link</p>
                    <input
                        readOnly={true}
                        value={`${document.location.origin}/${!props.isCoverLetter ? 'r' : 'cl'}/${sharedLink}`}
                        ref={sharedLinkRef}
                        className={styles.ShareLinkInput}
                        onClick={copyToClipboard}
                    />
                </div>
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        resumeVisibility: state.auth.resumeVisibility,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeResumeVisibility: ( token, resumeVisibility ) => dispatch( actions.changeVisibility( token, resumeVisibility ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ShareItem );