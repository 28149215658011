import React from 'react';

import BerlinPdf from '../../components/PDFTemplates/BerlinPdf';
import TokyoPdf from '../../components/PDFTemplates/TokyoPdf';
import LondonPdf from '../../components/PDFTemplates/LondonPdf';
import ParisPdf from '../../components/PDFTemplates/ParisPdf';
import MadridPdf from '../../components/PDFTemplates/MadridPdf';
import HloomPdf from '../../components/PDFTemplates/HloomPdf';
import CleanBluePdf from '../../components/PDFTemplates/CleanBluePdf';
import CreativePdf from '../../components/PDFTemplates/CreativePdf';
import TraditionalPdf from '../../components/PDFTemplates/TraditionalPdf';
import DublinPdf from '../../components/PDFTemplates/DublinPdf';
import ModernBluePdf from '../../components/PDFTemplates/ModernBluePdf';
import ClassicPdf from '../../components/PDFTemplates/ClassicPdf';
import WoodlawnPdf from '../../components/PDFTemplates/WoodlawnPdf';
import MonospacedPdf from '../../components/PDFTemplates/MonospacedPdf';
import DarkRedPdf from '../../components/PDFTemplates/DarkRedPdf';
import BlankPdf from '../../components/PDFTemplates/BlankPdf';

import NoColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/NoColorsPdf';
import DifferentColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/DifferentColorsPdf';

function PDFTemplate(props) {
    const selectPdfTemplate = () => {
        if (!props.isCoverLetter) {
            switch(Number(props.document.templateId)) {
                case 1:   return <TokyoPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 2:   return <BerlinPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 3:   return <LondonPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 4:   return <ParisPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 5:   return <MadridPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 9:   return <HloomPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 10:  return <CleanBluePdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 11:  return <CreativePdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 12:  return <TraditionalPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 13:  return <DublinPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 14:  return <ModernBluePdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 15:  return <ClassicPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 16:  return <WoodlawnPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 17:  return <MonospacedPdf resume={props.document} resumeConfiguration={props.configuration} />;
                case 18:  return <DarkRedPdf resume={props.document} resumeConfiguration={props.configuration} />;
                default:  return <BlankPdf />;
            }
        } else {
            switch(Number(props.document.templateId)) {
                case 1:   return <NoColorsPdf coverLetter={props.document} coverLetterConfiguration={props.configuration} />;
                case 2:   return <DifferentColorsPdf coverLetter={props.document} coverLetterConfiguration={props.configuration} />;
                default:  return <BlankPdf />;
            }
        }
    }

    return selectPdfTemplate();
}

export default PDFTemplate;