import axios from 'axios';
import {apiUrl} from './config';

const instance = axios.create({
    //baseURL: 'https://resumeok-58869.firebaseio.com/',
    baseURL: `${apiUrl}/api/`,
    //baseURL: 'http://192.168.0.16/wsl-code/resumeok-api/public/api/',
    /* withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    } */
});

export default instance;