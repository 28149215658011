import React from 'react';
import Container from '@material-ui/core/Container';
import { NavLink } from 'react-router-dom';

import styles from './NotFoundPage.module.scss';
import notFoundBgImage from '../../../assets/images/404_bg.svg';
import notFoundImage from '../../../assets/images/404.svg';

function NotFoundPage() {
    return (
        <Container component="main" className={styles.Container} >
            <img src={notFoundImage}  alt="404" className={styles.NotFoundImage}/>
            <h3>Oops...it seems like the page you’re looking for has gone missing.</h3>
            <NavLink to="/" exact className={styles.Button}>
                Take me back
            </NavLink>
            <img src={notFoundBgImage} alt="page not found" className={styles.NotFoundBgImage} />
        </Container>
    );
}

export default NotFoundPage;