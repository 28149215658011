import React, {useState, useEffect, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../axios-resumes';
import { List } from 'react-content-loader';

import Tokyo from '../../components/ResumeTemplates/Tokyo/Tokyo';
import Berlin from '../../components/ResumeTemplates/Berlin/Berlin';
import London from '../../components/ResumeTemplates/London/London';
import Paris from '../../components/ResumeTemplates/Paris/Paris';
import Madrid from '../../components/ResumeTemplates/Madrid/Madrid';
import Hloom from '../../components/ResumeTemplates/Hloom/Hloom';
import CleanBlue from '../../components/ResumeTemplates/CleanBlue/CleanBlue';
import Creative from '../../components/ResumeTemplates/Creative/Creative';
import Traditional from '../../components/ResumeTemplates/Traditional/Traditional';
import Dublin from '../../components/ResumeTemplates/Dublin/Dublin';
import ModernBlue from '../../components/ResumeTemplates/ModernBlue/ModernBlue';
import Classic from '../../components/ResumeTemplates/Classic/Classic';
import Woodlawn from '../../components/ResumeTemplates/Woodlawn/Woodlawn';
import Monospaced from '../../components/ResumeTemplates/Monospaced/Monospaced';
import DarkRed from '../../components/ResumeTemplates/DarkRed/DarkRed';

import NoColors from '../../components/CoverLetterTemplates/NoColors';
import DifferentColors from '../../components/CoverLetterTemplates/DifferentColors';

import { getTransformedResume, getTransformedCoverLetter } from '../../shared/utility';

import styles from './DocumentPage.module.scss';

import bubblesBg from '../../assets/images/resume_bg/bubbles-bg.png';
import cubesBg from '../../assets/images/resume_bg/cubes-bg.png';
import dottedCirclesBg from '../../assets/images/resume_bg/dotted-circles-bg.png';
import gradientDotsBg from '../../assets/images/resume_bg/gradient-dots-bg.png';
import toysBg from '../../assets/images/resume_bg/toys-bg.png';
import wavesBg from '../../assets/images/resume_bg/waves-bg.png';


const patterns = {
    cubes: cubesBg,
    toys: toysBg,
    dottedCircles: dottedCirclesBg,
    bubbles: bubblesBg,
    gradientDots: gradientDotsBg,
    waves: wavesBg,
}

const DocumentPage = (props) => {
    const [document, setDocument] = useState({});
    const [configuration, setConfiguration] = useState({});
    const [err, setError] = useState(false);

    let { idHash } = useParams();

    //const resumeId = CryptoAES.decrypt(idHash.toString(), 'resumeok');
    let documentId = atob(idHash);

    useEffect(() => {
        let userId = props.userId ? btoa(props.userId) : '';

        if (!props.isCoverLetter) {
            let exampleApiUrl = props.successMessage === 'auth' ? `/example/resume/${documentId}/${userId}` : `/example/resume/${documentId}`;

            axios.get(exampleApiUrl)
            .then( response => {
                let document = getTransformedResume(response.data.resume);
                let configuration = response.data.resume.configuration;
                if (document) {
                    setDocument(document);
                }
                if (configuration) {
                    setConfiguration(configuration);
                }
            } )
            .catch( err => {
                if (props.successMessage === 'not-auth') {
                    setError(true);
                }
                console.log(err);
            } );
        } else {            
            let exampleApiUrl = props.successMessage === 'auth' ? `/example/cover-letters/${documentId}/${userId}` : `/example/cover-letters/${documentId}`;

            axios.get(exampleApiUrl)
            .then( response => {
                let document = getTransformedCoverLetter(response.data.cover_letter);
                let configuration = response.data.cover_letter.configuration;
                if (document) {
                    setDocument(document);
                }
                if (configuration) {
                    setConfiguration(configuration);
                }
            } )
            .catch( err => {
                if (props.successMessage === 'not-auth') {
                    setError(true);
                }
                console.log(err);
            } );
        }
    }, [props.successMessage, props.userId] );


    const documentViewerRef = useRef(null);

    const [contentHeight, setContentHeight] = useState(0);
    /* useEffect(() => {
        
        if (dataLoaded && resumeViewerRef.current) {
            //document.onreadystatechange = () => {
            setTimeout(() => {
                let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height;
                setContentHeight(resumeHeight);
            }, 100);
            //}
        };
    }, [dataLoaded, resume]); */

    /* useEffect(() => {
        
        if (resumeViewerRef.current) {
            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height;
                    setContentHeight(resumeHeight);
                }
            }
        };
    }, [resume]); */

    useEffect(() => {
        if (documentViewerRef.current) {
            setTimeout(() => {
                let documentHeight = documentViewerRef.current.getBoundingClientRect().height;
                setContentHeight(documentHeight);
            }, 300);
        }
    }, [document]);

    const selectResumeTemplate = () => {
        if (!props.isCoverLetter) {
            switch(Number(document.templateId)) {
                case 1:   return <Tokyo resume={document} resumeConfiguration={configuration} />;
                case 2:   return <Berlin resume={document} resumeConfiguration={configuration} />;
                case 3:   return <London resume={document} resumeConfiguration={configuration} />;
                case 4:   return <Paris resume={document} resumeConfiguration={configuration} />;
                case 5:   return <Madrid resume={document} resumeConfiguration={configuration} />;
                case 9:   return <Hloom resume={document} resumeConfiguration={configuration} />;
                case 10:  return <CleanBlue resume={document} resumeConfiguration={configuration} />;
                case 11:  return <Creative resume={document} resumeConfiguration={configuration} />;
                case 12:  return <Traditional resume={document} resumeConfiguration={configuration} />;
                case 13:  return <Dublin resume={document} resumeConfiguration={configuration} />;
                case 14:  return <ModernBlue resume={document} resumeConfiguration={configuration} />;
                case 15:  return <Classic resume={document} resumeConfiguration={configuration} />;
                case 16:  return <Woodlawn resume={document} resumeConfiguration={configuration} />;
                case 17:  return <Monospaced resume={document} resumeConfiguration={configuration} />;
                case 18:  return <DarkRed resume={document} resumeConfiguration={configuration} />;
                default:  return <div style={{padding: '35px 20px', backgroundColor: '#fff', flexGrow: '1', minHeight: '17in' }}><List /><List /><List /></div>;
            } 
        } else {
            switch(Number(document.templateId)) {
                case 1:   return <NoColors coverLetter={document} coverLetterConfiguration={configuration} />;
                case 2:   return <DifferentColors coverLetter={document} coverLetterConfiguration={configuration} />;
                default:  return <div style={{padding: '35px 20px', backgroundColor: '#fff', flexGrow: '1', minHeight: '17in' }}><List /><List /><List /></div>;
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{`${document.firstName ? document.firstName : ''} ${document.lastName ? document.lastName : ''} ${props.isCoverLetter ? 'Cover Letter' : 'Resume'}`}</title>
                <meta name="description" content={document.careerSummary ? document.careerSummary.replace(/<\/?[^>]+(>|$)/g, "") : ''} />
            </Helmet>
            {err ? (<Redirect to="/"/>) : (<></>)}
            <div style={{position: 'relative', minHeight: contentHeight}}>
                <div id="document-viewer" className={styles.Document} ref={documentViewerRef}>
                    {configuration.pattern && (<div className={styles.BgTop} style={{backgroundImage: `url(${patterns[configuration.pattern]})`}}></div>)}
                    {selectResumeTemplate()}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        successMessage: state.auth.successMessage,
    };
};

export default connect( mapStateToProps, null )( DocumentPage );