import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });

// Create Document Component
const HloomPdf = (props) => {
    
    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });

    //Font.registerHyphenationCallback(word => word.length === 1 ? [word] : Array.from(word).map(char => char));

    const mainColor = props.resumeConfiguration.primary_color || '#2196F3';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12 * multiplier;
    const headerTitleSize = 34.5 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const sectionTitleSize = 15 * multiplier;
    const repeatedTitleSize = 14 * multiplier;

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const languagesPercentages = {
        'Native Speaker': 100, 
        'Highly proficient': 80, 
        'Very good command': 60, 
        'Good working knowledge': 40,
        'Working knowledge': 20, 
        'C2': 100, 
        'C1': 100, 
        'B2': 80, 
        'B1': 60, 
        'A2': 40, 
        'A1': 20
    }

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
            position: 'relative',
            wordBreak: 'break-all',
        },
        table: {
            fontSize: mainSize,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            marginBottom: 50,
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            //alignContent: "stretch",
            flexWrap: "wrap",
            alignItems: "flex-start",
        },
        header: {
            position: 'relative',
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            paddingTop: 0,
            paddingBottom: 0,
            paddingHorizontal: 20,
            color: '#000',
            lineHeight: 1.3,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 0,
            width: "75%",
            paddingLeft: 7,
        },
        userImage: {
            width: '40mm',
            height: '40mm',
            objectFit: 'cover',
            flexGrow: 0,
            flexShrink: 0,
            borderColor: mainColor,
            borderRadius: '20mm',
            borderWidth: 3,
            marginRight: 10,
        },
        title: {
            fontSize: headerTitleSize,
            color: mainColor,
            lineHeight: 1.2,
            marginBottom: 0,
        },
        subtitle: {
            fontSize: headerSubtitleSize,
            marginBottom: 5,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
        },
        socialLink: {
            color: '#000',
            textDecoration: 'none',
            alignSelf: 'center',
        },
        socialIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 24,
            height: 24,
            marginRight: 6,
        },
        contactItem: {
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            marginVertical: 2,
        },
        contactLink: {
            color: 'inherit',
            textDecoration: 'none',
            alignSelf: 'center',
        },
        contactTitle: {
            fontWeight: 700,
            marginRight: 6,
            color: mainColor,
        },
        main: {
            flex: '1 1 100%',
            paddingTop: 25,
            paddingHorizontal: 20,
        },
        description: {
            lineHeight: 1.3,
            marginTop: 7,
        },
        careerSummary: {
            marginTop: 0,
        },
        sectionWrap: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        sectionTitle: {
            color: mainColor,
            textTransform: 'uppercase',
            fontSize: sectionTitleSize,
            lineHeight: 1.2,
            fontWeight: 'bold',
        },
        sectionTitleWrap: {
            textAlign: 'right',
            flex: '0 1 25%',
            /* alignSelf: 'flex-start', */
            width: '25%',
            //maxWidth: '145px',
            paddingRight: 7,
            paddingBottom: 22, 
            borderRightWidth: 1,
            borderColor: '#c2c1c1',
        },
        sectionContent: {
            position: 'relative',
            flex: '0 1 75%',
            width: '75%',
            maxWidth: '75%',
            marginLeft: 'auto',
            paddingLeft: 11,
            paddingBottom: 22,
            zIndex: 1,
        },
        sectionContentBorder: {
            position: 'absolute',
            top: 0,
            left: -6,
            width: 1,
            height: '100%',
            borderColor: '#c2c1c1',
            backgroundColor: '#c2c1c1',
            zIndex: 100,
        },
        sectionContentBefore: {
            position: 'absolute',
            top: 0,
            left: -5,
            width: 12,
            height: 12,
            borderWidth: 1.5,
            borderColor: '#c2c1c1',
            backgroundColor: '#fff',
            borderRadius: 6,
            zIndex: 100,
        },
        workBefore: {
            position: 'absolute',
            top: 0,
            left: -17,
            width: 12,
            height: 12,
            borderWidth: 1.5,
            borderColor: '#c2c1c1',
            backgroundColor: '#fff',
            borderRadius: 6,
            zIndex: 100,
        },
        repeatedItem: {
            paddingBottom: 7,
        },
        repeatedItemTitle: {
            fontSize: repeatedTitleSize,
            lineHeight: 1.2,
            fontWeight: 700,
            flex: '1 0 70%',
            width: '70%',
        },
        workTitle: {
            textTransform: 'uppercase',
            color: mainColor,
        },
        repeatedItemSubtitle: {
            fontWeight: 700,
        },
        repeatedTitleWrap: {
            display: 'flex',
            flexDirection: "row",
            justifyContent: 'space-between',
        },
        dateText: {
            fontStyle: 'italic',
        },
        dateTextWithTitle: {
            width: '30%',
            minWidth: '30%',
            flex: '0 0 30%',
            textAlign: 'right',
        },
        skillLevel: {
            height: 15,
            marginTop: 5,
            width: '90%',
        },
        skillItem: {
            position: 'relative',
            width: '47%',
            maxWidth: '47%',
            flex: '0 0 47%',
        },
        languageLevel: {
            height: 15,
            marginTop: 5,
            width: '90%',
        },
        languageItem: {
            position: 'relative',
            width: '47%',
            maxWidth: '47%',
            flex: '0 0 47%',
        },
        strong: {
            fontWeight: 700,
        },
        delimeter: {
            marginHorizontal: 3,
        }
    /*     itemAfter: {
            width: 2,
            height: '100%',
            position: 'absolute',
            top: 14,
            left: 0,
        }, */
    });

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, '#000', 'bullet'));
        }
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.facebook} style={styles.socialLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.linkedin} style={styles.socialLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.twitter} style={styles.socialLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.instagram} style={styles.socialLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.github} style={styles.socialLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.socialIcon}
                />
                <Link href={socials.youtube} style={styles.socialLink}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
            <View style={styles.sectionWrap}>
                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</Text></View>
                <View style={[styles.sectionContent, styles.row]}>
                    {props.resume.skillsFields.map((skillItem, index) => 
                        (skillItem.skill) ? (
                            <View style={[styles.repeatedItem, styles.skillItem]} wrap={false}>
                                <Text key={index} style={styles.skillTitle}>{skillItem.skill}</Text>
                                {Boolean(Number(props.resume.skillsVisibility) && skillItem.level) ? (
                                    <Canvas
                                        paint={(painter, availableWidth) => {
                                            let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                            painter.roundedRect(0, 0, availableWidth, 8, 5).lineWidth(1.5).strokeColor(mainColor).stroke();
                                            painter.roundedRect(0, 0, percentageValue, 8, 5).fillColor(mainColor).fill();
                                        }}
                                        style={styles.skillLevel}
                                    />
                                ) : (<></>)}
                            </View>
                        ) : <></>
                    )}
                    <View style={styles.sectionContentBefore}></View>
                </View>
            </View>
    ) : <></>;

    return (
        <Document>
            <Page style={styles.body}>
                {addPdfPattern(props.resumeConfiguration.pattern)}
                <View style={styles.table}>
                    <View style={styles.header}>
                        {props.resume.userImage ? (
                            <Image 
                                style={styles.userImage} 
                                src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            />
                        ) : <></>}

                        <View style={styles.headerText}>
                            <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                            <Text style={styles.subtitle}>{props.resume.jobPosition}</Text>

                            {props.resume.phoneNumber || props.resume.email ? (<View style={styles.contactItem}>
                                <Text style={styles.contactValue}>{props.resume.phoneNumber}</Text>
                                {props.resume.phoneNumber && props.resume.email ? (<Text style={styles.delimeter}>|</Text>) : <></>}
                                <Link src={`mailto:${props.resume.email}`} style={styles.contactLink}><Text style={styles.strong}>E:</Text> {props.resume.email}</Link>
                            </View>) : <></>}

                            {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) ? (<View style={styles.contactItem}>
                                <Text style={styles.contactValue}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                            </View>) : <></>}

                            {props.resume.nationality ? (<View style={styles.contactItem}><Text style={styles.contactTitle}>Nationality</Text><Text style={styles.contactValue}>{props.resume.nationality}</Text></View>) : <></>}
                            {props.resume.birthPlace ? (<View style={styles.contactItem}><Text style={styles.contactTitle}>Birth Place</Text><Text style={styles.contactValue}>{props.resume.birthPlace}</Text></View>) : <></>}
                            {props.resume.birthDate ? (<View style={styles.contactItem}><Text style={styles.contactTitle}>Birth Date</Text><Text style={styles.contactValue}>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text></View>) : <></>}
                            {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<View style={styles.contactItem}><Text style={styles.contactTitle}>Driving license</Text><Text style={styles.contactValue}>{props.resume.drivingLicenseType.join(', ')}</Text></View>) : <></>}
                            {props.resume.websiteSocialFields.map((socialItem, index) => (
                                (socialItem.link || socialItem.name) ? (
                                    <View key={index} style={styles.contactItem}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.9, .9).fillColor(mainColor).path('M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z').fill();
                                            }}
                                            style={styles.socialIcon}
                                        />
                                        <Link href={socialItem.link} style={styles.contactLink}>{socialItem.name ? socialItem.name : socialItem.link}</Link>
                                    </View>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : <></>}

                        </View>
                    </View>

                    <View style={styles.main}>
                        
                        {props.resume.careerSummary ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Professional Summary'}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    <View style={[styles.description, styles.careerSummary]}>
                                        {generateTinyMceElements(props.resume.careerSummary)}
                                    </View>
                                </View>
                            </View>) : <></>}

                        {resumeType === 'functional' ? skillsSection : <></>}
                        
                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Work History'}</Text></View>
                                <View style={styles.sectionContent}>
                                    {props.resume.workExperienceItems.map((workItem, index) => (
                                        <View key={workItem.id} style={styles.repeatedItem}>
                                            <View style={[styles.workSectionContentBefore, styles.workBefore]}></View>
                                            <View style={styles.repeatedTitleWrap}>
                                                {workItem.jobTitle ? (<Text style={[styles.repeatedItemTitle, styles.workTitle]}>{workItem.jobTitle}</Text>) : <></>}
                                                {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                                    <Text style={[styles.dateText, workItem.jobTitle ? styles.dateTextWithTitle : '']}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY", " to ")}</Text>
                                                ) : <></>}
                                            </View>                                                
                                            {(workItem.company || workItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{workItem.company}{workItem.company && workItem.city ? ' | ' : ''}{workItem.city}</Text>) : <></>}
                                            {workItem.description ? (
                                            <View style={[styles.description, styles.workDescription]}>
                                                {generateTinyMceElements(workItem.description)}
                                            </View>) : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {resumeType === 'chronological' ? skillsSection : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length  ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {props.resume.educationFields.map((educationItem, index) => (
                                        <View key={educationItem.id} style={[styles.repeatedItem, styles.educationItem]}>
                                            <View style={styles.repeatedTitleWrap}>
                                                {educationItem.degree ? (<Text style={styles.repeatedItemTitle}>{educationItem.degree}</Text>) : <></>}
                                                {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                                    <Text style={[styles.dateText, educationItem.degree ? styles.dateTextWithTitle : '']}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY", " to ")}</Text>
                                                ) : <></>}
                                            </View>                                                
                                            {(educationItem.institution || educationItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{educationItem.institution}{educationItem.institution && educationItem.city ? ' | ' : ''}{educationItem.city}</Text>) : <></>}
                                            {educationItem.description ? (
                                            <View style={[styles.description, styles.educationDescription]}>
                                                {generateTinyMceElements(educationItem.description)}
                                            </View>) : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}
                        
                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {props.resume.internshipFields.map(internshipItem => (
                                        <View key={internshipItem.id} style={styles.repeatedItem}>
                                            <View style={styles.repeatedTitleWrap}>
                                                {internshipItem.jobTitle ? (<Text style={styles.repeatedItemTitle}>{internshipItem.jobTitle}</Text>) : <></>}
                                                {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                                    <Text style={[styles.dateText, internshipItem.jobTitle ? styles.dateTextWithTitle : '']}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY", " to ")}</Text>
                                                ) : <></>}
                                            </View>                                                
                                            {(internshipItem.employer || internshipItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{internshipItem.employer}{internshipItem.employer && internshipItem.city ? ' | ' : ''}{internshipItem.city}</Text>) : <></>}
                                            {internshipItem.description ? 
                                            (<View style={styles.description}>
                                                {generateTinyMceElements(internshipItem.description)}
                                            </View>) : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {props.resume.coursesFields.map(courseItem => (
                                        <View key={courseItem.index} style={styles.repeatedItem}>
                                            <View style={styles.repeatedTitleWrap}>
                                                {courseItem.course ? (<Text style={styles.repeatedItemTitle}>{courseItem.course}</Text>) : <></>}
                                                {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                                    <Text style={[styles.dateText, courseItem.course ? styles.dateTextWithTitle : '']}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY", " to ")}</Text>
                                                ) : <></>}
                                            </View>                                                
                                            {courseItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{courseItem.institution}</Text>) : <></>}
                                            {courseItem.description ? 
                                            (<View style={styles.description}>
                                                {generateTinyMceElements(courseItem.description)}
                                            </View>)/* (<Text>{courseItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}
                        
                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                        props.resume.referencesFields.map(referenceItem => (
                                            <View key={referenceItem.id} style={styles.repeatedItem}>
                                                {referenceItem.referent ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}</Text>) : <></>}
                                                {referenceItem.company ? (<Text style={styles.repeatedItemSubtitle}>{referenceItem.company}</Text>) : <></>}
                                                <Text>
                                                    <Link style={styles.link} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}
                                                </Text>
                                                {referenceItem.description ? 
                                                (<View style={styles.description}>
                                                    {generateTinyMceElements(referenceItem.description)}
                                                </View>)/* (<Text>{referenceItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                            </View>
                                        )) : (<Text>References available upon request</Text>)}
                                </View>
                            </View>
                        ) : <></>}
                        
                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</Text></View>
                                <View style={[styles.sectionContent, styles.row]}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {props.resume.languagesFields.map((languageItem, index) =>
                                            (languageItem.language) ? (
                                                <View style={[styles.repeatedItem, styles.languageItem]} wrap={false}>
                                                    <Text key={index} style={styles.languageTitle}>{languageItem.language}</Text>
                                                    {languageItem.level ? (
                                                        <Canvas
                                                            paint={(painter, availableWidth) => {
                                                                let percentageValue = languagesPercentages[languageItem.level] * availableWidth / 100;
                                                                painter.roundedRect(0, 0, availableWidth, 8, 5).lineWidth(1.5).strokeColor(mainColor).stroke();
                                                                painter.roundedRect(0, 0, percentageValue, 8, 5).fillColor(mainColor).fill();
                                                            }}
                                                            style={styles.languageLevel}
                                                        />
                                                    ) : (<></>)}
                                                </View>
                                            ) : <></>
                                    )}
                                </View>
                            </View>
                        ) : <></>}
                            
                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}><Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text></View>
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionContentBefore}></View>
                                    {props.resume.customFields.map(customItem => (
                                        <View key={customItem.id} style={styles.repeatedItem}>
                                            <View style={styles.repeatedTitleWrap}>
                                                {customItem.customItemTitle ? (<Text style={styles.repeatedItemTitle}>{customItem.customItemTitle}</Text>) : <></>}
                                                {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                                    <Text style={[styles.dateText, customItem.customItemTitle ? styles.dateTextWithTitle : '']}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY", " to ")}</Text>
                                                    ) : <></>}
                                            </View>
                                            {customItem.city ? (<Text style={styles.repeatedItemSubtitle}>{customItem.city}</Text>) : <></>}
                                            {customItem.description ? 
                                            (<View style={styles.description}>
                                                {generateTinyMceElements(customItem.description)}
                                            </View>) : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                    </View>

                </View>
            </Page>
        </Document>
    );
}

export default HloomPdf;