import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import UserIcon from '../../assets/images/template_icons/icons8-user-40.png';
import WorkIcon from '../../assets/images/template_icons/icons8-briefcase-40.png';
import EducationIcon from '../../assets/images/template_icons/icons8-university-40.png';
import ReferencesIcon from '../../assets/images/template_icons/icons8-megaphone-40.png';
import CustomIcon from '../../assets/images/template_icons/icons8-medium-icons-40.png';
import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

const skillsPercentages = {
    'novice': 20,
    'beginner': 40,
    'skillful': 60,
    'experienced': 80,
    'expert': 100
}

const languagesPercentages = {
    'Native Speaker': 100, 
    'Highly proficient': 80, 
    'Very good command': 60, 
    'Good working knowledge': 40,
    'Working knowledge': 20, 
    'C2': 100, 
    'C1': 100, 
    'B2': 80, 
    'B1': 60, 
    'A2': 40, 
    'A1': 20
}

const showUrl = (url) => {
    let croppedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    return wordWrap(croppedUrl, 20);
}

const wordWrap = (str, maxWidth) => {
    let newLineStr = "\n";
    let res = '';
    while (str && str.length > maxWidth) {                 
        let found = false;
        // Inserts new line at first whitespace of the line
        for (let i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res = res + [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }

    }

    return res + str;
}

const testWhite = (x) => {
    let white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
};

// Create Document Component
const TokyoPdf = (props) => {
    
//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });
    // Create styles
    /* Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', fontWeight: 600 },
            /* { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf', fontStyle: 'italic' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600italic.ttf', fontWeight: 600, fontStyle: 'italic' }, */
    /*     ]
    }); */

    /* Font.register({ family: 'Bree Serif', fonts: [{src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Dancing Script', fonts: [{src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Lato', fonts: [{src: 'https://fonts.googleapis.com/css2?family=Lato&display=swap', fontWeight: 400 }] } );
    Font.register({ family: 'Open Sans', fonts: [{src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Overpass', fonts: [{src: 'https://fonts.gstatic.com/s/overpass/v5/qFdH35WCmI96Ajtm82Gi.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Quicksand', fonts: [{src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Raleway', fonts: [{src: 'https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Roboto', fonts: [{src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Rubik', fonts: [{src: 'https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Rufina', fonts: [{src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6V-LyURyLy-aKyow.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Source Serif Pro', fonts: [{src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Ubuntu', fonts: [{src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf', fontWeight: 400 }] } );
    Font.register({ family: 'Yellowtail', fonts: [{src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf', fontWeight: 400 }] } );
    //Font.register({ family: 'Marat Sans', fonts: [{src: 'https://fonts.cdnfonts.com/s/16213/Marat Sans Regular.woff', fontWeight: 400 }] } );
    */
    
    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });
    
    
    const mainColor  = props.resumeConfiguration.primary_color || '#4F91CA';
    const iconColor = props.resumeConfiguration.secondary_color || '#9EC7EE';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'medium';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 11.5 * multiplier;
    const headerTitleSize = 24 * multiplier;
    const headerSubtitleSize = 14 * multiplier;
    const dateTextSize = 11 * multiplier;
    const sectionTitleSize = 17 * multiplier;
    const sideTitleSize = 12 * multiplier;

    const styles = StyleSheet.create({
        body: {
            /* paddingTop: 27,
            paddingBottom: 30, */
            paddingVertical: 25,
            paddingHorizontal: 20,
            fontFamily: fontFamily
        },
        table: {
            fontSize: mainSize,
            lineHeight: 1.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            /*flexGrow: 0,
            flexShrink: 0,
            flexBasis: 35*/
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        headerTitle: {
            fontSize: headerTitleSize,
            fontWeight: 700,
        },
        headerSubtitle: {
            fontSize: headerSubtitleSize,
        },
        header: {
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 20,
        },
        userImage: {
            width: '20mm',
            height: '20mm',
            objectFit: 'cover',
            borderRadius: '2mm',
            flexGrow: 0,
            marginRight: 10,
        },
        mainContentCell: {
            display: "flex",
            flexDirection: "column",/* 
            justifyContent: "stretch",
            alignContent: "stretch", */
            flexWrap: "nowrap",
            alignItems: "stretch",
            maxWidth: '60%',
            width: '60%',
            lineHeight: 1.3,
        },
        detailsContentCell: {
            maxWidth: '27%',
            width: '27%',
        },
        sectionWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: 5,
        },
        contentSection: {
            marginBottom: 7,
        },
        sectionIcon: {
            width: 12,
            height: 12,
            objectFit: 'contain',
            marginTop: 5,
            marginRight: 7,
        },
        educationIcon: {
            width: 14,
            marginRight: 6,
        },
        sectionTitle: {
            display: 'block',
            fontSize: sectionTitleSize,
            fontWeight: 700,
        },
        repeatedItem: {
            marginBottom: 5,
        },
        sideItem: {
            marginBottom: 15,
            maxWidth: '100%',
        },
        dateText: {
            color: '#8190AA',
            fontSize: dateTextSize,
        },
        sideTitle: {
            fontSize: sideTitleSize,
            fontWeight: 700,
            marginBottom: 3,
        },
        sideSubtitle: {
            color: '#8190AA',
            marginTop: 5,
            marginBottom: 2,
        }, 
        progressLine: {
            width: '100%',
            height: 10,
            marginBottom: 5,
        },
        paragraph: {
            marginBottom: 0,
        },
        list: {
            paddingLeft: 10,
            paddingVertical: 5,
        },
        contactItem: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: 5,
            maxWidth: '100%',
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 15,
            height: 15,
            marginRight: 6,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
        },
    });
    

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, '#000', 'bullet'));
        }
    }
    
    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.link}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.link}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.link}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.link}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.link}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.link}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }


    return (
    <Document>          
        <Page style={styles.body}>
            {addPdfPattern(props.resumeConfiguration.pattern)}
            <View style={styles.table}>
                <View style={[styles.row, styles.header]}>
                    {/* <Image
                        style={[styles.userImage, styles.cell]}
                        src={`http://resumeapi.justfortest-staging1.com/storage/` + props.resume.userImage}
                    /> */}
                    {props.resume.userImage ? (
                        <Image 
                            style={styles.userImage} 
                            src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            /* allowDangerousPaths={true}
                            safePath={"https://resumeapi.justfortest-staging1.com/storage/"} */
                        />
                    ) : <></>}
                
                    <View style={styles.headerText}>
                        <Text style={styles.headerTitle}>{props.resume.firstName} {props.resume.lastName}</Text>
                        <Text style={styles.headerSubtitle}>{props.resume.jobPosition}</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.mainContentCell}>
                        {props.resume.careerSummary ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={styles.sectionIcon}
                                    src={UserIcon}
                                />
                                <View style={styles.contentSection}>
                                    <Text style={styles.sectionTitle}>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</Text>
                                    {generateTinyMceElements(props.resume.careerSummary)}
                                    {/* <Text>{props.resume.careerSummary.replace(/<[^>]+>/g, '')}</Text> */}
                                </View>
                            </View>
                        ) : <></>}
                        
                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={styles.sectionIcon}
                                    src={WorkIcon}
                                />
                                <View style={styles.contentSection}>                                
                                    {props.resume.experienceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Work Experience</Text>)}
                                    {props.resume.workExperienceItems.map(workItem => (
                                        <View style={styles.repeatedItem} key={workItem.id}>
                                            {(workItem.jobTitle || workItem.company || workItem.city) ? (<Text>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ' at ' : ''} {workItem.company}{((workItem.jobTitle || workItem.company) && workItem.city ) ? ', ' : ''}{workItem.city}</Text>) : (<></>)}
                                            {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {workItem.description ? generateTinyMceElements(workItem.description)/* (<Text>{workItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={[styles.sectionIcon, styles.educationIcon]}
                                    src={EducationIcon}
                                />
                                <View style={styles.contentSection}>
                                    {props.resume.educationSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.educationSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Education</Text>)}
                                    {props.resume.educationFields.map(educationItem => (
                                        <View style={styles.repeatedItem} key={educationItem.id}>
                                            {(educationItem.degree || educationItem.institution || educationItem.city) ? (<Text>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ' at ' : ''}{educationItem.institution}{((educationItem.degree || educationItem.institution) && educationItem.city ) ? ', ' : ''}{educationItem.city}</Text>) : (<></>)}
                                            {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {educationItem.description ? generateTinyMceElements(educationItem.description)/* (<Text>{educationItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={styles.sectionIcon}
                                    src={WorkIcon}
                                />
                                <View style={styles.contentSection}>                                
                                    {props.resume.internshipSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Internship</Text>)}
                                    {props.resume.internshipFields.map(internshipItem => (
                                        <View style={styles.repeatedItem} key={internshipItem.id}>
                                            {(internshipItem.jobTitle || internshipItem.employer || internshipItem.city) ? (<Text>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ' at ' : ''}{internshipItem.employer}{((internshipItem.jobTitle || internshipItem.employer) && internshipItem.city ) ? ', ' : ''}{internshipItem.city}</Text>) : (<></>)}
                                            {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {internshipItem.description ? generateTinyMceElements(internshipItem.description)/* (<Text>{internshipItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={[styles.sectionIcon, styles.educationIcon]}
                                    src={EducationIcon}
                                />
                                <View style={styles.contentSection}>
                                    {props.resume.courseSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.courseSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Courses</Text>)}
                                    {props.resume.coursesFields.map(courseItem => (
                                        <View style={styles.repeatedItem} key={courseItem.id}>
                                            {(courseItem.course || courseItem.institution) ? (<Text>{courseItem.course}{(courseItem.course && courseItem.institution) ? ' at ' : ''}{courseItem.institution}</Text>) : (<></>)}
                                            {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {courseItem.description ? generateTinyMceElements(courseItem.description)/* (<Text>{courseItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={styles.sectionIcon}
                                    src={ReferencesIcon}
                                />
                                <View style={styles.contentSection}>
                                    {props.resume.referenceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>References</Text>)}
                                    {   !Boolean(Number(props.resume.referencesVisibility)) ? 
                                        props.resume.referencesFields.map(referenceItem => (
                                            <View style={styles.repeatedItem} key={referenceItem.id}>
                                                {(referenceItem.referent || referenceItem.company) ? (<Text>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ' from ' : ''}{referenceItem.company}</Text>) : (<></>)}
                                                {(referenceItem.email || referenceItem.phone) ? (<Text><Link style={styles.link} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}</Text>) : (<></>)}
                                                {referenceItem.description ? generateTinyMceElements(referenceItem.description)/* (<Text>{referenceItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                            </View>
                                        )) : (<Text>References available upon request</Text>)}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Image
                                    style={styles.sectionIcon}
                                    src={CustomIcon}
                                />
                                <View style={styles.contentSection}>
                                    {props.resume.customSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text>) : (<></>)}
                                
                                    {props.resume.customFields.map(customItem => (
                                        <View style={styles.repeatedItem} key={customItem.id}>
                                            {(customItem.customItemTitle || customItem.city) ? (<Text>{customItem.customItemTitle}{(customItem.customItemTitle && customItem.city) ? ', ' : ''}{customItem.city}</Text>) : (<></>)}
                                            {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {customItem.description ? generateTinyMceElements(customItem.description)/* (<Text>{customItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    ))}
                                </View>
                            </View>
                        ) : <></>}
                        
                    </View>

                    <View style={styles.detailsContentCell} /* fixed */>
                        <View style={styles.sideItem}>
                            <Text style={styles.sideTitle}>Details</Text>
                            <Text>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                            {props.resume.phoneNumber ? (<Text>{props.resume.phoneNumber}</Text>) : <></>}
                            <Link style={styles.link} src={`mailto:${props.resume.email}`}>{wordWrap(props.resume.email, 20)}</Link>

                            {(props.resume.birthPlace || props.resume.birthDate) ? (
                                <>
                                    <Text style={styles.sideSubtitle}>{props.resume.birthDate ? 'Date' : ''}{props.resume.birthDate && props.resume.birthPlace ? ' / ' : ''}{props.resume.birthPlace ? 'Place' : ''} of birth</Text>
                                    {props.resume.birthDate ? (<Text>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                                    {props.resume.birthPlace ? (<Text>{props.resume.birthPlace}</Text>) : <></>}
                                </>
                            ) : (<></>)}

                            {props.resume.nationality ? (
                                <>
                                    <Text style={styles.sideSubtitle}>Nationality</Text>
                                    <Text>{props.resume.nationality}</Text>
                                </>
                            ) : (<></>)}
                            
                            {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (
                                <>
                                    <Text style={styles.sideSubtitle}>Driving license</Text>
                                    <Text>{props.resume.drivingLicenseType.join(', ')}</Text>
                                </>
                            ) : (<></>)}
                        </View>

                        {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                            <View style={styles.sideItem}>
                                {props.resume.socialSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.socialSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Links</Text>)}
                                {props.resume.websiteSocialFields.map(socialItem => (
                                    (socialItem.link || socialItem.name) ? (
                                        <Text key={socialItem.id} style={styles.contactItem}><Link style={styles.link} src={socialItem.link}>{socialItem.name}</Link></Text>
                                    ) : (<></>)
                                ))}
                                {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                            </View>
                        ) : <></>}
                        
                        {props.resume.skillsFields && props.resume.skillsFields.length ? (
                            <View style={styles.sideItem}>
                                {props.resume.skillSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.skillSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Skills</Text>)}
                                {props.resume.skillsFields.map(skillItem => 
                                    (<View key={skillItem.id} wrap={false}>
                                        {skillItem.skill ? (<Text>{skillItem.skill}</Text>) : (<></>)}
                                        {(skillItem.skill && skillItem.level && Boolean(Number(props.resume.skillsVisibility))) ? (
                                            <Canvas
                                                paint={(painter, availableWidth) => {
                                                    let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(2).strokeColor(mainColor).stroke();
                                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(mainColor).fill();
                                                }}
                                                style={styles.progressLine}
                                            />
                                        ) : (<></>)}
                                    </View>)
                                )}
                            </View>
                        ) : <></>}

                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sideItem}>
                                {props.resume.languageSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.languageSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Languages</Text>)}
                                {props.resume.languagesFields.map(languageItem => 
                                    (<View key={languageItem.id} wrap={false}>
                                        {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                        {languageItem.language && languageItem.level ? (<Canvas
                                                paint={(painter, availableWidth) => {
                                                    let percentageValue = languagesPercentages[languageItem.level] * availableWidth / 100;
                                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(2).strokeColor(mainColor).stroke();
                                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(mainColor).fill();
                                                }}
                                                style={styles.progressLine}
                                            />) : (<></>)
                                        }
                                    </View>)
                                )}
                            </View>
                        ) : <></>}

                    </View>
                    
                </View>
            </View>
        </Page>
    </Document>)
};

export default TokyoPdf;