import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { NavLink , Redirect } from 'react-router-dom';

import styles from './Login.module.scss';

import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import Alert from '@material-ui/lab/Alert';

import { Container, Snackbar, Link } from '@material-ui/core';

import loginImage from '../../assets/images/login_bg.svg';

import * as actions from '../../store/actions/index';
import { checkValidity } from '../../shared/utility';

function Login(props) {

    const [values, setValues] = useState({
        controls: {
            email: {
                elementConfig: {
                    type: 'email',
                    name: 'E-mail',
                    label: 'E-mail (your login email)'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
            password: {
                elementConfig: {
                    type: 'password',
                    name: 'Password',
                    label: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            }
        },
    });

    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);

    useEffect(() => {
        props.onAuthInit();
    }, [] );

    useEffect(() => {
        if (props.error) {
            setErrorMessageOpen(true);
        } else {
            setErrorMessageOpen(false);
        }
    }, [props.error] );

    const handleChange = (prop) => (event) => {
        const updatedControls = {
            ...values.controls,
            [prop]: {
                ...values.controls[prop],
                value: event.target.value,
                valid: checkValidity( event.target.value, values.controls[prop].validation ).valid,
                validationMessage: checkValidity( event.target.value, values.controls[prop].validation ).validationMessage,
                /* validation: {
                    message: checkValidity( event.target.value, values.controls[prop].validation ).validationMessage,
                }, */
                touched: true
            }
        };
        setValues({ ...values, controls: updatedControls });
    };

    const submitHandler = ( event ) => {
        event.preventDefault();

        if (validateForm(values.controls)) {
            props.onAuth(values.controls.email.value, values.controls.password.value );
        } else {
            console.log('Invalid Form');
        }
    }

    let errorMessage = null;

    if (props.error) {
        errorMessage = (
            <Alert severity="error">{props.error}</Alert>
        );
    }

    const  [authRedirect, setAuthRedirect] = useState(null);
    useEffect(() => {
        if (props.isAuthenticated) {
            setAuthRedirect(<Redirect to="/dashboard" />);
        }
    }, [props.isAuthenticated]);

    //Generate Form Inputs
    const formElementsArray = [];
    for ( let key in values.controls ) {
        formElementsArray.push( {
            id: key,
            config: values.controls[key]
        } );
    }
    let formInputs = formElementsArray.map( formElement => (
        <Input
            variant="outlined"
            key={formElement.id}
            id={formElement.id}
            type={formElement.config.elementConfig.type}
            name={formElement.config.elementConfig.name}
            label={formElement.config.elementConfig.label}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            validationMessage={formElement.config.validationMessage}
            touched={formElement.config.touched}
            classes={styles.Input}
            changed={handleChange(formElement.id)}
        />
    ) );

    // Form Validation
    const validateForm = (controls) => {
        let valid = true;
        
        let updatedControls = {
            ...values.controls,
        };

        Object.keys(controls).forEach(
            // if we have an error string set valid to false
            (key) => {
                console.log(values.controls[key].valid);

                updatedControls[key] = {
                    ...values.controls[key],
                    touched: true
                };

                return !values.controls[key].valid && (valid = false)
            }
        );
        
        setValues({ ...values, controls: updatedControls });
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Sign In - ResumeOK</title>
            </Helmet>
            <Container component="main" className={styles.Container}>
                <div className={styles.Paper}>
                    <h1>Welcome back!</h1>
                    <p>It's always good to keep your resume up to date.</p>
                    {authRedirect}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={errorMessageOpen}
                        autoHideDuration={5000}
                        onClose={() => setErrorMessageOpen(false)}
                    >
                        <Alert severity="error">{props.error}</Alert>
                    </Snackbar>
                    <form className={styles.Form} onSubmit={submitHandler}>
                        <h2>Sign into your account</h2>
                        {formInputs}

                        {/* <Link href="#" className={styles.ForgotLink}>
                            Forgotten password?
                        </Link> */}
                        <NavLink 
                            className={styles.ForgotLink}
                            to="/forgot-password"
                            exact>
                            Forgotten password?
                        </NavLink>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={styles.Button}
                        >
                            Take me to my resume
                        </Button>
                    </form>
                    
                    <div>
                        Don't have an account? 
                        <NavLink 
                            className={styles.SignUpLink}
                            to="/choose-template"
                            exact>
                            Sign up here
                        </NavLink>
                    </div>
                </div>
                
                <img src={loginImage} alt="Login" className={styles.LoginImage}/>
            </Container>
        </>
    );
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( email, password ) => dispatch( actions.login( email, password) ),
        onAuthInit: () => dispatch(actions.authInit()),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Login );