import React from 'react';
import Container from '@material-ui/core/Container';


function Faq() {
    return (
        <Container component="main">
            <h1>FAQ</h1>
        </Container>
    );
}

export default Faq;