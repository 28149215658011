import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationItems from '../NavigationItems/NavigationItems';
import UserNavigationItems from '../UserNavigationItems/UserNavigationItems';
import MobileAccountNavigationItems from '../AccountNavigationItems/MobileAccountNavigationItems';
import styles from './MobileSide.module.scss';
import { matchExact } from '../../../shared/utility';

const mobileSide = ( props ) => {
    const { location } = props;
    if (matchExact(/^\/sign-up$/, location.pathname)){
        return null;
    }

    let attachedClasses = [styles.MobileSide, styles.Close];
    if (props.open) {
        attachedClasses = [styles.MobileSide, styles.Open];
    }
    return (
        <>
            <div className={attachedClasses.join(' ')}  onClick={props.closed}>
                { 
                    props.isAuthenticated ? (
                        <nav>
                            <NavigationItems />
                            <hr/>
                            <MobileAccountNavigationItems />
                        </nav>
                    ) : (
                        <nav>
                            <UserNavigationItems />
                            <hr/>
                            <NavigationItems />
                        </nav>
                    )
                }
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default withRouter( connect( mapStateToProps, null )( mobileSide ) );