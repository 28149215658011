//import axios from 'axios';
import axios from '../../axios-resumes';

import * as actionTypes from './actionTypes';

export const authInit = () => {
    return {
        type: actionTypes.AUTH_INIT
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const userSuccess = (successMessage) => {
    return {
        type: actionTypes.USER_SUCCESS,
        successMessage: successMessage
    };
};

export const authSuccess = (token, userId, email, firstName, lastName) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        email: email,
        firstName: firstName,
        lastName: lastName
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

/* export const resetPassword = (email) => {
    return dispatch => {
        const data = {
            email: email,
            requestType: "PASSWORD_RESET"
        };
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyAoWz0V5KtEGwwZsLGKyN72DUOhrrnMK0s';
        axios.post(url, data)
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    dispatch(authFail(err.response.data.message));
                }
            });
    };
}; */

export const getUserData = (email, firstName, lastName) => {
    return {
        type: actionTypes.GET_USER_DATA,
        email: email,
        firstName: firstName,
        lastName: lastName
    };
};

export const logout = () => {
    /* const token = localStorage.getItem('token');

    const headers = {
        Authorization: `Bearer ${token}`
    }

    axios.post('/auth/logout', {}, {
        headers: headers
        })
        .then(response => {
            console.log(response.data.message);
        })
        .catch(err => {
            console.log(err);
        }); */


    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

/* export const auth = (email, password, isSignup) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAoWz0V5KtEGwwZsLGKyN72DUOhrrnMK0s';
        if (!isSignup) {
            url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAoWz0V5KtEGwwZsLGKyN72DUOhrrnMK0s';
        }
        axios.post(url, authData)
            .then(response => {
                console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.localId);
                dispatch(authSuccess(response.data.idToken, response.data.localId));
                dispatch(checkAuthTimeout(response.data.expiresIn));
            })
            .catch(err => {
                dispatch(authFail(err.response.data.error));
            });
    };
}; */


export const register = (firstName, lastName, email) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            first_name: firstName,
            last_name: lastName,
            email: email
        };

        axios.post('/auth/register', authData)
            .then(response => {
                console.log(response);
                const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 60 * 1000);
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.user.id);
                dispatch(authSuccess(response.data.access_token, response.data.user.id, response.data.user.email, response.data.user.first_name, response.data.user.last_name));
                //dispatch(checkAuthTimeout(3600/* response.expires_in */));
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                };
            });
    };
};

export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        };

        axios.post('/auth/login', authData)
            .then(response => {
                console.log(response);
                getAuthUser(response.data.access_token).then(userData => {
                    const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 60 * 1000);
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userId', userData.id);
                    dispatch(authSuccess(response.data.access_token, userData.id, userData.email, userData.first_name, userData.last_name));
                    dispatch(changeVisibilitySuccess(null, userData.resume_visibility));
                    //dispatch(checkAuthTimeout(3600/* response.expires_in */));
                    
                });
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                }
            });
    };
}

const getAuthUser = async (token) => {
    const response = await axios.get('/profile', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data
}

export const authCheckState = () => {
    return dispatch => {
/*         dispatch(authStart()); */
        const token = localStorage.getItem('token');
        if (token) {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                getAuthUser(token).then(userData => {                    
                    dispatch(authSuccess(token, userId, userData.email, userData.first_name, userData.last_name));
                    dispatch(changeVisibilitySuccess(null, userData.resume_visibility));
                    //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
                    
                });
            }   
        } else {
            dispatch(authFail());
        }
    };
};

export const createPassword = (password, hash) => {
    return dispatch => {
        dispatch(authStart());
        const verifyData = {
            hash: hash,
            password: password,
        };

        axios.post('/verify', verifyData)
        .then(response => {
            console.log('PasswordCreated');
            console.log(response.data.message);
            dispatch(userSuccess(response.data.message));
        })
        .catch(err => {
            if (err.response.data.error) {
                dispatch(authFail(err.response.data.error));
            } else {
                let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                dispatch(authFail(errors));
            }
        });
    };
}

export const changeUserData = (token, firstName, lastName, email) => {
    return dispatch => {
        dispatch(authStart());
        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
        };

        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.put('/profile', userData, {headers: headers})
            .then(response => {
                console.log('User Data Changed');
                console.log(response.data.message);
                dispatch(userSuccess("Your information was updated"));
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId, response.data.email, response.data.first_name, response.data.last_name));
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));   
                }
            });
    };
}

export const changeVisibility = (token, resumeVisibility) => {
    return dispatch => {
        dispatch(authStart());
        const userData = {
            resume_visibility: resumeVisibility
        };

        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.put('/profile/resume', userData, {headers: headers})
            .then(response => {
                console.log('Resume Visibility Changed');
                dispatch(changeVisibilitySuccess("Resume Visibility was updated", response.data.resume_visibility));
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                }
            });
    };
}

export const changeVisibilitySuccess = (successMessage, resumeVisibility) => {
    return {
        type: actionTypes.CHANGE_VISIBILITY_SUCCESS,
        successMessage: successMessage,
        resumeVisibility: resumeVisibility,
    };
};

export const changePassword = (token, currentPassword, newPassword) => {
    return dispatch => {
        dispatch(authStart());
        const userData = {
            old_password: currentPassword,
            password: newPassword
        };

        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.put('/profile/password', userData, {headers: headers})
            .then(response => {
                console.log('Password Changed');
                console.log(response.data.message);
                dispatch(userSuccess("Password was updated"));
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                }
            });
    };
}

export const deleteUser = (token, reasons) => {
    return dispatch => {
        dispatch(authStart());
        const userData = {
            reasons: reasons,
        };
        console.log('delete reasons');
        console.log(userData);

        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.delete('/profile/delete', {headers, userData})
            .then(response => {
                console.log('User Deleted');
                console.log(response.data.message);
                dispatch(deleteUserSuccess("Profile successfully deleted"));
            })
            .catch(err => {
                console.log(err.response.data);
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                }
            });
    };
}

export const deleteUserSuccess = (successMessage) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        successMessage: successMessage,
    };
};

export const restoreAccount = (userId) => {
    return dispatch => {
        dispatch(authStart());

        axios.get(`/profile/restore/${userId}`)
            .then(response => {
                dispatch(restoreAccountSuccess("Profile successfully restored"));
                //const token = localStorage.getItem('token');
                //dispatch(authSuccess(token, userId, response.data.email, response.data.first_name, response.data.last_name));
            ;
            })
            .catch(err => {
                if (err.response.data.error) {
                    dispatch(authFail(err.response.data.error));
                } else {
                    let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                    dispatch(authFail(errors));
                }
            });
    };
}

export const restoreAccountSuccess = (successMessage) => {
    return {
        type: actionTypes.RESTORE_USER_SUCCESS,
        successMessage: successMessage,
    };
};

export const refreshToken = () => {
    return dispatch => {
        const token = localStorage.getItem('token');

        const headers = {
            Authorization: `Bearer ${token}`
        }

        return new Promise((resolve, reject) => {
            axios.post('/auth/refresh', {}, {
                headers: headers
              })
                .then(response => {
                    if (response.status === 205) {
                        dispatch(refreshTokenSuccess(token));
                        resolve(token);
                    } else {
                        dispatch(refreshTokenSuccess(response.data.access_token));
                        const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 60 * 1000);
                        localStorage.setItem('token', response.data.access_token);
                        localStorage.setItem('expirationDate', expirationDate);
                        resolve(response.data.access_token);
                    }
                    
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })

    };
}

export const refreshTokenSuccess = (token) => {
    return {
        type: actionTypes.TOKEN_REFRESH_SUCCESS,
        token: token,
    };    
}


export const forgotPassword = (email) => {
    return dispatch => {
        dispatch(authStart());
        const userData = {
            email: email,
        };

        axios.post('/forgot/password', userData)
        .then(response => {
            console.log(response.data.message);
            dispatch(userSuccess(response.data.message));
        })
        .catch(err => {
            if (err.response.data.error) {
                dispatch(authFail(err.response.data.error));
            } else {
                let errors = Object.keys(err.response.data).map(error => err.response.data[error]);
                dispatch(authFail(errors));
            }
        });
    };
}