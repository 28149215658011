import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SwitchTemplateIcon(props) {
    return (
        <SvgIcon {...props}>
{/*             <path d="M0.767822 11.6166C0.767822 11.3404 0.99168 11.1166 1.26782 11.1166H7.6104C7.88654 11.1166 8.1104 11.3404 8.1104 11.6166V16.2906C8.1104 16.5668 7.88654 16.7906 7.6104 16.7906H1.26782C0.99168 16.7906 0.767822 16.5668 0.767822 16.2906V11.6166ZM10.7789 1.60547C10.7789 1.32933 11.0028 1.10547 11.2789 1.10547H15.953C16.2291 1.10547 16.453 1.32933 16.453 1.60547V4.61102C16.453 4.88716 16.2291 5.11102 15.953 5.11102H11.2789C11.0028 5.11102 10.7789 4.88716 10.7789 4.61102V1.60547ZM0.767822 1.60547C0.767822 1.32933 0.99168 1.10547 1.26782 1.10547H7.6104C7.88654 1.10547 8.1104 1.32933 8.1104 1.60547V7.94805C8.1104 8.22419 7.88654 8.44805 7.6104 8.44805H1.26782C0.99168 8.44805 0.767822 8.22419 0.767822 7.94805V1.60547ZM10.7789 8.27953C10.7789 8.00339 11.0028 7.77953 11.2789 7.77953H15.953C16.2291 7.77953 16.453 8.00339 16.453 8.27953V16.2906C16.453 16.5668 16.2291 16.7906 15.953 16.7906H11.2789C11.0028 16.7906 10.7789 16.5668 10.7789 16.2906V8.27953Z" /> */} 
                <path d="M2.32088 0H6.03516V5.57143H-0.000565052V2.14285C0.000985622 0.959966 1.03943 0.00140625 2.32088 0Z"/>
                <path d="M10.6786 0C11.96 0.00140625 12.9985 0.959966 13 2.14285V9.85713C12.9985 11.04 11.96 11.9986 10.6786 12H6.96431V0H10.6786Z"/>
                <path d="M6.03516 6.42871V12.0001H2.32088C1.03943 11.9987 0.000988007 11.0402 -0.000535488 9.8573V6.42871H6.03516Z"/>

        </SvgIcon>
    );
}