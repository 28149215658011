import React, {useEffect, forwardRef} from 'react';
import { connect } from 'react-redux';

import styles from './ResumeViewer.module.scss';

import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import axios from '../../axios-resumes';

import * as actions from '../../store/actions/index';
import { List } from 'react-content-loader';

import Tokyo from '../../components/ResumeMockups/Tokyo';
import Berlin from '../../components/ResumeMockups/Berlin';
import London from '../../components/ResumeMockups/London';
import Paris from '../../components/ResumeMockups/Paris/Paris';
import Madrid from '../../components/ResumeMockups/Madrid';
import Hloom from '../../components/ResumeMockups/Hloom';
import CleanBlue from '../../components/ResumeMockups/CleanBlue';
import Creative from '../../components/ResumeMockups/Creative/Creative';
import Traditional from '../../components/ResumeMockups/Traditional/Traditional';
import Dublin from '../../components/ResumeMockups/Dublin/Dublin';
import ModernBlue from '../../components/ResumeMockups/ModernBlue/ModernBlue';
import Classic from '../../components/ResumeMockups/Classic/Classic';
import Woodlawn from '../../components/ResumeMockups/Woodlawn/Woodlawn';
import Monospaced from '../../components/ResumeMockups/Monospaced';
import DarkRed from '../../components/ResumeMockups/DarkRed';

import NoColors from '../../components/CoverLetterTemplates/NoColors';
import DifferentColors from '../../components/CoverLetterTemplates/DifferentColors';

import bubblesBg from '../../assets/images/resume_bg/bubbles-bg.png';
import cubesBg from '../../assets/images/resume_bg/cubes-bg.png';
import dottedCirclesBg from '../../assets/images/resume_bg/dotted-circles-bg.png';
import gradientDotsBg from '../../assets/images/resume_bg/gradient-dots-bg.png';
import toysBg from '../../assets/images/resume_bg/toys-bg.png';
import wavesBg from '../../assets/images/resume_bg/waves-bg.png';


const patterns = {
    cubes: cubesBg,
    toys: toysBg,
    dottedCircles: dottedCirclesBg,
    bubbles: bubblesBg,
    gradientDots: gradientDotsBg,
    waves: wavesBg,
}

// Create Document Component
const ResumeViewer = (props, ref) => {

    useEffect(() => {

        return () => {
            //observer.disconnect();

            let svgElements = document.body.querySelectorAll('#document-viewer svg:not([class^="resume-mockup"])');
            svgElements.forEach(function(item) {
                item.setAttribute("width", 30);
                item.setAttribute("height", 30);
            });

            let svgElementsMockup = document.body.querySelectorAll('#document-viewer svg.resume-mockup');
            svgElementsMockup.forEach(function(item) {
                item.setAttribute("width", item.getBoundingClientRect().width);
                item.setAttribute("height", item.getBoundingClientRect().height);
                item.style.width = null;
                item.style.height= null;
            });

            let pageSvg = document.getElementById('document-viewer');
            if (pageSvg) {
                domtoimage.toPng(pageSvg, {
                        quality: 0.99,
                    })
                    .then(function(dataUrl) {
                        console.log(dataUrl);
                        //window.open(dataUrl);
                        let img = new Image();
                        img.src = dataUrl;
                    })
                    .catch(function(error) {
                        console.error('oops, something went wrong!', error);
                    });
            

                /* rasterizeHTML.drawHTML(html).then(function (renderResult) {
                    context.drawImage(renderResult.image, 10, 25);
                
                    let base64data = canvas.toDataURL('image/png');
                    console.log(base64data);
                }); */


                html2canvas(pageSvg, {
                    logging: true, 
                    letterRendering: true, 
                    backgroundColor: '#ffffff',
                    scale: 1,
                    useCORS: true,/* 
                    scrollX: -window.scrollX,
                    scrollY: -window.scrollY, */
                    
                    scrollY: 0, 
                    scrollX: -4,
                    //allowTaint: true
                }).then(function(canvas) {
                    let dataImage = canvas.toDataURL("image/png");
                    const formData = {
                        id: props.documentId,
                        screenshot: dataImage
                    };

                    const apiUrl = props.resume ? '/resume/upload/screenshot' : '/cover-letters/upload/screenshot';
                    
                    axios.post( apiUrl, formData, { headers: {
                        'Authorization': `Bearer ${props.token}`
                    }})
                    .then( response => {
                        console.log('Resume Image Saved');
                        props.onFetchResumes(props.token, props.userId);
                        props.onFetchCoverLetters(props.token, props.userId);
                    })
                    .catch( err => {
                        console.log(err);
                    });
                });
            }
        };

    }, []);

    const calcTopPosition = (el) => {
        let elTopPosition = el.offsetTop;
        let parentOffset = el.offsetParent;
        const resumeViewer = document.getElementById('document-viewer');
        
        while (parentOffset && parentOffset !== resumeViewer) {
            elTopPosition += parentOffset.offsetTop;
            parentOffset = parentOffset.offsetParent;
        }
        return elTopPosition;
    }

    useEffect(() => {
        setTimeout(() => {
            const resumeViewer = document.getElementById('document-viewer');

            document.querySelectorAll('.page-delimeter').forEach(e => e.remove());
            document.querySelectorAll('.page-line').forEach(e => e.remove());

            if (resumeViewer) {
                let documentHeight = resumeViewer.getBoundingClientRect().height;
                let documentHeightInches = documentHeight/96;
                let pages = Math.ceil(documentHeightInches / 11.69);
                
                //let resumeItems = resumeViewer.getElementsByClassName('movable');
                //let resumeSideItems = resumeViewer.getElementsByClassName('side-movable');
                let movableItems = resumeViewer.getElementsByClassName('movable')
                let resumeItemsArr = [...movableItems].map(movableItem => movableItem.getElementsByTagName('*'));
                let resumeItems = [];
                resumeItemsArr.forEach(resumeItem => resumeItems.push(...resumeItem));

                let sideMovableItems = resumeViewer.getElementsByClassName('side-movable')
                let resumeSideItemsArr = [...sideMovableItems].map(sideMovableItem => sideMovableItem.getElementsByTagName('*'));
                let resumeSideItems = [];
                resumeSideItemsArr.forEach(resumeSideItem => resumeSideItems.push(...resumeSideItem));


                for ( let i = 1; i <= pages; i++) {
                                    
                    let pageHeight = resumeViewer.clientWidth * i * 1.4;
                    
                    //let pagePadding = pageHeight * 2.5 / 100;
                    let pagePadding = 26;

                    let pageLine = document.createElement('span');
                        pageLine.setAttribute('class', 'page-line');
                        pageLine.style.top = `${pageHeight + 13}px`;
                    resumeViewer.append(pageLine);

                    for (let i = 0; i < resumeItems.length; ++i) {
                        let e = resumeItems[i];
                        if (e.hasChildNodes() && (e.tagName === 'DIV' || e.tagName === 'UL' || e.tagName === 'OL')) continue;
                        let elTopPosition = calcTopPosition(e);
                        /* if (e.offsetParent != resumeViewer) {
                            console.log(e);
                            elTopPosition = e.offsetTop + e.offsetParent.offsetTop;
                        } else {
                            elTopPosition = e.offsetTop;
                        } */

                        //let elTopPosition = e.getBoundingClientRect().top - resumeViewer.getBoundingClientRect().top;
                        let elBottomPosition = elTopPosition + e.clientHeight;
                        /* console.log(e);
                        console.log('elBottomPosition ' + elBottomPosition);
                        console.log('elTopPosition ' + elTopPosition);
                        console.log(resumeViewer.getBoundingClientRect().top); */
                        if (elBottomPosition >= (pageHeight - pagePadding)) {
                            let remainingSpace = (pageHeight - elTopPosition) + pagePadding * 2;
                            
                            let pageDelimeter = document.createElement('span');
                            pageDelimeter.setAttribute('class', 'page-delimeter');
                            pageDelimeter.style.height = `${remainingSpace}px`;

                            let parentNode = e.parentNode.classList.contains('MuiLinearProgress-root') ? e.closest('.movable') : e.parentNode;
                            let referenceNode = e.parentNode.classList.contains('MuiLinearProgress-root') ? null : e;
                            parentNode.insertBefore(pageDelimeter, referenceNode);
                            console.log(referenceNode);
                            console.log(parentNode);
                            break;
                        }
                    }
                    //console.log(`Page height: ${pageHeight}`);
                    
                    for (let i = 0; i < resumeSideItems.length; ++i) {
                        let e = resumeSideItems[i];
                        if (e.hasChildNodes() && (e.tagName === 'DIV' || e.tagName === 'UL' || e.tagName === 'OL')) continue;
                        let elTopPosition = calcTopPosition(e);
                        let elBottomPosition = elTopPosition + e.clientHeight;
                        if (elBottomPosition >= (pageHeight - pagePadding)) {
                            let remainingSpace = (pageHeight - elTopPosition) + pagePadding * 2;
                            
                            let pageDelimeter = document.createElement('span');
                            pageDelimeter.setAttribute('class', 'page-delimeter');
                            pageDelimeter.style.height = `${remainingSpace}px`;

                            let parentNode = e.parentNode.classList.contains('MuiLinearProgress-root') ? e.closest('.side-movable') : e.parentNode;
                            let referenceNode = e.parentNode.classList.contains('MuiLinearProgress-root') ? null : e;
                            parentNode.insertBefore(pageDelimeter, referenceNode);
                            break;
                        }
                    }
                }


                /* for (i = 0; i < descendents.length; ++i) {
                    e = descendents[i];
                    console.log(e);
                    if (pageContentHeight + e.getBoundingClientRect().bottom > page2Height) {
                        //e.classList.add("page-delimeter");
                            if (e.isSameNode(pageDelimeter)) {
                                continue;
                            } else {
                                let remainingSpace = page2Height - e.getBoundingClientRect().top;
                                pageDelimeter.setAttribute('height', remainingSpace)
                                e.prepend(pageDelimeter);
                                break;
                            }
                    }  else {
                        if (e.contains(pageDelimeter)) {
                            pageDelimeter.parentNode.removeChild(pageDelimeter);
                        }
                    }
                } */
            }
        }, 4000);
    }, [props.resume, props.coverLetter, props.resumeConfiguration]);

    const selectResumeTemplate = () => {
        if (props.resume) {
            switch(Number(props.templateId)) {
                case 1:   return <Tokyo resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 2:   return <Berlin resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 3:   return <London resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 4:   return <Paris resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 5:   return <Madrid resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 9:   return <Hloom resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 10:  return <CleanBlue resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 11:  return <Creative resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 12:  return <Traditional resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 13:  return <Dublin resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 14:  return <ModernBlue resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 15:  return <Classic resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 16:  return <Woodlawn resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 17:  return <Monospaced resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                case 18:  return <DarkRed resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
                default:  return <div style={{padding: '35px 20px', backgroundColor: '#fff', flexGrow: '1', }}><List /><List /><List /></div>;
            }
        } else {
            switch(Number(props.templateId)) {
                case 1:   return <NoColors coverLetter={props.coverLetter} coverLetterConfiguration={props.coverLetterConfiguration} />;
                case 2:   return <DifferentColors coverLetter={props.coverLetter} coverLetterConfiguration={props.coverLetterConfiguration} />;
                default:  return <div style={{padding: '35px 20px', backgroundColor: '#fff', flexGrow: '1', }}><List /><List /><List /></div>;
            }
        }
    }

    const pattern = props.resume ? props.resumeConfiguration.pattern : props.coverLetterConfiguration.pattern;

    return (
        <div id="document-viewer" className={styles.Document} ref={props.myForwardedRef}>
            {pattern && (<div className={styles.BgTop} style={{backgroundImage: `url(${patterns[pattern]})`}}></div>)}
            {selectResumeTemplate()}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        resumeConfiguration: state.resume.resumeConfiguration,
        coverLetterConfiguration: state.coverLetter.coverLetterConfiguration,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchResumes: (token, userId) => dispatch( actions.fetchResumes(token, userId) ),
        onFetchCoverLetters: (token, userId) => dispatch( actions.fetchCoverLetters(token, userId) ),
    };
};

const ConnectedResumeViewer = connect(
    mapStateToProps, mapDispatchToProps
)(ResumeViewer);

export default forwardRef((props, ref) =>
    <ConnectedResumeViewer {...props} myForwardedRef={ref} />
);