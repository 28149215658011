import React from 'react';
import { Page, Text, Document, StyleSheet, Font, View, Link } from '@react-pdf/renderer';

import {toJSON} from '../../../shared/domToJson';
import {JsonToPdfComponent} from '../../../shared/jsonToPdf';
import {createFragment} from '../../../shared/createFragment';
import { addPdfPattern } from '../../../shared/addPdfPattern';


// Create Document Component
const NoColorsPdf = (props) => {
    
//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });
    
    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });
    
    const mainColor = props.coverLetterConfiguration.primary_color || '#079a9a';
    
    const fontFamily = props.coverLetterConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.coverLetterConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 11.5 * multiplier;
    const headerTitleSize = 24 * multiplier;
    const headerSubtitleSize = 14 * multiplier;
    

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
        },
        table: {
            fontSize: mainSize,
            lineHeight: 1.3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        header: {
            position: 'relative',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingBottom: 10,
            paddingTop: 0,
            marginHorizontal: 40,
            marginBottom: 15,
            borderBottomWidth: 1,
            borderBottomColor: '#a2a2a2',
        },
        title: {
            fontSize: headerTitleSize,
            fontWeight: 700,
            marginBottom: 0,
            textTransform: 'uppercase',
        },
        subtitle: {
            fontSize: headerSubtitleSize,
            marginBottom: 0,
        },
        firstName: {
            color: mainColor,
        },
        link: {
            color: mainColor, 
            textDecoration: 'none',
        },
        contactInfo: {
            maxWidth: '27%',
            width: '27%',
            color: '#acacac',
            textAlign: 'right',
        },
        contactItem: {
            maxWidth: '100%',
            marginVertical: 2,
        },
        contactLink: {
            color: mainColor,
            textDecoration: 'none',
        },
        contactTitle: {
            color: mainColor,
            fontWeight: 700,
            marginRight: 6,
        },
        main: {
            paddingHorizontal: 40,
        },
        description: {
            maxWidth: '98%',
        },
        employerInfo: {
            color: '#acacac',
            marginBottom: '20pt',
        },
    });

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'dash'));
        }
    }

    return (
        <Document>
            <Page wrap size="A4" style={styles.body}>
                {addPdfPattern(props.coverLetterConfiguration.pattern)}
                <View style={styles.table}>
                    <View style={[styles.row, styles.header]}>
                        <View style={styles.headerText}>
                            <Text style={styles.title}><Text style={styles.firstName}>{props.coverLetter.firstName}</Text> {props.coverLetter.lastName}</Text>
                            <Text style={styles.subtitle}>{props.coverLetter.jobPosition}</Text>
                        </View>

                        {(props.coverLetter.email || props.coverLetter.phoneNumber) ? (
                            <View style={styles.contactInfo}>
                                {props.coverLetter.phoneNumber ? (<View style={styles.contactItem}>
                                    <Text style={styles.contactValue}>{props.coverLetter.phoneNumber}</Text>
                                </View>) : <></>}
                                {props.coverLetter.email ? (<View style={styles.contactItem}>
                                    <Link src={`mailto:${props.coverLetter.email}`} style={styles.contactLink}>{props.coverLetter.email}</Link>
                                </View>) : <></>}
                            </View>
                        ) : <></>}
                    </View>

                    <View style={styles.main}>
                        <View style={styles.employerInfo}>
                            {props.coverLetter.companyName ? (<Text style={styles.companyName}>{props.coverLetter.companyName}</Text>) : <></>}
                            {props.coverLetter.managerName ? (<Text style={styles.managerName}>{props.coverLetter.managerName}</Text>) : <></>}
                        </View>
                        {props.coverLetter.letterDetails ? (<View style={styles.description}>{generateTinyMceElements(props.coverLetter.letterDetails)}</View>) : <></>}
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default NoColorsPdf;