import React from 'react';
import {createUseStyles} from 'react-jss';

import LinkIcon from '@material-ui/icons/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

function Hloom (props) {
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 12,
            border: `1px solid ${mainColor}`,
            borderRadius: '5px',
            marginTop: 10,
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: mainColor,
        },
    }))(LinearProgress);

    const mainColor = props.resumeConfiguration.primary_color || '#2196F3';

    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';

    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12 * multiplier;
    const headerTitleSize = 34.5 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const sectionTitleSize = 15 * multiplier;
    const repeatedTitleSize = 14 * multiplier;
    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, sectionTitleSize, repeatedTitleSize};
    
    const resumeType = props.resumeConfiguration.resume_type || 'chronological';
    
    const templateStyles = createUseStyles({
        header: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            position: 'relative',
            padding: '20pt 20pt 0',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            lineHeight: 1.3,
            zIndex: 3,
        },
        headerText: {
            flex: '1 0 75%',
            '& ul': {
                marginLeft: '.5rem',
            },
            '& ol': {
                marginLeft: '.5rem',
            },
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            color: (prop) => prop.mainColor,
            lineHeight: 1.2,
            marginBottom: 0,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            marginBottom: '5pt',
        },
        headerContact: {},
        contactItem: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '2pt',
            marginBottom: '2pt',
            lineHeight: 1.3,
            '& a': {
                color: 'inherit',
                wordBreak: 'break-all',
            },
            '& svg': {
                marginRight: '0.5rem',
                marginTop: '0.25rem',
            },
        },
        userImage: {
            width: '150px',
            minHeight: '150px',
            maxWidth: '150px',
            borderRadius: '50%',
            objectFit: 'cover',
            flex: '1 0 23%',
            marginRight: '20px',
            border: (prop) => `4px solid ${prop.mainColor}`,
        },
        sectionTitle: {
            display: 'inline-block',
            color: (prop) => prop.mainColor,
            textTransform: 'uppercase',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 700,
            textAlign: 'right',
            flex: '0 0 25%',
            minWidth: '25%',
            paddingRight: '7pt',
            paddingBottom: '22pt',
            borderRight: '2px solid #c2c1c1',
        },
        contactTitle: {
            color: (prop) => prop.mainColor,
            fontWeight: 700,
            marginRight: '.5rem'
        },
        main: {
            flex: '1 1 100%',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '25pt 20pt 0',
            zIndex: 3,
            lineHeight: 1.3,
            '& p': {
                lineHeight: 1.3,
            },
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        workItemTitle: {
            color: (prop) => prop.mainColor,
            textTransform: 'uppercase',
        },
        repeatedItemTitle: {
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 700,
            flex: '1 0 70%',
        },
        repeatedItemSubtitle: {
            fontWeight: 700,
        },
        repeatedTitleWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        repeatedItem: {
            position: 'relative',
            marginBottom: '7pt',
        },
        workItem: {
            '&:before': {
                content: '""',
                position: 'absolute',
                top: '0px',
                left: '-25px',
                display: 'block',
                width: '15px',
                height: '15px',
                border: '2px solid #c2c1c1',
                backgroundColor: '#fff',
                borderRadius: '50%',
                zIndex: 10,
            },      
        },
        dateText: {
            fontStyle: 'italic',
            textAlign: 'right',
        },
        sectionWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        sectionContent: {
            position: 'relative',
            flex: '0 0 75%',
            paddingLeft: '15px',
            paddingBottom: '22pt',
            '&:before': {
                content: '""',    
                position: 'absolute',
                top: '0px',
                left: '-10px',
                display: 'block',
                width: '15px',
                height: '15px',
                border: '2px solid #c2c1c1',
                backgroundColor: '#fff',
                borderRadius: '50%',
                zIndex: 10,
            },
        },
        description: {
            marginTop: '7pt',
            '& p': {
                lineHeight: 1.3,
            },
            '& ol': {
                margin: 0,
                paddingLeft: '10pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: '#000',
                        fontSize: (prop) => '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
        },
        careerSummary: {
            marginTop: 0,
        },
        skillContent: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        languageContent: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        skillItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: '0 0 47%',
            marginBottom: '0.5rem',
            '& > *': {
                order: 1,
            },
        },
        languageItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flex: '0 0 47%',
            marginBottom: '0.5rem',
            '& > *': {
                order: 1,
            },
        },
        delimeter: {
            display: 'inline-block',
            margin: '0 .25rem',
        },
        link: {
            color: (prop) => prop.mainColor,
            textDecoration: 'none',
        },
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });
    
    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    };

    const languagesPercentages = {
        'Native Speaker': 100, 
        'Highly proficient': 80, 
        'Very good command': 60, 
        'Good working knowledge': 40,
        'Working knowledge': 20, 
        'C2': 100, 
        'C1': 100, 
        'B2': 80, 
        'B1': 60, 
        'A2': 40, 
        'A1': 20
    };

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<span className={classes.contactItem}><FacebookIcon style={{ color: mainColor }} /> <a href={socials.facebook}>{showUrl(socials.facebook)}</a></span>);
        }
        if (socials.linkedin) {
            socialsList.push(<span className={classes.contactItem}><LinkedInIcon style={{ color: mainColor }} /> <a href={socials.linkedin}>{showUrl(socials.linkedin)}</a></span>);
        }
        if (socials.twitter) {
            socialsList.push(<span className={classes.contactItem}><TwitterIcon style={{ color: mainColor }} /> <a href={socials.twitter}>{showUrl(socials.twitter)}</a></span>);
        }
        if (socials.instagram) {
            socialsList.push(<span className={classes.contactItem}><InstagramIcon style={{ color: mainColor }} /> <a href={socials.instagram}>{showUrl(socials.instagram)}</a></span>);
        }
        if (socials.github) {
            socialsList.push(<span className={classes.contactItem}><GitHubIcon style={{ color: mainColor }} /> <a href={socials.github}>{showUrl(socials.github)}</a></span>);
        }
        if (socials.youtube) {
            socialsList.push(<span className={classes.contactItem}><YouTubeIcon style={{ color: mainColor }} /> <a href={socials.youtube}>{showUrl(socials.youtube)}</a></span>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <div className={classes.sectionWrap}>
            <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</span></p>
            <div className={`${classes.sectionContent} ${classes.skillContent}`}>
                {props.resume.skillsFields.map((skillItem, index) => 
                    (skillItem.skill) ?
                        (
                            <div key={index} className={`movable ${classes.repeatedItem} ${classes.skillItem}`}>
                                <p>{skillItem.skill}</p>
                                {(Boolean(Number(props.resume.skillsVisibility)) && skillItem.level) ? (
                                    <BorderLinearProgress 
                                        variant="determinate" 
                                        value={skillsPercentages[skillItem.level]}
                                    />
                                ) : (<></>)}
                            </div>
                        ) : <></>
                )}
            </div>
        </div>
    ) : <></>;

    return (
        <>
            <div className={classes.header}>
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}

                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>

                    <div className={classes.headerContact}>                        
                        {(props.resume.phoneNumber || props.resume.email) && (<span className={classes.contactItem}>{props.resume.phoneNumber ? (<span>{props.resume.phoneNumber}</span>) : ''} {props.resume.phoneNumber && props.resume.email ? (<span className={classes.delimeter}>|</span>) : ''} {props.resume.email ? (<a href={`mailto:${props.resume.email}`}><strong>Email:</strong> {props.resume.email}</a>) : ''}</span>)}
                        {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={classes.contactItem}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span>)}
                        {props.resume.nationality && (<span className={classes.contactItem}><span className={classes.contactTitle}>Nationality:</span> <span>{props.resume.nationality}</span></span>)}
                        {props.resume.birthPlace && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Place</span> <span>{props.resume.birthPlace}</span></span>)}
                        {props.resume.birthDate && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Date</span> <span>{moment(props.resume.birthDate).format("DD/MM/YYYY")}</span></span>)}
                        {(Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (<span className={classes.contactItem}><span className={classes.contactTitle}>Driving license</span> <span>{props.resume.drivingLicenseType.join(', ')}</span></span>) : (<></>)}
                        {(props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) ? props.resume.websiteSocialFields.map((socialItem, index) => (
                            (socialItem.link || socialItem.name) ? (
                                <span key={index} className={classes.contactItem}><LinkIcon style={{ color: mainColor }} /> <a href={socialItem.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                            ) : (<></>)
                        )) : (<></>)}
                        
                        {props.resume.socials ? showSocials(props.resume.socials) : <></>}
                    </div>
                </div>
            </div>

            <div className={classes.main}>
                {props.resume.careerSummary ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Professional Summary'}</span></p>            
                        <div className={`movable ${classes.description} ${classes.sectionContent} ${classes.careerSummary}`}>
                            {generateTinyMceElements(props.resume.careerSummary)}
                        </div>
                    </div>
                ) : null}

                {resumeType === 'functional' ? skillsSection : <></>}

                {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                    <div className={classes.sectionWrap}>                               
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Work History'}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {props.resume.workExperienceItems.map((workItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem} ${classes.workItem}`}>
                                    <div className={classes.repeatedTitleWrap}>
                                        {workItem.jobTitle && (<p className={`${classes.repeatedItemTitle} ${classes.workItemTitle}`}>{workItem.jobTitle}</p>)}
                                        {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY", " to ")}</p>
                                        ) : <></>}
                                    </div>
                                    {(workItem.company || workItem.city) && (<p className={classes.repeatedItemSubtitle}>{workItem.company}{workItem.company && workItem.city ? (<span className={classes.delimeter}>|</span>) : ''}{workItem.city}</p>)}

                                    {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

                {resumeType === 'chronological' ? skillsSection : <></>}
                
                {props.resume.educationFields && props.resume.educationFields.length  ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {props.resume.educationFields.map((educationItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    <div className={classes.repeatedTitleWrap}>
                                        {educationItem.degree && (<p className={classes.repeatedItemTitle}>{educationItem.degree}</p>)}
                                        {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY", " to ")}</p>
                                        ) : <></>}
                                    </div>
                                    {(educationItem.institution || educationItem.city) && (<p className={classes.repeatedItemSubtitle}>{educationItem.institution}{educationItem.institution && educationItem.city ? (<span className={classes.delimeter}>|</span>) : ''}{educationItem.city}</p>)}
                                    {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}
                
                {props.resume.internshipFields && props.resume.internshipFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {props.resume.internshipFields.map((internshipItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    <div className={classes.repeatedTitleWrap}>
                                        {internshipItem.jobTitle && (<p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}</p>)}
                                        {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY", " to ")}</p>
                                        ) : <></>}
                                    </div>                                        
                                    {(internshipItem.employer || internshipItem.city) && (<p className={classes.repeatedItemSubtitle}>{internshipItem.employer}{internshipItem.employer&& internshipItem.city ? (<span className={classes.delimeter}>|</span>) : ''}{internshipItem.city}</p>)}
                                    {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.coursesFields && props.resume.coursesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {props.resume.coursesFields.map((courseItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    <div className={classes.repeatedTitleWrap}>
                                        {courseItem.course && (<p className={classes.repeatedItemTitle}>{courseItem.course}</p>)}
                                        {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY", " to ")}</p>
                                        ) : <></>}
                                    </div>                                            
                                    {courseItem.institution && (<p className={classes.repeatedItemSubtitle}>{courseItem.institution}</p>)}
                                    {courseItem.description ?(<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>)/* (<p>{courseItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}
                
                {props.resume.referencesFields && props.resume.referencesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {!Boolean(Number(props.resume.referencesVisibility)) ? 
                            props.resume.referencesFields.map((referenceItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {referenceItem.referent && (<p className={classes.repeatedItemTitle}>{referenceItem.referent}</p>)}
                                    {referenceItem.company && (<p className={classes.repeatedItemSubtitle}>{referenceItem.company}</p>)}
                                    <p>
                                        <a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}
                                    </p>
                                    {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>)/* (<p>{referenceItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            )) : (<p>References available upon request</p>)}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.languagesFields && props.resume.languagesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</span></p>
                        <div className={`${classes.sectionContent} ${classes.languageContent}`}>
                            {props.resume.languagesFields.map((languageItem, index) => 
                                (languageItem.language) ?
                                    (
                                        <div key={index} className={`movable ${classes.repeatedItem} ${classes.languageItem}`}>
                                            <p>{languageItem.language}</p>
                                            {(languageItem.level) ? (
                                                <BorderLinearProgress 
                                                    variant="determinate" 
                                                    value={languagesPercentages[languageItem.level]}
                                                />
                                            ) : (<></>)}
                                        </div>
                                    ) : <></>
                            )}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.customFields && props.resume.customFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.customSectionTitle}</span></p>
                        <div className={`movable ${classes.sectionContent}`}>
                            {props.resume.customFields.map((customItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    <div className={classes.repeatedTitleWrap}>
                                        {customItem.customItemTitle && (<p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>)}
                                        {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY", " to ")}</p>
                                        ) : <></>}
                                    </div>
                                    {customItem.city && (<p className={classes.repeatedItemSubtitle}>{customItem.city}</p>)}
                                    {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

            </div>
                    
        </>
    )
}

export default Hloom;