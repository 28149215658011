import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import axios from '../../axios-resumes';

import { getTransformedCoverLetter } from '../../shared/utility';

import { Container, Grid, IconButton, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';
import Loader from '../../components/UI/Loader/Loader';

import AutosizeInput from 'react-input-autosize';

import BuilderToolbar from '../../components/BuilderToolbar/BuilderToolbar';

import EyeIcon from '../../components/UI/Icons/EyeIcon';

import styles from './CoverLetterBuilder.module.scss';

import editIcon from '../../assets/images/edit_icon.svg';

import CoverLetterSection from './CoverLetterSection';

import CoverLetterResult from './CoverLetterResult';
import TemplatesSwitcher from './TemplatesSwitcher';

import * as actions from '../../store/actions/index';


function CoverLetterBuilder(props) {
    const [coverLetterTitle, setCoverLetterTitle] = useState('Untitled cover letter');

    const [mobileShowPreview, setMobileShowPreview] = useState(false);
    const handleMobileShowPreview = () => {
		setMobileShowPreview(!mobileShowPreview);
    };
    
    const [previewButtonShow, setPreviewButtonShow] = useState(false);
    const handlePreviewButtonShow = () => {
		setPreviewButtonShow(!previewButtonShow);
    };    

    const [coverLetter, setCoverLetter] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);
    const [switchTemplateOpen, setSwitchTemplateOpen] = useState(false);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    
    const coverLetterId = query.get("coverLetterId");

    useLayoutEffect(() => {
        coverLetterId && axios.get( `/cover-letters/${coverLetterId}`, { headers: {
            'Authorization': `Bearer ${props.token}`
        }})
        .then( response => {
            let coverLetter = getTransformedCoverLetter(response.data.letter);
            if (coverLetter) {
                setCoverLetter(coverLetter);
                setCoverLetterTitle(coverLetter.title);
                setDataLoaded(true);
            }
        } )
        .catch( err => {
            console.log(err);
        } );

    }, [] );

    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && coverLetterId) {
                props.onEditCoverLetter(coverLetter, props.token, coverLetterId);
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);

    }, [coverLetter] );

    const handleResumeTitleChange = (event) => {
        setCoverLetterTitle(event.target.value);
        let updatedCoverLetter = {
            ...coverLetter,
            title: event.target.value,
        }
        setCoverLetter(updatedCoverLetter);
    };

    const coverLetterInfoChange = (updatedValues) => {
        let updatedCoverLetter = {
            ...coverLetter,
            ...updatedValues,
        }
        setCoverLetter(updatedCoverLetter);
    }

    const coverLetterTitleInput = useRef(null); 

    const editResumeTitle = () => {
        coverLetterTitleInput.current.focus();
        coverLetterTitleInput.current.select();
    }

    const [messageOpen, setMessageOpen] = React.useState(false);
    /* useEffect(() => {
        if (props.location.state.signUpMessage) {
            setMessageOpen(true);
        }
    }, [props.location.state.signUpMessage] ); */

    return (
        <>
            <Helmet>
                <title>Cover Letter Builder - ResumeOK</title>
            </Helmet>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={messageOpen}
                autoHideDuration={5000}
                onClose={() => setMessageOpen(false)}
            >
                <Alert severity="success">{props.location.state && props.location.state.signUpMessage}</Alert>
            </Snackbar>
            <BuilderToolbar
                showTemplatesHandler={() => {setMobileShowPreview(false); setSwitchTemplateOpen(true);}}
                document={coverLetter}
                documentId={coverLetterId}
                isCoverLetter
            />
            <Container component="main" className={styles.Wrapper}> 
                <div className={`${styles.ResumeForm} ${switchTemplateOpen ? styles.Hidden : ''} ${mobileShowPreview ? styles.MobileHidden : ''}`} >
                { dataLoaded ? (<>
                    <form className={styles.Form} /* autoComplete="off" */ autoComplete="disabled">
                        <Grid container spacing={1} alignItems="flex-end" justify="center">
                            <Grid item>
                                <AutosizeInput
                                    name="coverLetter-title"
                                    value={coverLetterTitle || ''}
                                    placeholder="Untitled coverLetter 1"
                                    onChange={handleResumeTitleChange}
                                    className={styles.TitleInput}
                                    ref={coverLetterTitleInput}
                                    data-lpignore="true"
                                />
                            </Grid>
                            <Grid item>
                                <img src={editIcon} alt="edit icon" onClick={editResumeTitle}/>
                            </Grid>
                        </Grid>
                    
                        <div className={styles.FormSectionWrap}>
                            <CoverLetterSection onChange={coverLetterInfoChange} coverLetter={coverLetter} coverLetterId={coverLetterId} />
                        </div>
                    </form>

                    <Button
                        variant="contained"
                        color="primary"
                        clicked={handleMobileShowPreview}
                        className={`${styles.Button} ${styles.MobileCtaButton} ${previewButtonShow ? styles.Visible : ''}`}
                    >
                        Preview
                    </Button>

                    <IconButton className={`${styles.CircleButton} ${previewButtonShow ? styles.Hidden : ''}`} onClick={handlePreviewButtonShow}><EyeIcon viewBox="0 0 21 15" /></IconButton>
                </>
                ) : <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}><Loader /></div>
            }
            </div>    

            <TemplatesSwitcher 
                onChange={coverLetterInfoChange} 
                templateId={coverLetter.templateId} 
                onClose={() => setSwitchTemplateOpen(false)}
                open={switchTemplateOpen}
            />

            <CoverLetterResult
                visible={mobileShowPreview}
                closeHandler={handleMobileShowPreview}
                showTemplatesHandler={() => {setMobileShowPreview(false); setSwitchTemplateOpen(true);}}
                //showShareLinkHandler={() => handleOpenShareDialog()}
                coverLetter={coverLetter} 
                coverLetterId={coverLetterId}
            />

            </Container>
        </>
        )
    }

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        created: state.coverLetter.created,
        coverLetters: state.coverLetter.coverLetters,
        createdCoverLetterId: state.coverLetter.createdCoverLetterId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditCoverLetter: ( coverLetterData, token, coverLetterId ) => dispatch( actions.editCoverLetter( coverLetterData, token, coverLetterId ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( CoverLetterBuilder );