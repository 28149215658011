import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DocumentIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M11.8731 3.83812L8.16188 0.126872C8.08065 0.0456497 7.9705 0 7.85564 0H1.59289C0.714572 0 0 0.714572 0 1.59289V13.1906C0 14.0689 0.714572 14.7834 1.59286 14.7834H10.4071C11.2854 14.7834 12 14.0689 12 13.1906V4.14436C12 4.0295 11.9544 3.91935 11.8731 3.83812ZM8.28875 1.47872L10.5213 3.71125H9.01539C8.61473 3.71125 8.28875 3.38526 8.28875 2.98461V1.47872ZM10.4071 13.9172H1.59286C1.1922 13.9172 0.866217 13.5912 0.866217 13.1906V1.59289C0.866217 1.1922 1.1922 0.866218 1.59286 0.866218H7.4225V2.98461C7.4225 3.8629 8.13707 4.57747 9.01536 4.57747H11.1338V13.1906C11.1338 13.5912 10.8078 13.9172 10.4071 13.9172Z"/>
            <path d="M9.71108 12.0605H2.28858C2.04939 12.0605 1.85547 12.2545 1.85547 12.4937C1.85547 12.7328 2.04939 12.9268 2.28858 12.9268H9.71108C9.95027 12.9268 10.1442 12.7328 10.1442 12.4937C10.1442 12.2545 9.95027 12.0605 9.71108 12.0605Z"/>
            <path d="M9.71108 10.207H2.28858C2.04939 10.207 1.85547 10.4009 1.85547 10.6401C1.85547 10.8793 2.04939 11.0732 2.28858 11.0732H9.71108C9.95027 11.0732 10.1442 10.8793 10.1442 10.6401C10.1442 10.4009 9.95027 10.207 9.71108 10.207Z"/>
            <path d="M9.71108 8.34961H2.28858C2.04939 8.34961 1.85547 8.54353 1.85547 8.78272C1.85547 9.02191 2.04939 9.21583 2.28858 9.21583H9.71108C9.95027 9.21583 10.1442 9.02191 10.1442 8.78272C10.1442 8.54353 9.95027 8.34961 9.71108 8.34961Z"/>
            <path d="M2.28858 7.36036H7.85547C8.09466 7.36036 8.28858 7.16644 8.28858 6.92725C8.28858 6.68806 8.09466 6.49414 7.85547 6.49414H2.28858C2.04939 6.49414 1.85547 6.68806 1.85547 6.92725C1.85547 7.16644 2.04939 7.36036 2.28858 7.36036Z"/>
            <path d="M2.28858 5.5051H5.072C5.31119 5.5051 5.50511 5.31119 5.50511 5.07199V2.28858C5.50511 2.04939 5.31119 1.85547 5.072 1.85547H2.28858C2.04939 1.85547 1.85547 2.04939 1.85547 2.28858V5.07199C1.85547 5.31119 2.04939 5.5051 2.28858 5.5051ZM2.72169 2.72169H4.63889V4.63889H2.72169V2.72169Z"/>
        </SvgIcon>
    );
}