import React from 'react';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';


const RadioButton = withStyles({
    root: {
      color: '#E3E8F1',
      '&$checked svg:nth-child(2)': {
        color: '#24D39F',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

export default RadioButton;