
import React from 'react';

import {Grid, IconButton, InputLabel} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';
import LevelToggle from '../../components/UI/LevelToggle/LevelToggle';
import styles from './ResumeBuilder.module.scss';

function SkillsItem(props) {
    return (
        <div className={styles.FormRepeatedItem} >
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="skill"
                        id={`${props.index}-skill`}
                        type="text"
                        label="Skill"
                        value={props.values.skill}
                        changed={props.changed(props.index, 'skill')}
                    />
                </Grid>
                <Grid item xs={10} sm={5}>
                    <InputLabel className={styles.Label}>Level</InputLabel>
                    <LevelToggle skillLevel={props.values.level} changed={props.changed(props.index, 'level')} levelVisibility={props.levelVisibility} />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <IconButton onClick={props.deleted} className={styles.DeleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default SkillsItem;