import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EyeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10.8367 0.271484C6.17007 0.271484 2.15674 3.16482 0.570068 7.27148C2.15674 11.3782 6.17007 14.2715 10.8367 14.2715C15.5034 14.2715 19.5167 11.3782 21.1034 7.27148C19.5167 3.16482 15.5034 0.271484 10.8367 0.271484ZM10.8367 11.9382C8.2234 11.9382 6.17007 9.88482 6.17007 7.27148C6.17007 4.65815 8.2234 2.60482 10.8367 2.60482C13.4501 2.60482 15.5034 4.65815 15.5034 7.27148C15.5034 9.88482 13.4501 11.9382 10.8367 11.9382Z" fill="white"/>
            <circle cx="10.6846" cy="6.99414" r="1.75" fill="white"/>        
        </SvgIcon>
    );
}