
import React from 'react';

import {Grid, InputLabel, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';

import styles from './ResumeBuilder.module.scss';

function ReferenceItem(props) {
    const onFieldChange = (fieldKey, content) => {
        props.changed(props.index, fieldKey, content)();
    }
    
    return (
        <div className={styles.FormRepeatedItem} >
            <IconButton onClick={props.deleted} style={{float: 'right'}} className={styles.DeleteButton}>
                <DeleteIcon />
            </IconButton>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="referentName"
                        id="referentName"
                        type="text"
                        label="Referent's Full Name"
                        value={props.values.referent}
                        changed={props.changed(props.index, 'referent')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="referentCompany"
                        id="referentCompany"
                        type="text"
                        label="Company"
                        value={props.values.company}
                        changed={props.changed(props.index, 'company')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="referentPhone"
                        id="referentPhone"
                        type="text"
                        label="Phone"
                        value={props.values.phone}
                        changed={props.changed(props.index, 'phone')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="referentEmail"
                        id="referentEmail"
                        type="text"
                        label="Email"
                        value={props.values.email}
                        changed={props.changed(props.index, 'email')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={styles.Label}>Description</InputLabel>
                    <TextEditor
                        value={props.values.description}
                        changed={(content) => onFieldChange('description', content)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default ReferenceItem;