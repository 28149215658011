import React from 'react';

import styles from './AccountNavigationItems.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem';

const MobileAccountNavigationItems = () => (
    <>
        <p className={styles.AccountNavigationTitle}>My Account</p>
        <ul className={styles.AccountNavigationItems}>
            <NavigationItem link="/account" exact>Account settings</NavigationItem>
            <NavigationItem link="/faq" exact>FAQ</NavigationItem>
            <NavigationItem link="/logout" exact>Log out</NavigationItem>
        </ul>
    </>
);

export default MobileAccountNavigationItems;