import React from 'react';
import {createUseStyles} from 'react-jss';

import LinkIcon from '@material-ui/icons/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

import styles from './Traditional.module.scss';

function Traditional (props) {
    const mainColor = props.resumeConfiguration.primary_color || '#000';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12 * multiplier;
    const headerTitleSize = 27 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const sectionTitleSize = 20 * multiplier;
    const repeatedTitleSize = 15 * multiplier;
    const repeatedSubtitleSize = 10.5 * multiplier;
    const dateTextSize = 10.5 * multiplier;
    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, sectionTitleSize, repeatedTitleSize, repeatedSubtitleSize, dateTextSize};

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const templateStyles = createUseStyles({
        header: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            padding: '20pt 20pt 7pt 20pt',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 3,
        },
        headerText: {
            flex: '1 1 auto',
            wordBreak: 'break-word',
            lineHeight: 1.2,
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            fontWeight: 700,
            lineHeight: 1.2,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            lineHeight: 1.2,
        },
        link: {
            color: (prop) => prop.mainColor,
        },
        sectionTitle: {
            color: (prop) => prop.mainColor,
            borderBottom: (prop) => `2px solid ${prop.mainColor}`,
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            lineHeight: 1.3,
            fontWeight: 700,
            marginBottom: '12pt',
        },
        main: {
            flex: '1 1 100%',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '20pt 20pt 0',
            zIndex: 3,
            lineHeight: 1.3,
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        description: {
            margin: '7pt 0',
            '& p': {
                lineHeight: 1.3,
            },
            '& ol': {
                margin: 0,
                paddingLeft: '10pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: (prop) => prop.mainColor,
                        fontSize: (prop) => '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
        },
        repeatedItemTitle: {
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            fontWeight: 700,
            color: (prop) => prop.mainColor, 
        },
        repeatedItemSubtitle: {
            color: '#666',
            fontSize: (prop) => `${prop.repeatedSubtitleSize}pt`,
        },
        dateText: {
            textAlign: 'right',
            fontSize: (prop) => `${prop.dateTextSize}pt`,
            color: '#666',
        },
        contactLine: {
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '0 25px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'relative',
            zIndex: 5,
        },
        contactItem: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flex: '0 1 auto',
            lineHeight: 1,
            margin: '.5rem 0',            
            '&:not(:last-child)': {
                paddingRight: '.5rem',
                marginRight: '.5rem',
            },
            '& a': {
                color: (prop) => prop.mainColor,
            },
            '& svg': {
                marginRight: '.5rem',
            },
        },
        contactItemTitle: {
            color: (prop) => prop.mainColor,
            fontWeight: 700,
            marginRight: '.5rem',
        },
        contactInfo: {
            '& $contactItem:not(:last-child)': {
                borderRight: (prop) => `1px solid ${prop.mainColor}`,
            },
        },
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 13,
            borderWidth: '1px',
            borderColor: mainColor,
            borderStyle: 'solid',
            borderRadius: '5px',
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: mainColor,
        },
    }))(LinearProgress);

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<span className={classes.contactItem}><FacebookIcon style={{ color: mainColor }} /> <a href={socials.facebook}>{showUrl(socials.facebook)}</a></span>);
        }
        if (socials.linkedin) {
            socialsList.push(<span className={classes.contactItem}><LinkedInIcon style={{ color: mainColor }} /> <a href={socials.linkedin}>{showUrl(socials.linkedin)}</a></span>);
        }
        if (socials.twitter) {
            socialsList.push(<span className={classes.contactItem}><TwitterIcon style={{ color: mainColor }} /> <a href={socials.twitter}>{showUrl(socials.twitter)}</a></span>);
        }
        if (socials.instagram) {
            socialsList.push(<span className={classes.contactItem}><InstagramIcon style={{ color: mainColor }} /> <a href={socials.instagram}>{showUrl(socials.instagram)}</a></span>);
        }
        if (socials.github) {
            socialsList.push(<span className={classes.contactItem}><GitHubIcon style={{ color: mainColor }} /> <a href={socials.github}>{showUrl(socials.github)}</a></span>);
        }
        if (socials.youtube) {
            socialsList.push(<span className={classes.contactItem}><YouTubeIcon style={{ color: mainColor }} /> <a href={socials.youtube}>{showUrl(socials.youtube)}</a></span>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <div className={styles.SectionWrap}>
            <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</span></p>
            <div className={styles.FlexRow}>
                {props.resume.skillsFields.map(skillItem => 
                    (skillItem.skill) ? (<div className={`movable ${styles.RepeatedItem} ${styles.SkillsItem}`} key={skillItem.id}>
                        <p className={styles.SkillTitle}>{skillItem.skill}</p>
                        {skillItem.level && Boolean(Number(props.resume.skillsVisibility)) ? (
                            <div className={styles.Level}><BorderLinearProgress 
                                variant="determinate" 
                                value={skillsPercentages[skillItem.level]}
                            /></div>
                        ) : (<></>)}
                    </div>)  : (<></>)
                )}
            </div>
        </div>
    ) : <></>;

    return (
        <>
            <div className={classes.header}>
                {props.resume.userImage ? (
                    <img 
                        className={styles.UserImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}
            
                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    {props.resume.jobPosition ? (<p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>) : null}
                </div>
            </div>

            {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
            props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
            props.resume.city || props.resume.postalCode || props.resume.country || 
            (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
            <div className={`${classes.contactLine} ${classes.contactInfo}`}>
                {props.resume.email && (<span className={classes.contactItem}><a href={`mailto:${props.resume.email}`}>{props.resume.email}</a></span>)}
                {props.resume.phoneNumber && (<span className={classes.contactItem}>{props.resume.phoneNumber}</span>)}
                {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={classes.contactItem}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span>)}
                {props.resume.nationality && (<span className={classes.contactItem}><span className={classes.contactItemTitle}>Nationality:</span> {props.resume.nationality}</span>)}
                {props.resume.birthPlace && (<span className={classes.contactItem}><span className={classes.contactItemTitle}>Birth Place:</span> {props.resume.birthPlace}</span>)}
                {props.resume.birthDate && (<span className={classes.contactItem}><span className={classes.contactItemTitle}>Birth Date:</span> {moment(props.resume.birthDate).format("DD/MM/YYYY")}</span>)}
                {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<span className={classes.contactItem}><span className={classes.contactItemTitle}>Driving license:</span> {props.resume.drivingLicenseType.join(', ')}</span>) : (<></>)}
            </div>
            ) : <></>}

            {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                <div className={classes.contactLine}>
                    {props.resume.websiteSocialFields.map((socialItem, index) => (
                        (socialItem.link || socialItem.name) ? (
                            <span key={index} className={classes.contactItem}><LinkIcon style={{ color: mainColor }} /> <a href={socialItem.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                        ) : (<></>)
                    ))}
                    
                    {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                </div>
            ) : <></>}

            <div className={classes.main}>
                {props.resume.careerSummary ? (
                    <div className={styles.SectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</span></p>
                        <div className={`movable ${classes.description}`}>{generateTinyMceElements(props.resume.careerSummary)}</div>
                    </div>
                ) : <></>}

                {resumeType === 'functional' ? skillsSection : <></>}

                {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                    <div className={styles.SectionWrap}>                                
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Work Experience'}</span></p>
                        {props.resume.workExperienceItems.map((workItem, index) => (
                            <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                <div className={styles.RepeatedTitleWrap}>
                                    {workItem.jobTitle && (<p className={classes.repeatedItemTitle}>{workItem.jobTitle}</p>)}
                                    {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                        <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMM YYYY")}</p>
                                    ) : <></>}
                                </div>
                                {(workItem.company || workItem.city) && (<p className={classes.repeatedItemSubtitle}>{workItem.company}{workItem.company && workItem.city ? (<span className={styles.Delimeter}>-</span>) : ''}{workItem.city}</p>)}
                                {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {props.resume.educationFields && props.resume.educationFields.length  ? (
                    <div className={styles.SectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</span></p>
                        {props.resume.educationFields.map((educationItem, index) => (
                            <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                <div className={styles.RepeatedTitleWrap}>
                                    {educationItem.degree && (<p className={classes.repeatedItemTitle}>{educationItem.degree}</p>)}
                                    {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                        <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMM YYYY")}</p>
                                    ) : <></>}
                                </div>
                                {(educationItem.institution || educationItem.city) && (<p className={classes.repeatedItemSubtitle}>{educationItem.institution}{educationItem.institution && educationItem.city ? (<span className={styles.Delimeter}>-</span>) : ''}{educationItem.city}</p>)}
                                {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}
                
                {props.resume.internshipFields && props.resume.internshipFields.length ? (
                    <div className={styles.SectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</span></p>
                        {props.resume.internshipFields.map((internshipItem, index) => (
                            <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                <div className={styles.RepeatedTitleWrap}>
                                    {internshipItem.jobTitle && (<p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}</p>)}
                                    {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                        <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMM YYYY")}</p>
                                    ) : <></>}
                                </div>                                        
                                {(internshipItem.employer || internshipItem.city) && (<p className={classes.repeatedItemSubtitle}>{internshipItem.employer}{internshipItem.employer&& internshipItem.city ? (<span className={styles.Delimeter}>-</span>) : ''}{internshipItem.city}</p>)}
                                {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {props.resume.coursesFields && props.resume.coursesFields.length ? (
                    <div className={styles.SectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</span></p>
                        {props.resume.coursesFields.map((courseItem, index) => (
                            <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                <div className={styles.RepeatedTitleWrap}>
                                    {courseItem.course && (<p className={classes.repeatedItemTitle}>{courseItem.course}</p>)}
                                    {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                        <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMM YYYY")}</p>
                                    ) : <></>}
                                </div>                                            
                                {courseItem.institution && (<p className={classes.repeatedItemSubtitle}>{courseItem.institution}</p>)}
                                {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>)/* (<p>{courseItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}
                
                {props.resume.referencesFields && props.resume.referencesFields.length ? (
                    <div className={styles.SectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</span></p>
                        {!Boolean(Number(props.resume.referencesVisibility)) ? 
                            props.resume.referencesFields.map((referenceItem, index) => (
                                <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                    {referenceItem.referent && (<p className={classes.repeatedItemTitle}>{referenceItem.referent}</p>)}
                                    {referenceItem.company && (<p className={classes.repeatedItemSubtitle}>{referenceItem.company}</p>)}
                                    <p>
                                        <a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}
                                    </p>
                                    {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>)/* (<p>{referenceItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            )) : (<p>References available upon request</p>)}
                    </div>
                ) : <></>}

                {resumeType === 'chronological' ? skillsSection : <></>}

                {props.resume.languagesFields && props.resume.languagesFields.length ? (
                    <div className={styles.SectionWrap}>                            
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</span></p>
                        <div className={styles.FlexRow}>
                            {props.resume.languagesFields.map((languageItem, index) => 
                                (languageItem.language || languageItem.languageLevel) ? (<div key={index} className={`movable ${styles.RepeatedItem} ${styles.LanguagesItem}`}>
                                    {languageItem.language ? (<p className={styles.LanguageTitle}>{languageItem.language}</p>) : (<></>)}
                                    {languageItem.level ? (<span>{languageItem.level}</span>) : (<></>)}
                                </div>) : (<></>)
                            )}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.customFields && props.resume.customFields.length ? (
                    <div className={styles.SectionWrap}>
                        {props.resume.customSectionTitle ? (<p className={`movable ${classes.sectionTitle}`}><span>{props.resume.customSectionTitle}</span></p>) : (<></>)}
                        {props.resume.customFields.map((customItem, index) => (
                            <div key={index} className={`movable ${styles.RepeatedItem}`}>
                                <div className={styles.RepeatedTitleWrap}>
                                    {customItem.customItemTitle && (<p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>)}
                                    {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                        <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMM YYYY")}</p>
                                    ) : <></>}
                                </div>
                                {customItem.city && (<p className={classes.repeatedItemSubtitle}>{customItem.city}</p>)}
                                {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

            </div>
                    
        </>
    )
}

export default Traditional;