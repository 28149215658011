import React from 'react';

import styles from './BuilderToolbar.module.scss';

import bubblesBg from '../../assets/images/resume_bg/bubbles-pattern.png';
import cubesBg from '../../assets/images/resume_bg/cubes-pattern.png';
import dottedCirclesBg from '../../assets/images/resume_bg/dotted-circles-pattern.png';
import gradientDotsBg from '../../assets/images/resume_bg/gradient-dots-pattern.png';
import toysBg from '../../assets/images/resume_bg/toys-pattern.png';
import wavesBg from '../../assets/images/resume_bg/waves-pattern.png';

const DesignItem = (props) => {
    const handlePatternChange = (pattern) => {
        if (props.pattern === pattern) {
            props.onPatternChange(null);
        } else {
            props.onPatternChange(pattern);
        }
    };

    const patterns = {
        cubes: cubesBg,
        toys: toysBg,
        dottedCircles: dottedCirclesBg,
        bubbles: bubblesBg,
        gradientDots: gradientDotsBg,
        waves: wavesBg,
    }

    let patternsList = Object.keys(patterns).map( patternKey => (
        <li key={patternKey} className={`${styles.PatternItem} ${props.pattern === patternKey ? styles.Active : ''}`} style={{backgroundImage: `url(${patterns[patternKey]})`}} onClick={() => handlePatternChange(patternKey)}></li>
    ) );

    return (
        <>
            <p>Background patterns</p>
            {patternsList && (<ul className={styles.PatternsList}>{patternsList}</ul>)}
        </>
    );
}

export default DesignItem;