import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

const skillsPercentages = {
    'novice': 20,
    'beginner': 40,
    'skillful': 60,
    'experienced': 80,
    'expert': 100
}

const languagesPercentages = {
    'Native Speaker': 100, 
    'Highly proficient': 80, 
    'Very good command': 60, 
    'Good working knowledge': 40,
    'Working knowledge': 20, 
    'C2': 100, 
    'C1': 100, 
    'B2': 80, 
    'B1': 60, 
    'A2': 40, 
    'A1': 20
}


//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });


// Create Document Component
const CreativePdf = (props) => {
    
    const mainColor  = props.resumeConfiguration.primary_color || '#0a4870';
    const iconColor = props.resumeConfiguration.secondary_color || '#fff';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    const fontSize = props.resumeConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 12.5 * multiplier;
    const titleSize = 20 * multiplier;
    const subtitleSize = 16 * multiplier;
    const sideTitleSize = 14 * multiplier;
    const sectionTitleSize = 17 * multiplier;
    const dateTextSize = 11.5 * multiplier;
    
    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });
    
    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
        },
        table: {
            fontSize: mainSize,
            display: "flex",
            flexDirection: "column",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        mainContentCell: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "stretch",
            maxWidth: '63%',
            width: '63%',
            paddingHorizontal: 20,
            lineHeight: 1.3,
        },
        detailsContentBefore: {
            position: 'absolute',
            top: 0,
            height: 842,
            width: '35%',
            backgroundColor: mainColor,
        },
        detailsContentCell: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "stretch",
            maxWidth: '35%',
            width: '35%',
            height: '100%',
            paddingHorizontal: 30,
            backgroundColor: mainColor,
            color: iconColor,
            lineHeight: 1.5,
        },
        sideHeader: {
            textAlign: 'center',
        },
        userImage: {
            width: '40mm',
            height: '40mm',
            objectFit: 'cover',
            borderRadius: '20mm',
            borderWidth: 2,
            borderColor: iconColor,
            marginHorizontal: 'auto',
            marginBottom: 5,
        },
        title: {
            fontSize: titleSize,
            fontWeight: 700,
        },
        subtitle: {
            fontSize: subtitleSize,
        },
        subtitleBefore: {
            width: 37,
            height: 1,
            backgroundColor: iconColor,
            marginHorizontal: 'auto',
            marginVertical: 10,
        },
        sideItem: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "stretch",
            marginBottom: 10,
        },
        sideRepeatedItem: {
            paddingBbottom: 7,
            lineHeight: 1.7,
        },
        sideLink: {
            color: iconColor,
            textDecoration: 'none',
            maxWidth: 50,
        },
        sideTitle: {
            fontSize: sideTitleSize,
            fontWeight: 700,
            marginBottom: 7,
        },
        sideSubtitle: {
            marginTop: 7,
            marginBottom: 2,
            fontWeight: 700,
        }, 
        progressLine: {
            width: '100%',
            height: 10,
            marginBottom: 5,
        },
        contactLine: {
            marginBottom: 7,
        },
        contactItem: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            marginBottom: 3,
            maxWidth: '100%',
        },
        sectionWrap: {
            marginBottom: 5,
        },
        sectionTitleWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: 5,
        },
        sectionTitle: {
            display: 'block',
            fontSize: sectionTitleSize,
            fontWeight: 700,
            color: mainColor,
        },
        repeatedItem: {
            marginBottom: 5,
        },
        repeatedItemTitle: {
            fontWeight: 700,
        },
        dateText: {
            color: '#8190AA',
            fontSize: dateTextSize,
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 15,
            height: 15,
            marginRight: 6,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
            display: 'flex',
        },
    });
    
    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'bullet'));
        }
    }
    
    const showUrl = (url) => {
        let croppedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        return wordWrap(croppedUrl, 18);
    }
    
    const wordWrap = (str, maxWidth) => {
        let newLineStr = "\n";
        let res = '';
        while (str && str.length > maxWidth) {                 
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join('');
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join('');
                str = str.slice(maxWidth);
            }
    
        }
    
        return res + str;
    }
    
    const testWhite = (x) => {
        let white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    };
    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.sideLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.sideLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.sideLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.sideLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.sideLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(iconColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.sideLink}>{showUrl(socials.youtube)}</Link></View>);
        }
    
        return socialsList;
    }

    return (
        <Document>
            <Page style={styles.body}>
                {addPdfPattern(props.resumeConfiguration.pattern)}
                <View style={styles.detailsContentBefore} fixed></View>
                <View style={styles.table}>
                    <View style={styles.row}>
                        <View style={styles.detailsContentCell} >
                            <View style={styles.sideHeader}>
                                {props.resume.userImage ? (
                                    <Image 
                                        style={styles.userImage} 
                                        src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}

                                    />
                                ) : <></>}
                                <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                                <Text style={styles.subtitle}>{props.resume.jobPosition}</Text>
                                <View style={styles.subtitleBefore}></View>
                            </View>

                            <View style={styles.sideItem}>
                                <Text style={styles.sideTitle}>Details</Text>
                                <Text style={styles.contactLine}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                                {props.resume.phoneNumber ? (<Text>Tel {props.resume.phoneNumber}</Text>) : <></>}
                                <Text style={styles.sideLink} src={`mailto:${props.resume.email}`}>{wordWrap(props.resume.email, 20)}</Text>

                                {(props.resume.birthPlace || props.resume.birthDate) ? (
                                    <>
                                        <Text style={styles.sideSubtitle}>{props.resume.birthDate ? 'Date' : ''}{props.resume.birthDate && props.resume.birthPlace ? ' / ' : ''}{props.resume.birthPlace ? 'Place' : ''} of birth</Text>
                                        {props.resume.birthDate ? (<Text>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                                        {props.resume.birthPlace ? (<Text>{props.resume.birthPlace}</Text>) : <></>}
                                    </>
                                ) : (<></>)}

                                {props.resume.nationality ? (
                                    <>
                                        <Text style={styles.sideSubtitle}>Nationality</Text>
                                        <Text>{props.resume.nationality}</Text>
                                    </>
                                ) : (<></>)}
                                
                                {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (
                                    <>
                                        <Text style={styles.sideSubtitle}>Driving license</Text>
                                        <Text>{props.resume.drivingLicenseType.join(', ')}</Text>
                                    </>
                                ) : (<></>)}
                            </View>

                            {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                                <View style={styles.sideItem}>
                                    {props.resume.socialSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.socialSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Links</Text>)}
                                    {props.resume.websiteSocialFields.map(socialItem => (
                                        (socialItem.link || socialItem.name) ? (
                                            <Text key={socialItem.id} style={styles.contactItem}><Link style={styles.sideLink} src={socialItem.link}>{socialItem.name}</Link></Text>
                                        ) : (<></>)
                                    ))}
                                    {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                                </View>
                            ) : <></>}
                            
                            {props.resume.skillsFields && props.resume.skillsFields.length ? (
                                <View style={styles.sideItem}>
                                    {props.resume.skillSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.skillSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Skills</Text>)}
                                    {props.resume.skillsFields.map(skillItem => 
                                        (<View key={skillItem.id} wrap={false} style={styles.sideRepeatedItem}>
                                            {skillItem.skill ? (<Text>{skillItem.skill}</Text>) : (<></>)}
                                            {(skillItem.skill && skillItem.level && Boolean(Number(props.resume.skillsVisibility))) ? (
                                                <Canvas
                                                    paint={(painter, availableWidth) => {
                                                        let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                                        painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(1.5).strokeColor(iconColor).stroke();
                                                        painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(iconColor).fill();
                                                    }}
                                                    style={styles.progressLine}
                                                />
                                            ) : (<></>)}
                                        </View>)
                                    )}
                                </View>
                            ) : <></>}

                            {props.resume.languagesFields && props.resume.languagesFields.length ? (
                                <View style={styles.sideItem}>
                                    {props.resume.languageSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.languageSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Languages</Text>)}
                                    {props.resume.languagesFields.map(languageItem => 
                                        (<View key={languageItem.id} wrap={false} style={styles.sideRepeatedItem}>
                                            {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                            {languageItem.language && languageItem.level ? (<Canvas
                                                    paint={(painter, availableWidth) => {
                                                        let percentageValue = languagesPercentages[languageItem.level] * availableWidth / 100;
                                                        painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(1.5).strokeColor(iconColor).stroke();
                                                        painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(iconColor).fill();
                                                    }}
                                                    style={styles.progressLine}
                                                />) : (<></>)
                                            }
                                        </View>)
                                    )}
                                </View>
                            ) : <></>}

                        </View>

                        <View style={styles.mainContentCell}>
                            {props.resume.careerSummary ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        <Text style={styles.sectionTitle}>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</Text>
                                    </View>
                                    {generateTinyMceElements(props.resume.careerSummary)}
                                </View>
                            ) : <></>}
                            
                            {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />                             
                                        {props.resume.experienceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Work Experience</Text>)}
                                    </View>
                                    {props.resume.workExperienceItems.map(workItem => (
                                        <View style={styles.repeatedItem} key={workItem.id}>
                                            {(workItem.jobTitle || workItem.company || workItem.city) ? (<Text style={styles.repeatedItemTitle}>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ' - ' : ''} {workItem.company}{((workItem.jobTitle || workItem.company) && workItem.city ) ? ', ' : ''}{workItem.city}</Text>) : (<></>)}
                                            {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {workItem.description ? generateTinyMceElements(workItem.description): <></> }
                                        </View>
                                    ))}
                                </View>
                            ) : <></>}

                            {props.resume.educationFields && props.resume.educationFields.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />   
                                        {props.resume.educationSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.educationSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Education</Text>)}          
                                    </View>
                                    {props.resume.educationFields.map(educationItem => (
                                        <View style={styles.repeatedItem} key={educationItem.id}>
                                            {(educationItem.degree || educationItem.institution || educationItem.city) ? (<Text style={styles.repeatedItemTitle}>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ' - ' : ''}{educationItem.institution}{((educationItem.degree || educationItem.institution) && educationItem.city ) ? ', ' : ''}{educationItem.city}</Text>) : (<></>)}
                                            {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {educationItem.description ? generateTinyMceElements(educationItem.description) : <></> }
                                        </View>
                                    ))}
                                </View>
                            ) : <></>}

                            {props.resume.internshipFields && props.resume.internshipFields.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        {props.resume.internshipSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Internship</Text>)}
                                    </View>
                                    {props.resume.internshipFields.map(internshipItem => (
                                        <View style={styles.repeatedItem} key={internshipItem.id}>
                                            {(internshipItem.jobTitle || internshipItem.employer || internshipItem.city) ? (<Text style={styles.repeatedItemTitle}>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ' - ' : ''}{internshipItem.employer}{((internshipItem.jobTitle || internshipItem.employer) && internshipItem.city ) ? ', ' : ''}{internshipItem.city}</Text>) : (<></>)}
                                            {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {internshipItem.description ? generateTinyMceElements(internshipItem.description) : <></> }
                                        </View>
                                    ))}
                                </View>
                            ) : <></>}

                            {props.resume.coursesFields && props.resume.coursesFields.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        /> 
                                        {props.resume.courseSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.courseSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Courses</Text>)}
                                    </View>
                                    {props.resume.coursesFields.map(courseItem => (
                                        <View style={styles.repeatedItem} key={courseItem.id}>
                                            {(courseItem.course || courseItem.institution) ? (<Text style={styles.repeatedItemTitle}>{courseItem.course}{(courseItem.course && courseItem.institution) ? ' - ' : ''}{courseItem.institution}</Text>) : (<></>)}
                                            {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {courseItem.description ? generateTinyMceElements(courseItem.description) : <></> }
                                        </View>
                                    ))}
                                </View>
                            ) : <></>}

                            {props.resume.referencesFields && props.resume.referencesFields.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        /> 
                                        {props.resume.referenceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>References</Text>)}
                                    </View>
                                    {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                        props.resume.referencesFields.map(referenceItem => (
                                            <View style={styles.repeatedItem} key={referenceItem.id}>
                                                {(referenceItem.referent || referenceItem.company) ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ' from ' : ''}{referenceItem.company}</Text>) : (<></>)}
                                                {(referenceItem.email || referenceItem.phone) ? (<Text><Link style={styles.link} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}</Text>) : (<></>)}
                                                {referenceItem.description ? generateTinyMceElements(referenceItem.description) : <></> }
                                            </View>
                                        )) : (<Text>References available upon request</Text>)}
                                </View>
                            ) : <></>}

                            {props.resume.customFields && props.resume.customFields.length ? (
                                <View style={styles.sectionWrap}>
                                    <View style={styles.sectionTitleWrap}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(mainColor).path('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        {props.resume.customSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text>) : (<></>)}
                                    </View>
                                    {props.resume.customFields.map(customItem => (
                                        <View style={styles.repeatedItem} key={customItem.id}>
                                            {(customItem.customItemTitle || customItem.city) ? (<Text style={styles.repeatedItemTitle}>{customItem.customItemTitle}{(customItem.customItemTitle && customItem.city) ? ', ' : ''}{customItem.city}</Text>) : (<></>)}
                                            {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMM YYYY")}</Text>) : (<></>)}
                                            {customItem.description ? generateTinyMceElements(customItem.description) : <></> }
                                        </View>
                                    ))}
                                </View>
                            ) : <></>}
                            
                        </View>

                    </View>
                </View>
            </Page>
        </Document>
    );
}
export default CreativePdf;