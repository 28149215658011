import React from 'react';
import { View, Text, Image, Link, Canvas } from '@react-pdf/renderer';
import {JsonToPdfComponent} from './jsonToPdf';

import LinkIcon from '../assets/images/template_icons/link-icon.png';

export const createParagraphTextElement = (node, customStyles) => {
    return (<Text style={customStyles.paragraph}>
        {node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child, customStyles))}
    </Text>)
};

export const createStrongTextElement = (node, customStyles) => {
    return (<Text style={ {fontWeight: 'bold' }}>
        {node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child, customStyles))}
    </Text>)
};

export const createEmphasisTextElement = (node, customStyles) => {
    return React.createElement(
        Text,
        {
            //add the custom styles you have set for the pdf component
            style: {fontStyle: 'italic'},
        },
        node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child, customStyles))
    )
};

export const createSpanTextElement = (node, attributes, customStyles) => {
    let styles = {}
    if (attributes && attributes[0] && attributes[0].length) {

        let underlined =  attributes[0].find(element => element === 'text-decoration: underline;');
        if (underlined) {
            styles = {
                ...styles,
                textDecoration: 'underline'
            }
        }
    }
    return React.createElement(
        Text,
        {
            //add the custom styles you have set for the pdf component
            style: {...styles},
            wrap: false,
        },
        node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child, customStyles))
    )
};

export const createLinkElement = (node, attributes, customStyles) => {
    let linkUrl = '';
    let attributesObj = {};
    if (attributes && attributes.length) {
        attributesObj = Object.fromEntries(attributes);
        if (Object.keys(attributesObj).length !== 0 && attributesObj.constructor === Object) {
            linkUrl = attributesObj.href;
        }
    }
    console.log(customStyles);

    return (<Text style={customStyles.link} wrap={false}>
            {node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child, customStyles))}
            <Link src={linkUrl} style={{marginLeft: 10,}}>
                <Image
                    style={ {
                        width: 10,
                        height: 12,
                        objectFit: 'contain',
                        marginTop: 15,
                    }}
                    src={LinkIcon}
                />
            </Link>
        </Text>)
};

export const createListComponent = (node, tagName, customStyles, mainColor, dashListIcon) => {
    return (<View style={customStyles.list}>
                {node.childNodes && node.childNodes.filter(child => child.tagName === 'li').map((child, index) => JsonToPdfComponent(child, customStyles, mainColor, dashListIcon, tagName, index))}
            </View>)
}

const DashIconCanvas = (props) => {
    return (<Canvas paint={(painter) => {
        painter.strokeColor(props.color).moveTo(0,4).lineTo(7,4).stroke();
    }} style={ {
        width: 8,
        height: 8,
        objectFit: 'contain',
        marginTop: 3,
        marginRight: 7,
    }} />)
}

const BulletIconCanvas = (props) => {
    return (<Canvas paint={(painter) => {
        painter.circle(4,4,2).fillColor(props.color).fill();
    }} style={ {
        width: 8,
        height: 8,
        marginTop: 5,
        marginRight: 7,
    }} />)
}

const SquareIconCanvas = (props) => {
    return (<Canvas paint={(painter) => {
        painter.rect(2,0,4,4).strokeColor(props.color).stroke();
    }} style={ {
        width: 8,
        height: 8,
        marginTop: 5,
        marginRight: 7,
    }} />)
}

export const createListItemComponent = (node, listType, index, mainColor, dashListIcon) => {
    //const icon = dashListIcon ? (<DashIconCanvas color={mainColor} />) : (<BulletIconCanvas color={mainColor} />);
    let icon = (<BulletIconCanvas color={mainColor} />);
    if (dashListIcon === 'dash') {
        icon = (<DashIconCanvas color={mainColor} />);
    } else if (dashListIcon === 'square') {
        icon = (<SquareIconCanvas color={mainColor} />);
    } else {
        icon = (<BulletIconCanvas color={mainColor} />);
    }
    return (<View wrap={false} style={{
        display: 'flex',
        flexDirection: 'row',
    }}>
                {listType === 'ul' ? icon : (<Text style={{width: 8, marginRight: 7,}}>{`${index + 1}. `}</Text>)}<Text> {node.childNodes && node.childNodes.map(child => JsonToPdfComponent(child))} </Text>
            </View>)
}


export const createTextComponent = (content) => {
    if (content === String.fromCharCode(160)) return <Text wrap={false} style={{margin: 0}}>{'\n'}</Text>;
    return (content.trim()) ? (<Text wrap={false} style={{margin: 0}}>{`${content}`}</Text>) : <></>
    /* return content; */
};

export const createBreakLineComponent = (content) => {
    return "\n";
}