import React, { useState, useEffect, useRef } from 'react';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import LanguagesItem from './LanguagesItem';
import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import styles from './ResumeBuilder.module.scss';

import languages from '../../shared/languagesJson';

function LanguagesSection(props) {
    const [languagesPanelOpen, setLanguagesPanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('Languages');

    const [languagesFields, setLanguagesFields] = useState([
        { language: '', level: '' }
    ]);

    const [languagesDropdown, setLanguagesDropdown] = useState([]);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    
    useEffect(() => {
        if (props.resume) {
            if (props.resume.languagesFields && props.resume.languagesFields.length) {
                console.log('Languages Info Mounted');
                setLanguagesFields(props.resume.languagesFields);
            }
            if (props.resume.languageSectionTitle) {
                setSectionTitle(props.resume.languageSectionTitle);
            }
            setDataLoaded(true);
        }
        
        let languagesArr = [];
        for ( let key in languages ) {
            languagesArr.push(languages[key].name);
        }
        setLanguagesDropdown(languagesArr);
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.languagesFields && props.resume.languagesFields.length) {
                console.log('Languages Reload');
                setLanguagesFields(props.resume.languagesFields);
            }
            if (props.resume.languageSectionTitle) {
                setSectionTitle(props.resume.languageSectionTitle);
            }
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ languagesFields: languagesFields});
        }
    }, [languagesFields]);

/*     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ languagesFields: languagesFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, languagesFields]);
 */
    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ languageSectionTitle: sectionTitle});
        }
    }, [dataLoaded, sectionTitle]);

    const handleAddLanguagesFields = () => {
        const values = [...languagesFields];
        values.push({ language: '', level: '' });
        setLanguagesFields(values);
        setChanged(true);
    };

    const handleRemoveLanguagesFields = index => {
        const values = [...languagesFields];
        values.splice(index, 1);
        setLanguagesFields(values);
        handleCloseDeleteEntryDialog();
        setChanged(true);
    };

    const handleLanguagesInputChange = (index, fieldKey, content) => (event) => {
        const values = [...languagesFields];
        if (fieldKey === 'language') {
            values[index][fieldKey] = content;
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setLanguagesFields(values);
        setChanged(true);
    }
    
    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setLanguagesPanelOpen(!languagesPanelOpen)} className={styles.PanelToggle}>
                {languagesPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="language-section-title"
                    value={sectionTitle}
                    placeholder="Languages"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
                <IconButton onClick={props.deleted} style={{float: 'right'}}>
                    <DeleteIcon />
                </IconButton>    
            </div>
            { languagesPanelOpen ? (
                <>
                    {languagesFields.map((inputField, index) => (
                        <LanguagesItem 
                            key={`${inputField}~${index}`} 
                            values={languagesFields[index]} 
                            changed={handleLanguagesInputChange}
                            deleted={() => handleOpenDeleteEntryDialog(index)}
                            index={index}
                            languages={languagesDropdown}
                        />
                    ))}
                    <Link
                        component="a"
                        variant="body2"
                        className={styles.FormLink}
                        onClick={() => handleAddLanguagesFields()}
                    >
                        <AddCircleIcon /> Add language
                    </Link>
                </>
            ) : null }
            <Dialog 
                open={deleteEntryDialog.open}
                closed={handleCloseDeleteEntryDialog}
            >
                <h2>Delete Entry</h2>
                <p>Are you sure you want to delete this entry?</p>   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={() => handleRemoveLanguagesFields(deleteEntryDialog.deletedEntry)}
                    className={styles.Button}
                >
                    Delete
                </Button>
                <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
            </Dialog>
        </Paper>     
    )
}

export default LanguagesSection;