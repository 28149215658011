import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { pdfjs, Document, Page } from 'react-pdf';
import { pdf } from '@react-pdf/renderer';

import styles from './PDFViewer.module.scss';

import * as actions from '../../store/actions/index';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = (props) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        const child = React.Children.only(props.children);
        pdf(child).toBlob().then(blob => {
            setPdfUrl(URL.createObjectURL(blob));
        })
    }, [ props.resume ]);
    

    /* useEffect(() => {
        const child = React.Children.only(props.children);
        setTimeout(() => {
            pdf(child).toBlob().then(blob => {
                props.onCreatePdf(URL.createObjectURL(blob));
            });
        }, 1000);

    }, [ props.resume, props.resumeConfiguration ]); */

    /* useEffect(() => {
        const child = React.Children.only(props.children);
        pdf(child).toBlob().then(blob => {
            props.onCreatePdf(URL.createObjectURL(blob));
        })
    }, [ props.resumeConfiguration ]); */

    return (
        <div className={styles.PDFViewer}>
            <Document 
                file={pdfUrl} 
                onLoadSuccess={({numPages}) => props.loaded(numPages)} 
                className={styles.Document} 
                renderMode="canvas"
            >
                <IconButton onClick={props.prevPageHangler} disabled={props.pageNumber <= 1} >
                    <ChevronLeftIcon />
                </IconButton> 
                <Page pageNumber={props.pageNumber} renderTextLayer={false} scale={1.75} />
                
                <IconButton onClick={props.nextPageHangler} disabled={props.pageNumber >= props.numPages} >
                    <ChevronRightIcon />
                </IconButton>
            </Document>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pdfUrl: state.resume.pdfUrl,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreatePdf: ( pdfUrl ) => dispatch( actions.createPdfSuccess( pdfUrl ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( PDFViewer );