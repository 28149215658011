
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import PDFViewer from '../PDFViewer/PDFViewer';
import ResumeViewer from '../PDFViewer/ResumeViewer';

import DifferentColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/DifferentColorsPdf';
import NoColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/NoColorsPdf';
import BlankPdf from '../../components/PDFTemplates/BlankPdf';

import styles from './CoverLetterBuilder.module.scss';

import {IconButton} from '@material-ui/core';
import SavedIcon from '../../components/UI/Icons/SavedIcon';
import SpinnerIcon from '../../components/UI/Icons/SpinnerIcon';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

function СoverLetterResult(props) {
    
    //Prevent PDF Render Onload Error
    const [open, setOpen] = useState(false);

    const resumeViewerRef = useRef(null);

    // Callback function to execute when mutations are observed
    const setResumeParentHeight = function(mutationsList, observer) {
        if (resumeViewerRef.current) {
            let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height + 100;
            //let resumeHeight = window.getComputedStyle(resumeViewerRef.current).height;
            setContentHeight(resumeHeight);
        }
    };
    
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(setResumeParentHeight);

    useEffect(() => {
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('resume-result');
        
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        observer.observe(targetNode, config);

        return () => {
            observer.disconnect();
        }
    }, []);

    /* useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 1);
    }); */

    const [numPages, setNumPages] = useState(1);
    const pageNumber = 1;

    const [contentHeight, setContentHeight] = useState(0);

    const selectPdfTemplate = () => {
        switch(Number(props.coverLetter.templateId)) {
            case 1:   return <NoColorsPdf coverLetter={props.coverLetter} coverLetterConfiguration={props.configuration}/>;
            case 2:   return <DifferentColorsPdf coverLetter={props.coverLetter} coverLetterConfiguration={props.configuration} />;
            default:  return <BlankPdf />;
        }
    }

    function onDocumentLoadSuccess(numPages) {
        setNumPages(numPages);
    }

    return (
        <div id="resume-result" className={`${styles.ResumeResult} ${props.visible ? styles.Visible : ''} ${props.fixed ? styles.Fixed : ''}`}>
            <div className={styles.ResumeInfo}>
                {props.isAuthenticated ? (
                    <div className={styles.ResumeStatus}>
                        {props.loading ? (<><SpinnerIcon viewBox='0 0 15 20' /><span>Saving</span></>) : (<><SavedIcon viewBox='0 0 20 20' /><span>Saved</span></>)}
                    </div>
                ) : (
                    <div className={`${styles.ResumeStatus} ${styles.NotAuth}`}><ErrorIcon /><span>Sign Up to Save Your Resume</span></div>
                )}
                
                {/* <div className={styles.ResumePagination}>{pageNumber}/{numPages}</div> */}
                <IconButton onClick={props.closeHandler} className={styles.ResumeCloseButton}>
                    <CloseIcon viewBox='3 3 18 18'/>
                </IconButton>     
            </div>
            
                
            
            <div className={styles.ResultContent} style={{minHeight: contentHeight}} >
                <ResumeViewer coverLetter={props.coverLetter} templateId={props.coverLetter.templateId} coverLetterConfiguration={{}} documentId={props.coverLetterId} ref={resumeViewerRef} />
            
                {open && (
                    <PDFViewer
                        resume={props.coverLetter}
                        resumeId={props.coverLetterId} 
                        loaded={onDocumentLoadSuccess}
                        pageNumber={pageNumber}
                        numPages={numPages}
                        showTemplatesHandler={props.showTemplatesHandler}
                        showShareLinkHandler={props.showShareLinkHandler}
                        className={styles.ViewerControls}
                    >
                        {selectPdfTemplate()}
                    </PDFViewer>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.coverLetter.loading,
        isAuthenticated: state.auth.token !== null,
        configuration: state.coverLetter.coverLetterConfiguration,
    };
};

export default connect( mapStateToProps )( СoverLetterResult );