import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import axios from '../../axios-resumes';

import Button from '../../components/UI/Button/Button';
  
import styles from './TemplatesList.module.scss';

import Container from '@material-ui/core/Container';

import {apiUrl} from '../../config';
import * as actions from '../../store/actions/index';


function TemplatesList(props) {
    const [templates, setTemplates] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [redirectToBuilder, setRedirectToBuilder] = useState(false);
    const [hover, setHover] = useState(false);

    const  [builderRedirect, setBuilderRedirect] = useState(null);

    const selectTemplate = (templateId) => {
        if (props.isAuthenticated) {
            const document = {
                templateId: templateId,
                userId: props.userId,
                firstName: props.userFirstName,
                lastName: props.userLastName,
                email: props.userEmail,
            }
            if (props.isCoverLetter) {
                props.onCreateCoverLetter(document, props.token);
            } else {
                props.onCreateResume(document, props.token);
            }
        } else {    
            setRedirectToBuilder(true);
            setBuilderRedirect(<Redirect to={{ pathname: '/sign-up', state: { templateId: templateId, isCoverLetter: props.isCoverLetter } }} />);      
        }           
    }

    useEffect(() => {
        let templatesUrl = props.isCoverLetter ? '/cover-letter/templates' : '/templates';
        axios.get( templatesUrl, { headers: {
            'Authorization': `Bearer ${props.token}`
        }})
        .then( response => {
            console.log('Templates Loaded');
            setTemplates(response.data.templates);
            setDataLoaded(true);
        })
        .catch( err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        if (dataLoaded && props.createdResumeId !== '') {
            setRedirectToBuilder(true);
            setBuilderRedirect(<Redirect to={{ pathname: '/resume-builder', search: `?resumeId=${props.createdResumeId}`}} />); 
        }
    }, [props.createdResumeId]);

    useEffect(() => {
        if (dataLoaded && props.createdCoverLetterId !== '') {
            setRedirectToBuilder(true);
            setBuilderRedirect(<Redirect to={{ pathname: '/cover-letter-builder', search: `?coverLetterId=${props.createdCoverLetterId}`}} />); 
        }
    }, [props.createdCoverLetterId]);

    const templatesList = templates.map((template, index) => {
        return (
            <div className={styles.TemplateItem} key={index} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => selectTemplate(template.id)}>
                <img src={`${apiUrl}/storage${template.preview_image}`} alt={template.title} />
                <Button variant="contained" color="primary" /* clicked={() => selectTemplate(template.id)} */ className={styles.TemplateButton}>Use this template</Button>
            </div>
        )
    });

    return dataLoaded && (
        <Container maxWidth={false} className={styles.TemplatesWrap}>
            {redirectToBuilder && builderRedirect}
            <h1>Select a template for your {props.isCoverLetter ? 'cover letter' : 'resume'}</h1>
            <p className={styles.PageDesription}>Job-winning {props.isCoverLetter ? 'cover letter' : 'resume'} templates</p>
            <div className={`${styles.TemplatesList} ${hover ? styles.TemplatesListContentHover : null} `}>
                {templatesList}
            </div>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        userEmail: state.auth.userEmail,
        userFirstName: state.auth.userFirstName,
        userLastName: state.auth.userLastName,
        createdResumeId: state.resume.createdResumeId,
        createdCoverLetterId: state.coverLetter.createdCoverLetterId,
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreateResume: ( resumeData, token ) => dispatch( actions.createResume( resumeData, token ) ),
        onCreateCoverLetter: ( coverLetterData, token ) => dispatch( actions.createCoverLetter( coverLetterData, token ) ),
    };
};

const TemplatesListWithRouter = withRouter(TemplatesList);
export default connect( mapStateToProps, mapDispatchToProps )( TemplatesListWithRouter );