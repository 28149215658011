import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DesignToolsIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M17.884 7.61517C17.7836 7.49048 17.6328 7.41797 17.4726 7.41797H2.63672C2.38953 7.41797 2.1753 7.58936 2.12174 7.83092L0.0124947 17.3933C-0.0221123 17.5493 0.0160652 17.7126 0.115904 17.8379C0.216292 17.9624 0.402373 18.0351 0.562499 18.0351H15.3632C15.6104 18.0351 15.8246 17.8635 15.8782 17.622L17.9876 8.05957C18.022 7.90357 17.984 7.74028 17.884 7.61517ZM13.746 15.3983C13.746 15.6897 13.5102 15.9257 13.2186 15.9257H9.00002C8.70847 15.9257 8.47268 15.6897 8.47268 15.3983V13.2889C8.47268 12.9974 8.70847 12.7616 9.00002 12.7616H13.2186C13.5102 12.7616 13.746 12.9974 13.746 13.2889V15.3983Z"/>
            <path d="M9.52734 13.8164H12.6913V14.8711H9.52734V13.8164Z"/>
            <path d="M2.10938 6.4531V4.25391H0.527345C0.235932 4.25391 0 4.4897 0 4.78125V12.5872L1.09232 7.60228C1.21413 7.05309 1.60483 6.6293 2.10938 6.4531Z"/>
            <path d="M10.5819 6.36316V4.5798C10.906 4.20887 11.1093 3.72946 11.1093 3.19923C11.1093 2.17544 9.67924 0.521303 9.39236 0.202012C9.19254 -0.0204622 8.80733 -0.0204622 8.60752 0.202012C8.32064 0.521303 6.89062 2.17544 6.89062 3.19923C6.89062 3.72946 7.09387 4.20874 7.41797 4.5798V6.36316H10.5819Z"/>
            <path d="M17.6003 1.59713L14.7493 0.225204C14.6221 0.163955 14.4774 0.155165 14.3461 0.202956C14.2143 0.248824 14.1062 0.345642 14.0453 0.471847L11.6367 5.47819V6.36315H15.3428L13.8882 5.66331C13.6257 5.53711 13.5154 5.22194 13.6416 4.95936C13.7682 4.69775 14.0839 4.58706 14.3455 4.71272L16.2467 5.62733L16.7038 4.67701L14.8029 3.76254C14.5401 3.63633 14.43 3.32116 14.5561 3.05859C14.6834 2.79642 14.9995 2.68574 15.26 2.81194L17.1612 3.72642L17.8469 2.30108C17.9731 2.03836 17.863 1.72319 17.6003 1.59713Z"/>
            <path d="M3.16406 6.36315H6.36329V4.94179C6.22294 4.73016 6.11692 4.49684 6.03027 4.25391H3.16406V6.36315Z"/>
            <path d="M5.28124 0.387349C5.13814 -0.0431789 4.4243 -0.0431789 4.28121 0.387349L3.34668 3.19917H5.83591C5.83591 2.95761 5.8903 2.70506 5.9661 2.45018L5.28124 0.387349Z"/>
       </SvgIcon>
    );
}