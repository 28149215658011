import React, {useState, forwardRef} from 'react';

import styles from './Input.module.scss';
import { makeStyles } from '@material-ui/core/styles';

import {OutlinedInput, InputLabel, FormControl, IconButton, InputAdornment }  from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: '#E3E8F1',
        },
        "&:hover $notchedOutline": {
            borderColor: '#8190AA',
            borderWidth: '1px',
        },
        "&$focused $notchedOutline": {
            borderColor: '#8190AA',
            borderWidth: '1px',
        }
    },
    input: {
        padding: '17px 14px',
    },
    focused: {},
    notchedOutline: {},
    error: {
        borderColor: '#F03153 !important',
        "&:hover $notchedOutline": {
            borderColor: '#F03153',
        },
        "&$focused $notchedOutline": {
            borderColor: '#F03153',
        }
    }
}));

const UIInput = (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const outlinedInputClasses = useOutlinedInputStyles();

    let validationError = null;
    let error = false;
	if (props.invalid && props.shouldValidate && props.touched) {
        error = true;
        if (props.validationMessage) {
            validationError = <p className={styles.ValidationError}>{props.validationMessage}</p>;
        } else {
            validationError = <p className={styles.ValidationError}>Please enter a valid {props.name}</p>;
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    return (
        <FormControl className={`${styles.FormControl} ${props.classes}`} variant={props.variant}>
            <InputLabel className={styles.Label} shrink htmlFor={props.id}>{props.label}</InputLabel>
            { props.type === 'password' ? (
                <OutlinedInput
                    {...props.inputParams}
                    error={error}
                    classes={outlinedInputClasses}
                    id={props.id}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={props.value || ''}
                    placeholder={props.placeholder || ''}
                    onChange={props.changed}
                    onClick={props.clicked}
                    inputProps={{ 'data-lpignore': "true",}}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    ref={ref}
                />
            ) : (
                <OutlinedInput 
                    error={error}
                    classes={outlinedInputClasses}
                    id={props.id}
                    type={props.type}
                    fullWidth
                    value={props.value || ''}
                    placeholder={props.placeholder || ''}
                    onChange={props.changed}
                    onClick={props.clicked}
                    inputProps={{ 'data-lpignore': "true",}}
                    ref={ref}
                />
            ) }
            {validationError}
        </FormControl>
    )
};

export default forwardRef(UIInput);