import React from 'react';
import {createUseStyles} from 'react-jss';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RoomIcon from '@material-ui/icons/Room';
import LinkIcon from '@material-ui/icons/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ComputerIcon from '@material-ui/icons/Computer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LanguageIcon from '@material-ui/icons/Language';
import HowToRegIcon from '@material-ui/icons/HowToReg';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';
    
import styles from './Classic.module.scss';

function Classic (props) {

    const mainColor = props.resumeConfiguration.primary_color || '#283755';
    const additionalColor = props.resumeConfiguration.secondary_color || '#d4dbe3';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 27 * multiplier;
    const headerSubtitleSize = 20 * multiplier;
    const sectionTitleSize = 17 * multiplier;
    const repeatedTitleSize = 14 * multiplier;
    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, sectionTitleSize, repeatedTitleSize};

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const templateStyles = createUseStyles({
        header: {
            display: 'flex',
            padding: '35px 25px 20px',
            backgroundColor: (prop) => prop.mainColor,
            color: (prop) => prop.additionalColor,
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            '& > *': {
                position: 'relative',
                zIndex: 3,
            },
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            wordBreak: 'break-word',
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            fontWeight: 700,
            lineHeight: 1.2,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            fontWeight: 400,
            fontStyle: 'italic',
            lineHeight: 1.2,
            marginBottom: '5pt',
        },
        userImage: {
            width: '33mm',
            height: '33mm',
            objectFit: 'cover',
            borderRadius: '17mm',
            flexGrow: 0,
            marginLeft: '20px',
        },
        sectionTitle: {
            display: 'block',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            fontWeight: 700,
            marginBottom: 0,
            color: (prop) => prop.mainColor,
            order: 1,
        },
        description: {
            marginTop: '3pt',
            '& p': {
                lineHeight: 1.3,
            },
            '& ol': {
                margin: 0,
                paddingLeft: '17pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                paddingLeft: '7pt',
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: (prop) => prop.mainColor,
                        fontSize: (prop) => '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
        },
        main: {
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '20px 25px',
            zIndex: 3,
            lineHeight: 1.3,
            flex: '1 1 100%',
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        link: {
            color: (prop) => prop.mainColor,
            textDecoration: 'none',
        },
        flexRow: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        languagesItem: {
            flexBasis: '45%',
            marginRight: '3%',
            marginLeft: '2%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            '& > *': {
                order: 1,
            },
        },
        languageTitle: {
            position: 'relative',
            paddingLeft: '8px',
            '&:before': {
                content: '"•"',
                position: 'absolute',
                top: '-5pt',
                left: '-8px',
                display: 'inline-block',
                width: '8px',
                fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            },
        },
        languageLevel: {
            fontStyle: 'italic',
            color: (prop) => prop.mainColor,
            paddingLeft: '8px',
        },
        skillsItem: {
            flexBasis: '45%',
            marginRight: '3%',
            marginLeft: '2%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            '& > *': {
                order: 1,
            },
        },
        skillTitle: {
            position: 'relative',
            paddingLeft: '8px',
            '&:before': {
                content: '"•"',
                position: 'absolute',
                top: '-5pt',
                left: '-8px',
                display: 'inline-block',
                width: '8px',
                fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            },
        },
        skillLevel: {
            paddingLeft: '8px',
        },
        repeatedItem: {
            marginBottom: '15pt',
        },
        repeatedItemTitle: {
            fontWeight: 700,
            flexBasis: '70%',
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            lineHeight: 1.2,
        },
        repeatedItemSubtitle: {
            fontWeight: 400,
            lineHeight: 1.2,
        },
        repeatedTitleWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        dateText: {
            color: '#8190AA',
            textAlign: 'right',
            flexGrow: 1,
        },
        sectionWrap: {
            marginBottom: '25pt',
        },
        sectionTitleWrap: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: '7pt',
            '> svg': {
                order: 1,
            },
        },
        sectionIcon: {
            width: '22pt',
            height: '22pt',
            objectFit: 'contain',
            marginRight: '10pt',
            order: 1,
        },
    });

    let classes = templateStyles({ mainColor, additionalColor, fontFamily, ...fontSizes });

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 13,
            borderWidth: '1px',
            borderColor: mainColor,
            borderStyle: 'solid',
            borderRadius: '5px',
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: mainColor,
        },
    }))(LinearProgress);
    
    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="fb-link"><FacebookIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.facebook}>{showUrl(socials.facebook)}</a></p>);
        }
        if (socials.linkedin) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="li-link"><LinkedInIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.linkedin}>{showUrl(socials.linkedin)}</a></p>);
        }
        if (socials.twitter) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="tw-link"><TwitterIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.twitter}>{showUrl(socials.twitter)}</a></p>);
        }
        if (socials.instagram) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="in-link"><InstagramIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.instagram}>{showUrl(socials.instagram)}</a></p>);
        }
        if (socials.github) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="gh-link"><GitHubIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.github}>{showUrl(socials.github)}</a></p>);
        }
        if (socials.youtube) {
            socialsList.push(<p className={`movable ${styles.ContactItem}`} key="yt-link"><YouTubeIcon style={{ color: additionalColor }} /> <a className={classes.link} href={socials.youtube}>{showUrl(socials.youtube)}</a></p>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <div className={classes.sectionWrap}>
            <div className={`movable ${classes.sectionTitleWrap}`}>
                <HowToRegIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                {props.resume.skillSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.skillSectionTitle}</p>) : (<p className={`movable ${classes.sectionTitle}`}>Skills</p>)}
            </div> 
            <div className={classes.flexRow}>
                {props.resume.skillsFields.map(skillItem => 
                    (skillItem.skill) ? (<div className={`movable ${classes.repeatedItem} ${classes.skillsItem}`} key={skillItem.id}>
                        <p className={classes.skillTitle}>{skillItem.skill}</p>
                        {skillItem.level && Boolean(Number(props.resume.skillsVisibility)) ? (
                            <div className={classes.skillLevel}><BorderLinearProgress 
                                variant="determinate" 
                                value={skillsPercentages[skillItem.level]}
                            /></div>
                        ) : (<></>)}
                    </div>)  : (<></>)
                )}
            </div>
        </div>
    ) : <></>;

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    {/* <p className={styles.Title}>{props.resume.firstName} {props.resume.lastName} {props.resume.jobPosition ? (<span className={styles.Subtitle}>{props.resume.jobPosition}</span>) : null}</p> */}
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                    {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
                        props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
                        props.resume.city || props.resume.postalCode || props.resume.country || 
                        (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
                        <div className={styles.ContactLine}>
                            {props.resume.email && (<span className={styles.ContactItem}><EmailIcon style={{ color: additionalColor }} /> <a href={`mailto:${props.resume.email}`}>{props.resume.email}</a></span>)}
                            {props.resume.phoneNumber && (<span className={styles.ContactItem}><PhoneIphoneIcon style={{ color: additionalColor }} /> {props.resume.phoneNumber}</span>)}
                            {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={styles.ContactItem}><RoomIcon style={{ color: additionalColor }} />{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span>)}
                            {props.resume.nationality && (<span className={styles.ContactItem}><span className={styles.ContactTitle}>Nationality:</span> {props.resume.nationality}</span>)}
                            {props.resume.birthPlace && (<span className={styles.ContactItem}><span className={styles.ContactTitle}>Birth Place:</span> {props.resume.birthPlace}</span>)}
                            {props.resume.birthDate && (<span className={styles.ContactItem}><span className={styles.ContactTitle}>Birth Date:</span> {moment(props.resume.birthDate).format("DD/MM/YYYY")}</span>)}
                            {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<span className={styles.ContactItem}><span className={styles.ContactTitle}>Driving license:</span> {props.resume.drivingLicenseType.join(', ')}</span>) : (<></>)}
                        </div>
                        ) : <></>}

                        {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                            <div className={styles.ContactLine}>
                                {props.resume.websiteSocialFields.map((socialItem, index) => (
                                    (socialItem.link || socialItem.name) ? (
                                        <span key={index} className={styles.ContactItem}><LinkIcon style={{ color: additionalColor }} /> <a href={socialItem.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                                    ) : (<></>)        
                                ))}
                                
                                {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                            </div>
                        ) : <></>}
                </div>
                
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}
            </div>
            <div className={classes.main}>
                    {props.resume.careerSummary ? (
                        <div className={`movable ${classes.sectionWrap}`}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <AccountCircleIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</span></p>
                            </div>
                            <div className={classes.description}>{generateTinyMceElements(props.resume.careerSummary)}</div>
                            {/* <p>{props.resume.careerSummary.replace(/<[^>]+>/g, '')}</p> */}
                        </div>
                    ) : <></>}

                    {resumeType === 'functional' ? skillsSection : <></>}
                    
                    {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <ComputerIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.experienceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.experienceSectionTitle}</p>) : (<p className={classes.sectionTitle}>Work Experience</p>)}    
                            </div>
                            {props.resume.workExperienceItems.map(workItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={workItem.id}>
                                    <div className={classes.repeatedTitleWrap}>
                                        <p className={classes.repeatedItemTitle}>
                                            {workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ', ' : ''}
                                            <span className={classes.repeatedItemSubtitle}>
                                                {workItem.company}
                                                {((workItem.jobTitle || workItem.company) && workItem.city) ? ', ' : ''}
                                                {workItem.city}
                                            </span>
                                        </p>
                                        {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "YYYY-MM")}</p>
                                        ) : <></>}
                                    </div>
                                    {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.educationFields && props.resume.educationFields.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <AccountBalanceIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.educationSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.educationSectionTitle}</p>) : (<p className={classes.sectionTitle}>Education</p>)}    
                            </div>
                            {props.resume.educationFields.map(educationItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={educationItem.id}>
                                        <div className={classes.repeatedTitleWrap}>
                                            <p className={classes.repeatedItemTitle}>
                                                {educationItem.degree}
                                                {(educationItem.degree && educationItem.institution) ? ', ' : ''}
                                                <span className={classes.repeatedItemSubtitle}>
                                                    {educationItem.institution}
                                                    {((educationItem.degree || educationItem.institution) && educationItem.city) ? ', ' : ''}
                                                    {educationItem.city}
                                                </span>
                                            </p>
                                            {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                                <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "YYYY-MM")}</p>
                                            ) : <></>}
                                        </div>
                                        {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                        </div>
                    ) : <></>}

                    {props.resume.internshipFields && props.resume.internshipFields.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <ComputerIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.internshipSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.internshipSectionTitle}</p>) : (<p className={classes.sectionTitle}>Internship</p>)}
                            </div>
                            {props.resume.internshipFields.map(internshipItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={internshipItem.id}>
                                    <div className={classes.repeatedTitleWrap}>
                                        <p className={classes.repeatedItemTitle}>
                                            {internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ', ' : ''}
                                            <span className={classes.repeatedItemSubtitle}>
                                                {internshipItem.employer}
                                                {((internshipItem.jobTitle || internshipItem.employer) && internshipItem.city) ? ', ' : ''}
                                                {internshipItem.city}
                                            </span>
                                        </p>
                                        {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "YYYY-MM")}</p>
                                        ) : <></>}
                                    </div>   
                                    {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.coursesFields && props.resume.coursesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <AccountBalanceIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.courseSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.courseSectionTitle}</p>) : (<p className={classes.sectionTitle}>Courses</p>)}
                            </div>
                            {props.resume.coursesFields.map(courseItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={courseItem.id}>
                                    <div className={classes.repeatedTitleWrap}>
                                        <p className={classes.repeatedItemTitle}>{courseItem.course}{(courseItem.course && courseItem.institution) ? ', ' : ''}{courseItem.institution ? (<span className={classes.repeatedItemSubtitle}>{courseItem.institution}</span>) : <></>}</p>
                                        {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "YYYY-MM")}</p>
                                        ) : <></>}
                                    </div> 
                                    {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.referencesFields && props.resume.referencesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <RateReviewIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.referenceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.referenceSectionTitle}</p>) : (<p className={`movable ${classes.sectionTitle}`}>References</p>)}
                            </div>    
                            {   !Boolean(Number(props.resume.referencesVisibility)) ? 
                                props.resume.referencesFields.map(referenceItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={referenceItem.id}>
                                        <p className={classes.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ' from ' : ''}{referenceItem.company}</p>
                                        <p><a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}</p>
                                        {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>) : <></> }
                                    </div>
                                )) : (<p>References available upon request</p>)}
                        </div>
                    ) : <></>}

                    {resumeType === 'chronological' ? skillsSection : <></>}

                    {props.resume.languagesFields && props.resume.languagesFields.length ? (
                        <div className={`movable ${classes.sectionWrap}`}>   
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <LanguageIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.languageSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.languageSectionTitle}</p>) : (<p className={`movable ${classes.sectionTitle}`}>Languages</p>)}
                            </div>                          
                            <div className={classes.flexRow}>
                                {props.resume.languagesFields.map((languageItem, index) => 
                                    (languageItem.language || languageItem.languageLevel) ? (<div key={index} className={`${classes.repeatedItem} ${classes.languagesItem}`}>
                                        {languageItem.language ? (<p className={classes.languageTitle}>{languageItem.language}</p>) : (<></>)}
                                        {languageItem.level ? (<span className={classes.languageLevel}>{languageItem.level}</span>) : (<></>)}
                                    </div>) : (<></>)
                                )}
                            </div>
                        </div>
                    ) : <></>}  

                    {props.resume.customFields && props.resume.customFields.length ? (
                        <div className={classes.sectionWrap}>
                            <div className={`movable ${classes.sectionTitleWrap}`}>
                                <AddCircleIcon style={{ color: mainColor }} className={classes.sectionIcon}/>
                                {props.resume.customSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.customSectionTitle}</p>) : (<></>)}
                            </div>
                            {props.resume.customFields.map(customItem => (
                                <div className={`movable ${classes.repeatedItem}`} key={customItem.id}>
                                    <div className={classes.repeatedTitleWrap}>
                                        <p className={classes.repeatedItemTitle}>{customItem.customItemTitle}{(customItem.customItemTitle && customItem.city) ? ', ' : ''}{customItem.city ? (<span className={classes.repeatedItemSubtitle}>{customItem.city}</span>) : <></>}</p>
                                        {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                            <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "YYYY-MM")}</p>
                                        ) : <></>}
                                    </div>
                                    {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

            </div>
        </>
    )
}

export default Classic;