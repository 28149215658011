import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStylesSpinner = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#10b58c',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));
  
function Loader(props) {
    const classes = useStylesSpinner();

    return (
        <div className={classes.root}>
        <CircularProgress
            variant="determinate"
            className={classes.bottom}
            size={40}
            thickness={4}
            {...props}
            value={100}
        />
        <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.top}
            classes={{
            circle: classes.circle,
            }}
            size={40}
            thickness={4}
            {...props}
        />
        </div>
    );
}

export default Loader;