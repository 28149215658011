export const toJSON = (node) => {
    let propFix = { for: 'htmlFor', class: 'className' };
    let specialGetters = {
        style: (node) => node.style.cssText,
    };
    let attrDefaultValues = { style: '' };
    let obj = {
      nodeType: node.nodeType,
    };
    if (node.tagName) {
      obj.tagName = node.tagName.toLowerCase();
    } else if (node.nodeName) {
      obj.nodeName = node.nodeName;
    }
    if (node.nodeValue) {
      obj.nodeValue = node.nodeValue;
    }
    let attrs = node.attributes;
    if (attrs) {
      let defaultValues = new Map();
      for (let i = 0; i < attrs.length; i++) {
        let name = attrs[i].nodeName;
        defaultValues.set(name, attrDefaultValues[name]);
      }
      // Add some special cases that might not be included by enumerating
      // attributes above. Note: this list is probably not exhaustive.
      switch (obj.tagName) {
        case 'input': {
          if (node.type === 'checkbox' || node.type === 'radio') {
            defaultValues.set('checked', false);
          } else if (node.type !== 'file') {
            // Don't store the value for a file input.
            defaultValues.set('value', '');
          }
          break;
        }
        case 'option': {
          defaultValues.set('selected', false);
          break;
        }
        case 'textarea': {
          defaultValues.set('value', '');
          break;
        }
        default: break;
      }
      let arr = [];
      for (let [name, defaultValue] of defaultValues) {
        let propName = propFix[name] || name;
        let specialGetter = specialGetters[propName];
        let value = specialGetter ? specialGetter(node) : node[propName];
        if (value !== defaultValue) {
          arr.push([name, value]);
        }
      }
      if (arr.length) {
        obj.attributes = arr;
      }
    }
    let childNodes = node.childNodes;
    // Don't process children for a textarea since we used `value` above.
    if (obj.tagName !== 'textarea' && childNodes && childNodes.length) {
        let arr = (obj.childNodes = []);
        for (let i = 0; i < childNodes.length; i++) {
            arr[i] = toJSON(childNodes[i]);
        }
    }
    return obj;
}