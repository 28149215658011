import React from 'react';
import { Document, Page } from '@react-pdf/renderer';


// Create Document Component
const BlankPdf = (props) => (
    <Document>
        <Page>
        </Page>
    </Document>
);

export default BlankPdf;