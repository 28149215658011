import React from 'react';
import {Tooltip} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { makeStyles } from '@material-ui/core/styles';

const switchStyles = makeStyles(theme => ({
    tooltip: {
        color: "#fff",
        backgroundColor: "#5C6783",
        borderRadius: '5px',
        padding: '10px 14px',
        fontSize: '.9rem',
        maxWidth: '250px',
    },
    arrow: {
        color: "#5C6783",
    }
}));

function UiTooltip(props) {
    const classes = switchStyles();

    return (
        <Tooltip title={props.tooltipContent} classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
        }} arrow={true} placement="top">
            <HelpIcon style={{fill: '#5C6783'}} />
        </Tooltip>
    );
}

export default UiTooltip;