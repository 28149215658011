import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function TextEditor(props) {

    return (
        <Editor
            apiKey="hh91spn5s8zudhrlorpkmd3qxmlxla6zm46qth7g9uvrqq4d"
            value={props.value}
            onEditorChange={props.changed}
            id={props.id}
            key={props.id}
            textareaName={props.id}
            init={{
                /* setup : function(editor) {
                    editor.on('paste', console.log('test'));
                }, */
                setup: function (editor) {

                    // Listen for paste event, add "Paste as plain text" callback
                    /* editor.on('paste', function ( e) {
            
                        // Prevent default paste behavior
                        e.preventDefault();
            
                        // Check for clipboard data in various places for cross-browser compatibility.
                        // Get that data as text.
                        var content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        // Let TinyMCE do the heavy lifting for inserting that content into the editor.
                        editor.execCommand('mceInsertContent', false, content);
                        props.changed(editor.getContent());
                    }); */
                    /* editor.on('keyup', function(e) {
                        props.changed(editor.getContent());
                    }); */
                    /* editor.on('change', function(e) {
                        props.changed(editor.getContent());
                    }); */
                },
                content_css : '/css/editor.css',
                min_height: 175,
                max_height: 500,
                menubar: false,
                branding: false,
                statusbar: false,
                plugins: 'link lists autoresize paste',
                target_list: false,
                link_title: false,
                default_link_target: '_blank',
                toolbar: 'bold italic underline | bullist numlist | link',
                autoresize_bottom_margin: 10,
                autoresize_on_init: false,
                lists_indent_on_tab: false,
                paste_as_text: true,
                paste_enable_default_filters: false,
                paste_retain_style_properties: "all",
                paste_word_valid_elements: "b,strong,i,em,p,span,a,ol,ul,li",
            }}
        />
    );
}

export default TextEditor;