import * as actionTypes from './actionTypes';
import axios from '../../axios-resumes';
import { getTransformedResume, postTransformedResume } from '../../shared/utility';

export const createResumeSuccess = ( resumeData ) => {
    return {
        type: actionTypes.CREATE_RESUME_SUCCESS,
        resumeData: resumeData
    };
};

export const editResumeSuccess = ( resumeData ) => {
    return {
        type: actionTypes.EDIT_RESUME_SUCCESS,
        resumeData: resumeData
    };
};

export const createResumeFail = ( error ) => {
    return {
        type: actionTypes.CREATE_RESUME_FAIL,
        error: error
    };
}

export const createResumeStart = () => {
    return {
        type: actionTypes.CREATE_RESUME_START
    };
};

/* export const createResume = ( resumeData, token ) => {
    return dispatch => {
        dispatch( createResumeStart() );
        axios.post( '/resumes.json?auth=' + token, resumeData )
            .then( response => {
                console.log( response.data );
                dispatch( createResumeSuccess( response.data.name, resumeData ) );
            } )
            .catch( error => {
                dispatch( createResumeFail( error ) );
            } );
    };
}; */

export const createResume = ( resumeData, token ) => {
    return dispatch => {
        dispatch( createResumeStart() );
        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.post( '/resume', postTransformedResume(resumeData), {headers: headers} )
            .then( response => {
                dispatch( createResumeSuccess( getTransformedResume(response.data.resume) ) );
            } )
            .catch( error => {
                dispatch( createResumeFail( error ) );
            } );
    };
};

/* export const editResume = ( resumeData, token, resumeId ) => {
    return dispatch => {
        dispatch( createResumeStart() );
        
        axios.patch( '/resumes/' + resumeId + '.json', resumeData )
            .then( response => {
                dispatch( editResumeSuccess( response.data.name, resumeData ) );
                dispatch( fetchResumes(token, response.data.userId) );
            } )
            .catch( error => {
                dispatch( createResumeFail( error ) );
            } );
    };
}; */

export const editResume = ( resumeData, token, resumeId ) => {
    return dispatch => {
        dispatch( createResumeStart() );

        const headers = {
            Authorization: `Bearer ${token}`
        }
        
        axios.put( `/resume/${resumeId}`, postTransformedResume(resumeData), {headers: headers})
            .then( response => {
                /* console.log( response.data ); */
                dispatch( editResumeSuccess( getTransformedResume(resumeData) ) );
                //dispatch( fetchResumes(token, response.data.resume.user_id) );
            } )
            .catch( error => {
                dispatch( createResumeFail( error ) );
            } );
    };
};

export const createResumeInit = () => {
    return {
        type: actionTypes.CREATE_RESUME_INIT
    };
};

export const fetchResumesSuccess = ( resumes ) => {
    return {
        type: actionTypes.FETCH_RESUMES_SUCCESS,
        resumes: resumes
    };
};

export const fetchResumesFail = ( error ) => {
    return {
        type: actionTypes.FETCH_RESUMES_FAIL,
        error: error
    };
};

export const fetchResumesStart = () => {
    return {
        type: actionTypes.FETCH_RESUMES_START
    };
};

/* export const fetchResumes = (token, userId) => {
    return dispatch => {
        dispatch(fetchResumesStart());
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
        axios.get( '/resumes.json' + queryParams)
            .then( res => {
                const fetchedResumes = [];
                for ( let key in res.data ) {
                    fetchedResumes.push( {
                        ...res.data[key],
                        id: key
                    } );
                }
                localStorage.setItem('resumes', JSON.stringify(fetchedResumes));
                dispatch(fetchResumesSuccess(fetchedResumes));
            } )
            .catch( err => {
                dispatch(fetchResumesFail(err));
            } );
    };
}; */

export const fetchResumes = (token) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        
        dispatch(fetchResumesStart());
        axios.get( '/resumes', { headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then( res => {
                const fetchedResumes = [];
                for ( let key in res.data.resumes ) {
                    fetchedResumes.push( getTransformedResume(res.data.resumes[key]));
                }
                //const fetchedResumes = res.data.resumes;
                localStorage.setItem('resumes', JSON.stringify(fetchedResumes));
                dispatch(fetchResumesSuccess(fetchedResumes));
            } )
            .catch( err => {
                dispatch(fetchResumesFail(err));
            } );
    };
};

export const deleteResumeFail = ( error ) => {
    return {
        type: actionTypes.DELETE_RESUME_FAIL,
        error: error
    };
}

export const deleteResumeStart = () => {
    return {
        type: actionTypes.DELETE_RESUME_START
    };
};

export const deleteResumeSuccess = (resumeId) => {
    return {
        type: actionTypes.DELETE_RESUME_SUCCESS,
        resumeId: resumeId,
    };
};

/* export const deleteResume = ( token, resumeId, userId ) => {
    return dispatch => {
        dispatch( deleteResumeStart() );
        
        axios.delete( '/resumes/' + resumeId + '.json' )
            .then( response => {
                dispatch( deleteResumeSuccess(resumeId) );
            } )
            .catch( error => {
                dispatch( deleteResumeFail( error ) );
                console.log(error);
            } );
    };
}; */

export const deleteResume = ( token, resumeId) => {
    return dispatch => {
        dispatch( deleteResumeStart() );

        const headers = {
            Authorization: `Bearer ${token}`
        }
        axios.delete( `/resume/${resumeId}`, {headers} )
            .then( response => {
                dispatch( deleteResumeSuccess(resumeId) );
/*                 dispatch( fetchResumes(token, userId) );*/
            } )
            .catch( error => {
                dispatch( deleteResumeFail( error ) );
                console.log(error);
            } );
    };
};

export const createPdfSuccess = ( pdfUrl ) => {
    return {
        type: actionTypes.CREATE_PDF_SUCCESS,
        pdfUrl: pdfUrl
    };
};


export const resumeConfigurationSuccess = (resumeConfiguration) => {
    return {
        type: actionTypes.RESUME_СONFIGURATION_SUCCESS,
        resumeConfiguration: resumeConfiguration
    };
};

export const resumeConfigurationFail = ( error ) => {
    return {
        type: actionTypes.RESUME_СONFIGURATION_FAIL,
        error: error
    };
}

export const createResumeConfiguration = (resumeConfigurationId, resumeConfiguration) => {
    return {
        type: actionTypes.CREATE_RESUME_СONFIGURATION,
        resumeConfigurationId: resumeConfigurationId,
        resumeConfiguration: resumeConfiguration,
    };
};

export const getResumeConfiguration = (resumeConfiguration) => {
    return {
        type: actionTypes.GET_RESUME_СONFIGURATION,
        resumeConfiguration: resumeConfiguration
    };
};

export const resumeConfigurationStart = () => {
    return {
        type: actionTypes.RESUME_СONFIGURATION_START
    };
};

export const resumeConfigurationInit = (resumeId, token) => {
    return dispatch => {
        dispatch(resumeConfigurationStart());
        const headers = {
            Authorization: `Bearer ${token}`
        }
        
        axios.get(`/resume/configuration/${resumeId}`, { headers: {
            'Authorization': `Bearer ${token}`
            }
        })
        .then( response =>  {   
            dispatch(getResumeConfiguration(response.data.configuration))
        })
        .catch( err => {
            axios.post( `/resume/configuration`, {resume_id: resumeId}, {headers: headers})
            .then( response => dispatch(createResumeConfiguration(response.data.configuration.id, response.data.configuration)))
            .catch( err => {
                dispatch(resumeConfigurationFail(err));
            } );
        })
    }
}

export const resumeConfigurationEdit = ( resumeConfiguration, token, resumeConfigurationId) => {
    return dispatch => {
        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.put( `/resume/configuration/${resumeConfigurationId}`, resumeConfiguration, {headers: headers})
        .then( response => dispatch(resumeConfigurationSuccess(resumeConfiguration)))
        .catch( err => {
            dispatch(resumeConfigurationFail(err));
        } );
    };
};