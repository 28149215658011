import React from 'react';

import styles from './UserNavigationItems.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem';

const UserNavigationItems = () => (
    <ul className={styles.NavigationItems}>
        <NavigationItem link="/" exact>Login</NavigationItem>
        <NavigationItem link="/choose-template" customClass={styles.LinkButton}>Sign Up</NavigationItem>
    </ul>
);

export default UserNavigationItems;