import React from 'react';
import styles from './Button.module.scss';
import Button from '@material-ui/core/Button';

const UIButton = (props) => (
    <Button
        variant={props.variant}
        color={props.color}
        onClick={props.clicked}
        className={`${styles.Button} ${props.className}`}
        type={props.type}
        href={props.href}
        disabled={props.disabled}
    >
        {props.children}
    </Button>
);

export default UIButton;