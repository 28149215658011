export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_DATA = 'GET_USER_DATA';
export const USER_SUCCESS = 'USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const RESTORE_USER_SUCCESS = 'RESTORE_USER_SUCCESS';
export const CHANGE_VISIBILITY_SUCCESS = 'CHANGE_VISIBILITY_SUCCESS';

export const CREATE_RESUME_START = 'CREATE_RESUME_START';
export const CREATE_RESUME_SUCCESS = 'CREATE_RESUME_SUCCESS';
export const CREATE_RESUME_FAIL = 'CREATE_RESUME_FAIL';
export const CREATE_RESUME_INIT = 'CREATE_RESUME_INIT';

export const EDIT_RESUME_SUCCESS = 'EDIT_RESUME_SUCCESS';

export const DELETE_RESUME_START = 'DELETE_RESUME_START';
export const DELETE_RESUME_SUCCESS = 'DELETE_RESUME_SUCCESS';
export const DELETE_RESUME_FAIL = 'DELETE_RESUME_FAIL';

export const FETCH_RESUMES_START = 'FETCH_RESUMES_START';
export const FETCH_RESUMES_SUCCESS = 'FETCH_RESUMES_SUCCESS';
export const FETCH_RESUMES_FAIL = 'FETCH_RESUMES_FAIL';

export const CREATE_PDF_SUCCESS = 'CREATE_PDF_SUCCESS';

export const CREATE_RESUME_СONFIGURATION = 'CREATE_RESUME_СONFIGURATION';
export const EDIT_RESUME_СONFIGURATION = 'EDIT_RESUME_СONFIGURATION';
export const GET_RESUME_СONFIGURATION = 'GET_RESUME_СONFIGURATION';
export const RESUME_СONFIGURATION_SUCCESS = 'RESUME_СONFIGURATION_SUCCESS';
export const RESUME_СONFIGURATION_FAIL = 'RESUME_СONFIGURATION_FAIL';
export const RESUME_CONFIGURATION_INIT = 'RESUME_CONFIGURATION_INIT';
export const RESUME_СONFIGURATION_START = 'RESUME_СONFIGURATION_START';

export const CREATE_COVER_LETTER_START = 'CREATE_COVER_LETTER_START';
export const CREATE_COVER_LETTER_SUCCESS = 'CREATE_COVER_LETTER_SUCCESS';
export const CREATE_COVER_LETTER_FAIL = 'CREATE_COVER_LETTER_FAIL';


export const DELETE_COVER_LETTER_START = 'DELETE_COVER_LETTER_START';
export const DELETE_COVER_LETTER_FAIL = 'DELETE_COVER_LETTER_FAIL';
export const DELETE_COVER_LETTER_SUCCESS = 'DELETE_COVER_LETTER_SUCCESS';

export const EDIT_COVER_LETTER_SUCCESS = 'EDIT_COVER_LETTER_SUCCESS';

export const FETCH_COVER_LETTERS_START = 'FETCH_COVER_LETTERS_START';
export const FETCH_COVER_LETTERS_SUCCESS = 'FETCH_COVER_LETTERS_SUCCESS';
export const FETCH_COVER_LETTERS_FAIL = 'FETCH_COVER_LETTERS_FAIL';

export const COVER_LETTER_СONFIGURATION_START = 'COVER_LETTER_СONFIGURATION_START';
export const COVER_LETTER_СONFIGURATION_FAIL = 'COVER_LETTER_СONFIGURATION_FAIL';
export const CREATE_COVER_LETTER_СONFIGURATION = 'CREATE_COVER_LETTER_СONFIGURATION';
export const GET_COVER_LETTER_СONFIGURATION = 'GET_COVER_LETTER_СONFIGURATION';
export const COVER_LETTER_СONFIGURATION_SUCCESS = 'COVER_LETTER_СONFIGURATION_SUCCESS';