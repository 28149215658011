import React from 'react';
import {createUseStyles} from 'react-jss';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RoomIcon from '@material-ui/icons/Room';
import LinkIcon from '@material-ui/icons/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';


function London (props) {
    const mainColor = props.resumeConfiguration.primary_color || '#ED8936';
    const additionalColor = props.resumeConfiguration.secondary_color || '#14344d';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    const fontSize = props.resumeConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 34.5 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const dateTextSize = 12 * multiplier;
    const sectionTitleSize = 20 * multiplier;
    const repeatedTitleSize = 19.5 * multiplier;
    const repeatedSubtitleSize = 18 * multiplier;

    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, dateTextSize, sectionTitleSize, repeatedTitleSize, repeatedSubtitleSize};

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            flex: '0 0 47%',
            height: 12,
            marginTop: 5,
            marginBottom: 2,
            marginLeft: 14,
            border: 'none',
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: mainColor,
        },
    }))(LinearProgress);

    const templateStyles = createUseStyles({
        header: {
            display: 'flex',
            position: 'relative',
            padding: '20pt',
            backgroundColor: (prop) => prop.additionalColor,
            color: '#fff',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 3,
            '& p': {
                
            }
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            wordBreak: 'break-word',
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            lineHeight: 1.2,
            marginBottom: 0,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            color: (prop) => prop.mainColor,
            marginBottom: '5pt',
        },
        userImage: {
            width: '30mm',
            height: '30mm',
            objectFit: 'cover',
            borderRadius: '7.5pt',
            border: (prop) => `4pt solid ${prop.mainColor}`,
            flexGrow: 0,
            marginLeft: '25pt',
        },
        contactLine: {
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            backgroundColor: '#001528',
            color: '#fff',
            padding: '7pt 20pt',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'relative',
            zIndex: 3,
        },
        contactItem: {
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: 1.7,
            paddingRight: '10pt',
            marginTop: '2pt',
            marginBottom: '2pt',
            '& a': {
                color: 'inherit',
            },
            '& svg': {
                marginRight: '3pt',
            },
        },
        contactTitle: {
            fontWeight: 700,
            marginRight: '6pt',
        },
        sectionTitle: {
            display: 'inline-block',
            color: (prop) => prop.mainColor,
            textTransform: 'uppercase',
            borderBottom: (prop) => `2pt solid ${prop.mainColor}`,
            letterSpacing: '1pt',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 700,
            marginBottom: '10pt',
        },
        dateCityWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontStyle: 'italic',
            fontSize: (prop) => `${prop.dateTextSize}pt`,
            color: (prop) => prop.mainColor,
        },
        description: {
            marginTop: '8pt',
            '& ol': {
                margin: 0,
                paddingLeft: '10pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: (prop) => prop.mainColor,
                        fontSize: (prop) => '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
            '& p': {
                lineHeight: 1.3,
            },
        },
        main: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flex: '1 1 100%',
            alignContent: 'stretch',
            flexWrap: 'nowrap',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '20pt',
            zIndex: 3,
            lineHeight: 1.3,
            '& p': {
                lineHeight: 1.3,
            },
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        mainContentCell: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            maxWidth: '47%',
            width: '47%',
        },
        repeatedItem: {
            marginBottom: '7pt',
        },
        repeatedItemTitle: {
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 700,
        },
        repeatedItemSubtitle: {
            fontSize: (prop) => `${prop.repeatedSubtitleSize}pt`,
        },
        sectionWrap: {
            marginBottom: '22pt',
        },
        skillItem: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: '7pt',
        },
        skillTitle: {
            flex: '0 0 47%',
        },
        languagesItem: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& p': {
                flex: '1 1 35%',
            },
        },
        languageLevel: {
            display: 'flex',
            flex: '1 1 60%',
        },
        levelPoint: {
            flex: '1 1 auto',
            margin: '3pt 6pt',
        },
        dateText: {},
        link: {
            color: (prop) => prop.mainColor,
            textDecoration: 'none',
        },
    });

    let classes = templateStyles({ mainColor, additionalColor, fontFamily, ...fontSizes });

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const languageValues = {
        'Native Speaker': 5, 
        'Highly proficient': 4, 
        'Very good command': 3, 
        'Good working knowledge': 2,
        'Working knowledge': 1, 
        'C2': 5, 
        'C1': 5, 
        'B2': 4, 
        'B1': 3, 
        'A2': 2, 
        'A1': 1
    }

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showLanguageLevel = (level) => {
        if (!level) {
            return null;
        }
        
        let languageLevel = [];
        for (let i = 1; i <=5; i++) {
            if (i <= languageValues[level]) {
                languageLevel.push(
                    <div className={classes.levelPoint}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill={mainColor} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                        </svg>
                    </div>
                );
            } else {
                languageLevel.push(
                    <div className={classes.levelPoint}>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill={mainColor} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path>
                        </svg>
                    </div>
                );
            }
        }

        return (<div className={classes.languageLevel}>{languageLevel}</div>);
    }
    
    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<span className={classes.contactItem}><FacebookIcon style={{ color: '#fff' }} /> <a href={socials.facebook}>{showUrl(socials.facebook)}</a></span>);
        }
        if (socials.linkedin) {
            socialsList.push(<span className={classes.contactItem}><LinkedInIcon style={{ color: '#fff' }} /> <a href={socials.linkedin}>{showUrl(socials.linkedin)}</a></span>);
        }
        if (socials.twitter) {
            socialsList.push(<span className={classes.contactItem}><TwitterIcon style={{ color: '#fff' }} /> <a href={socials.twitter}>{showUrl(socials.twitter)}</a></span>);
        }
        if (socials.instagram) {
            socialsList.push(<span className={classes.contactItem}><InstagramIcon style={{ color: '#fff' }} /> <a href={socials.instagram}>{showUrl(socials.instagram)}</a></span>);
        }
        if (socials.github) {
            socialsList.push(<span className={classes.contactItem}><GitHubIcon style={{ color: '#fff' }} /> <a href={socials.github}>{showUrl(socials.github)}</a></span>);
        }
        if (socials.youtube) {
            socialsList.push(<span className={classes.contactItem}><YouTubeIcon style={{ color: '#fff' }} /> <a href={socials.youtube}>{showUrl(socials.youtube)}</a></span>);
        }

        return socialsList;
    }

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                    {props.resume.careerSummary ? (<div className={classes.description}>{generateTinyMceElements(props.resume.careerSummary)}</div>) : null}
                </div>
                
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}
            </div>

            {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
            props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
            props.resume.city || props.resume.postalCode || props.resume.country || 
            (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
            <div className={classes.contactLine}>
                {props.resume.email && (<span className={classes.contactItem}><EmailIcon style={{ color: '#fff' }} /> <a href={`mailto:${props.resume.email}`}>{props.resume.email}</a></span>)}
                {props.resume.phoneNumber && (<span className={classes.contactItem}><PhoneIphoneIcon style={{ color: '#fff' }} /> {props.resume.phoneNumber}</span>)}
                {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={classes.contactItem}><RoomIcon style={{ color: '#fff' }} />{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span>)}
                {props.resume.nationality && (<span className={classes.contactItem}><span className={classes.contactTitle}>Nationality:</span> {props.resume.nationality}</span>)}
                {props.resume.birthPlace && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Place:</span> {props.resume.birthPlace}</span>)}
                {props.resume.birthDate && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Date:</span> {moment(props.resume.birthDate).format("DD/MM/YYYY")}</span>)}
                {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<span className={classes.contactItem}><span className={classes.contactTitle}>Driving license:</span> {props.resume.drivingLicenseType.join(', ')}</span>) : (<></>)}
            </div>
            ) : <></>}

            {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                <div className={classes.contactLine}>
                    {props.resume.websiteSocialFields.map((socialItem, index) => (
                        (socialItem.link || socialItem.name) ? (
                            <span key={index} className={classes.contactItem}><LinkIcon style={{ color: '#fff' }} /> <a href={socialItem.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                        ) : (<></>)
                    ))}
                    
                    {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                </div>
            ) : <></>}

            <div className={classes.main}>
                <div className={classes.mainContentCell}>
                    {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`movable ${classes.sectionTitle}`}><span>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Work Experience'}</span></h2>
                            {props.resume.workExperienceItems.map((workItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {workItem.jobTitle && (<p className={classes.repeatedItemTitle}>{workItem.jobTitle}</p>)}
                                    {workItem.company && (<p className={classes.repeatedItemSubtitle}>{workItem.company}</p>)}
                                    {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{workItem.city}</p>
                                        </div>
                                    ) : <></>}
                                    {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.languagesFields && props.resume.languagesFields.length ? (
                        <div className={`movable ${classes.sectionWrap}`}>
                            <h2 className={classes.sectionTitle}><span>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</span></h2>
                            {props.resume.languagesFields.map((languageItem, index) => 
                                (languageItem.language) ? 
                                (<div key={index} className={`${classes.repeatedItem} ${classes.languagesItem}`}>
                                    <p>{languageItem.language}</p>
                                    {showLanguageLevel(languageItem.level)}
                                </div>) : <></>
                            )}
                        </div>
                    ) : <></>}
                </div>

                <div className={classes.mainContentCell}>

                    {props.resume.educationFields && props.resume.educationFields.length  ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</span></h2>
                            {props.resume.educationFields.map((educationItem, index) => (
                                <div key={index} className={`side-movable ${classes.repeatedItem}`}>
                                    {educationItem.degree && (<p className={classes.repeatedItemTitle}>{educationItem.degree}</p>)}
                                    {educationItem.institution && (<p className={classes.repeatedItemSubtitle}>{educationItem.institution}</p>)}
                                    {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{educationItem.city}</p>
                                        </div>
                                    ) : <></>}
                                    {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.skillsFields && props.resume.skillsFields.length ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</span></h2>

                            {props.resume.skillsFields.map((skillItem, index) => 
                                (skillItem.skill) ?
                                    (
                                        <div key={index} className={`side-movable ${classes.repeatedItem} ${classes.skillItem}`}>
                                            <p className={classes.skillTitle}>{skillItem.skill}</p>
                                            {(Boolean(Number(props.resume.skillsVisibility)) && skillItem.level) ? (
                                                <BorderLinearProgress 
                                                    variant="determinate" 
                                                    value={skillsPercentages[skillItem.level]}
                                                />
                                            ) : (<></>)}
                                        </div>
                                    ) : <></>
                            )}
                        </div>
                    ) : <></>}

                    {props.resume.internshipFields && props.resume.internshipFields.length ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</span></h2>
                            {props.resume.internshipFields.map((internshipItem, index) => (
                                <div key={index} className={`side-movable ${classes.repeatedItem}`}>
                                    {internshipItem.jobTitle && (<p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}</p>)}
                                    {internshipItem.employer && (<p className={classes.repeatedItemSubtitle}>{internshipItem.employer}</p>)}
                                    {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        <p>{internshipItem.city}</p>
                                    </div>
                                    ) : <></>}
                                    {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                    {props.resume.coursesFields && props.resume.coursesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</span></h2>
                            {props.resume.coursesFields.map((courseItem, index) => (
                                <div key={index} className={`side-movable ${classes.repeatedItem}`}>
                                    {courseItem.course && (<p className={classes.repeatedItemTitle}>{courseItem.course}</p>)}
                                    {courseItem.institution && (<p className={classes.repeatedItemSubtitle}>{courseItem.institution}</p>)}
                                    {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        </div>
                                    ) : <></> }
                                    {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>)/* (<p>{courseItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}
                    
                    {props.resume.referencesFields && props.resume.referencesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</span></h2>
                            {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                props.resume.referencesFields.map((referenceItem, index) => (
                                    <div key={index} className={`side-movable ${classes.repeatedItem}`}>
                                        {referenceItem.referent && (<p className={classes.repeatedItemTitle}>{referenceItem.referent}</p>)}
                                        {referenceItem.company && (<p className={classes.repeatedItemSubtitle}>{referenceItem.company}</p>)}
                                        <p>
                                            <a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}
                                        </p>
                                        {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>)/* (<p>{referenceItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                    </div>
                                )) : (<p>References available upon request</p>)}
                        </div>
                    ) : <></>}

                    {props.resume.customFields && props.resume.customFields.length ? (
                        <div className={classes.sectionWrap}>
                            {props.resume.customSectionTitle ? (<h2 className={`side-movable ${classes.sectionTitle}`}><span>{props.resume.customSectionTitle}</span></h2>) : (<></>)}
                        
                            {props.resume.customFields.map((customItem, index) => (
                                <div key={index} className={`side-movable ${classes.repeatedItem}`}>
                                    {customItem.customItemTitle && (<p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>)}
                                    {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{customItem.city}</p>
                                        </div>
                                    ) : <></> }
                                    {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    ) : <></>}

                </div>

            </div>
                    
        </>
    )
}

export default London;