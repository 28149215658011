import React, { forwardRef } from 'react';

import styles from './Select.module.scss';
import { makeStyles } from '@material-ui/core/styles';

import { FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

const useSelectStyles = makeStyles(theme => ({
    selectWrap: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #8190AA",
            outline: 'none'
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #8190AA",
            outline: 'none'
        },
    },
    selectRoot: {
        backgroundColor: "#FFFFFF",
        padding: '17px 14px',
        "& ~ .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E3E8F1',
        },
        "&[aria-expanded='true'] ~ .MuiOutlinedInput-notchedOutline": {
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
            borderBottom: 'none',
        },
    },
    menuPaper: {
        maxHeight: 200,
        maxWidth: '100%',
        border: '1px solid #677089',
        borderTop: 'none',
        boxShadow: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        transform: 'translate(-1px, -1px) !important',
        
        "&:before": {
            content: '""',
            display: 'block',
            height: '1px',
            width: '100%',
            backgroundColor: '#E3E8F1'
        }
    }
}));

const UISelect = (props, ref) => {
    const classes = useSelectStyles();

    return (
        <FormControl variant="outlined" className={`${styles.FormControl} ${props.classes}`} classes={{root: classes.selectWrap}} disabled={props.disabled}>
            <InputLabel className={styles.Label} shrink htmlFor={props.id}>{props.label}</InputLabel>
            <Select
                id={props.id}
                value={props.value ? props.value : ''}
                onChange={props.changed}
                displayEmpty
                classes={{root: classes.selectRoot}}
                ref={ref}
                native={props.native}
                MenuProps={{ 
                    classes: { 
                        paper: classes.menuPaper 
                    }, 
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null 
                }}
            >
                {props.placeholder ? (
                    <MenuItem value="">
                        {props.placeholder}
                    </MenuItem>
                ) : (<></>)}
                {props.native ? props.options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                )) : props.options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default forwardRef(UISelect);