import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';

import styles from './MonthYearPicker.module.scss';
import {Grow, Paper, Popper, ClickAwayListener, IconButton} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Input from '../Input/Input';
import Switch from '../Switch/Switch';

function MonthYearPicker(props) {
    
    const [dataLoaded, setDataLoaded] = useState(false);

    const [datepickerValue, setDatepickerValue] = useState('');

    const [monthes, setMonthes] = useState({1:'Jan', 2:'Feb', 3:'Mar', 4:'Apr', 5:'May', 6:'Jun', 7:'Jul', 8:'Aug', 9:'Sept', 10:'Oct', 11:'Nov', 12:'Dec'});
    const [years, setYears] = useState([]);
    
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        let currentYear = new Date().getFullYear();
        let years = [];
        for (let i = 0; i <= 10; i++) {
            years.unshift(currentYear--);
        }
        setYears(years);


    }, []);

    useEffect(() => {
        if ( !dataLoaded && props.value) {
            let check = moment(props.value, 'YYYY/MM/DD');
            let month = check.format('M');
            let year  = check.format('YYYY');
            if (!props.monthHidden) {
                setSelectedMonth(month);
            }
            setSelectedYear(year);

            if (year <= (currentYear - 11)) {
                let yearPosition = +((currentYear - year) % 11) + +year;
                let years = [];
                for (let i = 0; i <= 10; i++) {
                    years.unshift(yearPosition--);
                }
                setYears(years);
            }
            
            setDataLoaded(true);
        }
    }, [props.presentDate, props.value]);


    const setPreviousYears = () => {
        let lastYear = years[0];
        if (lastYear) {
            let years = [];
            for (let i = 0; i <= 10; i++) {
                years.unshift(--lastYear);
            }
            setYears(years);
        }
    };

    const setNextYears = () => {
        let latestYear = years[years.length - 1];
        if (latestYear && (latestYear < currentYear)) {
            let years = [];
            for (let i = 0; i <= 10; i++) {
                years.push(++latestYear);
            }
            setYears(years);
        }
    };

    const minTwoDigits = (n) => ( n && n < 10 ? '0' : '') + n;

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const yearToggle = (year) => {
        if (year == selectedYear) {
            setSelectedYear('');
        } else {
            setSelectedYear(year);
        }

        /* if (selectedMonth && year) {
            handleClose();
        }  */
    }

    const monthToggle = (month) => {
        if (month == selectedMonth) {
            setSelectedMonth('');
        } else {
            setSelectedMonth(month);
        }

        /* if (month && selectedYear) {
            setOpen(false);
        }  */
    }

    useEffect(() => {
        let updatedValue = '';
        updatedValue = props.presentDate ? 'Present' : `${minTwoDigits(selectedMonth)}${(selectedMonth && selectedYear) ? '/' : ''}${selectedYear}`;
        setDatepickerValue(updatedValue);
    }, [props.presentDate, selectedMonth, selectedYear]);

    useEffect(() => {
        if (open && datepickerValue && selectedMonth && selectedYear) {
            handleClose();
        }
    }, [datepickerValue])

    /* useEffect(() => {
        if (datepickerValue) {
            props.changed(new Date(selectedYear, selectedMonth || 12, 0));
        } else {
            props.changed('');
        }
        
        if (selectedMonth) {
            props.changeMonthHiddenHandler(false);
        } else {
            props.changeMonthHiddenHandler(true);
        }
    }, [datepickerValue]); */

    const changeValue = (/* currentYear */) => {
        /* if (currentYear) {
            if (datepickerValue) {
                props.changed(new Date(currentYear, selectedMonth || 12, 0));
            } else {
                props.changed('');
            }
        } else {
            if (datepickerValue) {
                props.changed(new Date(selectedYear, selectedMonth || 12, 0));
            } else {
                props.changed('');
            }
        } */
        
        
        if (selectedMonth) {
            props.changeMonthHiddenHandler(false);
        } else {
            props.changeMonthHiddenHandler(true);
        }

        if (datepickerValue) {
            props.changed(new Date(selectedYear || currentYear, selectedMonth || 12, 0));
        } else {
            props.changed('');
        }
    }

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = (e) => {
        e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
        if (open) {
            if (!selectedYear && selectedMonth) {
                setSelectedYear(currentYear);
                changeValue();
            } else {
                changeValue();
            }
        }
    };

    const handleClose = (event) => {
        /* if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        } */
        setOpen(false);
        if (!selectedYear && selectedMonth) {
            setSelectedYear(currentYear);
            changeValue();
        } else {
            changeValue();
        }
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Input
                ref={anchorRef} 
                variant="outlined"
                type="text"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                classes={styles.DatepickerInput}
                //value={props.presentDate ? 'Present' : props.value ? moment(props.value, ["MM/YYYY", "MM", "YYYY"]).format("MMM YYYY") : ''}
                value={datepickerValue}
                clicked={handleToggle}
            />
            <Popper 
                open={open} 
                anchorEl={anchorRef.current} 
                transition 
                style={{ zIndex: 1 }}
                placement={'bottom-start'}
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div onKeyDown={handleListKeyDown} className={`${styles.DatepickerDropdown} ${props.presentDate ? styles.Disabled : ''}`}>
                                    <div className={styles.FlexRow}>
                                        <span className={styles.ControlsCell}>
                                            <IconButton onClick={setPreviousYears} className={styles.IconButton}><ChevronLeftIcon /></IconButton>
                                            <IconButton onClick={setNextYears} className={styles.IconButton} disabled={years[years.length - 1] >= currentYear}><ChevronRightIcon /></IconButton>
                                        </span>
                                        {years.map(year => (<span key={year} onClick={() => yearToggle(year)} className={`${styles.Cell} ${(year == selectedYear) ? styles.Active : ''}`}>{year}</span>))}
                                    </div>
                                    <div className={`${styles.FlexRow} ${styles.MonthesRow}`}>
                                        {Object.keys(monthes).map(key => (<span  key={key} onClick={() => monthToggle(key)} className={`${styles.Cell} ${(key == selectedMonth) ? styles.Active : ''}`}>{monthes[key]}</span>))}
                                    </div>
                                    {props.showCurrentlySwitch && (<div>
                                        <Switch
                                            checked={props.presentDate}
                                            swichChangeHandler={props.swichChangeHandler}
                                            name="presentDate"
                                        />
                                        {props.switchLabel || 'Present'}
                                    </div>)}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>        
        </>
    )
}

export default MonthYearPicker;