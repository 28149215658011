import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import axios from '../../axios-resumes';

import { getTransformedResume } from '../../shared/utility';

import { Container, Snackbar } from '@material-ui/core';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import Alert from '@material-ui/lab/Alert';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import Logo from '../../components/Logo/Logo';
import { checkValidity } from '../../shared/utility';

import styles from './SignUpBuilder.module.scss';

import ResumeViewer from '../PDFViewer/ResumeViewer';

import * as actions from '../../store/actions/index';

function ResumeBuilder(props) {

    const [values, setValues] = useState({
        controls: {
            firstName: {
                elementConfig: {
                    type: 'text',
                    name: 'First Name',
                    label: 'First Name',
                    placeholder: 'Your First Name',
                },
                value: '',
                validation: {
                    required: true,
                    isAlphabetic: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
            lastName: {
                elementConfig: {
                    type: 'text',
                    name: 'Last Name',
                    label: 'Last Name',
                    placeholder: 'Your Last Name',
                },
                value: '',
                validation: {
                    required: true,
                    isAlphabetic: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
            email: {
                elementConfig: {
                    type: 'email',
                    name: 'E-mail',
                    label: 'E-mail',
                    placeholder: 'Your E-mail Address',
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    message: '',
                },
                valid: false,
                touched: false,
                validationMessage: '',
            },
        },
    });

    const  [builderRedirect, setBuilderRedirect] = useState(null);

    const handleChange = (prop) => (event) => {
        //let validation = checkValidity( event.target.value, values.controls[prop].validation );
        const updatedControls = {
            ...values.controls,
            [prop]: {
                ...values.controls[prop],
                value: event.target.value,
                valid: checkValidity( event.target.value, values.controls[prop].validation ).valid,
                validationMessage: checkValidity( event.target.value, values.controls[prop].validation ).validationMessage,
                /* validation: {
                    message: checkValidity( event.target.value, values.controls[prop].validation ).validationMessage,
                }, */
                touched: true
            }
        };
        setValues({ ...values, controls: updatedControls });

        setResume({...resume, [prop]: event.target.value});
    };

    const submitHandler = ( event ) => {
        event.preventDefault();

        if (validateForm(values.controls)) {
            props.onAuth(values.controls.firstName.value, values.controls.lastName.value, values.controls.email.value );
        } else {
            console.log('Invalid Form');
        }
    }

    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const sampleId = query.get("id");
    const selectedTemplate = props.location.state ? props.location.state.templateId : 1;
    const templateId = query.get("templateId") ? query.get("templateId") : selectedTemplate;
    const [dataLoaded, setDataLoaded] = useState(false);

    const successMessage = sampleId ? 'Resume was created successfully' : 'Your account was created successfully' ;

    const [resume, setResume] = useState({});

    const isCoverLetter = props.location.state ? props.location.state.isCoverLetter : false;

    useEffect(() => {
        props.onAuthInit();
        
        if (sampleId) {
            axios.get( `/sample/resume/${sampleId}` )
                .then( response => {
                    let resume = getTransformedResume(response.data.resume);
                    if (resume) {
                        if (templateId) {
                            resume.templateId = templateId;
                        }
                        setResume(resume);
                        setDataLoaded(true);
                        /* if (props.isAuthenticated) {
                            setDataLoaded(true);
                        } else {
                            setDataLoaded(true);
                        } */
                    }
                } )
                .catch( err => {
                    console.log(err);
                } );
        } else {
            setDataLoaded(true);
            /* axios.get( `/example/resume/4` )
                .then( response => {
                    let resumeSample = getTransformedResume(response.data.resume);
                    if (resumeSample) {
                        setResume({ ...resumeSample, templateId: templateId, userId: props.userId, });
                        setDataLoaded(true);
                    }
                } )
                .catch( err => {
                    console.log(err);
                } ); */
        }
    }, [] );

    useEffect(() => {
        if (props.error) {
            setErrorMessageOpen(true);
        } else {
            setErrorMessageOpen(false);
        }
    }, [props.error] );

    useEffect(() => {
        if (props.isAuthenticated && dataLoaded) {
                handleSignUp();
                //setBuilderRedirect(<Redirect to={{ pathname: '/resume-builder', search: `?id=${sampleId}`}} />);
        }
    }, [props.isAuthenticated, dataLoaded]);

    const handleSignUp = () => {
        if (sampleId) {
            props.onCreateResume({
                ...resume,
                templateId: templateId,
                userId: props.userId,
                firstName: props.firstName,
                lastName: props.lastName,
                email: props.email
            }, props.token);
        } else {
            let document = {
                templateId: templateId,
                userId: props.userId,
                firstName: values.controls.firstName.value,
                lastName: values.controls.lastName.value,
                email: values.controls.email.value
            };

            if (isCoverLetter) {
                props.onCreateCoverLetter(document, props.token);
            } else {
                props.onCreateResume(document, props.token);
            }
            /* props.onCreateResume({
                templateId: templateId,
                userId: props.userId,
                firstName: values.controls.firstName.value,
                lastName: values.controls.lastName.value,
                email: values.controls.email.value
            }, props.token); */
        }

        console.log('created');
    }
    
    useEffect(() => {
        if (props.createdResumeId !== '') {
            setBuilderRedirect(<Redirect to={{ pathname: '/resume-builder', search: `?resumeId=${props.createdResumeId}`, state: { signUpMessage: successMessage }}} />);
        }
    }, [props.createdResumeId]);

    useEffect(() => {
        if (props.createdCoverLetterId !== '') {
            setBuilderRedirect(<Redirect to={{ pathname: '/cover-letter-builder', search: `?coverLetterId=${props.createdCoverLetterId}`, state: { signUpMessage: successMessage }}} />); 
        }
    }, [props.createdCoverLetterId]);

    //Generate Form Inputs
    const formElementsArray = [];
    for ( let key in values.controls ) {
        formElementsArray.push( {
            id: key,
            config: values.controls[key]
        } );
    }
    let formInputs = formElementsArray.map( formElement => (
        <Input
            variant="outlined"
            key={formElement.id}
            id={formElement.id}
            type={formElement.config.elementConfig.type}
            name={formElement.config.elementConfig.name}
            label={formElement.config.elementConfig.label}
            placeholder={formElement.config.elementConfig.placeholder}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            validationMessage={formElement.config.validationMessage}
            touched={formElement.config.touched}
            classes={styles.Input}
            changed={handleChange(formElement.id)}
        />
    ) );

    //Form Validation
    const validateForm = (controls) => {
        let valid = true;
        
        let updatedControls = {
            ...values.controls,
        };

        Object.keys(controls).forEach(
            // if we have an error string set valid to false
            (key) => {
                console.log(values.controls[key].valid);

                updatedControls[key] = {
                    ...values.controls[key],
                    touched: true
                };

                return !values.controls[key].valid && (valid = false)
            }
        );
        
        setValues({ ...values, controls: updatedControls });
        return valid;
    }


    const [contentHeight, setContentHeight] = useState('11.69in');
    const resumeViewerRef = useRef(null);

    // Callback function to execute when mutations are observed
    const setResumeParentHeight = function(mutationsList, observer) {
        if (resumeViewerRef.current) {
            let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height + 100;
            setContentHeight(resumeHeight);
        }
    };
    
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(setResumeParentHeight);

    useEffect(() => {
        const targetNode = document.getElementById('resume-result');
        const config = { attributes: true, childList: true, subtree: true };
        observer.observe(targetNode, config);
        return () => {
            observer.disconnect();
        }
    }, []);

    return (
        <>
            {builderRedirect}
            <Helmet>
                <title>Resume Builder - ResumeOK</title>
            </Helmet>
            <Container component="main" className={styles.Wrapper}> 
                <div className={styles.ResumeForm} >
                    <div className={styles.Logo}>
                        <Logo />
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={errorMessageOpen}
                        autoHideDuration={5000}
                        onClose={() => setErrorMessageOpen(false)}
                    >
                        <Alert severity="error">{props.error}</Alert>
                    </Snackbar>
                    <form className={styles.Form}>
                        <h2>Start your resume by adding your name</h2>
                        {formInputs}
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            clicked={submitHandler}
                            className={styles.Button}
                        >
                            Continue <ArrowRightAltIcon />
                        </Button>
                    </form>
                    <div>
                        Already have a resume? 
                        <NavLink 
                            className={styles.SignInLink}
                            to="/login"
                            exact>
                            Login here
                        </NavLink>
                    </div>
                </div>

                <div id="resume-result" className={styles.ResumeResult}>           
                    <div className={styles.ResultContent} style={{minHeight: contentHeight}} >
                        {
                            isCoverLetter ? (<ResumeViewer coverLetter={resume} templateId={templateId} documentId={0} ref={resumeViewerRef} />)
                            : (<ResumeViewer resume={resume} templateId={templateId} documentId={0} ref={resumeViewerRef} />)
                        }
                    </div>
                </div>
            </Container>
        </>
        )
    }

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        createdResumeId: state.resume.createdResumeId,
        createdCoverLetterId: state.coverLetter.createdCoverLetterId,
        firstName: state.auth.userFirstName,
        lastName: state.auth.userLastName,
        email: state.auth.userEmail,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( firstName, lastName, email, password ) => dispatch( actions.register( firstName, lastName, email, password ) ),
        onAuthInit: () => dispatch(actions.authInit()),
        onCreateResume: ( resumeData, token ) => dispatch( actions.createResume( resumeData, token ) ),
        onCreateCoverLetter: ( coverLetterData, token ) => dispatch( actions.createCoverLetter( coverLetterData, token ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ResumeBuilder );