import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SavedIcon(props) {
    return (
        <SvgIcon {...props} style={{animation:'spin .7s linear infinite'}} > 
            <path d="M6.09131 3.80664V6.05664L9.09131 3.05664L6.09131 0.0566406V2.30664C2.77631 2.30664 0.0913086 4.99164 0.0913086 8.30664C0.0913086 9.48414 0.436308 10.5791 1.02131 11.5016L2.11631 10.4066C1.77881 9.78414 1.59131 9.06414 1.59131 8.30664C1.59131 5.82414 3.60881 3.80664 6.09131 3.80664ZM11.1613 5.11164L10.0663 6.20664C10.3963 6.83664 10.5913 7.54914 10.5913 8.30664C10.5913 10.7891 8.57381 12.8066 6.09131 12.8066V10.5566L3.09131 13.5566L6.09131 16.5566V14.3066C9.40631 14.3066 12.0913 11.6216 12.0913 8.30664C12.0913 7.12914 11.7463 6.03414 11.1613 5.11164Z" />
            {/* <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="360 1 1" to="0 1 1" dur="0.7s" additive="sum" repeatCount="indefinite" /> */}
        </SvgIcon>
    );
}