import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container, Paper, Link, Grid, Checkbox, FormControlLabel, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import Switch from '../../components/UI/Switch/Switch';

import styles from './Account.module.scss';

import { checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';

const GreenCheckbox = withStyles({
    root: {
      color: '#10b58c',
      '&$checked': {
        color: '#10b58c',
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Account(props) {
    const [userData, setUserData] = useState({
        firstName: {
            value: props.firstName || '',
            validation: {
                required: true,
                isAlphabetic: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        lastName: {
            value: props.lastName || '',
            validation: {
                required: true,
                isAlphabetic: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        email: {
            value: props.email || '',
            validation: {
                required: true,
                isEmail: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
    });

    /* const [resumeVisibility, setResumeVisibility] = useState(props.resumeVisibility || false); */

    const [changePasswordOpen, setChangePasswordOpen] = useState(false);

    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: ''
    });

    const [deleteUserData, setDeleteUserData] = useState({
        showDeletePanel: false,
        deleteReasons: {
            'Reason #1': false,
            'Reason #2': false,
            'Reason #3': false,
            'Reason #4': false,
            'Other Reason': false,
        },
    });

    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);

    useEffect(() => {
        if (props.successMessage) {
            setSuccessMessageOpen(true);
        }
    }, [props.successMessage] );

    useEffect(() => {
        if (props.error) {
            setErrorMessageOpen(true);
        }
    }, [props.error] );

    const handleChange = (prop) => (event) => {
        const updatedControls = {
            ...userData,
            [prop]: {
                ...userData[prop],
                value: event.target.value,
                valid: checkValidity( event.target.value, userData[prop].validation ).valid,
                validationMessage: checkValidity( event.target.value, userData[prop].validation ).validationMessage,
                touched: true
            }
        };

        setUserData({ ...updatedControls });
    };
    
    const changeUserData = (event) => {
        event.preventDefault();
        props.onChangeUserData( props.token, userData.firstName.value, userData.lastName.value, userData.email.value );
    }

    const handlePasswordChangeInput = (prop) => (event) => {
        setPasswords({ ...passwords, [prop] : event.target.value });
    }

    const changePassword = (event) => {
        event.preventDefault();
        props.onChangePassword( props.token, passwords.currentPassword, passwords.newPassword );
    }

    const deleteUser = (event) => {
        event.preventDefault();
        let reasons = Object.keys(deleteUserData.deleteReasons).filter(reason => deleteUserData.deleteReasons[reason]);
        console.log(reasons);
        props.onDeleteUser( props.token, reasons );
    }

    const handleReasonChange = (event) => {
        const updatedReasons = {
            ...deleteUserData.deleteReasons,
            [event.target.name]:  event.target.checked
        };
        setDeleteUserData({ ...deleteUserData, deleteReasons: updatedReasons });
    };

    const restoreAccount = () => {
        props.onRestoreAccount(props.userId);
        setDeleteUserData({...deleteUserData, showDeletePanel:false})
    }

    return (
        <>
            <Helmet>
                <title>Account Settings - ResumeOK</title>
            </Helmet>
            <Container component="main" maxWidth="md" className={styles.AccountContainer}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={successMessageOpen}
                    autoHideDuration={5000}
                    severity="success"
                    onClose={() => setSuccessMessageOpen(false)}
                >
                    <Alert severity="success">{props.successMessage}</Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={errorMessageOpen}
                    autoHideDuration={5000}
                    onClose={() => setErrorMessageOpen(false)}
                >
                    <Alert severity="error">{props.error}</Alert>
                </Snackbar>
                <Paper className={styles.Paper}>
                    <h3>Personal details</h3>
                    <form className={styles.Form}>
                        <Input 
                            variant="outlined"
                            id="firstName"
                            type="text"
                            label="First Name"
                            name="First Name"
                            classes={styles.Input}
                            value={userData.firstName.value}
                            invalid={!userData.firstName.valid}
                            shouldValidate={userData.firstName.validation}
                            validationMessage={userData.firstName.validationMessage}
                            touched={userData.firstName.touched}
                            changed={handleChange("firstName")}
                        />

                        <Input 
                            variant="outlined"
                            id="lastName"
                            type="text"
                            label="Last Name"
                            name="Last Name"
                            classes={styles.Input}
                            value={userData.lastName.value}
                            invalid={!userData.lastName.valid}
                            shouldValidate={userData.lastName.validation}
                            validationMessage={userData.lastName.validationMessage}
                            touched={userData.lastName.touched}
                            changed={handleChange("lastName")}
                        />

                        <Input 
                            variant="outlined"
                            id="email"
                            type="email"
                            label="E-mail (your login email)"
                            name="E-mail"
                            classes={styles.Input}
                            value={userData.email.value}
                            invalid={!userData.email.valid}
                            shouldValidate={userData.email.validation}
                            validationMessage={userData.email.validationMessage}
                            touched={userData.email.touched}
                            changed={handleChange("email")}
                        />
                    </form>

                    <Link href="#" className={styles.Link} onClick={changeUserData}>
                        Save
                    </Link>
                </Paper>

                <Paper className={styles.Paper}>
                    <h3>Password</h3>
                    <p>Your password is well protected but you can changed  it whenever your need.</p>
                    {changePasswordOpen ? (
                        <>
                            <Grid container spacing={1} style={{marginBottom: '1rem'}}>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant="outlined"
                                        id="current-pass"
                                        type="password"
                                        name="Current Password"
                                        label="Current Password"
                                        value={passwords.currentPassword}
                                        changed={handlePasswordChangeInput('currentPassword')}
                                        classes={styles.Input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        variant="outlined"
                                        id="new-pass"
                                        type="password"
                                        name="New Password"
                                        label="New Password"
                                        value={passwords.newPassword}
                                        classes={styles.Input}
                                        changed={handlePasswordChangeInput('newPassword')}
                                    />
                                </Grid>
                            </Grid>
                            <Link href="#" className={styles.Link} onClick={changePassword}>
                                Change password
                            </Link>
                        </>
                    ): (
                        <Link href="#" className={styles.Link} onClick={(event) => {event.preventDefault(); setChangePasswordOpen(true);}}>
                            Change password
                        </Link>
                    )}
                </Paper>

                <Paper className={styles.Paper}>
                    <div className={styles.FlexRow}>
                        <h3>Profile visibility</h3>
                        <Switch
                            checked={Boolean(Number(props.resumeVisibility))}
                            swichChangeHandler={(event) => props.onChangeResumeVisibility(props.token, Number(event.target.checked))}
                            name="resumeVisibility"
                        />
                    </div>
                    <p>By default your Resume is visible on Google and employers could contact you at any point.</p>
                </Paper>
                {props.userDeleted ? (
                    <Paper className={styles.Paper}>
                        <h3>Delete account</h3>
                        <p>Thank your for your feedback! The process of deleting your account has started. You can cancel this and restore your account.</p>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            clicked={restoreAccount}
                            className={styles.Button}
                        >
                            Restore Account
                        </Button>
                    </Paper>
                ) : (
                    <Paper className={styles.Paper}>
                        <h3>Delete account</h3>
                        {deleteUserData.showDeletePanel ? (
                            <>
                                <p>By deleting your account, you will lose all your CVs and progress that you made so far. 
                                    This action is ireversible.</p>
                                <p>Before you go, we would like to know what made you delete your resume.ok account? 
                                Your answer will help us improve our product in the future</p>
                                {Object.keys(deleteUserData.deleteReasons).map((reason) => (
                                    <FormControlLabel style={{display: 'block'}} key={reason}
                                        control={<GreenCheckbox checked={deleteUserData.deleteReasons[reason]} onChange={handleReasonChange} name={reason} />}
                                        label={reason}
                                    />
                                ))}
                                <Link href="#" color="error" onClick={deleteUser}>
                                    Delete account
                                </Link>
                            </>
                        ) : (
                            <>
                                <p>By deleting your account, you will lose all your CVs and progress that you made so far. This action is ireversible.</p>
                                <Link href="#" color="error" onClick={(event) => {event.preventDefault(); setDeleteUserData({...deleteUserData, showDeletePanel:true})}}>
                                    Delete account
                                </Link>
                            </>
                        )}
                    </Paper>
                )}
                
            </Container>
        </>
    );
}

const mapStateToProps = state => {
    return {
        email: state.auth.userEmail,
        firstName: state.auth.userFirstName,
        lastName: state.auth.userLastName,
        token: state.auth.token,
        userId: state.auth.userId,
        error: state.auth.error,
        successMessage: state.auth.successMessage,
        resumeVisibility: state.auth.resumeVisibility,
        userDeleted: state.auth.userDeleted,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeUserData: ( token, firstName, lastName, email ) => dispatch( actions.changeUserData( token, firstName, lastName, email ) ),
        onChangeResumeVisibility: ( token, resumeVisibility ) => dispatch( actions.changeVisibility( token, resumeVisibility ) ),
        onChangePassword: ( token, currentPassword, newPassword ) => dispatch( actions.changePassword( token, currentPassword, newPassword ) ),
        onDeleteUser: ( token, reasons ) => dispatch( actions.deleteUser( token, reasons ) ),
        onRestoreAccount: ( userId ) => dispatch( actions.restoreAccount( userId ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Account );