import React, { useState } from 'react';
import styles from './BuilderToolbar.module.scss';

import FontItem from './FontItem.js';
import ColorItem from './ColorItem.js';
import DesignItem from './DesignItem.js';
import ShareItem from './ShareItem.js';
import ComplectionItem from './ComplectionItem.js';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import FontIcon from '../UI/Icons/FontIcon';
import DesignIcon from '../UI/Icons/DesignIcon';
import SwitchTemplateIcon from '../UI/Icons/SwitchTemplateIcon';
import DownloadIcon from '../UI/Icons/DownloadIcon';
//import LinkedinIcon from '../UI/Icons/LinkedinIcon';
import SamplesIcon from '../UI/Icons/SamplesIcon';
import ResumeTypeIcon from '../UI/Icons/ResumeTypeIcon';
import ShareIcon from '../UI/Icons/ShareIcon';
import DesignToolsIcon from '../UI/Icons/DesignToolsIcon';

import Dialog from '../UI/Dialog/Dialog';
import Button from '../UI/Button/Button';

import Link from '@material-ui/core/Link';


function MobileToolbar(props) {
    const [designToolsDialogOpen, setDesignToolsDialogOpen] = useState(false);

    const [toolDialogOpen, setToolDialogOpen] = useState(false);
    const [activeDialog, setActiveDialog] = useState('font');

    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

    const toolsDialogs = {
        font: {
            name: 'Font',
            icon: <FontIcon viewBox="0 0 14 14" />,
            content: <FontItem
                        fontFamily={props.fontFamily}
                        onFontFamilyChange={props.handleFontFamilyChange}
                        fontSize={props.fontSize}
                        onFontSizeChange={props.handleFontSizeChange}
                    />,
            showBtn: true,
        },
        color: {
            name: 'Colors',
            icon: <span className={styles.ColorIcon} style={{backgroundColor: props.primaryColor}}></span>,
            content: <ColorItem 
                        primaryColor={props.primaryColor}
                        secondaryColor={props.secondaryColor}
                        onPrimaryColorChange={props.handlePrimaryColorChange}
                        onSecondaryColorChange={props.handleSecondaryColorChange}
                    />,
        },
        design: {
            name: 'Design',
            icon: <DesignIcon  viewBox="0 0 13 12" />,
            content: <DesignItem 
                        className={styles.Mobile}
                        pattern={props.pattern}
                        onPatternChange={props.handlePatternChange}
                    />,
            showBtn: true,
        },
        share: {
            name: 'Share',
            icon: <ShareIcon viewBox="0 0 19 19" />,
            content: <ShareItem documentId={props.documentId} isCoverLetter={props.isCoverLetter} />,
            showBtn: false,
        },
        type: {
            name: 'Resume Type',
            icon: <ResumeTypeIcon viewBox="0 0 10 10" />,
            content: <span>{props.resumeTypes && Object.keys(props.resumeTypes).map(type => <li onClick={() => props.handleChooseType(type)} key={type} className={styles.ResumeTypeItem}>{props.resumeTypes[type]}</li>)}</span>,
            showBtn: true,
        },
        complection: {
            name: 'Progress',
            icon: <></>,
            content: <ComplectionItem  resume={props.document} scrollToProgressHandler={props.scrollToProgressHandler} onProgressValueChange={props.handleProgressValue} />,
            showBtn: false,
        },
        download: {
            name: 'Download',
            icon: <DownloadIcon viewBox="0 0 19 19" />,
            content: <><span onClick={props.generatePdf} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}>Download PDF</span><span onClick={props.generateScreenshot} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}>Download PNG</span></>,
            showBtn: false,
        }
    };

    const selectActiveDialog = (dialogKey) => {
        setActiveDialog(dialogKey);
        setDesignToolsDialogOpen(false);
        setToolDialogOpen(true);
    }

    
    return (
        <>
            <nav className={`${styles.BuilderToolbar} ${styles.Mobile}`}>
                <ul className={styles.ResumeTools}>
                    <li className={styles.ToolbarItem} onClick={() => setDesignToolsDialogOpen(true)}>
                        <DesignToolsIcon viewBox="0 0 18 18" /><span>Resume Design</span>
                    </li>
                </ul>
                <ul className={styles.ResumeActions}>
                    {!props.isCoverLetter && (
                        <li className={styles.ToolbarItem} onClick={() => selectActiveDialog('complection')}>
                            <CircularProgressWithLabel progressValue={props.progressValue} />
                        </li>
                    )}
                    {/* <li className={styles.ToolbarItem}>
                        <LinkedinIcon viewBox="0 0 11 11" />
                    </li> */}
                    <li className={styles.ToolbarItem} onClick={() => selectActiveDialog('share')}>
                        <ShareIcon viewBox="0 0 19 19" />
                    </li>
                    <li className={styles.ToolbarItem} onClick={() => selectActiveDialog('download')}>
                        <DownloadIcon viewBox="0 0 19 19" />
                    </li>
                    {/* <li>
                        <span onClick={props.generatePdf} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}><DownloadIcon viewBox="0 0 19 19" />PDF</span>
                    </li>                
                    <li>
                        <span onClick={props.generateScreenshot} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}><DownloadIcon viewBox="0 0 19 19" />PNG</span>
                    </li> */}
                    {/* <a href={props.pdfUrl} download={`${props.resume.firstName} ${props.resume.lastName} resume.pdf`} type="application/pdf" className={styles.Button}>
                        <DownloadIcon viewBox="0 0 19 19" />
                    </a> */}
                </ul>
            </nav>

            <Dialog
                open={designToolsDialogOpen}
                closed={() => setDesignToolsDialogOpen(false)}
                className={styles.DesignToolsDialog}
                classNameCloseBtn={styles.CloseDialogButton}
                dialogBg="transparent"
                dialogPadding="0"
                title={(<div className={styles.DialogTitle}><DesignToolsIcon viewBox="0 0 18 18" /><span>Resume Design</span></div>)}
            >
                <ul className={`${styles.DialogContentWrap} ${styles.ButtonsWrap}`}>
                    <li onClick={() => selectActiveDialog('font')} className={styles.IconButton}>
                        <FontIcon viewBox="0 0 14 14" />
                        <span className={styles.Label}>Font</span>
                    </li>
                    <li onClick={() => selectActiveDialog('color')} className={styles.IconButton}>
                        <span className={styles.ColorIcon} style={{backgroundColor: props.primaryColor}}></span>
                        <span className={styles.Label}>Colors</span>
                    </li>
                    <li onClick={() => selectActiveDialog('design')} className={styles.IconButton}>
                        <DesignIcon  viewBox="0 0 13 12" />
                        <span className={styles.Label}>Design</span>
                    </li>
                    <li onClick={() => {setDesignToolsDialogOpen(false); props.showTemplatesHandler();}} className={styles.IconButton}>
                        <SwitchTemplateIcon viewBox="0 0 13 12" />
                        <span className={styles.Label}>Template</span>
                    </li>
                    {!props.isCoverLetter && (<>
                        <li>
                            <a href="http://www.resumeok.com/resume-samples/" target="_blank" rel="noopener noreferrer" className={styles.IconButton}>
                                <SamplesIcon viewBox="0 0 11 11" />
                                <span className={styles.Label}>Samples</span>
                            </a>
                        </li>
                        <li onClick={() => selectActiveDialog('type')} className={styles.IconButton}>
                            <ResumeTypeIcon viewBox="0 0 10 10" />
                            <span className={styles.Label}>{props.activeType}</span>
                        </li> 
                    </>)}
                </ul>
                <Link onClick={() => setDesignToolsDialogOpen(false)} className={styles.Link}>Cancel</Link>
            </Dialog>

            <Dialog
                open={toolDialogOpen}
                closed={() => setToolDialogOpen(false)}
                className={styles.DesignToolsDialog}
                classNameCloseBtn={styles.CloseDialogButton}
                dialogBg="transparent"
                dialogPadding="0"
                title={(<div className={styles.DialogTitle}>{toolsDialogs[activeDialog].icon}<span>{toolsDialogs[activeDialog].name}</span></div>)}
            >
                <div className={styles.DialogContentWrap}>
                    {toolsDialogs[activeDialog].content}
                </div>
                {toolsDialogs[activeDialog].showBtn && <Button variant="contained" color="primary" clicked={() => setToolDialogOpen(false)} className={styles.Button}>Apply and go back</Button>}
                <Link onClick={() => setToolDialogOpen(false)} className={styles.Link}>Cancel</Link>
            </Dialog>

            {!props.isCoverLetter && (<ComplectionItem resume={props.document} scrollToProgressHandler={props.scrollToProgressHandler} onProgressValueChange={props.handleProgressValue} hidden/>)}
        </>
    )
}
export default MobileToolbar;