import React, {useState} from 'react';

import {Grid, InputLabel, IconButton, Collapse} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';
import MonthYearPicker from '../../components/UI/MonthYearPicker/MonthYearPicker';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { generateDateString } from '../../shared/utility';

import { Draggable } from 'react-beautiful-dnd';

import styles from './ResumeBuilder.module.scss';

const DatepickerInput = ({onChange, placeholder, value, isSecure, id, onClick}) => (
	<input
		onChange={onChange}
		placeholder={placeholder}
		value={value}
		isSecure={isSecure}
		id={id}
		onClick={onClick}
	/>
);

function WorkExperienceItem(props) {

    //const [presentDate, setPresentDate] = useState(false);
    const [dateValue, setDateValue] = useState(null);

    const handlePresentDateChange = (event) => {
        props.changed(props.index, 'presentDate', event.target.checked)();
        //setPresentDate(event.target.checked);
    };

    const onFieldChange = (fieldKey, content) => {
        /* let date = content;
        setDateValue(date); */
        props.changed(props.index, fieldKey, content)();
    }

    const changeDateValue = (fieldKey, content) => {
        let date = content;
        setDateValue(date);
        props.changed(props.index, fieldKey, content)();
    }
    
    /* useEffect(() => {
        if (presentDate) {
            props.changed(props.index, 'endDate', 'Present')();
        }
    }, [presentDate]); */

    let workDatesString = generateDateString(props.values.startDate, props.values.endDate, props.values.presentDate, props.values.startDateMonthHidden, props.values.endDateMonthHidden, "MMM YYYY");

    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided) => (
                <div 
                    className={styles.FormRepeatedItem} 
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <Grid container spacing={1} alignItems="center" justify="space-between" wrap='nowrap'>
                        <Grid item onClick={props.toggled} className={styles.ItemTitle} {...provided.dragHandleProps}>
                            <h4 className={styles.ResumeItemTitle}>{props.values.jobTitle} {(props.values.jobTitle && props.values.company) ? 'at' : ''} {props.values.company}</h4>
                            {workDatesString ? (<p className={styles.ResumeItemDates}>{workDatesString}</p>) : ''}
                        </Grid>
                        <Grid item className={styles.ItemControls}>
                            <div className={styles.DragButton} {...provided.dragHandleProps}><DragIndicatorIcon /></div>
                            <IconButton onClick={props.toggled} className={styles.PanelToggle}>
                                {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                            </IconButton>
                            <IconButton onClick={props.deleted} style={{float: 'right', padding: '.15rem'}} className={styles.DeleteButton}>
                                <DeleteIcon style={{fontSize: '1.3rem'}}/>
                            </IconButton>                 
                        </Grid>
                    </Grid>
                    <Collapse in={props.open}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    variant="outlined"
                                    key="jobTitle"
                                    id="jobTitle"
                                    type="text"
                                    label="Job Title"
                                    value={props.values.jobTitle}
                                    changed={props.changed(props.index, 'jobTitle')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    variant="outlined"
                                    key="company"
                                    id="company"
                                    type="text"
                                    label="Company"
                                    value={props.values.company}
                                    changed={props.changed(props.index, 'company')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <MonthYearPicker
                                    label="Start Date"
                                    name="Start Date"
                                    changed={(date) => onFieldChange('startDate', date)}
                                    placeholder="mm/yyyy"
                                    value={props.values.startDate}
                                    monthHidden={props.values.startDateMonthHidden}
                                    changeMonthHiddenHandler={(isHidden) => onFieldChange('startDateMonthHidden', isHidden)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <MonthYearPicker
                                    label="End Date"
                                    name="End Date"
                                    changed={(date) => onFieldChange('endDate', date)}
                                    placeholder="mm/yyyy"
                                    showCurrentlySwitch={true}
                                    presentDate={props.values.presentDate}
                                    swichChangeHandler={handlePresentDateChange}
                                    switchLabel="Currently work here"
                                    value={props.values.endDate}
                                    monthHidden={props.values.endDateMonthHidden}
                                    changeMonthHiddenHandler={(isHidden) => onFieldChange('endDateMonthHidden', isHidden)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>                                        
                                <Input
                                    variant="outlined"
                                    key="city"
                                    id="city"
                                    type="text"
                                    label="City"
                                    value={props.values.city}
                                    changed={props.changed(props.index, 'city')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className={styles.Label}>Resposabilities & summary</InputLabel>
                                <TextEditor
                                    value={props.values.description}
                                    changed={(content) => onFieldChange('description', content)}
                                    key={props.id}
                                    id={props.id}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>
            )}
        </Draggable>
    )
}

export default WorkExperienceItem;