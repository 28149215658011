import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Container, Paper, Grid, Tabs, Tab }  from '@material-ui/core';

import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';
import ResumeCard from '../../components/ResumeCard/ResumeCard';
import TabPanel from '../../components/UI/TabPanel/TabPanel';

import { makeStyles } from "@material-ui/core/styles";
import styles from './Dashboard.module.scss';

import goodResumeIcon from '../../assets/images/good_resume_icon.svg';
import objectiveSamplesIcon from '../../assets/images/objective_samples_icon.svg';
import resumeSamplesIcon from '../../assets/images/resume_samples_icon.svg';
import tipsTricksIcon from '../../assets/images/tips_tricks_icon.svg';
import dashboardResumeImage from '../../assets/images/dashboard-resume.svg';
import dashboardCoverLetterImage from '../../assets/images/dashboard-cover-letter.svg';

import BerlinPdf from '../../components/PDFTemplates/BerlinPdf';
import TokyoPdf from '../../components/PDFTemplates/TokyoPdf';
import LondonPdf from '../../components/PDFTemplates/LondonPdf';
import ParisPdf from '../../components/PDFTemplates/ParisPdf';
import MadridPdf from '../../components/PDFTemplates/MadridPdf';
import HloomPdf from '../../components/PDFTemplates/HloomPdf';
import CleanBluePdf from '../../components/PDFTemplates/CleanBluePdf';
import CreativePdf from '../../components/PDFTemplates/CreativePdf';
import TraditionalPdf from '../../components/PDFTemplates/TraditionalPdf';
import DublinPdf from '../../components/PDFTemplates/DublinPdf';
import ModernBluePdf from '../../components/PDFTemplates/ModernBluePdf';
import ClassicPdf from '../../components/PDFTemplates/ClassicPdf';
import WoodlawnPdf from '../../components/PDFTemplates/WoodlawnPdf';
import MonospacedPdf from '../../components/PDFTemplates/MonospacedPdf';
import DarkRedPdf from '../../components/PDFTemplates/DarkRedPdf';
import BlankPdf from '../../components/PDFTemplates/BlankPdf';
import NoColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/NoColorsPdf';
import DifferentColorsPdf from '../../components/PDFTemplates/CoverLetterTemplates/DifferentColorsPdf';

import { pdf } from '@react-pdf/renderer';

import * as actions from '../../store/actions/index';

const tabStyles = makeStyles(theme => ({
    hideIndicator: {
        display: 'none',
    },
    tabItem: {
        textTransform: "none",
        color: '#8190AA',
        opacity: 1,
        width: 'auto',
        fontSize: '1.3rem',
        backgroundColor: 'transparent',
        flex: '0 0 auto'
    },
    selectedTab: {
        color: '#677089',
    },
}));

const DashboardItem = (props) =>
    <Grid item xs={12} sm={6} md={3} 
        className={styles.ResumeCardWrap} 
        onMouseEnter={props.addHoverClass}
        onMouseLeave={props.removeHoverClass}
        key={props.item.id}
    >
        <Link
            to={{
                pathname: props.pathname,
                search: props.search,
                state: {
                    isEditing: true,
                    template: props.item.templateId,
                }
            }}
            className={styles.ResumeCardLink}
        >
            <ResumeCard
                templateId={props.item.templateId}
                className={styles.ResumeCard}
                cardImage={props.cardImage} 
            />
        </Link>
        <Paper className={styles.ResumeControls}>
            <Link
                to={{
                    pathname: props.pathname,
                    search: props.search,
                    state: {
                        isEditing: true,
                        template: props.item.templateId,
                    }
                }}
                className={styles.ControlLink}
            >Edit</Link>
            <div className={styles.ControlLink} onClick={props.openShareDialog}>Share</div> 
            <div className={styles.ControlLink} onClick={props.downloadDocument}>Download</div> 
            <div className={`${styles.ControlLink} ${styles.Danger} `} onClick={props.openDeleteDialog}>Delete</div>                
        </Paper>
    </Grid>


function Dashboard(props) {
    const classes = tabStyles();

    const [deleteDialog, setDeleteDialog] = useState({open: false, deletedId: ''});
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [sharedLink, setSharedLink] = useState('');
    const [hover, setHover] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        props.onFetchResumes(props.token, props.userId);
        props.onFetchCoverLetters(props.token, props.userId);
    }, []);

    const addHoverClass = () => {
        setHover(true);
    }

    const removeHoverClass = () => {
        setHover(false);
    }

    const handleOpenDeleteDialog = (resumeId) => {
        setDeleteDialog({open: true, deletedId: resumeId});
    };
    
    const handleCloseDeleteDialog = () => {
        setDeleteDialog({open: false, deletedId: ''});
    };

    const deleteResumeHandle = (resumeId) => {
        props.onDeleteResume(props.token, resumeId, props.userId)
        handleCloseDeleteDialog();
    };

    const deleteCoverLetterHandle = (coverLetterId) => {
        props.onDeleteCoverLetter(props.token, coverLetterId, props.userId)
        handleCloseDeleteDialog();
    };

    const handleOpenShareDialog = (documentId) => {
        setSharedLink(btoa(documentId));
        setShareDialogOpen(true);
    };
    
    const handleCloseShareDialog = () => {
        setShareDialogOpen(false);
    };

    const sharedLinkRef = useRef(null);

    const copyToClipboard = (e) => {
        sharedLinkRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const selectPdfTemplate = (document) => {
        if (tabValue === 0) {
            switch(Number(document.templateId)) {
                case 1:   return <TokyoPdf resume={document} resumeConfiguration={document.configuration} />;
                case 2:   return <BerlinPdf resume={document} resumeConfiguration={document.configuration} />;
                case 3:   return <LondonPdf resume={document} resumeConfiguration={document.configuration} />;
                case 4:   return <ParisPdf resume={document} resumeConfiguration={document.configuration} />;
                case 5:   return <MadridPdf resume={document} resumeConfiguration={document.configuration} />;
                case 9:   return <HloomPdf resume={document} resumeConfiguration={document.configuration} />;
                case 10:  return <CleanBluePdf resume={document} resumeConfiguration={document.configuration} />;
                case 11:  return <CreativePdf resume={document} resumeConfiguration={document.configuration} />;
                case 12:  return <TraditionalPdf resume={document} resumeConfiguration={document.configuration} />;
                case 13:  return <DublinPdf resume={document} resumeConfiguration={document.configuration} />;
                case 14:  return <ModernBluePdf resume={document} resumeConfiguration={document.configuration} />;
                case 15:  return <ClassicPdf resume={document} resumeConfiguration={document.configuration} />;
                case 16:  return <WoodlawnPdf resume={document} resumeConfiguration={document.configuration} />;
                case 17:  return <MonospacedPdf resume={document} resumeConfiguration={document.configuration} />;
                case 18:  return <DarkRedPdf resume={document} resumeConfiguration={document.configuration} />;
                default:  return <BlankPdf />;
            }
        }
        else {
            switch(Number(document.templateId)) {
                case 1:   return <NoColorsPdf coverLetter={document} coverLetterConfiguration={document.configuration} />;
                case 2:   return <DifferentColorsPdf coverLetter={document} coverLetterConfiguration={document.configuration} />;
                default:  return <BlankPdf />;
            }
        }
    }

    const downloadDocument = (documentPdf) => {
        console.log(documentPdf);
        pdf(selectPdfTemplate(documentPdf)).toBlob().then(blob => {
            const url = URL.createObjectURL(blob);
            const filename = `${documentPdf.firstName} ${documentPdf.lastName} resume.pdf`;
            // Create a new anchor element
            const a = document.createElement('a');
            
            a.href = url;
            a.download = filename || 'download';
            
            const clickHandler = () => {
                setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
                }, 50);
            };

            a.addEventListener('click', clickHandler, false);
            
            a.click();
        
            return a;
        })
    }

    let resumes = props.resumes.sort((a,b) =>  b.id-a.id).map( resume => 
        <DashboardItem 
            key = {resume.id}
            item = {resume}
            cardImage = {resume.resumeImage}
            pathname = "/resume-builder"
            search = {`?resumeId=${resume.id}`}
            addHoverClass = {addHoverClass}
            removeHoverClass = {removeHoverClass}
            openShareDialog = {() => handleOpenShareDialog(resume.id)}
            downloadDocument = {() => downloadDocument(resume)}
            openDeleteDialog = {() => handleOpenDeleteDialog(resume.id)}
        />);

    const coverLetters = props.coverLetters.sort((a,b) =>  b.id-a.id).map( coverLetter => 
        <DashboardItem 
            key = {coverLetter.id}
            item = {coverLetter}
            cardImage = {coverLetter.coverLetterImage}
            pathname = "/cover-letter-builder"
            search = {`?coverLetterId=${coverLetter.id}`}
            addHoverClass = {addHoverClass}
            removeHoverClass = {removeHoverClass}
            openShareDialog = {() => handleOpenShareDialog(coverLetter.id)}
            downloadDocument = {() => downloadDocument(coverLetter)}
            openDeleteDialog = {() => handleOpenDeleteDialog(coverLetter.id)}
        />);

    return (
        <>
            <Helmet>
                <title>Dashboard - ResumeOK</title>
            </Helmet>
            <Container component="main" className={styles.Dashboard}>
                <Grid container spacing={3} className={styles.DashboardTitle} alignItems="center"> 
                    <Grid item xs={6}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            classes={{ root: classes.tabsWrap, indicator: classes.hideIndicator }} 
                        >
                            <Tab label="Resumes" classes={{ root: classes.tabItem, selected: classes.selectedTab }} />
                            <Tab label="Cover Letters" classes={{ root: classes.tabItem, selected: classes.selectedTab }} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={6} className={styles.ButtonWrap}>
                        {tabValue === 1 ? 
                            (<Link to="/choose-cover-template" className={styles.Button}>+ New Cover Letter</Link>) : 
                            (<Link to="/choose-template" className={styles.Button}>+ New Resume</Link>)}
                    </Grid>
                </Grid>

                <TabPanel value={tabValue} index={0}>
                    {/* {!props.loading ? ( */}
                    <Grid container spacing={3} className={`${styles.DashboardContent} ${hover ? styles.DashboardContentHover : null} `}>
                        {resumes && resumes.length > 0 ? 
                        (<>
                            {resumes}
                            <Grid item xs={12} sm={6} md={3}>
                                <div className={styles.AddNewCard}>
                                    <div className={styles.Content}>
                                        <Link to="/choose-template" className={styles.Button}>+ New Resume</Link>
                                        <div>or <a href="https://www.resumeok.com/resume-samples/" target="_blank" rel="noopener noreferrer" className={styles.Link}>see our resume examples</a></div>                
                                    </div>
                                </div>
                            </Grid>
                        </>) : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <img src={dashboardResumeImage} alt="dashboardImage" className={styles.DashboardImage} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h3>Oh...looks like you haven’t created any resume</h3>
                                    <p>Empower your job search in just a few clicks!</p>
                                    <Link to="/choose-template" className={styles.Button}>Create your resume</Link>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {/* ) : ''} */}
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={3} className={`${styles.DashboardContent} ${hover ? styles.DashboardContentHover : null} `} alignItems="center">
                        {coverLetters && coverLetters.length > 0 ? coverLetters : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <img src={dashboardCoverLetterImage} alt="dashboardImage" className={styles.DashboardImage} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h3>Oh...looks like you haven’t created any cover letter</h3>
                                    <p>Empower your job search in just a few clicks!</p>
                                    <Link to="/choose-cover-template" className={styles.Button}>Create a cover letter</Link>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </TabPanel>
                
                <h4>Helpful resources to boost your resume</h4>
                <Grid container spacing={3} className={styles.DashboardCardWrap}>
                    <Grid item xs={12} sm={6} md={3}>
                        <a href="https://www.resumeok.com/how-to-write-a-resume/" target="_blank" rel="noopener noreferrer">
                            <Paper className={styles.DashboardCard}>
                                <img src={goodResumeIcon} alt="goodResumeIcon" className={styles.CardIcon} />
                                <h5>How to write a good resume</h5>
                                <p>earn what's recommended to include in a resume</p>
                            </Paper>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <a href="https://www.resumeok.com/resume-samples/" target="_blank" rel="noopener noreferrer">
                            <Paper className={styles.DashboardCard}>
                                <img src={resumeSamplesIcon} alt="resumeSamplesIcon" className={styles.CardIcon} />
                                <h5>Resume Samples</h5>
                                <p>Explore 500+ resume samples</p>
                            </Paper>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <a href="https://www.resumeok.com/resume-objectives-summary/" target="_blank" rel="noopener noreferrer">
                            <Paper className={styles.DashboardCard}>
                                <img src={objectiveSamplesIcon} alt="objectiveSamplesIcon" className={styles.CardIcon} />
                                <h5>Objective Samples</h5>
                                <p>Explore hundreds of objective and career samples</p>
                            </Paper>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <a href="https://www.resumeok.com/resume-skills/" target="_blank" rel="noopener noreferrer">
                            <Paper className={styles.DashboardCard}>
                                <img src={tipsTricksIcon} alt="tipsTricksIcon" className={styles.CardIcon} />
                                <h5>Resume Skills Examples</h5>
                                <p>Explore hundreds of skills that you can put on a resume</p>
                            </Paper>
                        </a>
                    </Grid>
                </Grid>

                <Dialog
                    open={deleteDialog.open}
                    closed={handleCloseDeleteDialog}
                >

                    <h2>Delete {tabValue === 0 ? 'Resume' : 'Cover Letter'}</h2>
                    <p>Are you sure you want to delete this {tabValue === 0 ? 'resume' : 'cover letter'}?</p>   
                        <Button
                            variant="contained"
                            color="primary"
                            clicked={() => tabValue === 0 ? deleteResumeHandle(deleteDialog.deletedId) : deleteCoverLetterHandle(deleteDialog.deletedId)}
                            className={styles.Button}
                        >
                            Delete
                        </Button>
                        <a onClick={handleCloseDeleteDialog} className={styles.Link}>Cancel</a>
                </Dialog>

                <Dialog
                    open={shareDialogOpen}
                    closed={handleCloseShareDialog}
                    maxWidth="sm"
                    className={styles.ShareDialog}
                >
                    <h2>Share a Link to Your {tabValue === 0 ? 'Resume' : 'Cover Letter'}</h2>
                    <p>Share this link on social media or copy and paste the URL to send your {tabValue === 0 ? 'resume' : 'cover letter'} via text, email or to share your {tabValue === 0 ? 'resume' : 'cover letter'} on your personal website.</p>   
                    
                    <input
                        readOnly={true}
                        value={`${document.location.origin}/${tabValue === 0 ? 'r' : 'cl'}/${sharedLink}`}
                        ref={sharedLinkRef}
                        className={styles.LinkInput}
                    />   
                    <Button
                        variant="contained"
                        color="primary"
                        clicked={copyToClipboard}
                        className={styles.Button}
                    >
                        Copy
                    </Button>
                </Dialog>
            </Container>
        </>
    );
}

const mapStateToProps = state => {
    return {
        resumes: state.resume.resumes,
        coverLetters: state.coverLetter.coverLetters,
        loading: state.resume.loading,
        token: state.auth.token,
        userId: state.auth.userId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchResumes: (token, userId) => dispatch( actions.fetchResumes(token, userId) ),
        onDeleteResume: (token, resumeId) => dispatch( actions.deleteResume(token, resumeId) ),
        onFetchCoverLetters: (token, userId) => dispatch( actions.fetchCoverLetters(token, userId) ),
        onDeleteCoverLetter: (token, coverLetterId) => dispatch( actions.deleteCoverLetter(token, coverLetterId) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(Dashboard);