import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    resumes: [],
    loading: false,
    created: false,
    createdResumeId: '',
    resumeConfiguration: {},
    resumeConfigurationId: null,
};

const authInit = ( state, action ) => {
    return updateObject( state, { createdResumeId: '' } );
};

const createResumeInit = ( state, action ) => {
    return updateObject( state, { created: false } );
};

const createResumeStart = ( state, action ) => {
    return updateObject( state, { loading: true, created: false, createdResumeId: '' } );
};

const createResumeSuccess = ( state, action ) => {
    /* const newResume = updateObject( action.resumeData, { id: action.resumeId } ); */
    return updateObject( state, {
        loading: false,
        created: true,
        resumes: state.resumes.concat( action.resumeData ),
        createdResumeId: action.resumeData.id
    } );
};

const deleteResumeSuccess = ( state, action ) => {
    const updatedResumes = state.resumes.filter((resumeItem) => resumeItem.id !== action.resumeId);
/*     console.log(updatedResume); */
    return updateObject( state, {
        loading: false,
        created: true,
        resumes: updatedResumes
    } );
};


const editResumeSuccess = ( state, action ) => {
    const updatedResume = updateObject( action.resumeData );
    const updatedResumes = state.resumes.map((resumeItem) => {
        if (resumeItem.id === action.resumeId) {
            resumeItem = updatedResume
        }
        return resumeItem;
    });
/*     console.log(updatedResume); */
    return updateObject( state, {
        loading: false,
        created: true,
        resumes: updatedResumes
    } );
};

const createResumeFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const fetchResumesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchResumesSuccess = ( state, action ) => {
    return updateObject( state, {
        resumes: action.resumes,
        loading: false
    } );
};

const fetchResumesFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const createPdfSuccess = ( state, action ) => {
    return updateObject( state, { pdfUrl: action.pdfUrl } );
};

const getResumeConfiguration = (state, action) => {
    return updateObject( state, { 
        resumeConfiguration: action.resumeConfiguration,
        resumeConfigurationId: action.resumeConfiguration.id,
    } );
};

const resumeConfigurationSuccess = (state, action) => {
    return updateObject( state, { 
        resumeConfiguration: action.resumeConfiguration,
    } );
};

const createResumeConfiguration = (state, action) => {
    return updateObject( state, { 
        resumeConfigurationId: action.resumeConfigurationId,
        resumeConfiguration: action.resumeConfiguration,
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_INIT: return authInit(state, action);
        
        case actionTypes.CREATE_RESUME_INIT: return createResumeInit( state, action );
        case actionTypes.CREATE_RESUME_START: return createResumeStart( state, action );
        case actionTypes.CREATE_RESUME_SUCCESS: return createResumeSuccess( state, action );
        case actionTypes.CREATE_RESUME_FAIL: return createResumeFail( state, action );

        case actionTypes.DELETE_RESUME_SUCCESS: return deleteResumeSuccess( state, action );
        case actionTypes.EDIT_RESUME_SUCCESS: return editResumeSuccess( state, action );
        
        case actionTypes.FETCH_RESUMES_START: return fetchResumesStart( state, action );
        case actionTypes.FETCH_RESUMES_SUCCESS: return fetchResumesSuccess( state, action );
        case actionTypes.FETCH_RESUMES_FAIL: return fetchResumesFail( state, action );

        case actionTypes.CREATE_PDF_SUCCESS: return createPdfSuccess( state, action );
        
        case actionTypes.CREATE_RESUME_СONFIGURATION: return createResumeConfiguration( state, action );
        //case actionTypes.EDIT_RESUME_СONFIGURATION: return editResumeConfiguration( state, action );
        case actionTypes.GET_RESUME_СONFIGURATION: return getResumeConfiguration( state, action );
        case actionTypes.RESUME_СONFIGURATION_SUCCESS: return resumeConfigurationSuccess( state, action );

        default: return state;
    }
};

export default reducer;