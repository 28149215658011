import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

const skillsPercentages = {
    'novice': 20,
    'beginner': 40,
    'skillful': 60,
    'experienced': 80,
    'expert': 100
}

const languagesPercentages = {
    'Native Speaker': 100, 
    'Highly proficient': 80, 
    'Very good command': 60, 
    'Good working knowledge': 40,
    'Working knowledge': 20, 
    'C2': 100, 
    'C1': 100, 
    'B2': 80, 
    'B1': 60, 
    'A2': 40, 
    'A1': 20
}


//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });

// Create Document Component
const WoodlawnPdf = (props) => {
    const mainColor  = props.resumeConfiguration.primary_color || '#000';
    const additionalColor = props.resumeConfiguration.secondary_color || '#000';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    const fontSize = props.resumeConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 12.3 * multiplier;
    const headerTitleSize = 24 * multiplier;
    const headerSubtitleSize = 14 * multiplier;
    const sideTitleSize = 15 * multiplier;
    const contactLinkSize = 11 * multiplier;
    const sectionTitleSize = 15 * multiplier;
    const repeatedTitleSize = 14 * multiplier;
    const repeatedSubtitleSize = 13 * multiplier;

    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingHorizontal: 20,
            paddingVertical: 20,
        },
        table: {
            fontSize: mainSize,
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'stretch',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: '1 1 100%',
            alignContent: 'stretch',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
        },
        header: {
            textAlign: 'center',
            marginBottom: 20,
            lineHeight: 1.3,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
        },
        headerRow: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
            marginHorizontal: 'auto',
            marginBottom: 5,
        },
        userImage: {
            width: '25mm',
            height: '25mm',
            objectFit: 'cover',
            borderRadius: '12.5mm',
            flexGrow: 0,
            marginRight: 10,
        },
        title: {
            fontSize: headerTitleSize,
            fontWeight: 700,
            color: mainColor,
        },
        subtitle: {
            fontSize: headerSubtitleSize,
            color: mainColor,
        },
        careerSummary: {
            textAlign: 'left',
        },
        mainContentCell: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: "stretch",
            maxWidth: '70%',
            width: '70%',
            lineHeight: 1.3,
        },
        detailsContentCell: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: "stretch",
            maxWidth: '25%',
            width: '25%',
            height: '100%',
            paddingLeft: 15,
            lineHeight: 1.5,
            borderLeftColor: '#e4e4e4',
            borderLeftWidth: 1,
        },
        sideItem: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "stretch",
            marginBottom: 10,
        },
        sideRepeatedItem: {
            paddingBbottom: 7,
            lineHeight: 1.7,
        },
        sideTitle: {
            display: 'block',
            fontSize: sideTitleSize,
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 1,
            marginBottom: 10,
            paddingBottom: 3,
            borderBottomColor: '#e4e4e4',
            borderBottomWidth: 1.5,
            color: mainColor,
        },
        sideSubtitle: {
            marginTop: 5,
            color: '#8190AA',
        }, 
        progressLine: {
            width: '100%',
            height: 10,
            marginBottom: 5,
        },
        contactLine: {
            marginBottom: 7,
        },
        contactItem: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: 5,
            lineHeight: 1,
            maxWidth: '100%',
        },
        contactLink: {
            color: additionalColor,
            textDecoration: 'none',
            fontSize: contactLinkSize,
        },
        sectionWrap: {
            marginBottom: 5,
        },
        sectionTitleWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: 10,
            paddingBottom: 5,
            borderBottomColor: '#e4e4e4',
            borderBottomWidth: 1.5,
        },
        sectionTitle: {
            display: 'block',
            fontSize: sectionTitleSize,
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 1,
            color: mainColor,
        },
        repeatedItem: {
            paddingBottom: 10,
            marginBottom: 10,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#e4e4e4',
        },
        repeatedItemLast: {
            borderBottomWidth: 0,
            paddingBottom: 0,
        },
        repeatedItemTitle: {
            fontWeight: 700,
            marginBottom: 5,
            fontSize: repeatedTitleSize,
        },
        repeatedItemSubtitle: {
            color: '#8190AA',
            fontSize: repeatedSubtitleSize,
        },
        description: {
            marginTop: 3,
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 15,
            height: 15,
            marginRight: 6,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
        },
        contentLink: {
            color: '#8190AA',
            textDecoration: 'none',
        },
        list: {
            lineHeight: 1.4,
            textAlign: 'left',
        }
    });

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'bullet'));
        }
    }

    const showUrl = (url) => {
        let croppedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        return wordWrap(croppedUrl, 18);
    }

    const wordWrap = (str, maxWidth) => {
        let newLineStr = "\n";
        let res = '';
        while (str && str.length > maxWidth) {                 
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join('');
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join('');
                str = str.slice(maxWidth);
            }

        }

        return res + str;
    }

    const testWhite = (x) => {
        let white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    };

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.contactLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.contactLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.contactLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.contactLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.contactLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.contactLink}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }

    return (
    <Document>
        <Page style={styles.body}>
            {addPdfPattern(props.resumeConfiguration.pattern)}
            <View style={styles.table}>
                <View style={styles.header}>
                    <View style={[styles.row, styles.headerRow]}>
                        {props.resume.userImage ? (
                            <Image 
                                style={styles.userImage} 
                                src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            />
                        ) : <></>}
                    
                        <View style={styles.headerText}>
                            <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                            <Text style={styles.subtitle}>{props.resume.jobPosition}</Text>
                        </View>
                    </View>
                    {props.resume.careerSummary ? (<View style={styles.careerSummary}>{generateTinyMceElements(props.resume.careerSummary)}</View>) : <></>}
                </View>
                <View style={styles.row}>
                    <View style={styles.mainContentCell}>                    
                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                            <View style={styles.sectionWrap}>                        
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    />                             
                                    {props.resume.experienceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Work Experience</Text>)}
                                </View>                            
                                {props.resume.workExperienceItems.map((workItem, index) => (
                                    <View style={[styles.repeatedItem, index === (props.resume.workExperienceItems.length - 1) ? styles.repeatedItemLast : null]} key={workItem.id}>
                                        {(workItem.jobTitle || workItem.company) ? (<Text style={styles.repeatedItemTitle}>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ', ' : ''} {workItem.company}</Text>) : (<></>)}
                                        {(workItem.startDate || workItem.endDate || workItem.presentDate || workItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{workItem.city}{((workItem.startDate || workItem.endDate || workItem.presentDate) && workItem.city ) ? ' • ' : ''}{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMMM YYYY")}</Text>) : (<></>)}
                                        {workItem.description ? (<View style={styles.description}>{generateTinyMceElements(workItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    />   
                                    {props.resume.educationSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.educationSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Education</Text>)}          
                                </View>
                                {props.resume.educationFields.map((educationItem, index) => (
                                    <View style={[styles.repeatedItem, index === (props.resume.educationFields.length - 1) ? styles.repeatedItemLast : null]} key={educationItem.id}>
                                        {(educationItem.degree || educationItem.institution) ? (<Text style={styles.repeatedItemTitle}>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ', ' : ''}{educationItem.institution}</Text>) : (<></>)}
                                        {(educationItem.startDate || educationItem.endDate || educationItem.presentDate || educationItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{educationItem.city}{((educationItem.startDate || educationItem.endDate || educationItem.presentDate) && educationItem.city ) ? ' • ' : ''}{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMMM YYYY")}</Text>) : (<></>)}
                                        {educationItem.description ? (<View style={styles.description}>{generateTinyMceElements(educationItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    />
                                    {props.resume.internshipSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Internship</Text>)}
                                </View>
                                {props.resume.internshipFields.map((internshipItem, index) => (
                                    <View style={[styles.repeatedItem, index === (props.resume.internshipFields.length - 1) ? styles.repeatedItemLast : null]} key={internshipItem.id}>
                                        {(internshipItem.jobTitle || internshipItem.employer) ? (<Text style={styles.repeatedItemTitle}>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ', ' : ''}{internshipItem.employer}</Text>) : (<></>)}
                                        {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate || internshipItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{internshipItem.city}{((internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) && internshipItem.city ) ? ' • ' : ''}{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMMM YYYY")}</Text>) : (<></>)}
                                        {internshipItem.description ? (<View style={styles.description}>{generateTinyMceElements(internshipItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    /> 
                                    {props.resume.courseSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.courseSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Courses</Text>)}
                                </View>
                                {props.resume.coursesFields.map((courseItem, index) => (
                                    <View style={[styles.repeatedItem, index === (props.resume.coursesFields.length - 1) ? styles.repeatedItemLast : null]} key={courseItem.id}>
                                        {(courseItem.course || courseItem.institution) ? (<Text style={styles.repeatedItemTitle}>{courseItem.course}{(courseItem.course && courseItem.institution) ? ', ' : ''}{courseItem.institution}</Text>) : (<></>)}
                                        {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (<Text style={styles.repeatedItemSubtitle}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMMM YYYY")}</Text>) : (<></>)}
                                        {courseItem.description ? (<View style={styles.description}>{generateTinyMceElements(courseItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    /> 
                                    {props.resume.referenceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>References</Text>)}
                                </View>
                                {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                    props.resume.referencesFields.map((referenceItem, index) => (
                                        <View style={[styles.repeatedItem, index === (props.resume.referencesFields.length - 1) ? styles.repeatedItemLast : null]} key={referenceItem.id}>
                                            {(referenceItem.referent || referenceItem.company) ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ', ' : ''}{referenceItem.company}</Text>) : (<></>)}
                                            {(referenceItem.email || referenceItem.phone) ? (<Text style={styles.repeatedItemSubtitle}><Link style={styles.contentLink} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}</Text>) : (<></>)}
                                            {referenceItem.description ? (<View style={styles.description}>{generateTinyMceElements(referenceItem.description)}</View>) : <></> }
                                        </View>
                                    )) : (<Text>References available upon request</Text>)}
                            </View>
                        ) : <></>}

                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.7, .7).fillColor(mainColor).path('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z').fill();
                                        }}
                                        style={styles.svgIcon}
                                    />
                                    {props.resume.customSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text>) : (<></>)}
                                </View>
                                {props.resume.customFields.map((customItem, index) => (
                                    <View style={[styles.repeatedItem, index === (props.resume.customFields.length - 1) ? styles.repeatedItemLast : null]} key={customItem.id}>
                                        {customItem.customItemTitle ? (<Text style={styles.repeatedItemTitle}>{customItem.customItemTitle}</Text>) : (<></>)}
                                        {(customItem.startDate || customItem.endDate || customItem.presentDate || customItem.city) ? (<Text style={styles.repeatedItemSubtitle}>{customItem.city}{((customItem.startDate || customItem.endDate || customItem.presentDate) && customItem.city) ? ' • ' : ''}{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMMM YYYY")}</Text>) : (<></>)}
                                        {customItem.description ? (<View style={styles.description}>{generateTinyMceElements(customItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}
                        
                    </View>

                    <View style={styles.detailsContentCell} /* fixed */>
                        <View style={styles.sideItem}>
                            <Text>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                            {props.resume.phoneNumber ? (<Text>{props.resume.phoneNumber}</Text>) : <></>}
                            <Link style={styles.contactLink} src={`mailto:${props.resume.email}`}>{wordWrap(props.resume.email, 20)}</Link>

                            {(props.resume.birthPlace || props.resume.birthDate) ? (
                                <>
                                    <Text style={styles.sideSubtitle}>{props.resume.birthDate ? 'Date' : ''}{props.resume.birthDate && props.resume.birthPlace ? ' / ' : ''}{props.resume.birthPlace ? 'Place' : ''} of birth</Text>
                                    {props.resume.birthDate ? (<Text>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                                    {props.resume.birthPlace ? (<Text>{props.resume.birthPlace}</Text>) : <></>}
                                </>
                            ) : (<></>)}

                            {props.resume.nationality ? (
                                <>
                                    <Text style={styles.sideSubtitle}>Nationality</Text>
                                    <Text>{props.resume.nationality}</Text>
                                </>
                            ) : (<></>)}
                            
                            {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (
                                <>
                                    <Text style={styles.sideSubtitle}>Driving license</Text>
                                    <Text>{props.resume.drivingLicenseType.join(', ')}</Text>
                                </>
                            ) : (<></>)}
                        </View>

                        {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                            <View style={styles.sideItem}>
                                {props.resume.websiteSocialFields.map(socialItem => (
                                    (socialItem.link || socialItem.name) ? (
                                        <Text key={socialItem.id}><Link style={styles.contactLink} src={socialItem.link}>{socialItem.name}</Link></Text>
                                    ) : (<></>)
                                ))}
                                {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                            </View>
                        ) : <></>}
                        
                        {props.resume.skillsFields && props.resume.skillsFields.length ? (
                            <View style={styles.sideItem}>
                                {props.resume.skillSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.skillSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Skills</Text>)}
                                {props.resume.skillsFields.map(skillItem => 
                                    (<View key={skillItem.id} wrap={false}>
                                        {skillItem.skill ? (<Text>{skillItem.skill}</Text>) : (<></>)}
                                        {(skillItem.skill && skillItem.level && Boolean(Number(props.resume.skillsVisibility))) ? (
                                            <Canvas
                                                paint={(painter, availableWidth) => {
                                                    let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(2).strokeColor(additionalColor).stroke();
                                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(additionalColor).fill();
                                                }}
                                                style={styles.progressLine}
                                            />
                                        ) : (<></>)}
                                    </View>)
                                )}
                            </View>
                        ) : <></>}

                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sideItem}>
                                {props.resume.languageSectionTitle ? (<Text style={styles.sideTitle}>{props.resume.languageSectionTitle}</Text>) : (<Text style={styles.sideTitle}>Languages</Text>)}
                                {props.resume.languagesFields.map(languageItem => 
                                    (<View key={languageItem.id} wrap={false}>
                                        {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                        {languageItem.language && languageItem.level ? (<Canvas
                                                paint={(painter, availableWidth) => {
                                                    let percentageValue = languagesPercentages[languageItem.level] * availableWidth / 100;
                                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(2).strokeColor(additionalColor).stroke();
                                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(additionalColor).fill();
                                                }}
                                                style={styles.progressLine}
                                            />) : (<></>)
                                        }
                                    </View>)
                                )}
                            </View>
                        ) : <></>}

                    </View>
                    
                </View>
            </View>
        </Page>
    </Document>
    );
}

export default WoodlawnPdf;