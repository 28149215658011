import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

const skillsPercentages = {
    'novice': 20,
    'beginner': 40,
    'skillful': 60,
    'experienced': 80,
    'expert': 100
}

//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });

// Create Document Component
const ClassicPdf = (props) => {

    const mainColor = props.resumeConfiguration.primary_color || '#283755';
    const additionalColor = props.resumeConfiguration.secondary_color || '#d4dbe3';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 27 * multiplier;
    const headerSubtitleSize = 20 * multiplier;
    const sectionTitleSize = 17 * multiplier;
    const repeatedTitleSize = 14 * multiplier;

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
            position: 'relative',
        },
        table: {
            fontSize: mainSize,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            /* marginBottom: 50, */
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        topBackground: {
            top: -20,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        withImage: {
            width: '157mm',
            maxWidth: '157mm',
            flexGrow: 0,
            flexBasis: '157mm',
        },
        headerBefore: {
            position: 'absolute',
            top: 0,
            height: 21,
            width: '100%',
            backgroundColor: mainColor,
        },
        header: {
            position: 'relative',
            justifyContent: "flex-start",
            paddingTop: 0,
            paddingBottom: 20,
            paddingHorizontal: 20,
            backgroundColor: mainColor,
            color: additionalColor,
        },
        userImage: {
            width: '33mm',
            height: '33mm',
            objectFit: 'cover',
            borderRadius: '17mm',
            flexGrow: 0,
            marginLeft: 15,
        },
        titleWrap: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            maxWidth: '170mm',
            lineHeight: 1.5,
            marginBottom: 10,
        },
        title: {
            flexGrow: 1,
            fontSize: headerTitleSize,
            fontWeight: 700,
            lineHeight: 1.2,
        },
        subtitle: {
            fontSize: headerSubtitleSize,
            fontStyle: 'italic',
            marginBottom: 5,
            lineHeight: 1.2,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
        },
        contactItem: {
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            marginVertical: 2,
        },
        contactLink: {
            color: additionalColor,
            textDecoration: 'none',
            alignSelf: 'center',
        },
        contactTitle: {
            fontWeight: 700,
            marginRight: 6,
        },
        main: {
            paddingTop: 20,
            paddingHorizontal: 20,
        },
        sectionWrap: {
            marginBottom: 25,
        },
        sectionTitleWrap: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: 7,
        },
        sectionTitle: {
            fontSize: sectionTitleSize,
            lineHeight: 1,
            fontWeight: 700,
            marginBottom: 0,
            color: mainColor,
        },
        sectionIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 25,
            height: 25,
            marginRight: 5,
        },
        repeatedItem: {
            marginBottom: 15,
        },
        repeatedItemTitle: {
            fontSize: repeatedTitleSize,
            lineHeight: 1.2,
            fontWeight: 700,
            flex: '1 0 70%',
            width: '70%',
        },
        repeatedItemSubtitle: {
            fontWeight: 400,
        },
        repeatedItemContent: {
            flex: '0 0 73%',
            width: '73%',
        },
        repeatedTitleWrap: {
            display: 'flex',
            flexDirection: "row",
            justifyContent: 'space-between',
        },
        dateTextWithTitle: {
            width: '30%',
            minWidth: '30%',
            flex: '0 0 30%',
            textAlign: 'right',
        },
        dateText: {
            color: '#8190AA',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
        skillsRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        languagesRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        languagesItem: {
            width: '48%',
            position: 'relative',
            paddingLeft: 10,
        },
        skillsItem: {
            width: '48%',
            position: 'relative',
            paddingLeft: 10,
        },
        languageLevel: {
            fontStyle: 'italic',
            color: mainColor,
        },
        progressLine: {
            width: '100%',
            height: 10,
            marginTop: 5,
        },
        bullet: {
            width: 8,
            height: 8,
            marginTop: 5,
            marginRight: 7,
            position: 'absolute',
            top: 0,
            left: 0,
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 24,
            height: 24,
            marginRight: 6,
        },
        description: {
            marginTop: 3,
            maxWidth: '98%',
        },
        list: {
            paddingLeft: 7,
        }
    });

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'bullet'));
        }
    }

    const showUrl = (url) => {
        let croppedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        return wordWrap(croppedUrl, 18);
    }

    const wordWrap = (str, maxWidth) => {
        let newLineStr = "\n";
        let res = '';
        while (str && str.length > maxWidth) {                 
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join('');
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join('');
                str = str.slice(maxWidth);
            }

        }

        return res + str;
    }

    const testWhite = (x) => {
        let white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    };

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.contactLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.contactLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.contactLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.contactLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.contactLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(additionalColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.contactLink}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <View style={styles.sectionWrap}>
            <View style={styles.sectionTitleWrap}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M9 17l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4M15.47 20.5L12 17l1.4-1.41 2.07 2.08 5.13-5.17 1.4 1.41z').fill();
                    }}
                    style={styles.sectionIcon}
                /> 
                {props.resume.skillSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.skillSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Skills</Text>)}
            </View>
            <View style={styles.skillsRow}>
                {props.resume.skillsFields.map((skillItem, index) => 
                    (skillItem.skill || skillItem.level) ? (<View key={skillItem.id} wrap={false} style={[styles.repeatedItem, styles.skillsItem]}>
                        <Canvas paint={(painter) => {
                            painter.circle(4,4,2).fillColor(props.color).fill();
                        }} style={styles.bullet} />
                        {skillItem.skill ? (<Text style={styles.skillTitle}>{skillItem.skill}</Text>) : (<></>)}
                        {(skillItem.skill && skillItem.level && Boolean(Number(props.resume.skillsVisibility))) ? (
                            <Canvas
                                paint={(painter, availableWidth) => {
                                    let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(1.5).strokeColor(mainColor).stroke();
                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(mainColor).fill();
                                }}
                                style={styles.progressLine}
                            />
                        ) : (<></>)}
                    </View>) : (<></>)
                )}
            </View>
        </View>
    ) : <></>;

    return (
        <Document>
            <Page style={styles.body}>
            <View style={styles.headerBefore}></View>
                <View style={styles.table}>
                    <View style={[styles.row, styles.header]}>
                        {addPdfPattern(props.resumeConfiguration.pattern, styles.topBackground)}
                        <View style={[styles.headerText, props.resume.userImage ? styles.withImage : null]}>
    {/*                         <View style={styles.titleWrap}><Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text><Text style={styles.subtitle}>{props.resume.jobPosition}</Text></View> */}

                            <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                            {props.resume.jobPosition ? (<Text style={styles.subtitle}>{props.resume.jobPosition}</Text>) : (<></>)}
                            
                            {props.resume.email ? (<View style={styles.contactItem}>
                                <Canvas paint={(painter) => {
                                    painter.scale(.9, .9).fillColor(additionalColor).path('M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z').fill();
                                }} style={styles.svgIcon} />
                                <Link src={`mailto:${props.resume.email}`} style={styles.contactLink}>{props.resume.email}</Link>
                            </View>) : <></>}
                            {props.resume.phoneNumber ? (<View style={styles.contactItem}>
                                <Canvas paint={(painter) => {
                                        painter.scale(.9, .9).fillColor(additionalColor).path('M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z').fill();
                                    }} style={styles.svgIcon} />
                                <Text style={styles.contactValue}>{props.resume.phoneNumber}</Text>
                            </View>) : <></>}
                            {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) ? (<View style={styles.contactItem}>
                                <Canvas paint={(painter) => {
                                        painter.fillColor(additionalColor).path('M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z').fill();
                                    }} style={styles.svgIcon} />
                                <Text style={styles.contactValue}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                            </View>) : <></>}
                            {props.resume.nationality ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Nationality:</Text> {props.resume.nationality}</Text>) : <></>}
                            {props.resume.birthPlace ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Place:</Text> {props.resume.birthPlace}</Text>) : <></>}
                            {props.resume.birthDate ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Date:</Text> {moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                            {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Driving license:</Text> {props.resume.drivingLicenseType.join(', ')}</Text>) : <></>}

                            {props.resume.websiteSocialFields.map((socialItem, index) => (
                                (socialItem.link || socialItem.name) ? (
                                    <View key={index} style={styles.contactItem}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.9, .9).fillColor(additionalColor).path('M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        <Link href={socialItem.link} style={styles.contactLink}>{socialItem.name ? socialItem.name : socialItem.link}</Link>
                                    </View>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                        </View>
                        
                        {props.resume.userImage ? (
                            <Image 
                                style={styles.userImage} 
                                src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            />
                        ) : <></>}
                    </View>
                    
                    <View style={styles.main}>
                        
                    {props.resume.careerSummary ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    /> 
                                    <Text style={styles.sectionTitle}>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</Text> 
                                </View>
                                {props.resume.careerSummary ? (<View style={styles.description}>{generateTinyMceElements(props.resume.careerSummary)}</View>) : <></>}
                            </View>
                        ) : <></>}

                        {resumeType === 'functional' ? skillsSection : <></>}
                        
                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                            <View style={styles.sectionWrap}>                        
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    />                             
                                    {props.resume.experienceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Work Experience</Text>)}
                                </View>                            
                                {props.resume.workExperienceItems.map(workItem => (
                                    <View style={styles.repeatedItem} key={workItem.id}>
                                        <View style={styles.repeatedTitleWrap}>
                                            {(workItem.jobTitle || workItem.company || workItem.city) ? (
                                                <Text style={styles.repeatedItemTitle}>
                                                    {workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ', ' : ''}
                                                    {(workItem.company || workItem.city) ? (
                                                        <Text style={styles.repeatedItemSubtitle}>
                                                            {workItem.company}
                                                            {((workItem.jobTitle || workItem.company) && workItem.city) ? ', ' : ''}
                                                            {workItem.city}
                                                        </Text>
                                                    ) : (<></>)}
                                                </Text>
                                            ) : <></>}
                                            {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                                <Text style={[styles.dateText, (workItem.jobTitle || workItem.company || workItem.city) ? styles.dateTextWithTitle : '']}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "YYYY-MM")}</Text>
                                            ) : <></>}
                                        </View>         
                                        {workItem.description ? (<View style={styles.description}>{generateTinyMceElements(workItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    />   
                                    {props.resume.educationSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.educationSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Education</Text>)}          
                                </View>
                                {props.resume.educationFields.map(educationItem => (
                                    <View style={styles.repeatedItem} key={educationItem.id}>
                                        <View style={styles.repeatedTitleWrap}>
                                            {(educationItem.degree || educationItem.institution || educationItem.city) ? (
                                                <Text style={styles.repeatedItemTitle}>
                                                    {educationItem.degree}{(educationItem.degree && educationItem.institution) ? ', ' : ''}
                                                    {(educationItem.institution || educationItem.city) ? (
                                                        <Text style={styles.repeatedItemSubtitle}>
                                                            {educationItem.institution}
                                                            {((educationItem.degree || educationItem.institution) && educationItem.city) ? ', ' : ''}
                                                            {educationItem.city}
                                                        </Text>
                                                    ) : (<></>)}
                                                </Text>
                                            ) : <></>}
                                            {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                                <Text style={[styles.dateText, (educationItem.degree || educationItem.institution || educationItem.city) ? styles.dateTextWithTitle : '']}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "YYYY-MM")}</Text>
                                            ) : <></>}
                                        </View>
                                        {educationItem.description ? (<View style={styles.description}>{generateTinyMceElements(educationItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    />
                                    {props.resume.internshipSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Internship</Text>)}
                                </View>
                                {props.resume.internshipFields.map(internshipItem => (
                                    <View style={styles.repeatedItem} key={internshipItem.id}>
                                        <View style={styles.repeatedTitleWrap}>
                                            {(internshipItem.jobTitle || internshipItem.employer || internshipItem.city) ? (
                                                <Text style={styles.repeatedItemTitle}>
                                                    {internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ', ' : ''}
                                                    {(internshipItem.employer || internshipItem.city) ? (
                                                        <Text style={styles.repeatedItemSubtitle}>
                                                            {internshipItem.employer}
                                                            {((internshipItem.jobTitle || internshipItem.employer) && internshipItem.city) ? ', ' : ''}
                                                            {internshipItem.city}
                                                        </Text>
                                                    ) : (<></>)}
                                                </Text>
                                            ) : <></>}
                                            {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                                <Text style={[styles.dateText, (internshipItem.jobTitle || internshipItem.employer || internshipItem.city) ? styles.dateTextWithTitle : '']}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "YYYY-MM")}</Text>
                                            ) : <></>}
                                        </View>
                                        {internshipItem.description ? (<View style={styles.description}>{generateTinyMceElements(internshipItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M4 10h3v7H4zM10.5 10h3v7h-3zM2 19h20v3H2zM17 10h3v7h-3zM12 1L2 6v2h20V6z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    /> 
                                    {props.resume.courseSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.courseSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Courses</Text>)}
                                </View>
                                {props.resume.coursesFields.map(courseItem => (
                                    <View style={styles.repeatedItem} key={courseItem.id}>
                                        <View style={styles.repeatedTitleWrap}>
                                            {(courseItem.course || courseItem.institution) ? (
                                                <Text style={styles.repeatedItemTitle}>
                                                    {courseItem.course}{(courseItem.course && courseItem.institution) ? ', ' : ''}
                                                    {courseItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{courseItem.institution}</Text>) : (<></>)}
                                                </Text>
                                            ) : <></>}
                                            {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                                <Text style={[styles.dateText, (courseItem.course || courseItem.institution) ? styles.dateTextWithTitle : '']}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "YYYY-MM")}</Text>
                                            ) : <></>}
                                        </View>
                                        {courseItem.description ? (<View style={styles.description}>{generateTinyMceElements(courseItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    /> 
                                    {props.resume.referenceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>References</Text>)}
                                </View>
                                {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                    props.resume.referencesFields.map(referenceItem => (
                                        <View style={styles.repeatedItem} key={referenceItem.id}>
                                            {(referenceItem.referent || referenceItem.company) ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ' from ' : ''}{referenceItem.company}</Text>) : (<></>)}
                                            {(referenceItem.email || referenceItem.phone) ? (<Text><Link style={styles.link} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}</Text>) : (<></>)}
                                            {referenceItem.description ? (<View style={styles.description}>{generateTinyMceElements(referenceItem.description)}</View>) : <></> }
                                        </View>
                                    )) : (<Text>References available upon request</Text>)}
                            </View>
                        ) : <></>}

                        {resumeType === 'chronological' ? skillsSection : <></>}

                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    />
                                    {props.resume.languageSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.languageSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Languages</Text>)}
                                </View>
                                <View style={[styles.flexRow, styles.languagesRow]}>
                                    {props.resume.languagesFields.map((languageItem, index) => 
                                        (languageItem.language || languageItem.languageLevel) ? (<View key={index} style={[styles.repeatedItem, styles.languagesItem]} wrap={false}>
                                            <Canvas paint={(painter) => {
                                                painter.circle(4,4,2).fillColor(props.color).fill();
                                            }} style={styles.bullet} />
                                            {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                            {languageItem.level ? (<Text style={styles.languageLevel}>{languageItem.level}</Text>) : (<></>)}
                                        </View>) : (<></>)
                                    )}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                <View style={styles.sectionTitleWrap}>
                                    <Canvas
                                        paint={(painter) => {
                                            painter.scale(.9, .9).fillColor(mainColor).path('M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z').fill();
                                        }}
                                        style={styles.sectionIcon}
                                    />
                                    {props.resume.customSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text>) : (<></>)}
                                </View>
                                {props.resume.customFields.map(customItem => (
                                    <View style={styles.repeatedItem} key={customItem.id}>
                                        <View style={styles.repeatedTitleWrap}>
                                            {(customItem.customItemTitle || customItem.city) ? (
                                                <Text style={styles.repeatedItemTitle}>
                                                    {customItem.customItemTitle}{(customItem.customItemTitle && customItem.city) ? ', ' : ''}
                                                    {customItem.city ? (<Text style={styles.repeatedItemSubtitle}>{customItem.city}</Text>) : (<></>)}
                                                </Text>
                                            ) : <></>}
                                            {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                                <Text style={[styles.dateText, (customItem.customItemTitle || customItem.city) ? styles.dateTextWithTitle : '']}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "YYYY-MM")}</Text>
                                            ) : <></>}
                                        </View>
                                        {customItem.description ? (<View style={styles.description}>{generateTinyMceElements(customItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default ClassicPdf;