import {createStrongTextElement, 
        createEmphasisTextElement, 
        createParagraphTextElement, 
        createSpanTextElement, 
        createLinkElement,
        createListComponent, 
        createTextComponent,
        createListItemComponent,
        createBreakLineComponent
    } from './pdfElements';

export const JsonToPdfComponent = (input, styles, mainColor, dashListIcon, listType, index, ) => {
    /*
        the object was created with following values 
        { 
            nodeType: <integer>, 
            nodeTagName: <string>, 
            nodeName: <string>, 
            nodeValue: <string>, 
            childNodes: <array> 
            attributes: <array of attributes arrays>
        }
    */
    //console.log(input);
    //first we have an error handler which will result in blank elements in the pdf rather than crashes
    if (input === undefined) { console.error("JsonToPdfComponent: Undefined JSON Input"); return null; }
    //create the object, either parsing a JSON string or just using an existing javascript object
    let json = typeof input === 'string' ? JSON.parse(input) : input;
    //define the node type
    let nodeType = json.nodeType;
    //define the tag type
    let tagType = json.tagName;
    //then the construction process is different depending on the type of node
    switch (nodeType) {
        //MOST OF THE WORK DONE ON ELEMENT_NODES
        case 0:
            return json.nodeValue;
        case 1: 
            //then we need to create styled component views for each tag
            switch(tagType) {
                case 'strong':
                    return createStrongTextElement(json, styles);
                case 'b':
                    return createStrongTextElement(json, styles);
                case 'em':
                    return createEmphasisTextElement(json, styles);
                case 'i':
                    return createEmphasisTextElement(json, styles);
                case 'p':
                    return createParagraphTextElement(json, styles);
                case 'span':
                    return createSpanTextElement(json, json.attributes, styles);
                //we add a link tag only when the anchor tag has children, i.e. text
                case 'a':
                    return createLinkElement(json, json.attributes, styles);
                //special processing for ordered and unordered list components
                case 'ol':
                    return createListComponent(json, tagType, styles);
                case 'ul':
                    return createListComponent(json, tagType, styles, mainColor, dashListIcon);
                case 'li':
                    return createListItemComponent(json, listType, index, mainColor, dashListIcon);
                case 'br':
                    return createBreakLineComponent(json);
                default:
                    console.log(`No Processing for Tag ${tagType.toUpperCase()}`);
            }
            break;
        //TEXT_NODE - we can just create the simple text item
        case 3: 
            //this will return a null value if the text filtered for formatting characters has a length of zero
            return createTextComponent(json.nodeValue);
        default: 
            console.log("Skipping Node", json);
    }

};