import React, { useState, useEffect } from 'react';


import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image'

import axios from '../../axios-resumes';
import { connect } from 'react-redux';
import useViewportHandler from '../../shared/useViewportHandler';
import { getPosition } from '../../shared/getPosition';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DesktopToolbar from './DesktopToolbar';
import MobileToolbar from './MobileToolbar';

import PDFTemplate from '../../containers/PDFViewer/PDFTemplate';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import * as actions from '../../store/actions/index';

function BuilderToolbar(props) {
    useEffect(() => {
        props.onInitConfiguration(props.documentId, props.token);
    }, []);

    const [downloadBtnActive, setDownloadBtnActive] = useState(false);
    useEffect(() => {
        if (props.configuration /* && Object.keys(props.configuration).length === 0 */ && props.configuration.constructor === Object) {
            setDownloadBtnActive(true);
        }
    }, [props.configuration, props.document]);

    const sleep = async (ms) => {
        return await new Promise((resolve) => setTimeout(resolve, ms));
    };
    
    const generatePdf = async () => {
        const blob = await pdf(
            <PDFTemplate configuration={props.configuration} document={props.document} isCoverLetter={props.isCoverLetter} />
        ).toBlob();
        saveAs(blob, `${props.document.firstName} ${props.document.lastName} ${!props.isCoverLetter ? 'resume' : 'cover letter'}.pdf`);
    };

    const generateScreenshot = () => {
        let svgElements = document.body.querySelectorAll('#document-viewer svg:not([class^="resume-mockup"])');
        svgElements.forEach(function(item) {
            item.setAttribute("width", 30);
            item.setAttribute("height", 30);
        });

        let svgElementsMockup = document.body.querySelectorAll('#document-viewer svg.resume-mockup');
        svgElementsMockup.forEach(function(item) {
            item.setAttribute("width", item.getBoundingClientRect().width);
            item.setAttribute("height", item.getBoundingClientRect().height);
            item.style.width = null;
            item.style.height= null;
        });

        let pageSvg = document.getElementById('document-viewer');
        if (pageSvg) {
            /* let screenshoot = pageSvg.cloneNode(true);
            screenshoot.setAttribute( 'id', 'document-screenshoot' );
            console.log(screenshoot);
            document.body.appendChild(screenshoot); */

            /* domtoimage.toBlob(pageSvg, {
                quality: 0.99,
                width: pageSvg.clientWidth * 2,
                height: pageSvg.clientHeight * 2,
                style: {
                    position: 'static',
                    top: 0,
                    left: 0,
                    transform: 'scale(2)',
                    transformOrigin: 'top left',
                },
                filter: (node) => { let className = node.tagName === 'svg' ? node.className.baseVal : node.className; return (className !== 'page-line' && className !== 'resume-mockup' && className !== 'page-delimeter')},
            })
            .then(function (blob) {
                window.saveAs(blob, `${props.document.firstName} ${props.document.lastName} ${!props.isCoverLetter ? 'resume' : 'cover letter'}.png`);
            }); */
            /* domtoimage.toPng(pageSvg, {
                    quality: 0.99,
                })
                .then(function(dataUrl) {
                    console.log(dataUrl);
                    let img = new Image();
                    img.src = dataUrl;        
                    saveAs(dataUrl, `${props.document.firstName} ${props.document.lastName} ${!props.isCoverLetter ? 'resume' : 'cover letter'}.png`);

                })
                .catch(function(error) {
                    console.error('oops, something went wrong!', error);
                }); */
            document.querySelectorAll('.resume-mockup').forEach(function(el) {
                el.style.display = 'none';
            });
            const { width, height } = pageSvg.getBoundingClientRect();
            const { x, y } = getPosition(pageSvg);
            const { top, left } = pageSvg.getBoundingClientRect()
            //ignoreElements: (node) => { let className = node.tagName === 'svg' ? node.className.baseVal : node.className; return (/* className == 'page-line' ||  */className == 'resume-mockup' /* || className == 'page-delimeter' */)},

            //window.scrollTo(0,0);     
            /* html2canvas(pageSvg, {
                logging: true, 
                letterRendering: true, 
                backgroundColor: '#ffffff',
                scale: 1,
                useCORS: true,
                scrollY: 0, 
                scrollX: -4,
                //onclone: async (doc) => {
                //    await sleep(500);
                //},
                x: left,
                y: top,
            }).then(function(canvas) {
                document.querySelectorAll('.resume-mockup').forEach(function(el) {
                    el.style.display = 'block';
                });
                let dataImage = canvas.toDataURL("image/png");
                saveAs(dataImage, `${props.document.firstName} ${props.document.lastName} ${!props.isCoverLetter ? 'resume' : 'cover letter'}.png` );
            });*/
            //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

            htmlToImage.toPng(pageSvg, { quality: 0.95, style: {position: 'static', transform: 'none'} })
            .then(function (dataUrl) {
                saveAs(dataUrl, `${props.document.firstName} ${props.document.lastName} ${!props.isCoverLetter ? 'resume' : 'cover letter'}.png`);
            });
        }
    };

    const [typeDropdownClose, setTypeDropdownClose] = useState(false);

    const resumeTypes = {
        functional: 'Functional Resume',
        chronological: 'Chronological Resume',
    };

    const [resumeType, setResumeType] = useState('functional');

    const staticTypeTemplates = [1, 3, 4, 11, 13];

    const handleChooseType = (resumeType) => {
        if (staticTypeTemplates.includes(Number(props.document.templateId))) {
            setMessageOpen(true);
        } else {
            setResumeType(resumeType);
            props.onEditConfiguration({...props.configuration, resume_type: resumeType}, props.token, props.configurationId);
            setTypeDropdownClose(true);
        }
    }

    const [primaryColor, setPrimaryColor] = useState('#24D39F');
    const [secondaryColor, setSecondaryColor] = useState('#219653');

    const handlePrimaryColorChange = (updatedValue) => {
        setPrimaryColor(updatedValue);
        props.onEditConfiguration({...props.configuration, primary_color: updatedValue}, props.token, props.configurationId);
    }

    const handleSecondaryColorChange = (updatedValue) => {
        setSecondaryColor(updatedValue);
        props.onEditConfiguration({...props.configuration, secondary_color: updatedValue}, props.token, props.configurationId);
    }

    const [pattern, setPattern] = useState(null);

    const handlePatternChange = (updatedValue) => {
        setPattern(updatedValue);
        props.onEditConfiguration({...props.configuration, pattern: updatedValue}, props.token, props.configurationId);
    }

    const [fontFamily, setFontFamily] = useState('Open Sans');

    const handleFontFamilyChange = (updatedValue) => {
        setFontFamily(updatedValue);
        props.onEditConfiguration({...props.configuration, font_name: updatedValue}, props.token, props.configurationId);
    }

    const [fontSize, setFontSize] = useState('small');

    const handleFontSizeChange = (updatedValue) => {
        setFontSize(updatedValue);
        props.onEditConfiguration({...props.configuration, font_size: updatedValue}, props.token, props.configurationId);
    }

    const [progressValue, setProgressValue] = useState(0);

    const handleProgressValue = (updatedValue) => {
        setProgressValue(updatedValue);
    }

    useEffect(() => {
        axios.get(`/${!props.isCoverLetter ? 'resume' : 'cover-letters'}/configuration/${props.documentId}`, { headers: {
            'Authorization': `Bearer ${props.token}`
            }
        })
        .then( response =>  {
            if (response.data.configuration.primary_color) {
                setPrimaryColor(response.data.configuration.primary_color);
            }
            if (response.data.configuration.secondary_color) {
                setSecondaryColor(response.data.configuration.secondary_color);
            }
            if (response.data.configuration.pattern) {
                setPattern(response.data.configuration.pattern);
            }
            if (response.data.configuration.font_name) {
                setFontFamily(response.data.configuration.font_name);
            }
            if (response.data.configuration.font_size) {
                setFontSize(response.data.configuration.font_size);
            }
            if (response.data.configuration.resume_type) {
                setResumeType(response.data.configuration.resume_type);
            }
        })
        .catch( err => {
            console.log(err);
        })
    }, [] );

    const [messageOpen, setMessageOpen] = React.useState(false);
    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setMessageOpen(false);
    };
    
    const { width } = useViewportHandler();
    const breakpoint = 1024;
    return (<>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={messageOpen}
                autoHideDuration={5000}
                onClose={handleMessageClose}
            >
                <Alert severity="success">This feature is not available for the selected template</Alert>
            </Snackbar>
            {width > breakpoint ? 
                (<DesktopToolbar 
                    {...props}
                    primaryColor={primaryColor}
                    handlePrimaryColorChange={handlePrimaryColorChange}
                    secondaryColor={secondaryColor}
                    handleSecondaryColorChange={handleSecondaryColorChange}
                    pattern={pattern}
                    handlePatternChange={handlePatternChange}
                    fontFamily={fontFamily}
                    handleFontFamilyChange={handleFontFamilyChange}
                    fontSize={fontSize}
                    handleFontSizeChange={handleFontSizeChange}
                    progressValue={progressValue}
                    handleProgressValue={handleProgressValue}
                    handleChooseType={handleChooseType}
                    typeDropdownClose={typeDropdownClose}
                    resumeTypes={resumeTypes}
                    activeType={resumeTypes[resumeType]}
                    generatePdf={generatePdf}
                    generateScreenshot={generateScreenshot}
                    downloadBtnActive={downloadBtnActive}

                />) : 
                (<MobileToolbar
                    {...props}
                    primaryColor={primaryColor}
                    handlePrimaryColorChange={handlePrimaryColorChange}
                    secondaryColor={secondaryColor}
                    handleSecondaryColorChange={handleSecondaryColorChange}
                    pattern={pattern}
                    handlePatternChange={handlePatternChange}
                    fontFamily={fontFamily}
                    handleFontFamilyChange={handleFontFamilyChange}
                    fontSize={fontSize}
                    handleFontSizeChange={handleFontSizeChange}
                    progressValue={progressValue}
                    handleProgressValue={handleProgressValue}
                    handleChooseType={handleChooseType}
                    typeDropdownClose={typeDropdownClose}
                    resumeTypes={resumeTypes}
                    activeType={resumeTypes[resumeType]}
                    generatePdf={generatePdf}
                    generateScreenshot={generateScreenshot}
                    downloadBtnActive={downloadBtnActive}
                />)}
        </>)
}

const mapStateToProps = (state, props) => {
    return {
        pdfUrl: !props.isCoverLetter ? state.resume.pdfUrl : state.coverLetter.pdfUrl,
        token: state.auth.token,
        configurationId: !props.isCoverLetter ? state.resume.resumeConfigurationId : state.coverLetter.coverLetterConfigurationId,
        configuration: !props.isCoverLetter ? state.resume.resumeConfiguration : state.coverLetter.coverLetterConfiguration,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onInitConfiguration: ( documentId, token ) => !props.isCoverLetter ? dispatch( actions.resumeConfigurationInit( documentId, token ) ) : dispatch( actions.coverLetterConfigurationInit( documentId, token ) ),
        onEditConfiguration: ( configuration, token, configurationId ) => !props.isCoverLetter ? dispatch( actions.resumeConfigurationEdit( configuration, token, configurationId ) ) : dispatch( actions.coverLetterConfigurationEdit( configuration, token, configurationId ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BuilderToolbar );