import React, {useState} from 'react';

import { makeStyles } from "@material-ui/core/styles";
import styles from './BuilderToolbar.module.scss';

import { ChromePicker, CirclePicker } from 'react-color';

import { Tabs, Tab }  from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import TabPanel from '../UI/TabPanel/TabPanel';

const tabStyles = makeStyles(theme => ({
    tabsWrap: {
        borderBottom: '1px solid #E3E8F1',
    },
    hideIndicator: {
        display: 'none',
    },
    tabItem: {
        textTransform: "none",
        color: '#677089',
        opacity: 1,
    },
    selectedTab: {
        backgroundColor: '#E3E8F1',
        color: '#8190AA',
    },
}));

const ColorItem = (props) => {
    const classes = tabStyles();

    const [primaryCustomOpen, setPrimaryCustomOpen] = useState(true);
    const [secondaryCustomOpen, setSecondaryCustomOpen] = useState(true);

    const handlePrimaryChangeComplete = (color) => {
        props.onPrimaryColorChange(color.hex);
    };

    const handleSecondaryChangeComplete = (color) => {
        props.onSecondaryColorChange(color.hex);
    };
    
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <p>Color</p>
            <div className={styles.ColorTabsWrap}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    classes={{ root: classes.tabsWrap, indicator: classes.hideIndicator }} 
                >
                    <Tab label="Primary" classes={{ root: classes.tabItem, selected: classes.selectedTab }} />
                    <Tab label="Secondary" classes={{ root: classes.tabItem, selected: classes.selectedTab }} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <CirclePicker 
                        colors={["#F6C823", "#FFA133", "#F03153", "#5C6783", "#219653", "#10B58C", "#9B51E0", "#4F4F4F"]}
                        onChangeComplete={handlePrimaryChangeComplete}
                        width={'100%'}
                        circleSize={36}
                        circleSpacing={11} 
                    />
                    <span className={styles.CustomColorToggle} onClick={() => setPrimaryCustomOpen(!primaryCustomOpen)}>
                        <span>Custom color</span>
                        {primaryCustomOpen ? <ArrowDropUpIcon className={styles.ArrowIcon} /> : <ArrowDropDownIcon className={styles.ArrowIcon} /> }
                    </span>
                    {primaryCustomOpen ? (
                        <ChromePicker
                            color={props.primaryColor}
                            onChangeComplete={handlePrimaryChangeComplete}
                            disableAlpha={true}
                        />
                    ) : (<></>)}
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <CirclePicker 
                        colors={["#F6C823", "#FFA133", "#F03153", "#5C6783", "#219653", "#10B58C", "#9B51E0", "#4F4F4F"]}
                        onChangeComplete={handleSecondaryChangeComplete}
                        width={'100%'}
                        circleSize={36}
                        circleSpacing={11} 
                    />
                    <span className={styles.CustomColorToggle} onClick={() => setSecondaryCustomOpen(!primaryCustomOpen)}>
                        <span>Custom color</span>
                        {secondaryCustomOpen ? <ArrowDropUpIcon className={styles.ArrowIcon} /> : <ArrowDropDownIcon className={styles.ArrowIcon} /> }
                    </span>
                    {secondaryCustomOpen ? (
                        <ChromePicker
                            color={props.secondaryColor}
                            onChangeComplete={handleSecondaryChangeComplete}
                            disableAlpha={true}
                        />
                    ) : (<></>)}
                </TabPanel>
            </div>
        </>
    );
}

export default ColorItem;