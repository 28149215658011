import React from 'react';

import {Grid, InputLabel, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import MonthYearPicker from '../../components/UI/MonthYearPicker/MonthYearPicker';

import styles from './ResumeBuilder.module.scss';

function CustomItem(props) {

    const handlePresentDateChange = (event) => {
        props.changed(props.index, 'presentDate', event.target.checked)();
    };

    const onFieldChange = (fieldKey, content) => {
        props.changed(props.index, fieldKey, content)();
    }
    
    return (
        <div className={styles.FormRepeatedItem} >
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="customItemTitle"
                        id="customItemTitle"
                        type="text"
                        label="Activity name, job or book title, etc"
                        value={props.values.customItemTitle}
                        changed={props.changed(props.index, 'customItemTitle')}
                    />
                </Grid>
                <Grid item xs={10} sm={5}>                                        
                    <Input
                        variant="outlined"
                        key="city"
                        id="city"
                        type="text"
                        label="City"
                        value={props.values.city}
                        changed={props.changed(props.index, 'city')}
                    />
                </Grid>
                <Grid item xs={2} sm={1} className={styles.DeleteIconWrap}>            
                    <IconButton onClick={props.deleted} className={styles.DeleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label="Start Date"
                        name="Start Date"
                        changed={(date) => onFieldChange('startDate', date)}
                        placeholder="mm/yyyy"
                        value={props.values.startDate}
                        monthHidden={props.values.startDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('startDateMonthHidden', isHidden)}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label={(<><span style={{marginRight: 'auto'}}>End Date</span><Tooltip tooltipContent="Leave this field blank if you don't want to display dates." /></>)}
                        name="End Date"
                        changed={(date) => onFieldChange('endDate', date)}
                        placeholder="mm/yyyy"
                        showCurrentlySwitch={true}
                        presentDate={props.values.presentDate}
                        swichChangeHandler={handlePresentDateChange}
                        value={props.values.endDate}
                        monthHidden={props.values.endDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('endDateMonthHidden', isHidden)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={styles.Label}>Description</InputLabel>
                    <TextEditor
                        value={props.values.description}
                        changed={(content) => onFieldChange('description', content)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default CustomItem;