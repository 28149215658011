export const getPosition = element => {
    const currentTop = window.pageXOffset;
    const currentLeft = window.pageYOffset;
  
    if (element) {
      if (element.getBoundingClientRect) {
        const { top, left } = element.getBoundingClientRect();
        return { x: left + currentLeft, y: top + currentTop };
      } else {
        // polyfill
        let xPosition = 0;
        let yPosition = 0;
  
        while (element) {
          xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
          yPosition += element.offsetTop - element.scrollTop + element.clientTop;
          element = element.offsetParent;
        }
        return { x: xPosition, y: yPosition };
      }
    }
  
    return {
      x: 0,
      y: 0,
    };
};