import React from 'react';

//import logoImage from '../../assets/images/logo.svg';
import logoImage from '../../assets/images/ResumeOK_logo.png';
import styles from './Logo.module.scss';
import { NavLink } from 'react-router-dom';

const logo = (props) => (
    <NavLink 
        to="/"
        exact
        className={styles.Logo}
    >
        <img src={logoImage} alt="ResumeOK" />
    </NavLink>
/*     <a href="/" className={styles.Logo}>
        <img src={logoImage} alt="ResumeOK" />
    </a> */
);

export default logo;