
import React from 'react';

import {Grid, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';

import styles from './ResumeBuilder.module.scss';

function WebsiteSocialItem(props) {
    return (
        <div className={styles.FormRepeatedItem} >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <Input
                        variant="outlined"
                        key="name"
                        id="name"
                        type="text"
                        label="Name"
                        value={props.values.name}
                        changed={props.changed(props.index, 'name')}
                    />
                </Grid>
                <Grid item xs={10} sm={6}>
                    <Input
                        variant="outlined"
                        key="link"
                        id="link"
                        type="text"
                        label="Url"
                        value={props.values.link}
                        changed={props.changed(props.index, 'link')}
                    />
                </Grid>
                <Grid item xs={2} sm={1} className={styles.DeleteIconWrap}>            
                    <IconButton onClick={props.deleted} className={styles.DeleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default WebsiteSocialItem;