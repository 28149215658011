import React, { useState, useEffect, useRef } from 'react';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import Switch from '../../components/UI/Switch/Switch';

import ReferenceItem from './ReferenceItem';
import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import styles from './ResumeBuilder.module.scss';

function ReferencesSection(props) {
    const [referencesPanelOpen, setReferencesPanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('References');

    const [referencesFields, setReferencesFields] = useState([
        { referent: '', company: '', phone: '', email: '', description: '' }
    ]);    
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.referencesFields && props.resume.referencesFields.length) {
                console.log('References Info Mounted');
                setReferencesFields(props.resume.referencesFields);
            }
            if (props.resume.referenceSectionTitle) {
                setSectionTitle(props.resume.referenceSectionTitle);
            }
            setReferencesVisibility(props.resume.referencesVisibility);
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.referencesFields && props.resume.referencesFields.length) {
                console.log('References Info Reload');
                setReferencesFields(props.resume.referencesFields);
            }
            if (props.resume.referenceSectionTitle) {
                setSectionTitle(props.resume.referenceSectionTitle);
            }
            setReferencesVisibility(props.resume.referencesVisibility);
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ referencesFields: referencesFields});
        }
    }, [referencesFields]);

/*     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ referencesFields: referencesFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, referencesFields]); */

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ referenceSectionTitle: sectionTitle});
        }
    }, [dataLoaded, sectionTitle]);


    const handleAddReferencesFields = () => {
        const values = [...referencesFields];
        values.push({ referent: '', company: '', phone: '', email: '', description: '' });
        setReferencesFields(values);
        setChanged(true);
    };

    const handleRemoveReferencesFields = index => {
        const values = [...referencesFields];
        values.splice(index, 1);
        setReferencesFields(values);
        handleCloseDeleteEntryDialog();
        setChanged(true);
    };

    const handleReferencesInputChange = (index, fieldKey, content) => (event) => {
        const values = [...referencesFields];
        if (fieldKey === 'description') {
            values[index][fieldKey] = content;
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setReferencesFields(values);
        setChanged(true);
    };

    const [referencesVisibility, setReferencesVisibility] = React.useState(false);

    const handleReferencesVisibilityChange = (event) => {
        setReferencesVisibility(event.target.checked);
        setChanged(true);
    };

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ referencesVisibility: referencesVisibility});
        }
    }, [dataLoaded, referencesVisibility]);
    
    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setReferencesPanelOpen(!referencesPanelOpen)} className={styles.PanelToggle}>
                {referencesPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="reference-section-title"
                    value={sectionTitle}
                    placeholder="References"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
                <IconButton onClick={props.deleted} style={{float: 'right'}}>
                    <DeleteIcon />
                </IconButton>    
            </div>
            { referencesPanelOpen ? (
                <>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <span>I'd like to hide references and make them available only upon request</span>                                        
                        <Switch
                            checked={Boolean(Number(referencesVisibility))}
                            swichChangeHandler={handleReferencesVisibilityChange}
                            name="referencesVisibility"
                        />
                    </div>
                    {referencesFields.map((inputField, index) => (
                        <ReferenceItem 
                            key={`${inputField}~${index}`} 
                            values={referencesFields[index]} 
                            changed={handleReferencesInputChange}
                            deleted={() => handleOpenDeleteEntryDialog(index)}
                            index={index}
                            referencesVisibility={referencesVisibility}
                        />
                    ))}
                    <Link
                        component="a"
                        variant="body2"
                        className={styles.FormLink}
                        onClick={() => handleAddReferencesFields()}
                    >
                        <AddCircleIcon /> Add reference
                    </Link>
                </>
            ) : null }
            <Dialog 
                open={deleteEntryDialog.open}
                closed={handleCloseDeleteEntryDialog}
            >
                <h2>Delete Entry</h2>
                <p>Are you sure you want to delete this entry?</p>   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={() => handleRemoveReferencesFields(deleteEntryDialog.deletedEntry)}
                    className={styles.Button}
                >
                    Delete
                </Button>
                <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
            </Dialog>
        </Paper>     
    )
}

export default ReferencesSection;