import React from 'react';

import {Grid, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';
import MonthYearPicker from '../../components/UI/MonthYearPicker/MonthYearPicker';

import styles from './ResumeBuilder.module.scss';

function CoursesItem(props) {

    const handlePresentDateChange = (event) => {
        props.changed(props.index, 'presentDate', event.target.checked)();
    };

    const onFieldChange = (fieldKey, content) => {
        props.changed(props.index, fieldKey, content)();
    }
    
    return (
        <div className={styles.FormRepeatedItem} >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="course"
                        id="course"
                        type="text"
                        label="Course"
                        value={props.values.course}
                        changed={props.changed(props.index, 'course')}
                    />
                </Grid>
                <Grid item xs={10} sm={5}>
                    <Input
                        variant="outlined"
                        key="institution"
                        id="institution"
                        type="text"
                        label="Institution"
                        value={props.values.institution}
                        changed={props.changed(props.index, 'institution')}
                    />
                </Grid>
                <Grid item xs={2} sm={1} className={styles.DeleteIconWrap}>            
                    <IconButton onClick={props.deleted} className={styles.DeleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label="Start Date"
                        name="Start Date"
                        changed={(date) => onFieldChange('startDate', date)}
                        placeholder="mm/yyyy"
                        value={props.values.startDate}
                        monthHidden={props.values.startDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('startDateMonthHidden', isHidden)}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label="End Date"
                        name="End Date"
                        changed={(date) => onFieldChange('endDate', date)}
                        placeholder="mm/yyyy"
                        showCurrentlySwitch={true}
                        presentDate={props.values.presentDate}
                        swichChangeHandler={handlePresentDateChange}
                        value={props.values.endDate}
                        monthHidden={props.values.endDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('endDateMonthHidden', isHidden)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default CoursesItem;