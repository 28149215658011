import React from 'react';

import Select from '../UI/Select/Select';

const FontItem = (props) => {

    const fonts = ['Rubik', 'Lato', 'Raleway', 'Montserrat', 'Open Sans', 'Ubuntu', 'Roboto', 'Overpass', 'Quicksand', 'Dancing Script', 'Bree Serif', 'Rufina', 'Source Serif Pro', 'Yellowtail'];

    const fontSizes = ['small', 'medium', 'large'];

    return (
        <>
            <Select
                id="font-family-select"
                label="Font family"
                value={props.fontFamily}
                changed={(e) => props.onFontFamilyChange(e.target.value)}
                options={fonts}
                native
            />
            <Select
                id="font-family-select"
                label="Size"
                value={props.fontSize}
                changed={(e) => props.onFontSizeChange(e.target.value)}
                options={fontSizes}
                native
            />
        </>
    );
}

export default FontItem;