import React from 'react';

import styles from './AccountNavigationItems.module.scss';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import NavigationItem from '../NavigationItem/NavigationItem';

const AccountNavigationItems = () => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={styles.AccountNavigationButton}
            >
            My account
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal 
                className={styles.AccountDropdown}>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper className={styles.AccountNavigationWrap} >
                    <ClickAwayListener onClickAway={handleClose}>
                    <ul id="menu-list-grow" onKeyDown={handleListKeyDown} className={styles.AccountNavigationItems}>
                        <NavigationItem onClick={handleClose} link="/account" exact>Account settings</NavigationItem>
                        <NavigationItem onClick={handleClose} link="/faq" exact>FAQ</NavigationItem>
                        <NavigationItem onClick={handleClose} link="/logout" exact>Log out</NavigationItem>
                    </ul>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </>
    );
}

export default AccountNavigationItems;