
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import PDFViewer from '../PDFViewer/PDFViewer';
import ResumeViewer from '../PDFViewer/ResumeViewer';

import BerlinPdf from '../../components/PDFTemplates/BerlinPdf';
import TokyoPdf from '../../components/PDFTemplates/TokyoPdf';
import LondonPdf from '../../components/PDFTemplates/LondonPdf';
import ParisPdf from '../../components/PDFTemplates/ParisPdf';
import MadridPdf from '../../components/PDFTemplates/MadridPdf';
import HloomPdf from '../../components/PDFTemplates/HloomPdf';
import CleanBluePdf from '../../components/PDFTemplates/CleanBluePdf';
import CreativePdf from '../../components/PDFTemplates/CreativePdf';
import TraditionalPdf from '../../components/PDFTemplates/TraditionalPdf';
import DublinPdf from '../../components/PDFTemplates/DublinPdf';
import ModernBluePdf from '../../components/PDFTemplates/ModernBluePdf';
import ClassicPdf from '../../components/PDFTemplates/ClassicPdf';
import WoodlawnPdf from '../../components/PDFTemplates/WoodlawnPdf';
import MonospacedPdf from '../../components/PDFTemplates/MonospacedPdf';
import DarkRedPdf from '../../components/PDFTemplates/DarkRedPdf';
import BlankPdf from '../../components/PDFTemplates/BlankPdf';

import Button from '../../components/UI/Button/Button';

import styles from './ResumeBuilder.module.scss';

import {IconButton} from '@material-ui/core';
import SavedIcon from '../../components/UI/Icons/SavedIcon';
import SpinnerIcon from '../../components/UI/Icons/SpinnerIcon';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

function ResumeResult(props) {
    
    //Prevent PDF Render Onload Error
    const [open, setOpen] = useState(false);

    /* useEffect(() => {
        setOpen(false);
        setOpen(true);
        return () => setOpen(false);
    }); */

    const resumeViewerRef = useRef(null);

    // Callback function to execute when mutations are observed
    const setResumeParentHeight = function(mutationsList, observer) {
        if (resumeViewerRef.current) {
            let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height + 100;
            //let resumeHeight = window.getComputedStyle(resumeViewerRef.current).height;
            setContentHeight(resumeHeight);
        }
    };
    
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(setResumeParentHeight);

    useEffect(() => {
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('resume-result');
        
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        observer.observe(targetNode, config);

        return () => {
            observer.disconnect();
        }
    }, []);

/*      useEffect(() => {
        if (resumeViewerRef.current) {
            setTimeout(() => {
            //document.onreadystatechange = () => {
                let resumeHeight = resumeViewerRef.current.getBoundingClientRect().height + 100;
                //let resumeHeight = window.getComputedStyle(resumeViewerRef.current).height;
                setContentHeight(resumeHeight);
            //};
            }, 3000);
        }

    }, [props.resume]); */

/*     useEffect(() => {
        setContentHeight(resumeViewerRef.current.getBoundingClientRect().height + 150);
    }, [resumeViewerRef.current?.getBoundingClientRect().height, props.resume]); */

    /* useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 1);
    }); */

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const [contentHeight, setContentHeight] = useState(0);

    
    function changePage(offset) { 
        setPageNumber(prevPageNumber => prevPageNumber + offset); 
    } 
    
    const selectPdfTemplate = () => {
        switch(Number(props.resume.templateId)) {
            case 1:   return <TokyoPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 2:   return <BerlinPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 3:   return <LondonPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 4:   return <ParisPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 5:   return <MadridPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 9:   return <HloomPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 10:  return <CleanBluePdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 11:  return <CreativePdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 12:  return <TraditionalPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 13:  return <DublinPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 14:  return <ModernBluePdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 15:  return <ClassicPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 16:  return <WoodlawnPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 17:  return <MonospacedPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            case 18:  return <DarkRedPdf resume={props.resume} resumeConfiguration={props.resumeConfiguration} />;
            default:  return <BlankPdf />;
        }
    }

    function onPrevPage() { 
        changePage(-1); 
    } 
    
    function onNextPage() { 
        changePage(1); 
    } 

    function onDocumentLoadSuccess(numPages) {
        setNumPages(numPages);
    }

    return (
        <div id="resume-result" className={`${styles.ResumeResult} ${props.visible ? styles.Visible : ''} ${props.fixed ? styles.Fixed : ''}`}>
            <div className={styles.ResumeInfo}>
                {props.isAuthenticated ? (
                    <div className={styles.ResumeStatus}>
                        {props.loading ? (<><SpinnerIcon viewBox='0 0 15 20' /><span>Saving</span></>) 
                            : (<><SavedIcon viewBox='0 0 20 20' /><span>Saved</span></>)}
                    </div>
                ) : (
                    <div className={`${styles.ResumeStatus} ${styles.NotAuth}`}><ErrorIcon /><span>Sign Up to Save Your Resume</span></div>
                )}
                
                {/* <div className={styles.ResumePagination}>{pageNumber}/{numPages}</div> */}
                <IconButton onClick={props.closeHandler} className={styles.ResumeCloseButton}>
                    <CloseIcon viewBox='3 3 18 18'/>
                </IconButton>     
            </div>
            
                
            
            <div className={styles.ResultContent} style={{minHeight: contentHeight}} >
                <ResumeViewer resume={props.resume} templateId={props.resume.templateId} documentId={props.resumeId} ref={resumeViewerRef} />
                
                {/* <p>Resume Template Id: { props.templateId }</p> */}
            
                {open && (
                    <PDFViewer
                        resume={props.resume}
                        resumeConfiguration={props.resumeConfiguration}
                        resumeId={props.resumeId} 
                        loaded={onDocumentLoadSuccess}
                        pageNumber={pageNumber}
                        numPages={numPages}
                        prevPageHangler={onPrevPage}
                        nextPageHangler={onNextPage}
                        showTemplatesHandler={props.showTemplatesHandler}
                        showShareLinkHandler={props.showShareLinkHandler}
                        className={styles.ViewerControls}
                    >
                        {selectPdfTemplate()}
                    </PDFViewer>
                )}
                {/* <Button
                    variant="contained"
                    color="primary"
                    className={`${styles.Button} ${styles.DownloadButton}`}
                >
                    Download
                </Button> */}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.resume.loading,
        resumeConfiguration: state.resume.resumeConfiguration,
        isAuthenticated: state.auth.token !== null,
    };
};

export default connect( mapStateToProps )( ResumeResult );