import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import EducationItem from './EducationItem';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import styles from './ResumeBuilder.module.scss';

import  {shallowEqual} from '../../shared/utility';

function EducationSection(props) {
    const [educationPanelOpen, setEducationPanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('Education');

    const [educationFields, setEducationFields] = useState([
        { degree: '', institution: '', startDate: null, endDate: null, presentDate: false, city: '', description: '' }
    ]);    

    const  [educationItemOpen, setEducationItemOpen]  = useState(0);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.educationFields && props.resume.educationFields.length) {
                console.log('Education Info Mounted');
                setEducationFields(props.resume.educationFields);
            }
            if (props.resume.educationSectionTitle) {
                setSectionTitle(props.resume.educationSectionTitle);
            }
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.educationFields && props.resume.educationFields.length) {
                console.log('Education Info Reload');
                setEducationFields(props.resume.educationFields);
            }
            if (props.resume.educationSectionTitle) {
                setSectionTitle(props.resume.educationSectionTitle);
            }
        }
    }, [props.exampleLoaded] );


    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ educationFields: educationFields });
        }
    }, [educationFields]);

/*     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ educationFields: educationFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, educationFields]); */

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ educationSectionTitle: sectionTitle});
        }
    }, [dataLoaded, sectionTitle]);

    const handleAddEducationFields = () => {
        const values = [...educationFields];
        values.forEach((item) => {
            item.open = false;
        });
        values.push({ degree: '', institution: '', startDate: null, endDate: null, presentDate: false, city: '', description: '' });
        setEducationFields(values);
        setEducationItemOpen(values.length - 1);
        setChanged(true);
    };

    const handleRemoveEducationFields = index => {
        const values = [...educationFields];
        values.splice(index, 1);
        setEducationFields(values);
        setChanged(true);

        if (educationItemOpen === index) {
            setEducationItemOpen(null);
        }

        handleCloseDeleteEntryDialog();
    };

    const handleToggleEducationFields = index => {
        if (educationItemOpen === index) {
            setEducationItemOpen(null);
        } else {
            setEducationItemOpen(index);
        }
    }

    const handleEducationInputChange = (index, fieldKey, content) => (event) => {
        const values = [...educationFields];
        if ((fieldKey === 'description') || (fieldKey === 'presentDate') || (fieldKey === 'startDateMonthHidden') || (fieldKey === 'endDateMonthHidden')) {
            values[index][fieldKey] = content;
        } else if ((fieldKey === 'startDate') || (fieldKey === 'endDate')) {
            //Fix One day off JS issue
            if (moment(content/* , 'MM/yyyy', true */).isValid()) {
                const date = new Date(content.getTime() - (content.getTimezoneOffset() * 60000));
                values[index][fieldKey] = date;
            } else {
                values[index][fieldKey] = content;
            }
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setEducationFields(values);
        setChanged(true);
    };

    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    const onDragEnd = result => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const values = [...educationFields];
        let draggableEl = values.splice(source.index, 1);
        console.log(draggableEl);
        values.splice(destination.index, 0, draggableEl[0]);
        
        if (!shallowEqual(educationFields[educationItemOpen], values[educationItemOpen])) {
            if (educationItemOpen === source.index) {
                setEducationItemOpen(destination.index);
            } else if (educationItemOpen > destination.index) {
                setEducationItemOpen(educationItemOpen + 1);
            } else if (educationItemOpen < destination.index) {
                setEducationItemOpen(educationItemOpen - 1);
            } else if (educationItemOpen === destination.index) {
                setEducationItemOpen(source.index);
            }
        }

        setEducationFields(values);
        setChanged(true);
    };

    return ( 
        <DragDropContext onDragEnd={onDragEnd}>                      
            <Paper className={`${styles.FormSection}`}>
                <IconButton onClick={() => setEducationPanelOpen(!educationPanelOpen)} className={styles.PanelToggle}>
                    {educationPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                </IconButton>

                <div className={styles.PanelTitle}>
                    <AutosizeInput
                        name="education-section-title"
                        value={sectionTitle}
                        placeholder="Education"
                        onChange={handleSectionTitleChange}
                        className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                        ref={sectionTitleInput}
                        data-lpignore="true"
                    />
                    <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
                </div>
                { educationPanelOpen ? (
                    <>
                        <p>Add your most recent educational achievements, schools you attended and the relevant dates.</p>
                        <Droppable droppableId="educationSection">
                            {provided => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {educationFields.map((inputField, index) => (
                                        <EducationItem 
                                            key={`education~${index}`}
                                            id={`education~${index}`}
                                            index={index} 
                                            values={educationFields[index]} 
                                            changed={handleEducationInputChange}
                                            deleted={() => handleOpenDeleteEntryDialog(index)}
                                            toggled={() => handleToggleEducationFields(index)}
                                            open={educationItemOpen === index}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                            
                        </Droppable>
                        <Link
                            component="a"
                            variant="body2"
                            className={styles.FormLink}
                            onClick={() => handleAddEducationFields()}
                        >
                            <AddCircleIcon /> Add education
                        </Link>
                    </>
                ) : null }
                <Dialog 
                    open={deleteEntryDialog.open}
                    closed={handleCloseDeleteEntryDialog}
                >
                    <h2>Delete Entry</h2>
                    <p>Are you sure you want to delete this entry?</p>   
                    <Button
                        variant="contained"
                        color="primary"
                        clicked={() => handleRemoveEducationFields(deleteEntryDialog.deletedEntry)}
                        className={styles.Button}
                    >
                        Delete
                    </Button>
                    <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
                </Dialog>
            </Paper>
        </DragDropContext>        
    )
}

export default EducationSection;