import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { Font } from '@react-pdf/renderer';

import { StylesProvider } from "@material-ui/core/styles";

import Layout from "./hoc/Layout/Layout";
import Login from "./containers/Login/Login";
import SignUpBuilder from "./containers/SignUpBuilder/SignUpBuilder";
import ResumeBuilder from "./containers/ResumeBuilder/ResumeBuilder";
import CoverLetterBuilder from "./containers/CoverLetterBuilder/CoverLetterBuilder";
import Account from "./containers/Account/Account";
import CreatePassword from "./containers/Account/CreatePassword";
import ForgotPassword from "./containers/Account/ForgotPassword";
import Faq from "./containers/Faq";
import Dashboard from "./containers/Dashboard/Dashboard";
import Logout from "./containers/Account/Logout/Logout";
import NotFoundPage from './components/UI/NotFoundPage/NotFoundPage';
import TemplatesList from './containers/TemplatesList/TemplatesList';
import DocumentPage from './containers/DocumentPage/DocumentPage';
import * as actions from './store/actions/index';

class App extends Component {
    componentDidMount () {
        this.props.onTryAutoSignup();

        //Font.register({ family: 'Bree Serif', fonts: [{src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf'}] } );
        //Font.register({ family: 'Dancing Script', fonts: [{src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Lato', fonts: [{src: 'https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHvxk.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Open Sans', fonts: [{src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Overpass', fonts: [{src: 'https://fonts.gstatic.com/s/overpass/v5/qFdH35WCmI96Ajtm82Gi.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Quicksand', fonts: [{src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Raleway', fonts: [{src: 'https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Roboto', fonts: [{src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Rubik', fonts: [{src: 'https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Rufina', fonts: [{src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6V-LyURyLy-aKyow.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Source Serif Pro', fonts: [{src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Ubuntu', fonts: [{src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Yellowtail', fonts: [{src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf', fontWeight: '400' }] } );
        //Font.register({ family: 'Marat Sans', fonts: [{src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf', fontWeight: '400' }] } );
        /* https://fonts.cdnfonts.com/s/16213/Marat Sans Regular.woff */
    
        //italic, bold missing
        Font.register({
            family: 'Bree Serif',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf' },
                { src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/breeserif/v10/4UaHrEJCrhhnVA3DgluAx60.ttf', fontWeight: 700 },
            ]
        });

        //italic missing
        Font.register({
            family: 'Dancing Script',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf' },
                { src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTQ.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i0HTQ.ttf', fontWeight: 700 },
            ]
        });

        Font.register({
            family: 'Lato',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHvxk.ttf' },
                { src: 'https://fonts.gstatic.com/s/lato/v17/S6u8w4BMUTPHjxswWw.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVew8.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/lato/v17/S6u_w4BMUTPHjxsI5wqPHA.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        /* Font.register({
            family: 'Open Sans',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.ttf' },
                { src: 'https://fonts.gstatic.com/s/opensans/v18/mem6YaGs126MiZpBA-UFUJ0e.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rg-VQ.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWiUOhs.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        }); */

        Font.register({
            family: 'Open Sans',
            fonts: [
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', fontWeight: 600 },
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf', fontStyle: 'italic' },
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
                { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600italic.ttf', fontWeight: 600, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Overpass',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/overpass/v5/qFdH35WCmI96Ajtm82Gi.ttf' },
                { src: 'https://fonts.gstatic.com/s/overpass/v5/qFdB35WCmI96Ajtm81GgU94.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/overpass/v5/qFdA35WCmI96Ajtm81kedv7K.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/overpass/v5/qFdC35WCmI96Ajtm81Gga2LP4h8.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        //italic missing
        Font.register({
            family: 'Quicksand',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf' },
                { src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Raleway',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf' },
                { src: 'https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCP.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/raleway/v19/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQ.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Roboto',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf' },
                { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu52xP.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBhc9.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Rubik',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf' },
                { src: 'https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0Uw.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUw.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        //Italic missing
        Font.register({
            family: 'Rufina',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6V-LyURyLy-aKyow.ttf' },
                { src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6V-LyURyLy-aKyow.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6W-LyURyLy-aKKHztAvA.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/rufina/v8/Yq6W-LyURyLy-aKKHztAvA.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Source Serif Pro',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIQzD-0qpwxpaWvjeD0X88SAOeaiXM.ttf' },
                { src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIWzD-0qpwxpaWvjeD0X88SAOeauXE-pQ.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIXzD-0qpwxpaWvjeD0X88SAOeasc8bhSs.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/sourceserifpro/v10/neIVzD-0qpwxpaWvjeD0X88SAOeauXEGGS6qwQ.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        //bold & italic missing
        Font.register({ 
            family: 'Yellowtail',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf' },
                { src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/yellowtail/v11/OZpGg_pnoDtINPfRIlLotlw.ttf', fontWeight: 700, fontStyle: 'italic' },
            ] 
        });

        Font.register({
            family: 'Montserrat',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm45xW4.ttf' },
                { src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459Wxhzg.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE7g4.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZcgvD-w.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        Font.register({
            family: 'Ubuntu',
            fonts: [
                { src: 'https://cdn.jsdelivr.net/npm/@typopro/web-ubuntu@3.7.5/TypoPRO-Ubuntu-Regular.ttf' },
                { src: 'https://cdn.jsdelivr.net/npm/@typopro/web-ubuntu@3.7.5/TypoPRO-Ubuntu-Italic.ttf', fontStyle: 'italic' },
                { src: 'https://cdn.jsdelivr.net/npm/@typopro/web-ubuntu@3.7.5/TypoPRO-Ubuntu-Bold.ttf', fontWeight: 700 },
                { src: 'https://cdn.jsdelivr.net/npm/@typopro/web-ubuntu@3.7.5/TypoPRO-Ubuntu-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        });

        /* Font.register({
            family: 'Ubuntu',
            fonts: [
                { src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf' },
                { src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCu6KVjbNBYlgoKeg7z.ttf', fontStyle: 'italic' },
                { src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf', fontWeight: 700 },
                { src: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejZPsmyI.ttf', fontWeight: 700, fontStyle: 'italic' },
            ]
        }); */
    }

    render () {
        let routes = (
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/sign-up" exact component={SignUpBuilder} />
                <Route path="/choose-template" exact component={TemplatesList} />
                <Route path="/choose-cover-template" exact render={() => <TemplatesList isCoverLetter />} />
                <Route path="/" exact component={Login} />
                <Route path="/account/password/:userHash" exact component={CreatePassword} />
                <Route path="/r/:idHash" exact component={DocumentPage} />
                <Route path="/cl/:idHash" exact render={() => <DocumentPage isCoverLetter />} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                {/* <Route component={NotFoundPage} status={404} /> */}
                <Route path="/account/password/:userHash" exact component={CreatePassword} />
                <Route path="/resume-builder/sample" component={SignUpBuilder} />
                <Route path="*" component={Login} />
            </Switch>
          );
      
          if ( this.props.isAuthenticated ) {
            routes = (
                <Switch>
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/resume-builder/sample" exact component={SignUpBuilder} />
                    <Route path="/resume-builder" component={ResumeBuilder} />
                    <Route path="/cover-letter-builder" component={CoverLetterBuilder} />
                    <Route path="/choose-template" exact component={TemplatesList} />
                    <Route path="/choose-cover-template" exact render={() => <TemplatesList isCoverLetter />} />
                    <Route path="/account" exact component={Account} />
                    <Route path="/faq" exact component={Faq} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/sign-up" exact component={SignUpBuilder} />
                    <Route path="/account/password/:userHash" exact component={CreatePassword} />
                    <Route path="/r/:idHash" exact component={DocumentPage} />
                    <Route path="/cl/:idHash" exact render={() => <DocumentPage isCoverLetter />} />
                    <Route path="/forgot-password" exact component={ForgotPassword} />
                    <Route path="/" exact component={Login} />
                    {/* <Route component={NotFoundPage} status={404} /> */}
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            );
          }
      
        return (
            <>
                <Helmet>
                    <title>ResumeOK</title>
                </Helmet>
                <StylesProvider injectFirst>
                    <Layout>
                        {/*this.props.loaded ? routes : null*/}
                        {routes}
                    </Layout>
                </StylesProvider>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        loaded: !state.auth.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
    };
};
  
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );
