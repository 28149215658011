import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-resumes';

import {Paper, Grid, InputLabel, } from '@material-ui/core';
import Autocomplete from '../../components/UI/Autocomplete/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';

import styles from './CoverLetterBuilder.module.scss';

import { checkValidity } from '../../shared/utility';

function CoverLetterSection(props) {
        
    const [resumeSamples, setResumeSamples] = useState([]);

    const [values, setValues] = useState({
        firstName: {
            elementConfig: {
                type: 'text',
                name: 'First Name',
                label: 'First Name'
            },
            value: '',
            validation: {
                required: true,
                isAlphabeticCharacters: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        lastName: {
            elementConfig: {
                type: 'text',
                name: 'Last Name',
                label: 'Last Name'
            },
            value: '',
            validation: {
                required: true,
                isAlphabeticCharacters: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        email: {
            elementConfig: {
                type: 'email',
                name: 'E-mail',
                label: 'E-mail'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        phoneNumber: {
            elementConfig: {
                type: 'text',
                name: 'Phone',
                label: 'Phone'
            },
            value: '',
            validation: {
                required: false,
                isPhoneNumber: true,
                message: '',
            },
            valid: false,
            touched: false,
            validationMessage: '',
        },
        jobPosition: {
            value: '',
            valid: true,
        },
        companyName: {
            value: '',
            valid: true,
        },
        managerName: {
            value: '',
            valid: true,
        },
        letterDetails: {
            value: '',
            valid: true,
        },
    });

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const handleChange = (field, content) => {
        const updatedControls = {
            ...values,
            [field]: {
                ...values[field],
                value: content,
            },
        }
        setValues({ ...updatedControls });
        setChanged(true);
    };

    const handleChangeWithValidation = (field) => (event) => {
        const updatedControls = {
            ...values,
            [field]: {
                ...values[field],
                value: event.target.value,
                valid: checkValidity( event.target.value, values[field].validation ).valid,
                validationMessage: checkValidity( event.target.value, values[field].validation ).validationMessage,
                touched: true
            }
        };
        setValues({ ...updatedControls });
        setChanged(true);
    };


    useEffect(() => {   
        axios.get( '/jobs', { headers: {
            'Authorization': `Bearer ${props.token}`
        }})
        .then( response => {
            let jobs = response.data.jobs;
            if (jobs) {
                setResumeSamples(jobs);
            }
        } )
        .catch( err => {
            console.log(err);
        });

        if (props.coverLetter) {
            let updatedControls = {
                ...values,
            };
            
            console.log('Cover Letter Mounted');
            Object.keys(updatedControls).forEach(
                (key) => {
                    updatedControls[key] = {
                        ...values[key],
                        value: props.coverLetter[key],
                        valid: true
                    };
                }
            );
            setValues({ ...updatedControls });
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        const formData = {};
        for (let formElementIdentifier in values) {
            formData[formElementIdentifier] = values[formElementIdentifier].value;
        }
        if (dataLoaded && changed) {
            props.onChange(formData);
        }
    }, [values, dataLoaded]);

    return (                       
        <Paper className={styles.FormSection}>
            <div className={styles.PanelTitle}>
                <h4>Personal Details</h4>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="firstName"
                        id="firstName"
                        type={values.firstName.elementConfig.type}
                        name={values.firstName.elementConfig.name}
                        label={values.firstName.elementConfig.label}
                        value={values.firstName.value}
                        invalid={!values.firstName.valid}
                        shouldValidate={values.firstName.validation}
                        validationMessage={values.firstName.validationMessage}
                        touched={values.firstName.touched}
                        changed={handleChangeWithValidation("firstName")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="lastName"
                        id="lastName"
                        type={values.lastName.elementConfig.type}
                        name={values.lastName.elementConfig.name}
                        label={values.lastName.elementConfig.label}
                        value={values.lastName.value}
                        invalid={!values.lastName.valid}
                        shouldValidate={values.lastName.validation}
                        validationMessage={values.lastName.validationMessage}
                        touched={values.lastName.touched}
                        changed={handleChangeWithValidation("lastName")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="email"
                        id="email"
                        type={values.email.elementConfig.type}
                        name={values.email.elementConfig.name}
                        label={values.email.elementConfig.label}
                        value={values.email.value}
                        invalid={!values.email.valid}
                        shouldValidate={values.email.validation}
                        validationMessage={values.email.validationMessage}
                        touched={values.email.touched}
                        changed={handleChangeWithValidation("email")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="phoneNumber"
                        id="phoneNumber"
                        type={values.phoneNumber.elementConfig.type}
                        name={values.phoneNumber.elementConfig.name}
                        label={values.phoneNumber.elementConfig.label}
                        value={values.phoneNumber.value || ''}
                        invalid={!values.phoneNumber.valid}
                        shouldValidate={values.phoneNumber.validation}
                        validationMessage={values.phoneNumber.validationMessage}
                        touched={values.phoneNumber.touched}
                        changed={handleChangeWithValidation("phoneNumber")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        freeSolo
                        inputValue={values.jobPosition.value || ''}
                        inputChanged={(event, newValue) => {
                            handleChange('jobPosition', newValue);
                        }}
                        id="jobPosition"
                        options={resumeSamples}
                        filterOptions={createFilterOptions({
                            limit: 500,
                        })}
                        renderOption={(option) => (
                        <span style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', width: '100%', }}>
                            <span style={{flex: '1 0 auto', textTransform: 'capitalize', }}>{option.name}</span>
                        </span>
                        )}
                        getOptionLabel={(option) => option.name}
                        label="Job Title"
                    />
                </Grid>    
            </Grid>
            
            <div className={styles.PanelTitle}>
                <h4>Employer details</h4>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="companyName"
                        id="companyName"
                        type="text"
                        label="Company Name"
                        value={values.companyName.value}
                        changed={(e) => handleChange("companyName", e.target.value)}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="managerName"
                        id="managerName"
                        type="text"
                        label="Hiring Manager Name"
                        value={values.managerName.value}
                        changed={(e) => handleChange("managerName", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={styles.Label}>Letter details</InputLabel>
                    <TextEditor
                        value={values.letterDetails.value}
                        changed={(content) => handleChange('letterDetails', content)}
                        key={props.id}
                    />
                </Grid>    
            </Grid>

        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        created: state.resume.created,
        resumes: state.resume.resumes
    };
};

export default connect( mapStateToProps )( CoverLetterSection );