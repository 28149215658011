import React, {useState, useEffect} from 'react';
import styles from './BuilderToolbar.module.scss';

import Grid from '@material-ui/core/Grid';

import languagesIcon from '../../assets/images/languages_icon.svg';
import experinceIcon from '../../assets/images/experience_icon.svg';
import skillsIcon from '../../assets/images/skills_icon.svg';
import extrasIcon from '../../assets/images/extras_icon.svg';
import educationIcon from '../../assets/images/education_icon.svg';
import coursesIcon from '../../assets/images/courses_icon.svg';

const ComplectionItem = (props) => {

    const [progressCriterias, setProgressCriterias] = useState({
        mainInfo: {
            value: 20,
            complete: false,
            label: 'Main Info',
            icon: languagesIcon,
            linkTo: 'General'
        },
        additioanalInfo: {
            value: 10,
            complete: false,
            label: 'Additional Info',
            icon: educationIcon,
            linkTo: 'Additional'
        },
        careerSummary: {
            value: 15,
            complete: false,
            label: 'Career Summary',
            icon: coursesIcon,
            linkTo: 'Career Summary'
        },
        experience: {
            value: 25,
            complete: false,
            label: 'Experience',
            icon: experinceIcon,
            linkTo: 'Work Experience'
        },
        education: {
            value: 10,
            complete: false,
            label: 'Education',
            icon: educationIcon,
            linkTo: 'Education'
        },
        skills: {
            value: 10,
            complete: false,
            label: 'Skills',
            icon: skillsIcon,
            linkTo: 'Skills'
        },
        courses: {
            value: 5,
            complete: false,
            label: 'Courses',
            icon: coursesIcon,
            linkTo: 'Courses'
        },
        addPhoto: {
            value: 5,
            complete: false,
            label: 'Add Photo',
            icon: extrasIcon,
            linkTo: 'Photo'
        },
    });

    useEffect(() => {
        let updatedValue = 0;
        Object.keys(progressCriterias).forEach(
            // if we have an error string set valid to false
            (key) => {
                if (progressCriterias[key].complete) {
                    updatedValue += progressCriterias[key].value;
                }
            }
        );
        props.onProgressValueChange(updatedValue);
    }, [progressCriterias] );

    useEffect(() => {
        let resumeData = props.resume;

        let updatedCriterias = {...progressCriterias};

        if (resumeData) {
            if ((resumeData.firstName && resumeData.firstName !== '') && 
                (resumeData.lastName && resumeData.lastName !== '') && 
                (resumeData.email && resumeData.email !== '') && 
                (resumeData.phoneNumber && resumeData.phoneNumber !== '') && 
                (resumeData.jobPosition && resumeData.jobPosition !== '') ) {

                updatedCriterias['mainInfo'] = {
                    ...updatedCriterias['mainInfo'],
                    complete: true
                }
            } else {
                updatedCriterias['mainInfo'] = {
                    ...updatedCriterias['mainInfo'],
                    complete: false
                }
            }
            if ((resumeData.country && resumeData.country !== '') || 
                (resumeData.city && resumeData.city !== '') || 
                (resumeData.adress && resumeData.adress !== '') || 
                (resumeData.postalCode && resumeData.postalCode !== '') || 
                (resumeData.nationality && resumeData.nationality !== '') || 
                (resumeData.birthPlace && resumeData.birthPlace !== '') || 
                (resumeData.birthDate && resumeData.birthDate !== '') || 
                (resumeData.drivingLicenseType && resumeData.drivingLicenseType !== '') ) {
                    
                updatedCriterias['additioanalInfo'] = {
                    ...updatedCriterias['additioanalInfo'],
                    complete: true
                }            
            } else {
                updatedCriterias['additioanalInfo'] = {
                    ...updatedCriterias['additioanalInfo'],
                    complete: false
                }  
            }
            if (resumeData.careerSummary && resumeData.careerSummary !== '') {
                updatedCriterias['careerSummary'] = {
                    ...updatedCriterias['careerSummary'],
                    complete: true
                }
            } else {
                updatedCriterias['careerSummary'] = {
                    ...updatedCriterias['careerSummary'],
                    complete: false
                }
            }
            if (resumeData.coursesFields && resumeData.coursesFields.length > 0) {
                updatedCriterias['courses'] = {
                    ...updatedCriterias['courses'],
                    complete: true
                }
            } else {
                updatedCriterias['courses'] = {
                    ...updatedCriterias['courses'],
                    complete: false
                }
            }
            if (resumeData.skillsFields && resumeData.skillsFields.length > 0) {
                updatedCriterias['skills'] = {
                    ...updatedCriterias['skills'],
                    complete: true
                }
            } else {
                updatedCriterias['skills'] = {
                    ...updatedCriterias['skills'],
                    complete: false
                }
            }
            if (resumeData.workExperienceItems && resumeData.workExperienceItems.length > 0) {
                updatedCriterias['experience'] = {
                    ...updatedCriterias['experience'],
                    complete: true
                }
            } else {
                updatedCriterias['experience'] = {
                    ...updatedCriterias['experience'],
                    complete: false
                }
            }
            if (resumeData.educationFields && resumeData.educationFields.length > 0) {
                updatedCriterias['education'] = {
                    ...updatedCriterias['education'],
                    complete: true
                }
            } else {
                updatedCriterias['education'] = {
                    ...updatedCriterias['education'],
                    complete: false
                }
            }
            if (resumeData.userImage) {
                updatedCriterias['addPhoto'] = {
                    ...updatedCriterias['addPhoto'],
                    complete: true
                }
            } else {
                updatedCriterias['addPhoto'] = {
                    ...updatedCriterias['addPhoto'],
                    complete: false
                }
            }
            setProgressCriterias(updatedCriterias);
        }
    }, [props.resume] );


    let progressCriteriasItems = null;
    if (progressCriterias) {
        progressCriteriasItems = Object.keys(progressCriterias).map(
            (key) => (
                <Grid item xs={6} sm={4} className={`${styles.CriteriaItem} ${progressCriterias[key].complete ? styles.Complete : ''}`} key={key} onClick={() => scrollToSection(progressCriterias[key].linkTo)}>
                    <img src={progressCriterias[key].icon} alt="criteria icon"/>
                    <span className={styles.CriteriaLabel}>{progressCriterias[key].label}</span>
                    <span className={styles.CriteriaValue}>{progressCriterias[key].value ? `+${progressCriterias[key].value}%` : ''}</span>
                </Grid>
            )
        );
    }

    const scrollToSection = (scrollTo) => {
        props.scrollToProgressHandler(scrollTo);
    }


    return !props.hidden && (
        <>
            <small>To increase your completion percentage you can add the following:</small>
            <Grid container spacing={1}>
                {progressCriteriasItems}
            </Grid>
        </>
    );
}

export default ComplectionItem;