import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    let message = '';
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
        //message = 'This field is required';
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isPhoneNumber ) {
        //const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        //const pattern = /^((\+1)?[\s-]?)?\(?[1-9]\d\d\)?[\s-]?[1-9]\d\d[\s-]?\d\d\d\d/;
        //const pattern = /^(?=.*?[1-9])[0-9()-]+$/;
        const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[–-\s\./0-9]*$/g;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d*$/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isAlphabetic ) {
        const pattern = /^[A-Za-z\u0400-\u04FF\s-]*$/;
        isValid = pattern.test( value ) && isValid
    }

    if (rules.isAlphabeticCharacters) {
        const pattern = /^\D+$/;
        isValid = pattern.test( value ) && isValid
    }

    let validation = {
        valid: isValid,
        validationMessage: message
    };

    return validation;
};

export const generatePassword = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const generateDateString = (startDate, endDate, isPresent, startDateMonthHidden, endDateMonthHidden, dateFormat = "MM YYYY", delimeter = " - ") => {
    let workDatesString = '';
    if (startDate) {
        workDatesString = startDateMonthHidden ? moment(startDate).format("YYYY") : moment(startDate).format(dateFormat);
    }
    if (startDate && (endDate || isPresent)) {
        workDatesString += delimeter;
    }
    if (endDate || isPresent) {
        workDatesString += isPresent ? 'Present' : endDateMonthHidden ? moment(endDate).format("YYYY") : moment(endDate).format(dateFormat);
    };

    return workDatesString;
}

export const generateContactString = (...contactItems) => {
    return contactItems.filter(contactItem => contactItem && contactItem !== '').join(', ');
}

export const matchExact = (r, str) => {
    var match = str.match(r);
    return match && str === match[0];
}

export const shallowEqual = (object1, object2) => {
    if (object1 && object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
    
        if (keys1.length !== keys2.length) {
        return false;
        }
    
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }
}

export const getElementByPoint = ( parent, x, y ) => {
    if ( x < parent.getBoundingClientRect().width && y < parent.getBoundingClientRect().height ) {
        /* console.log(parent.getBoundingClientRect().top);
        console.log(y + parent.offsetTop); */
        return document.elementFromPoint( x + parent.getBoundingClientRect().left, y + parent.getBoundingClientRect().top );
    } else {
        return null;
    }
}

export const getTransformedResume = (resume) => {
    let transformedEducations = [];
    if (resume.educations && resume.educations.length) {
        transformedEducations = resume.educations.sort((a,b) =>  a.id-b.id).map((education, index) => {
            let transformedItem = {
                id: index,
                degree: education.degree,
                institution: education.institution,
                city: education.city,
                startDate: education.start_date,
                startDateMonthHidden: Boolean(Number(education.start_date_month_hidden)),
                endDate: education.end_date,
                endDateMonthHidden: Boolean(Number(education.end_date_month_hidden)),
                presentDate: Boolean(Number(education.present_date)),
                description: education.summary
            }
            return transformedItem;
        });
    }

    let transformedEmployments = [];
    if (resume.employments && resume.employments.length) {
        transformedEmployments = resume.employments.sort((a,b) =>  a.id-b.id).map((employment, index) => {
            let transformedItem = {
                id: index,
                jobTitle: employment.job_title,
                company: employment.company,
                startDate: employment.start_date,
                startDateMonthHidden: Boolean(Number(employment.start_date_month_hidden)),
                endDate: employment.end_date,
                endDateMonthHidden: Boolean(Number(employment.end_date_month_hidden)),
                presentDate: Boolean(Number(employment.present_date)),
                city: employment.city,
                description: employment.summary,
            }
            return transformedItem;
        });
    }

    let transformedInternships = [];
    if (resume.internships && resume.internships.length) {
        transformedInternships = resume.internships.sort((a,b) =>  a.id-b.id).map((internship, index) => {
            let transformedItem = {
                id: index,
                jobTitle: internship.job_title,
                employer: internship.employer,
                startDate: internship.start_date,
                startDateMonthHidden: Boolean(Number(internship.start_date_month_hidden)),
                endDate: internship.end_date,
                endDateMonthHidden: Boolean(Number(internship.end_date_month_hidden)),
                presentDate: Boolean(Number(internship.present_date)),
                city: internship.city,
                description: internship.summary
            }
            return transformedItem;
        });
    }

    let transformedCourses = [];
    if (resume.courses && resume.courses.length) {
        transformedCourses = resume.courses.sort((a,b) =>  a.id-b.id).map((course, index) => {
            let transformedItem = {
                id: index,
                course: course.course,
                institution: course.institution,
                startDate: course.start_date,
                startDateMonthHidden: Boolean(Number(course.start_date_month_hidden)),
                endDate: course.end_date,
                endDateMonthHidden: Boolean(Number(course.end_date_month_hidden)),
                presentDate: Boolean(Number(course.present_date)),
            }
            return transformedItem;
        });
    }

    let transformedActivities = [];
    if (resume.activities && resume.activities.length) {
        transformedActivities = resume.activities.sort((a,b) =>  a.id-b.id).map((activity, index) => {
            let transformedItem = {
                id: index,
                customItemTitle: activity.activity,
                startDate: activity.start_date,
                startDateMonthHidden: Boolean(Number(activity.start_date_month_hidden)),
                endDate: activity.end_date,
                endDateMonthHidden: Boolean(Number(activity.end_date_month_hidden)),
                presentDate: Boolean(Number(activity.present_date)),
                city: activity.city,
                description: activity.description,
            }
            return transformedItem;
        });
    }

    let transformedSkills = [];
    if (resume.skills && resume.skills.length) {
        transformedSkills = resume.skills.sort((a,b) =>  a.id-b.id);
    }

    let transformedLanguages = [];
    if (resume.languages && resume.languages.length) {
        transformedLanguages = resume.languages.sort((a,b) =>  a.id-b.id);
    }

    let transformedReferences = [];
    if (resume.references && resume.references.length) {
        transformedReferences = resume.references.sort((a,b) =>  a.id-b.id);
    }

    let transformedSocials = [];
    if (resume.socials && resume.socials.length) {
        transformedSocials = resume.socials.sort((a,b) =>  a.id-b.id);
    }

    const transformedResume =  {
        id: resume.id,
        userId: resume.user_id,
        templateId: resume.template_id,
        title: resume.title,
        resumeImage: resume.screenshot,
        jobPosition: resume.job_title,
        jobId: resume.job_id,
        firstName: resume.first_name,
        lastName: resume.last_name,
        email: resume.email,
        phoneNumber: resume.phone,
        country: resume.country,
        city: resume.city,
        adress: resume.address,
        postalCode: resume.postcode,
        nationality: resume.nationality,
        birthPlace: resume.birth_place, 
        birthDate: resume.birth_date,
        drivingLicense: resume.driver_license,
        drivingLicenseType: resume.driver_license_types,
        careerSummary: resume.summary,
        workExperienceItems: transformedEmployments,
        educationFields: transformedEducations,
        websiteSocialFields: transformedSocials,
        skillsFields: transformedSkills,
        referencesFields: transformedReferences,
        languagesFields: transformedLanguages,
        internshipFields: transformedInternships,
        coursesFields: transformedCourses,
        customFields: transformedActivities,
        userImage: resume.user_img,
        skillsVisibility: resume.skills_visibility,
        referencesVisibility: resume.references_visibility,
        careerSummaryTitle: resume.summary_title,
        customSectionTitle: resume.custom_title,
        experienceSectionTitle: resume.experience_title,
        educationSectionTitle: resume.education_title,
        socialSectionTitle: resume.social_title,
        skillSectionTitle: resume.skill_title,
        referenceSectionTitle: resume.reference_title,
        courseSectionTitle: resume.course_title,
        internshipSectionTitle: resume.internship_title,
        languageSectionTitle: resume.language_title,
        socials: resume.links,
        configuration: resume.configuration,
    };

    return transformedResume;
}

export const postTransformedResume = (resume) => {
    let transformedEducations = [];
    if (resume.educationFields && resume.educationFields.length) {
        transformedEducations = resume.educationFields.map(education => {
            let transformedItem = {
                degree: education.degree,
                institution: education.institution,
                city: education.city,
                start_date: education.startDate,
                start_date_month_hidden: education.startDateMonthHidden,
                end_date: education.endDate,
                end_date_month_hidden: education.endDateMonthHidden,
                present_date: education.presentDate,
                summary: education.description
            }
            return transformedItem;
        });
    }

    let transformedEmployments = [];
    if (resume.workExperienceItems && resume.workExperienceItems.length) {
        transformedEmployments = resume.workExperienceItems.map(employment => {
            let transformedItem = {
                job_title: employment.jobTitle,
                company: employment.company,
                start_date: employment.startDate,
                start_date_month_hidden: employment.startDateMonthHidden,
                end_date: employment.endDate,
                end_date_month_hidden: employment.endDateMonthHidden,
                city: employment.city,
                present_date: employment.presentDate,
                summary: employment.description,
            }
            return transformedItem;
        });
    }

    let transformedInternships = [];
    if (resume.internshipFields && resume.internshipFields.length) {
        transformedInternships = resume.internshipFields.map(internship => {
            let transformedItem = {
                job_title: internship.jobTitle,
                employer: internship.employer,
                start_date: internship.startDate,
                start_date_month_hidden: internship.startDateMonthHidden,
                end_date: internship.endDate,
                end_date_month_hidden: internship.endDateMonthHidden,
                city: internship.city,
                present_date: internship.presentDate,
                summary: internship.description
            }
            return transformedItem;
        });
    }

    let transformedCourses = [];
    if (resume.coursesFields && resume.coursesFields.length) {
        transformedCourses = resume.coursesFields.map(course => {
            let transformedItem = {
                course: course.course,
                institution: course.institution,
                start_date: course.startDate,
                start_date_month_hidden: course.startDateMonthHidden,
                end_date: course.endDate,
                end_date_month_hidden: course.endDateMonthHidden,
                present_date: course.presentDate,
            }
            return transformedItem;
        });
    }

    let transformedActivities = [];
    if (resume.customFields && resume.customFields.length) {
        transformedActivities = resume.customFields.map(activity => {
            let transformedItem = {
                title: '',
                activity: activity.customItemTitle,
                start_date: activity.startDate,
                start_date_month_hidden: activity.startDateMonthHidden,
                end_date: activity.endDate,
                end_date_month_hidden: activity.endDateMonthHidden,
                present_date: activity.presentDate,
                city: activity.city,
                description: activity.description,
            }
            return transformedItem;
        });
    }

    const transformedResume = {
        id: resume.id,
        user_id: resume.userId,
        template_id: resume.templateId,
        title: resume.title,
        image: resume.avatar,
        job_title: resume.jobPosition,
        job_id: resume.jobId,
        first_name: resume.firstName,
        last_name: resume.lastName,
        email: resume.email,
        phone: resume.phoneNumber,
        country: resume.country,
        city: resume.city,
        address: resume.adress,
        postcode: resume.postalCode,
        nationality: resume.nationality,
        birth_place: resume.birthPlace, 
        birth_date: resume.birthDate,
        driver_license: resume.drivingLicense,
        driver_license_types: resume.drivingLicenseType,
        summary: resume.careerSummary,
        employments: transformedEmployments,
        educations: transformedEducations,
        socials: resume.websiteSocialFields,
        skills: resume.skillsFields,
        references: resume.referencesFields,
        languages: resume.languagesFields,
        internships: transformedInternships,
        courses: transformedCourses,
        activities: transformedActivities,
        user_img: resume.userImage,
        screenshot: resume.resumeImage,
        skills_visibility: resume.skillsVisibility,
        references_visibility: resume.referencesVisibility,
        summary_title: resume.careerSummaryTitle,
        custom_title: resume.customSectionTitle,
        experience_title: resume.experienceSectionTitle,
        education_title: resume.educationSectionTitle,
        social_title: resume.socialSectionTitle,
        skill_title: resume.skillSectionTitle,
        reference_title: resume.referenceSectionTitle,
        course_title: resume.courseSectionTitle,
        internship_title: resume.internshipSectionTitle,
        language_title: resume.languageSectionTitle,
        links: resume.socials,
    };

    return transformedResume;
}

export const postTransformedCoverLetter = (coverLetter) => {
    const transformedCoverLetter = {
        id: coverLetter.id,
        job_title: coverLetter.jobPosition,
        title: coverLetter.title,
        first_name: coverLetter.firstName,
        last_name: coverLetter.lastName,
        email: coverLetter.email,
        phone: coverLetter.phoneNumber,
        company_name: coverLetter.companyName,
        manager_name: coverLetter.managerName,
        details: coverLetter.letterDetails,
        template_id: coverLetter.templateId,
        screenshot: coverLetter.coverLetterImage,
    };
    
    return transformedCoverLetter;
};
export const getTransformedCoverLetter = (coverLetter) => {
    const transformedCoverLetter = {
        id: coverLetter.id,
        jobPosition: coverLetter.job_title,
        title: coverLetter.title,
        firstName: coverLetter.first_name,
        lastName: coverLetter.last_name,
        email: coverLetter.email,
        phoneNumber: coverLetter.phone,
        companyName: coverLetter.company_name,
        managerName: coverLetter.manager_name,
        letterDetails: coverLetter.details,
        templateId: coverLetter.template_id,
        coverLetterImage: coverLetter.screenshot,
        configuration: coverLetter.configuration,
    };
    
    return transformedCoverLetter;
};