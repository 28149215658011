import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import {OutlinedInput, FormControl, InputLabel }  from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './Autocomplete.module.scss';

const GreenCheckbox = withStyles({
    root: {
      color: '#10b58c',
      '&$checked': {
        color: '#10b58c',
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: '.5rem',
        "& $notchedOutline": {
            borderColor: '#E3E8F1',
            top: '0',
        },
        "&:hover $notchedOutline": {
            borderColor: '#8190AA',
            borderWidth: '1px',
        },
        "&$focused $notchedOutline": {
            borderColor: '#677089',
            borderWidth: '1px',
            /* borderBottom: '1px solid #EEF1F8', */
            borderBottomRightRadius: 'none',
            borderBottomLeftRadius: 'none',
        },
        /* "&$focused fieldset": {
            borderRadius: '4px 4px 0 0',
        }, */
    },
    input: {
        padding: '17px 14px',
    },
    focused: {},
    notchedOutline: {},
    error: {
        borderColor: '#F03153 !important',
        "&:hover $notchedOutline": {
            borderColor: '#F03153',
        },
        "&$focused $notchedOutline": {
            borderColor: '#F03153',
        }
    }
}));

const useTextFieldStyles = makeStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            paddingTop: '6px',
            paddingBottom: '6px',
            "& fieldset": {
                borderColor: "#E3E8F1"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#8190AA",
                borderWidth: "1px"
            },
            "&:hover fieldset": {
                borderColor: "#8190AA",
                borderWidth: "1px"
            },
        }
    },
}));

const useAutocompleteStyles = makeStyles(theme => ({
    popper: {
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        marginTop: '-15px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        overflow: 'hidden',
    },
    paper: {
        border: '1px solid #677089',
        borderTop: '1px solid #EEF1F8',
        //borderTop: 'none',
        outline: 'none',
        boxShadow: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
    }
}));

const UIAutocomplete = (props) => {
    const outlinedInputClasses = useOutlinedInputStyles();
    const textFieldClasses = useTextFieldStyles();
    const autocompleteClasses = useAutocompleteStyles();

    return props.multiple ? (
        <Autocomplete
            multiple
            disabled={props.disabled}
            disableCloseOnSelect
            id={props.id}
            options={props.options}
            value={props.value}
            onChange={props.changed ? props.changed : () => {}}
            getOptionLabel={props.getOptionLabel}
            closeIcon=""
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <GreenCheckbox
                        checked={selected}
                    />
                    {option}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <>
                <InputLabel className={styles.Label} shrink >{props.label}</InputLabel>
                <TextField {...params} inputProps={{ ...params.inputProps, 'data-lpignore': "true",}} variant="outlined" classes={textFieldClasses} autoComplete="false" />
                </>
            )}
        />
    ) :
    (
        <Autocomplete
            freeSolo={props.freeSolo}
            value={props.value}
            inputValue={props.inputValue}
            onChange={props.changed ? props.changed : () => {}}
            id={props.id}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            renderOption={props.renderOption}
            filterOptions={props.filterOptions}
            onInputChange={props.inputChanged ? props.inputChanged : () => {}} //** on every input change hitting my api**
            classes={autocompleteClasses}
            disablePortal={true}
            renderInput={(params) => (
                <FormControl className={`${styles.FormControl} ${styles.SelectInput}`} variant="outlined" ref={params.InputProps.ref}>
                    <InputLabel className={styles.Label} shrink >{props.label}</InputLabel>
                    <OutlinedInput
                        {...params.inputProps}
                        classes={outlinedInputClasses}
                        inputProps={{ 'data-lpignore': "true", 'autoComplete' : "new-autocomplete"}}
                        type="text"
                        fullWidth
                    />
                </FormControl>
            )}
        />
    )
}

export default UIAutocomplete;