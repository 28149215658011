import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import styles from './LevelToggle.module.scss';

export default function ToggleButtons(props) {
/*   const [skillLevel, setSkillLevel] = React.useState('novice');

  const handleSkillLevel = (event, newSkillLevel) => {
    setSkillLevel(newSkillLevel);
  }; */

  let activeClass = '';
  switch (props.skillLevel) {
    case 'novice':
        activeClass = styles.Novice;
        break;
    case 'beginner':
        activeClass = styles.Beginner;
        break;
    case 'skillful':
        activeClass = styles.Skillful;
        break;
    case 'experienced':
        activeClass = styles.Experienced;
        break;
    case 'expert':
        activeClass = styles.Expert;
        break;
    default:
        break;
  }

  let disabledClass  = !props.levelVisibility ? styles.Disabled: '';

    return (
        <>
            <ToggleButtonGroup
                value={props.skillLevel}
                exclusive
                onChange={props.changed}
                aria-label="skill level"
                className={`${styles.SkillButtonWrap} ${activeClass} ${disabledClass}`}
            >
                <ToggleButton value="novice" aria-label="novice" className={styles.SkillButton}><span></span></ToggleButton>
                <ToggleButton value="beginner" aria-label="beginner" className={styles.SkillButton}><span></span></ToggleButton>
                <ToggleButton value="skillful" aria-label="skillful" className={styles.SkillButton}><span></span></ToggleButton>
                <ToggleButton value="experienced" aria-label="experienced" className={styles.SkillButton}><span></span></ToggleButton>
                <ToggleButton value="expert" aria-label="expert" className={styles.SkillButton}><span></span></ToggleButton>
            </ToggleButtonGroup>
            <p className={styles.Caption}>{props.skillLevel}</p>
        </>
    );
}