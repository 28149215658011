import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString, generateContactString } from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });

// Create Document Component
const MonospacedPdf = (props) => {

    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });


    //Font.registerHyphenationCallback(word => word.length === 1 ? [word] : Array.from(word).map(char => char));

    const mainColor = props.resumeConfiguration.primary_color || '#00796b';
    const additionalColor = props.resumeConfiguration.secondary_color || '#fff';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 27 * multiplier;
    const headerSubtitleSize = 16 * multiplier;
    const contactItemSize = 11.5 * multiplier;
    const sectionTitleSize = 18 * multiplier;
    const repeatedTitleSize = 15 * multiplier;

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
            position: 'relative',
        },
        table: {
            fontSize: mainSize,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
            /* marginBottom: 50, */
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        topBackground: {
            top: -20,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
        },
        headerBefore: {
            position: 'absolute',
            top: 0,
            height: 21,
            width: '100%',
            backgroundColor: mainColor,
        },
        header: {
            position: 'relative',
            justifyContent: "flex-start",
            paddingTop: 0,
            paddingBottom: 20,
            paddingHorizontal: 20,
            backgroundColor: mainColor,
            color: '#fff',
        },
        userImage: {
            width: '40mm',
            height: '40mm',
            objectFit: 'cover',
            borderRadius: '20mm',
            flexGrow: 0,
            marginLeft: 25,
        },
        title: {
            flexGrow: 1,
            fontSize: headerTitleSize,
            fontWeight: 700,
            marginBottom: 5,
        },
        subtitle: {
            fontSize: headerSubtitleSize,
            fontStyle: 'italic',
            marginBottom: 5,
        },
        contactLine: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        сontactColumnLeft: {
            flexGrow: 0,
            flexShrink: 1,
            width: '62%',
            paddingRight: 15,
        },
        сontactColumnRight: {
            flexGrow: 0,
            flexShrink: 1,
            width: '35%',
        },
        contactItem: {
            display: 'flex',
            flexDirection: 'row',
            /* flexGrow: 0,
            flexShrink: 1,
            width: '45%', */
            paddingRight: 10,
            marginVertical: 2,
            fontSize: contactItemSize,
        },
        contactLink: {
            color: '#fff',
            textDecoration: 'none',
            alignSelf: 'center',
        },
        contactTitle: {
            fontWeight: 700,
            marginRight: 6,
            color: additionalColor,
        },
        contactValue: {
            maxWidth: '100%',
        },
        main: {
            paddingTop: 20,
            paddingHorizontal: 20,
            lineHeight: 1.3,
        },
        sectionTitle: {
            color: mainColor,
            borderBottomColor: mainColor,
            borderBottomWidth: 1,
            borderTopColor: mainColor,
            borderTopWidth: 1,
            textAlign: 'center',
            fontSize: sectionTitleSize,
            letterSpacing: 1,
            lineHeight: 1.2,
            paddingVertical: 3,
            fontWeight: 700,
            marginBottom: 10,
            minHeight: 22,
        },
        sectionWrap: {
            marginBottom: 22,
        },
        repeatedItem: {
            marginBottom: 7,
        },
        repeatedItemTitle: {
            fontSize: repeatedTitleSize,
            lineHeight: 1.2,
            fontWeight: 700,
        },
        repeatedItemSubtitle: {
            fontWeight: 400,
        },
        repeatedItemContent: {
            flex: '0 0 73%',
            width: '73%',
        },
        dateCityWrap: {
            flex: '0 0 23%',
            width: '23%',
            marginLeft: 11,
            textAlign: 'right',
        },
        dateText: {
            color: mainColor,
        },
        link: {
            color: mainColor,
            textDecoration: 'none',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
        skillsRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        languagesRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        languagesItem: {
            width: '48%',
            position: 'relative',
            paddingLeft: 10,
        },
        skillsItem: {
            width: '48%',
            position: 'relative',
            paddingLeft: 10,
        },
        languageLevel: {
            fontStyle: 'italic',
            color: mainColor,
        },
        progressLine: {
            width: '100%',
            height: 10,
            marginTop: 5,
        },
        bullet: {
            width: 8,
            height: 8,
            marginTop: 5,
            marginRight: 7,
            position: 'absolute',
            top: 0,
            left: 0,
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 18,
            height: 18,
            marginRight: 6,
        },
        description: {
            marginVertical: 8,
        },
        list: {
            paddingLeft: 15,
        }
        /* careerSummary: {
            color: '#fff',
        }, */
        /* paragraph: {
            color: 'inherit'
        } */
    });

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const generateTinyMceElements = (content) => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, '#000', 'bullet'));
        }
    }

    const showUrl = (url) => {
        let croppedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        return croppedUrl;
    }

    const wordWrap = (str, maxWidth) => {
        let newLineStr = "\n";
        let res = '';
        while (str && str.length > maxWidth) {                 
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join('');
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join('');
                str = str.slice(maxWidth);
            }

        }

        return res + str;
    }

    const testWhite = (x) => {
        let white = new RegExp(/^\s$/);
        return white.test(x.charAt(0));
    };

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.contactLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.contactLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.contactLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.contactLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.contactLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.7, .7).fillColor(additionalColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.contactLink}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <View style={styles.sectionWrap}>
            <Text style={styles.sectionTitle}>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</Text>
            <View style={styles.skillsRow}>
                {props.resume.skillsFields.map((skillItem, index) => 
                    (skillItem.skill || skillItem.level) ? (<View key={skillItem.id} wrap={false} style={[styles.repeatedItem, styles.skillsItem, ((index + 1) % 4 === 0) ? styles.forthItem : null]}>
                        <Canvas paint={(painter) => {
                            painter.circle(4,4,2).fillColor(props.color).fill();
                        }} style={styles.bullet} />
                        {skillItem.skill ? (<Text style={styles.skillTitle}>{skillItem.skill}</Text>) : (<></>)}
                        {(skillItem.skill && skillItem.level && Boolean(Number(props.resume.skillsVisibility))) ? (
                            <Canvas
                                paint={(painter, availableWidth) => {
                                    let percentageValue = skillsPercentages[skillItem.level] * availableWidth / 100;
                                    painter.roundedRect(0, 0, availableWidth, 10, 5).lineWidth(1.5).strokeColor(mainColor).stroke();
                                    painter.roundedRect(0, 0, percentageValue, 10, 5).fillColor(mainColor).fill();
                                }}
                                style={styles.progressLine}
                            />
                        ) : (<></>)}
                    </View>) : (<></>)
                )}
            </View>
        </View>
    ) : <></>;

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.headerBefore}></View>
                <View style={styles.table}>
                    <View style={[styles.row, styles.header]}>
                        {addPdfPattern(props.resumeConfiguration.pattern, styles.topBackground)}
                        <View style={styles.headerText}>
                            <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                            {props.resume.jobPosition ? (<Text style={styles.subtitle}>{props.resume.jobPosition}</Text>) : (<></>)}

                            {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
                            props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
                            props.resume.city || props.resume.postalCode || props.resume.country || 
                            (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
                                <View style={styles.contactLine}>
                                    <View style={styles.сontactColumnLeft}>
                                        {props.resume.email ? (<View style={styles.contactItem}>
                                            <Canvas paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(additionalColor).path('M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z').fill();
                                            }} style={styles.svgIcon} />
                                            <Link src={`mailto:${props.resume.email}`} style={styles.contactLink}>{wordWrap(props.resume.email, 38)}</Link>
                                        </View>) : <></>}
                                        
                                        {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) ? (<View style={styles.contactItem}>
                                            <Canvas paint={(painter) => {
                                                    painter.scale(.7, .7).fillColor(additionalColor).path('M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z').fill();
                                                }} style={styles.svgIcon} />
                                            <Text style={styles.contactValue}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                                        </View>) : <></>}

                                        {props.resume.birthPlace ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Place:</Text> {props.resume.birthPlace}</Text>) : <></>}
                                        
                                        {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Driving license:</Text> {props.resume.drivingLicenseType.join(', ')}</Text>) : <></>}
                                    </View>
                                    <View style={styles.сontactColumnRight}>
                                        {props.resume.phoneNumber ? (<View style={styles.contactItem}>
                                            <Canvas paint={(painter) => {
                                                    painter.scale(.7, .7).fillColor(additionalColor).path('M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z').fill();
                                                }} style={styles.svgIcon} />
                                            <Text style={styles.contactValue}>{props.resume.phoneNumber}</Text>
                                        </View>) : <></>}

                                        {props.resume.nationality ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Nationality:</Text> {props.resume.nationality}</Text>) : <></>}
                                        
                                        {props.resume.birthDate ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Date:</Text> {moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                                    </View>
                                </View>
                            ) : <></>}

                            {props.resume.websiteSocialFields.map((socialItem, index) => (
                                (socialItem.name || socialItem.link) ? (
                                    <View key={index} style={styles.contactItem}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.7, .7).fillColor(additionalColor).path('M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        <Link href={socialItem.link} style={styles.contactLink}>{socialItem.name ? socialItem.name : socialItem.link}</Link>
                                    </View>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}

                        </View>
                        
                        {props.resume.userImage ? (
                            <Image 
                                style={styles.userImage} 
                                src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            />
                        ) : <></>}
                    </View>
                    
                    <View style={styles.main}>
                        
                        {props.resume.careerSummary  ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.careerSummaryTitle ? (<Text style={styles.sectionTitle}>{props.resume.careerSummaryTitle}</Text>) : (<></>)} 
                                <View style={styles.description}>{generateTinyMceElements(props.resume.careerSummary)}</View>
                            </View>
                        ) : <></>}

                        {resumeType === 'functional' ? skillsSection : <></>}

                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Professional Experience'}</Text> 
                                {props.resume.workExperienceItems.map(workItem => (
                                    <View style={[styles.repeatedItem, styles.flexRow]} key={workItem.id}>
                                        <View style={(workItem.startDate || workItem.endDate || workItem.presentDate || workItem.city) ? styles.repeatedItemContent : null}>
                                            {(workItem.jobTitle || workItem.company) ? (<Text style={styles.repeatedItemTitle}>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ', ' : ''} {workItem.company ? (<Text style={styles.repeatedItemSubtitle}>{workItem.company}</Text>) : (<></>)}</Text>) : (<></>)}
                                            {workItem.description ? (<View style={styles.description}>{generateTinyMceElements(workItem.description)}</View>) : <></> }
                                        </View>
                                        {(workItem.startDate || workItem.endDate || workItem.presentDate || workItem.city) ? 
                                            (<View style={styles.dateCityWrap}>
                                                {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY")}</Text>) : (<></>)}
                                                {workItem.city ? (<Text style={styles.location}>{workItem.city}</Text>) : (<></>)}
                                            </View>) : (<></>)}
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</Text>
                                {props.resume.educationFields.map(educationItem => (
                                    <View style={[styles.repeatedItem, styles.flexRow]} key={educationItem.id}>
                                        <View style={(educationItem.startDate || educationItem.endDate || educationItem.presentDate || educationItem.city) ? styles.repeatedItemContent : null}>
                                            {(educationItem.degree || educationItem.institution) ? (<Text style={styles.repeatedItemTitle}>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ', ' : ''} {educationItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{educationItem.institution}</Text>) : (<></>)}</Text>) : (<></>)}
                                            {educationItem.description ? (<View style={styles.description}>{generateTinyMceElements(educationItem.description)}</View>) : <></> }
                                        </View>
                                        {(educationItem.startDate || educationItem.endDate || educationItem.presentDate || educationItem.city) ? 
                                            (<View style={styles.dateCityWrap}>
                                                {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY")}</Text>) : (<></>)}
                                                {educationItem.city ? (<Text style={styles.location}>{educationItem.city}</Text>) : (<></>)}
                                            </View>) : (<></>)}
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</Text>
                                {props.resume.internshipFields.map(internshipItem => (
                                    <View style={[styles.repeatedItem, styles.flexRow]} key={internshipItem.id}>
                                        <View style={(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate || internshipItem.city) ? styles.repeatedItemContent : null}>
                                            {(internshipItem.jobTitle || internshipItem.employer) ? (<Text style={styles.repeatedItemTitle}>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ', ' : ''} {internshipItem.employer ? (<Text style={styles.repeatedItemSubtitle}>{internshipItem.employer}</Text>) : (<></>)}</Text>) : (<></>)}
                                            {internshipItem.description ? (<View style={styles.description}>{generateTinyMceElements(internshipItem.description)}</View>) : <></> }
                                        </View>
                                        {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate || internshipItem.city) ? 
                                            (<View style={styles.dateCityWrap}>
                                                {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY")}</Text>) : (<></>)}
                                                {internshipItem.city ? (<Text style={styles.location}>{internshipItem.city}</Text>) : (<></>)}
                                            </View>) : (<></>)}
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</Text>
                                {props.resume.coursesFields.map(courseItem => (
                                    <View style={[styles.repeatedItem, styles.flexRow]} key={courseItem.id}>
                                        <View style={(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? styles.repeatedItemContent : null}>
                                            {(courseItem.course || courseItem.institution) ? (<Text style={styles.repeatedItemTitle}>{courseItem.course}{(courseItem.course && courseItem.institution) ? ', ' : ''} {courseItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{courseItem.institution}</Text>) : (<></>)}</Text>) : (<></>)}
                                            {courseItem.description ? (<View style={styles.description}>{generateTinyMceElements(courseItem.description)}</View>) : <></> }
                                        </View>
                                        {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? 
                                            (<View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                            </View>) : (<></>)}
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</Text>
                                {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                    props.resume.referencesFields.map(referenceItem => (
                                        <View style={styles.repeatedItem} key={referenceItem.id}>
                                            {(referenceItem.referent || referenceItem.company) ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ', ' : ''}{referenceItem.company}</Text>) : (<></>)}
                                            {(referenceItem.email || referenceItem.phone) ? (<Text><Link style={styles.link} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}</Text>) : (<></>)}
                                            {referenceItem.description ? (<View style={styles.description}>{generateTinyMceElements(referenceItem.description)}</View>) : <></> }
                                        </View>
                                    )) : (<Text>References available upon request</Text>)}
                            </View>
                        ) : <></>}

                        {resumeType === 'chronological' ? skillsSection : <></>}

                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</Text>
                                <View style={[styles.flexRow, styles.languagesRow]}>
                                    {props.resume.languagesFields.map((languageItem, index) => 
                                        (languageItem.language || languageItem.languageLevel) ? (<View key={index} style={[styles.repeatedItem, styles.languagesItem, ((index + 1) % 4 === 0) ? styles.forthItem : null]} wrap={false}>
                                            <Canvas paint={(painter) => {
                                                painter.circle(4,4,2).fillColor(props.color).fill();
                                            }} style={styles.bullet} />
                                            {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                            {languageItem.level ? (<Text style={styles.languageLevel}>{languageItem.level}</Text>) : (<></>)}
                                        </View>) : (<></>)
                                    )}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                <Text style={styles.sectionTitle}>{props.resume.customSectionTitle ? props.resume.customSectionTitle : ''}</Text>
                                {props.resume.customFields.map(customItem => (
                                    <View style={[styles.repeatedItem, styles.flexRow]} key={customItem.id}>
                                        <View style={(customItem.startDate || customItem.endDate || customItem.presentDate || customItem.city) ? styles.repeatedItemContent : null}>
                                            {customItem.customItemTitle ? (<Text style={styles.repeatedItemTitle}>{customItem.customItemTitle}</Text>) : (<></>)}
                                            {customItem.description ? (<View style={styles.description}>{generateTinyMceElements(customItem.description)}</View>) : <></> }
                                        </View>
                                        {(customItem.startDate || customItem.endDate || customItem.presentDate || customItem.city) ? 
                                            (<View style={styles.dateCityWrap}>
                                                {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (<Text style={styles.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY")}</Text>) : (<></>)}
                                                {customItem.city ? (<Text style={styles.location}>{customItem.city}</Text>) : (<></>)}                                        
                                            </View>) : (<></>)}
                                    </View>
                                ))}
                            </View>
                        ) : <></>}
                    
                    </View>

                </View>
            </Page>
        </Document>
    );
}

export default MonospacedPdf;