import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import axios from '../../axios-resumes';

import { getTransformedResume } from '../../shared/utility';

import { Container, Grid, Paper, IconButton, Link, Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import Loader from '../../components/UI/Loader/Loader';

import AutosizeInput from 'react-input-autosize';

import BuilderToolbar from '../../components/BuilderToolbar/BuilderToolbar';

import EyeIcon from '../../components/UI/Icons/EyeIcon';

import styles from './ResumeBuilder.module.scss';

import editIcon from '../../assets/images/edit_icon.svg';

import GeneralInfoSection from './GeneralInfoSection';
import AdditionalSection from './AdditionalSection';
import CareerSummarySection from './CareerSummarySection';
import WorkExperienceSection from './WorkExperienceSection';
import EducationSection from './EducationSection';
import WebsiteSocialSection from './WebsiteSocialSection';
import SkillsSection from './SkillsSection';

import ReferencesSection from './ReferencesSection';
import LanguagesSection from './LanguagesSection';
import InternshipSection from './InternshipSection';
import CoursesSection from './CoursesSection';
import CustomSection from './CustomSection';

import ResumeResult from './ResumeResult';
import TemplatesSwitcher from './TemplatesSwitcher';

import languagesIcon from '../../assets/images/languages_icon.svg';
import experinceIcon from '../../assets/images/experience_icon.svg';
import referencesIcon from '../../assets/images/references_icon.svg';
import extrasIcon from '../../assets/images/extras_icon.svg';
import coursesIcon from '../../assets/images/courses_icon.svg';

import useScrollHandler from '../../shared/useScrollHandler';

import * as actions from '../../store/actions/index';


const scrollToRef = (ref) => {
    if (ref.current) {
        window.scrollTo(0, ref.current.offsetTop - 100);
    }
};

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;
  
    return {
      height,
      offsetTop,
      offsetBottom,
    };
};

function ResumeBuilder(props) {
    const [extraSections, setExtraSections] = useState({
        referencesFields: {
            open: false,
            label: 'References',
            icon: referencesIcon,
        },
        languagesFields: {
            open: false,
            label: 'Languages',
            icon: languagesIcon
        },
        internshipFields: {
            open: false,
            label: 'Internship',
            icon: experinceIcon,
        },
        coursesFields: {
            open: false,
            label: 'Courses',
            icon: coursesIcon,
        },
        customFields: {
            open: false,
            label: 'Custom Section',
            icon: extrasIcon
        },
    });

    const [resumeTitle, setResumeTitle] = useState('Untitled resume 1');

    const [mobileShowPreview, setMobileShowPreview] = useState(false);
    const handleMobileShowPreview = () => {
		setMobileShowPreview(!mobileShowPreview);
    };
    
    const [previewButtonShow, setPreviewButtonShow] = useState(false);
    const handlePreviewButtonShow = () => {
		setPreviewButtonShow(!previewButtonShow);
    };    

    const [resume, setResume] = useState({});

    const [dataLoaded, setDataLoaded] = useState(false);
    const [exampleLoaded, setExampleLoaded] = useState(false);

    const [switchTemplateOpen, setSwitchTemplateOpen] = useState(false);

    const [visibleSection, setVisibleSection] = useState();

    const generalRef = useRef(null);
    const additionalRef = useRef(null);
    const careerSummaryRef = useRef(null);
    const workExperienceRef = useRef(null);
    const educationRef = useRef(null);
    const websiteSocialRef = useRef(null);
    const skillsRef = useRef(null);
    const referencesRef = useRef(null);
    const languagesRef = useRef(null);
    const internshipRef = useRef(null);
    const coursesRef = useRef(null);
    const customRef = useRef(null);
    const additionalSectionsRef = useRef(null);
         
    const sectionRefs = [
        { section: "General", ref: generalRef },
        { section: "Additional", ref: additionalRef },
        { section: "Career Summary", ref: careerSummaryRef },
        { section: "Work Experience", ref: workExperienceRef },
        { section: "Education", ref: educationRef },
        { section: "Website Socials", ref: websiteSocialRef },
        { section: "Skills", ref: skillsRef },
        { section: "References", ref: referencesRef },
        { section: "Languages", ref: languagesRef },
        { section: "Internship", ref: internshipRef },
        { section: "Courses", ref: coursesRef },
        { section: "Custom", ref: customRef },
    ];

    const [deleteSectionDialog, setDeleteSectionDialog] = React.useState({open: false, deletedSection: ''});

    const handleOpenDeleteSectionDialog = (sectionName) => {
        setDeleteSectionDialog({...deleteSectionDialog, open: true, deletedSection: sectionName});
    };
    
    const handleCloseDeleteSectionDialog = () => {
        setDeleteSectionDialog({...deleteSectionDialog, open: false, deletedSection: ''});
    };

    const executeScroll = (ref) => scrollToRef(ref);

    useEffect(() => {
        const handleScroll = () => {
            //const scrollPosition = window.scrollY + 60;
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    let isFocused = scrollPosition > offsetTop && scrollPosition < offsetBottom;
                    return isFocused;
                }
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }

            sectionRefs.forEach(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    let isFocused = scrollPosition > offsetTop && scrollPosition < offsetBottom;
                    if (isFocused) {
                        ele.style.opacity = '1';
                    } else {
                        ele.style.opacity = '.5';
                    }
                }
            });
            
            if (dataLoaded && window.scrollY == 0) {
                sectionRefs[0].ref.current.style.opacity = '1';
                sectionRefs[1].ref.current.style.opacity = '.5';
            }

        };

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        
    }, [visibleSection]);

    const scroll = useScrollHandler();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    
    const resumeId = query.get("resumeId");

    const sampleId = query.get("id");
    const templateSampleId = query.get("templateId");

    //const sharedLink = CryptoAES.encrypt(resumeId, 'secret key 123');
    const sharedLink = btoa(resumeId);

    const [copySuccess, setCopySuccess] = useState('');
    const sharedLinkRef = useRef(null);

    const copyToClipboard = (e) => {
        sharedLinkRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('Copied!');
    };

    const resumes =  props.resumes.length ? props.resumes : JSON.parse(localStorage.getItem('resumes'));

    const [resumeCreated, setResumeCreated] = useState(false);
    useEffect(() => {
        if (props.createdResumeId !== '') {
            setResumeCreated(true);
        }
    }, [props.createdResumeId]);

    useLayoutEffect(() => {
        /* if (resumeId && resumes) {
            let resume = resumes.find(resume => resume.id == resumeId);
            if (resume) {
                setResume(resume);
                setResumeTitle(resume.title);
                console.log('Resume Builder Mounted');
            }
        } */

        if (sampleId) {
            axios.get( `/sample/resume/${sampleId}`)
            .then( response => {
                let resume = getTransformedResume(response.data.resume);
                if (resume) {
                    if (templateSampleId) {
                        resume.templateId = templateSampleId;
                    }
                    setResume(resume);
                    setResumeTitle(resume.title);
                    console.log('Resume Builder Mounted');
                    setDataLoaded(true);
                }
            } )
            .catch( err => {
                console.log(err);
            } );
        } else {
            axios.get( `/resume/${resumeId}`, { headers: {
                'Authorization': `Bearer ${props.token}`
            }})
            .then( response => {
                let resume = getTransformedResume(response.data.resume);
                if (resume) {
                    setResume(resume);
                    setResumeTitle(resume.title);
                    console.log('Resume Builder Mounted');
                    setDataLoaded(true);
                }
            } )
            .catch( err => {
                console.log(err);
            } );
        }


        if (props.location.state && props.location.state.signUpMessage) {
            setMessageOpen(true);
        }

    }, [] );

    
    useEffect(() => {
        
        if (extraSections) {
            Object.keys(extraSections).forEach(
                (key) => {
                    if (resume && resume[key] &&  resume[key].length > 0) {
                        extraSections[key] = {
                            ...extraSections[key],
                            open: true
                        };
                    }
                }
            );
            setExtraSections({ ...extraSections });
        }
        
        /* if (dataLoaded) {
            props.onEditResume(resume, props.token, resumeId);
        } */

        /* if (dataLoaded && !resumeCreated) {
            props.onCreateResume({
                ...resume,
                userId: props.userId,
            }, props.token);
        }
 */
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && resumeId) {
                props.onEditResume(resume, props.token, resumeId);
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);

    }, [resume] );

    const handleResumeTitleChange = (event) => {
        setResumeTitle(event.target.value);
        let updatedResume = {
            ...resume,
            title: event.target.value,
        }
        setResume(updatedResume);
    };

    const resumeInfoChange = (updatedValues) => {
        let updatedResume = {
            ...resume,
            ...updatedValues,
        }
        setResume(updatedResume);
    }

    /* let successMessage = null;
    if (props.created && !isEditing) {
        successMessage = (
            <Alert severity="success">Resume Successfully Created</Alert>
        );
    } */

    const focusSectionHandler = (sectionTitle) => {
        sectionRefs.forEach(({ section, ref }) => {
            const ele = ref.current;
            if (ele) {
                if (section == sectionTitle) {
                    ele.style.opacity = '1';
                } else {
                    ele.style.opacity = '.5';
                }
            }
        });
    }

    const addExtraSection = (key) => {
        const updatedExtraSections = {
            ...extraSections,
            [key]: {
                ...extraSections[key],
                open: true
            }
        };
        setExtraSections({ ...updatedExtraSections });

        const selectedRef = sectionRefs.find(({ section, ref }) => section == extraSections[key].label);
        if (selectedRef) {
            executeScroll(selectedRef.ref);
        }
    }

    const deleteExtraSection = (key) => {
        handleCloseDeleteSectionDialog();
        const updatedExtraSections = {
            ...extraSections,
            [key]: {
                ...extraSections[key],
                open: false
            }
        };
        setExtraSections({ ...updatedExtraSections });

        let updatedResume = {
            ...resume,
            [key]: {},
        }; 
        setResume(updatedResume);
    }

    const hangleResumeProgressScrollTo = (sectionRef) => {
        if (sectionRef === "Photo") {
            window.scrollTo(0, 0);
        } else if (sectionRef == "Courses"){
            if (extraSections.coursesFields.open) {
                executeScroll(coursesRef);
            } else {
                executeScroll(additionalSectionsRef);
            }
        } else {
            const selectedRef = sectionRefs.find(({ section, ref }) => section == sectionRef);
            if (selectedRef) {
                executeScroll(selectedRef.ref);
            }
        }
    };

    const resumeTitleInput = useRef(null); 

    const editResumeTitle = () => {
        resumeTitleInput.current.focus();
        resumeTitleInput.current.select();
    }

    // Extra Section Items
    let extraSectionItems = null;
    if (extraSections) {
        extraSectionItems = Object.keys(extraSections).map(
            (key) => (
                <Grid item xs={12} sm={6} key={key}>
                    <Paper className={`${styles.ExtraFormSection} ${extraSections[key].open ? styles.Opened : ''}`} onClick={() => addExtraSection(key)}>
                        <img src={extraSections[key].icon} alt="criteria icon"/>
                        <span className={styles.CriteriaLabel}>{extraSections[key].label}</span>
                    </Paper>
                </Grid>
            )
        );
    }

    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const handleOpenShareDialog = (link) => {
        setShareDialogOpen(true);
    };
    
    const handleCloseShareDialog = () => {
        setShareDialogOpen(false);
    };


    const [messageOpen, setMessageOpen] = React.useState(false);
    /* useEffect(() => {
        if (props.location.state.signUpMessage) {
            setMessageOpen(true);
        }
    }, [props.location.state.signUpMessage] ); */

    return (
        <>
            <Helmet>
                <title>Resume Builder - ResumeOK</title>
            </Helmet>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={messageOpen}
                autoHideDuration={5000}
                onClose={() => setMessageOpen(false)}
            >
                <Alert severity="success">{props.location.state && props.location.state.signUpMessage}</Alert>
            </Snackbar>
            <BuilderToolbar
                showTemplatesHandler={() => {setMobileShowPreview(false); setSwitchTemplateOpen(true);}}
                document={resume}
                documentId={resumeId}
                scrollToProgressHandler={hangleResumeProgressScrollTo}
            />
            <Container component="main" className={styles.Wrapper}> 
                <div className={`${styles.ResumeForm} ${!scroll ? styles.Scrolled : ''}  ${switchTemplateOpen ? styles.Hidden : ''} ${mobileShowPreview ? styles.MobileHidden : ''}`} >
                { dataLoaded ? (<>
                    <form className={styles.Form} /* autoComplete="off" */ autoComplete="disabled">
                        <Grid container spacing={1} alignItems="flex-end" justify="center">
                            <Grid item>
                                <AutosizeInput
                                    name="resume-title"
                                    value={resumeTitle || ''}
                                    placeholder="Untitled resume 1"
                                    onChange={handleResumeTitleChange}
                                    className={styles.TitleInput}
                                    ref={resumeTitleInput}
                                    data-lpignore="true"
                                />
                            </Grid>
                            <Grid item>
                                <img src={editIcon} alt="edit icon" onClick={editResumeTitle}/>
                            </Grid>
                        </Grid>
                    
                        <div 
                            ref={generalRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("General")} 
                            onMouseEnter={() => focusSectionHandler("General")}
                            style={{opacity: '1'}}
                        >
                            <GeneralInfoSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded}/>
                        </div>

                        <div 
                            ref={additionalRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Additional")}
                            onMouseEnter={() => focusSectionHandler("Additional")}
                        >
                            <AdditionalSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} loadResumeSampleHandler={() => setExampleLoaded(true)}/>
                        </div>

                        <div 
                            ref={careerSummaryRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Career Summary")}
                            onMouseEnter={() => focusSectionHandler("Career Summary")}
                        >
                            <CareerSummarySection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />
                        </div>    
                                            
                        <div 
                            ref={workExperienceRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Work Experience")}
                            onMouseEnter={() => focusSectionHandler("Work Experience")}
                        >
                            <WorkExperienceSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />                        
                        </div>

                        <div 
                            ref={educationRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Education")}
                            onMouseEnter={() => focusSectionHandler("Education")}
                        >
                            <EducationSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />                        
                        </div>
                                                                    
                        <div 
                            ref={websiteSocialRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Website Socials")}
                            onMouseEnter={() => focusSectionHandler("Website Socials")}
                        >
                            <WebsiteSocialSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />
                        </div>
                                
                        <div 
                            ref={skillsRef} 
                            className={styles.FormSectionWrap} 
                            onClick={() => focusSectionHandler("Skills")}
                            onMouseEnter={() => focusSectionHandler("Skills")}
                        >
                            <SkillsSection onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />
                        </div>

                        <div 
                            ref={referencesRef} 
                            className={extraSections.referencesFields.open ? styles.FormSectionWrap : ''} 
                            onClick={() => focusSectionHandler("References")} 
                            onMouseEnter={() => focusSectionHandler("References")}
                        >
                            {extraSections.referencesFields.open ? <ReferencesSection deleted={() => handleOpenDeleteSectionDialog('referencesFields')} onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} />: null}
                        </div>

                        <div 
                            ref={languagesRef} 
                            className={extraSections.languagesFields.open ? styles.FormSectionWrap : ''} 
                            onClick={() => focusSectionHandler("Languages")}
                            onMouseEnter={() => focusSectionHandler("Languages")}
                        >
                            {extraSections.languagesFields.open ? <LanguagesSection deleted={() => handleOpenDeleteSectionDialog('languagesFields')} onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} /> : null}
                        </div>

                        <div 
                            ref={internshipRef} 
                            className={extraSections.internshipFields.open ? styles.FormSectionWrap : ''} 
                            onClick={() => focusSectionHandler("Internship")} 
                            onMouseEnter={() => focusSectionHandler("Internship")}
                        >
                            {extraSections.internshipFields.open ? <InternshipSection deleted={() => handleOpenDeleteSectionDialog('internshipFields')} onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} /> : null}
                        </div>
                        
                        <div 
                            ref={coursesRef} 
                            className={extraSections.coursesFields.open ? styles.FormSectionWrap : ''} 
                            onClick={() => focusSectionHandler("Courses")} 
                            onMouseEnter={() => focusSectionHandler("Courses")}
                        >
                            {extraSections.coursesFields.open ? <CoursesSection deleted={() => handleOpenDeleteSectionDialog('coursesFields')} onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} /> : null}
                        </div>

                        <div 
                            ref={customRef} 
                            className={extraSections.customFields.open ? styles.FormSectionWrap : ''} 
                            onClick={() => focusSectionHandler("Custom")} 
                            onMouseEnter={() => focusSectionHandler("Custom")}
                        >
                            {extraSections.customFields.open ? <CustomSection deleted={() => handleOpenDeleteSectionDialog('customFields')} onChange={resumeInfoChange} resume={resume} resumeId={resumeId} exampleLoaded={exampleLoaded} /> : null}
                        </div>

                    
                        <div className={styles.PanelTitle} ref={additionalSectionsRef}>
                            <h4>Extra sections</h4>
                            <Tooltip tooltipContent="Include additional sections to your resume by choosing another module from the list below." />
                        </div>

                        <Grid container spacing={1} style={{marginBottom: '40px'}}>
                            {extraSectionItems}
                        </Grid>
                    </form>

                    <Button
                        variant="contained"
                        color="primary"
                        clicked={handleMobileShowPreview}
                        className={`${styles.Button} ${styles.MobileCtaButton} ${previewButtonShow ? styles.Visible : ''}`}
                    >
                        Preview
                    </Button>

                    <IconButton className={`${styles.CircleButton} ${previewButtonShow ? styles.Hidden : ''}`} onClick={handlePreviewButtonShow}><EyeIcon viewBox="0 0 21 15" /></IconButton>
                </>
                ) : <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}><Loader /></div>
            }
            </div>    

            <TemplatesSwitcher 
                onChange={resumeInfoChange} 
                templateId={resume.templateId} 
                onClose={() => setSwitchTemplateOpen(false)}
                open={switchTemplateOpen}
            />

            <ResumeResult 
                fixed={!scroll}
                visible={mobileShowPreview}
                closeHandler={handleMobileShowPreview}
                showTemplatesHandler={() => {setMobileShowPreview(false); setSwitchTemplateOpen(true);}}
                showShareLinkHandler={() => handleOpenShareDialog()}
                resume={resume} 
                resumeId={resumeId}
            />

            </Container>

            <Dialog
                open={deleteSectionDialog.open}
                closed={handleCloseDeleteSectionDialog}
            >
                <h2>Delete Section</h2>
                <p>Are you sure you want to delete this section?</p>   
                    <Button
                        variant="contained"
                        color="primary"
                        clicked={() => deleteExtraSection(deleteSectionDialog.deletedSection)}
                        className={styles.Button}
                    >
                        Delete
                    </Button>
                <Link onClick={handleCloseDeleteSectionDialog} className={styles.Link}>Cancel</Link>
            </Dialog>

            
            <Dialog
                open={shareDialogOpen}
                closed={handleCloseShareDialog}
                maxWidth="sm"
                className={styles.ShareDialog}
            >
                <h2>Share a Link to Your Resume</h2>
                <p>Share this link on social media or copy and paste the URL to send your resume via text, email or to share your resume on your personal website.</p>   
   
                <input
                    readOnly={true}
                    value={`${document.location.origin}/r/${sharedLink}`}
                    ref={sharedLinkRef}
                    className={styles.LinkInput}
                />   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={copyToClipboard}
                    className={styles.Button}
                >
                    Copy
                </Button>
                {/* <p className={styles.Status}>{copySuccess}</p> */}
            </Dialog>
        </>
        )
    }

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        created: state.resume.created,
        resumes: state.resume.resumes,
        createdResumeId: state.resume.createdResumeId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreateResume: ( resumeData, token ) => dispatch( actions.createResume( resumeData, token ) ),
        onEditResume: ( resumeData, token, resumeId ) => dispatch( actions.editResume( resumeData, token, resumeId ) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ResumeBuilder );