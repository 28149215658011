import * as actionTypes from './actionTypes';
import axios from '../../axios-resumes';
import { getTransformedCoverLetter, postTransformedCoverLetter } from '../../shared/utility';

export const createCoverLetterStart = () => {
    return {
        type: actionTypes.CREATE_COVER_LETTER_START
    };
};

export const createCoverLetterSuccess = ( coverLetterData ) => {
    return {
        type: actionTypes.CREATE_COVER_LETTER_SUCCESS,
        coverLetterData: coverLetterData
    };
};

export const createCoverLetterFail = ( error ) => {
    return {
        type: actionTypes.CREATE_COVER_LETTER_FAIL,
        error: error
    };
}

export const createCoverLetter = ( coverLetterData, token ) => {
    return dispatch => {
        dispatch( createCoverLetterStart() );
        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.post( '/cover-letters', postTransformedCoverLetter(coverLetterData), {headers: headers} )
            .then( response => {
                dispatch( createCoverLetterSuccess( getTransformedCoverLetter(response.data.letter) ) );
            } )
            .catch( error => {
                dispatch( createCoverLetterFail( error ) );
            } );
    };
};



export const editCoverLetterSuccess = ( coverLetterData ) => {
    return {
        type: actionTypes.EDIT_COVER_LETTER_SUCCESS,
        coverLetterData: coverLetterData
    };
};


export const editCoverLetter = ( coverLetterData, token, coverLetterId ) => {
    return dispatch => {
        dispatch( createCoverLetterStart() );

        const headers = {
            Authorization: `Bearer ${token}`
        }
        
        axios.put( `/cover-letters/${coverLetterId}`, postTransformedCoverLetter(coverLetterData), {headers: headers})
            .then( response => {
                /* console.log( response.data ); */
                dispatch( editCoverLetterSuccess( getTransformedCoverLetter(coverLetterData) ) );
                //dispatch( fetchCoverLetters(token, response.data.resume.user_id) );
            } )
            .catch( error => {
                dispatch( createCoverLetterFail( error ) );
            } );
    };
};

export const fetchCoverLettersSuccess = ( coverLetters ) => {
    return {
        type: actionTypes.FETCH_COVER_LETTERS_SUCCESS,
        coverLetters: coverLetters
    };
};

export const fetchCoverLettersFail = ( error ) => {
    return {
        type: actionTypes.FETCH_COVER_LETTERS_FAIL,
        error: error
    };
};

export const fetchCoverLettersStart = () => {
    return {
        type: actionTypes.FETCH_COVER_LETTERS_START
    };
};

export const fetchCoverLetters = (token) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        
        dispatch(fetchCoverLettersStart());
        axios.get( '/cover-letters', { headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then( res => {
                const fetchedCoverLetters = [];
                for ( let key in res.data.letters ) {
                    fetchedCoverLetters.push( getTransformedCoverLetter(res.data.letters[key]));
                }
                localStorage.setItem('cover-letters', JSON.stringify(fetchedCoverLetters));
                dispatch(fetchCoverLettersSuccess(fetchedCoverLetters));
            } )
            .catch( err => {
                dispatch(fetchCoverLettersFail(err));
            } );
    };
};

export const deleteCoverLetterFail = ( error ) => {
    return {
        type: actionTypes.DELETE_COVER_LETTER_FAIL,
        error: error
    };
}

export const deleteCoverLetterStart = () => {
    return {
        type: actionTypes.DELETE_COVER_LETTER_START
    };
};

export const deleteCoverLetterSuccess = (coverLetterId) => {
    return {
        type: actionTypes.DELETE_COVER_LETTER_SUCCESS,
        coverLetterId: coverLetterId,
    };
};

export const deleteCoverLetter = ( token, coverLetterId) => {
    return dispatch => {
        dispatch( deleteCoverLetterStart() );

        const headers = {
            Authorization: `Bearer ${token}`
        }
        axios.delete( `/cover-letters/${coverLetterId}`, {headers} )
            .then( response => {
                dispatch( deleteCoverLetterSuccess(coverLetterId) );
                /* dispatch( fetchCoverLetters(token, userId) );*/
            } )
            .catch( error => {
                dispatch( deleteCoverLetterFail( error ) );
                console.log(error);
            } );
    };
};

export const coverLetterConfigurationSuccess = (coverLetterConfiguration) => {
    return {
        type: actionTypes.COVER_LETTER_СONFIGURATION_SUCCESS,
        coverLetterConfiguration: coverLetterConfiguration
    };
};

export const coverLetterConfigurationFail = ( error ) => {
    return {
        type: actionTypes.COVER_LETTER_СONFIGURATION_FAIL,
        error: error
    };
}

export const createCoverLetterConfiguration = (coverLetterConfigurationId, coverLetterConfiguration) => {
    return {
        type: actionTypes.CREATE_COVER_LETTER_СONFIGURATION,
        coverLetterConfigurationId: coverLetterConfigurationId,
        coverLetterConfiguration: coverLetterConfiguration,
    };
};

export const getCoverLetterConfiguration = (coverLetterConfiguration) => {
    return {
        type: actionTypes.GET_COVER_LETTER_СONFIGURATION,
        coverLetterConfiguration: coverLetterConfiguration
    };
};

export const coverLetterConfigurationStart = () => {
    return {
        type: actionTypes.COVER_LETTER_СONFIGURATION_START
    };
};

export const coverLetterConfigurationInit = (coverLetterId, token) => {
    return dispatch => {
        dispatch(coverLetterConfigurationStart());
        const headers = {
            Authorization: `Bearer ${token}`
        }
        
        axios.get(`/cover-letters/configuration/${coverLetterId}`, { headers: {
            'Authorization': `Bearer ${token}`
            }
        })
        .then( response =>  {   
            dispatch(getCoverLetterConfiguration(response.data.configuration))
        })
        .catch( err => {
            axios.post( `/cover-letters/configuration`, {cover_letter_id: coverLetterId}, {headers: headers})
            .then( response => dispatch(createCoverLetterConfiguration(response.data.configuration.id, response.data.configuration)))
            .catch( err => {
                dispatch(coverLetterConfigurationFail(err));
            } );
        })
    }
}

export const coverLetterConfigurationEdit = ( coverLetterConfiguration, token, coverLetterConfigurationId) => {
    return dispatch => {
        const headers = {
            Authorization: `Bearer ${token}`
        }

        axios.put( `/cover-letters/configuration/${coverLetterConfigurationId}`, coverLetterConfiguration, {headers: headers})
        .then( response => dispatch(coverLetterConfigurationSuccess(coverLetterConfiguration)))
        .catch( err => {
            dispatch(coverLetterConfigurationFail(err));
        } );
    };
};