import React from 'react';
import { NavLink } from 'react-router-dom';


import styles from './NavigationItem.module.scss';

const navigationItem = ( props ) => (
    <li className={`${props.customClass} ${styles.NavigationItem}`}>
        {
            props.external ? <a href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a> :
        
            <NavLink 
                to={props.link}
                exact={props.exact}
                activeClassName={styles.active}>
                {props.children}
            </NavLink>
        }
    </li>
);

export default navigationItem;