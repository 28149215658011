import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import axios from './axios-resumes';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import authReducer from './store/reducers/auth';
import resumeReducer from './store/reducers/resume';
import coverLetterReducer from './store/reducers/coverLetter';

import * as actions from './store/actions/index';
//import interceptor from './interceptors';

//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    resume: resumeReducer,
    coverLetter: coverLetterReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

axios.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    const { config, response: { status } } = error;
    const originalRequest = config;
    const isRefreshTokenUrl = config.url.includes('refresh');

    if (isRefreshTokenUrl && [401, 500].includes(error.response.status)) {
        store.dispatch(actions.logout());
    }

    if (status === 401) {
    if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        await store.dispatch(actions.refreshToken()).then((access_token) => {
            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(access_token)
        })
    }

    const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token
        resolve(axios(originalRequest))
        })
    })
    return retryOriginalRequest
    }
    return Promise.reject(error)
})

/* let subscribers = [];

const onAccessTokenFetched = (token) => {
    subscribers = subscribers.filter(callback => callback(token));
};

const addSubscriber = (callback) => {
    subscribers.push(callback);
};


axios.interceptors.response.use (
    undefined,
    function (error) {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem("token");
        if ( refreshToken && error.response.status === 401  && !error.config.__isRetryRequest && !error.response.config.headers['Extarnal-Host'] ) {
            originalRequest._retry = true;

            const headers = {
                Authorization: `Bearer ${refreshToken}`
            }
    
            let res = axios
                .post('/auth/refresh', {}, { headers: headers })
                .then(response => {
                    const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('expirationDate', expirationDate);
                    store.dispatch(actions.refreshToken(response.data.access_token));
                    
                    console.log("Access token refreshed!");
                    onAccessTokenFetched(response.data.access_token);

                    return axios(originalRequest);
                })

            return new Promise(resolve => {
                addSubscriber((token) => {
                    originalRequest.headers['Authorization'] = token;
                    resolve(axios(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    }
); */

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
