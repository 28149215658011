import React from 'react';
import {createUseStyles} from 'react-jss';

import UserIcon from '../../../assets/images/template_icons/icons8-user-40.png';
import WorkIcon from '../../../assets/images/template_icons/icons8-briefcase-40.png';
import EducationIcon from '../../../assets/images/template_icons/icons8-university-40.png';
import ReferencesIcon from '../../../assets/images/template_icons/icons8-megaphone-40.png';
import CustomIcon from '../../../assets/images/template_icons/icons8-medium-icons-40.png';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

function Tokyo (props) {

    const mainColor = props.resumeConfiguration.primary_color || '#4F91CA';
    const iconColor = props.resumeConfiguration.secondary_color || '#9EC7EE';

    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    const fontSize = props.resumeConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 11.5 * multiplier;
    const headerTitleSize = 24 * multiplier;
    const headerSubtitleSize = 14 * multiplier;
    const dateTextSize = 11 * multiplier;
    const sectionTitleSize = 17 * multiplier;
    const sideTitleSize = 12 * multiplier;

    const fontSizes = { mainSize, headerTitleSize, headerSubtitleSize, dateTextSize, sectionTitleSize, sideTitleSize };

    const templateStyles = createUseStyles({
        link: {
            color: (prop) => prop.mainColor,
            wordBreak: 'break-word',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: '30px 20px',
            fontSize: (prop) => `${prop.mainSize}pt`,
            fontFamily: (prop) => prop.fontFamily,
            zIndex: 1,
            '& p': {
                marginBottom: 0,
            },
        },
        main: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            alignContent: 'stretch',
            flexWrap: 'nowrap',
            flex: '1 1 100%',
            padding: '0 20px 35px',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 1,
            '& a': {
                color: (prop) => prop.mainColor,
                textDecoration: 'none',
            },
            '& p': {
                marginBottom: 0,
            },
        },
        dateText: {
            color: '#8190AA',
            fontSize: (prop) => `${prop.dateTextSize}pt`,
        },
        sectionIcon: {
            width: '12pt',
            height: '12pt',
            objectFit: 'contain',
            marginTop: '5pt',
            marginRight: '7pt',
        },
        educationIcon: {
            width: '14pt',
            marginRight: '6pt',
        },
        sectionWrap: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexGrow: 1,
            flexShrink: 0,
            marginBottom: '5pt',
        },
        contentSection: {
            marginBottom: '10pt',
            flexBasis: `calc(100% - 27px)`,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            wordWrap: 'break-word',
            fontWeight: 700,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            wordWrap: 'break-word',
        },
        userImage: {
            width: '20mm',
            height: '20mm',
            objectFit: 'cover',
            borderRadius: '2mm',
            flexGrow: 0,
            marginRight: '15px',
        },
        mainContentCell: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            maxWidth: '63%',
            width: '63%',
            '& p': {
                lineHeight: 1.3,
            },
            '& ul': {
                margin: '.5rem 0',
                lineHeight: 1.3,
            },
        },
        detailsContentCell: {
            maxWidth: '27%',
            width: '27%',
        },
        sectionTitle: {
            display: 'block',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            fontWeight: 700,
            marginBottom: 0,
        },
        repeatedItem: {
            marginBottom: '5pt',
        },
        sideItem: {
            marginBottom: '15pt',
        },
        sideRepeatedItem: {
            paddingBottom: '5pt',
            lineHeight: 1.7,
        },
        level: {
            height: '13px',
        },
        sideTitle: {
            fontSize: (prop) => `${prop.sideTitleSize}pt`,
            fontWeight: 700,
            marginBottom: '4pt',
        },
        sideSubtitle: {
            color: '#8190AA',
            marginTop: '5pt',
            marginBottom: '4pt',
        },
        contactItem: {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 2,
            wordBreak: 'break-all',
            '& svg': {
                marginRight: '0.5rem',
            },
        },
        description: {
            '& ol': {
                margin: 0,
                paddingLeft: '20pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                paddingLeft: '10pt',
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '10pt',
                    '&:before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        display: 'inline-block',
                        color: '#000',
                        fontSize: '14pt',
                        lineHeight: 1,
                        marginRight: '7pt',
                    },
                },
            },
        },
        phoneNumber: {
            whiteSpace: 'nowrap',
        },
        contactLine: {
            '& span': {
                display: 'inline-block',
                '&:not(:last-of-type):after': {
                    display: 'inline-block',
                    marginRight: '0.5em',
                    content: '","',
                },
            },
        },
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 13,
            borderWidth: '1px',
            borderColor: mainColor,
            borderStyle: 'solid',
            borderRadius: '5px',
        },
        colorPrimary: {
            backgroundColor: '#fff',
        },
        bar: {
            backgroundColor: mainColor,
        },
    }))(LinearProgress);

    const skillsPercentages = {
        'novice': 20,
        'beginner': 40,
        'skillful': 60,
        'experienced': 80,
        'expert': 100
    }

    const languagesPercentages = {
        'Native Speaker': 100, 
        'Highly proficient': 80, 
        'Very good command': 60, 
        'Good working knowledge': 40,
        'Working knowledge': 20, 
        'C2': 100, 
        'C1': 100, 
        'B2': 80, 
        'B1': 60, 
        'A2': 40, 
        'A1': 20
    }

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="fb-link"><FacebookIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.facebook}>{showUrl(socials.facebook)}</a></p>);
        }
        if (socials.linkedin) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="li-link"><LinkedInIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.linkedin}>{showUrl(socials.linkedin)}</a></p>);
        }
        if (socials.twitter) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="tw-link"><TwitterIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.twitter}>{showUrl(socials.twitter)}</a></p>);
        }
        if (socials.instagram) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="in-link"><InstagramIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.instagram}>{showUrl(socials.instagram)}</a></p>);
        }
        if (socials.github) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="gh-link"><GitHubIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.github}>{showUrl(socials.github)}</a></p>);
        }
        if (socials.youtube) {
            socialsList.push(<p className={`side-movable ${classes.contactItem}`} key="yt-link"><YouTubeIcon style={{ color: iconColor }} /> <a className={classes.link} href={socials.youtube}>{showUrl(socials.youtube)}</a></p>);
        }

        return socialsList;
    }

    return (
        <>
            <div className={classes.header}>
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}
            
                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                </div>
            </div>
            <div className={classes.main}>
                <div className={classes.mainContentCell}>
                    {props.resume.careerSummary ? (
                        <div className={`movable ${classes.sectionWrap}`}>
                            <img
                                className={classes.sectionIcon}
                                src={UserIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                <p className={classes.sectionTitle}><span>{props.resume.careerSummaryTitle ? props.resume.careerSummaryTitle : 'Profile'}</span></p>
                                <div className={classes.description}>{generateTinyMceElements(props.resume.careerSummary)}</div>
                                {/* <p>{props.resume.careerSummary.replace(/<[^>]+>/g, '')}</p> */}
                            </div>
                        </div>
                    ) : <></>}
                    
                    {props.resume.workExperienceItems && props.resume.workExperienceItems.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon}`}
                                src={WorkIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.experienceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.experienceSectionTitle}</p>) : (<p className={classes.sectionTitle}>Work Experience</p>)}
                                {props.resume.workExperienceItems.map(workItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={workItem.id}>
                                        <p>{workItem.jobTitle}{(workItem.jobTitle && workItem.company) ? ' at ' : ''}{workItem.company}{((workItem.jobTitle || workItem.company) && workItem.city ) ? ', ' : ''}{workItem.city}</p>
                                        <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MMM YYYY")}</p>
                                        {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}

                    {props.resume.educationFields && props.resume.educationFields.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon} ${classes.educationIcon }`}
                                src={EducationIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.educationSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.educationSectionTitle}</p>) : (<p className={classes.sectionTitle}>Education</p>)}
                                {props.resume.educationFields.map(educationItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={educationItem.id}>
                                        <p>{educationItem.degree}{(educationItem.degree && educationItem.institution) ? ' at ' : ''}{educationItem.institution}{((educationItem.degree || educationItem.institution) && educationItem.city ) ? ', ' : ''}{educationItem.city}</p>
                                        <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MMM YYYY")}</p>
                                        {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}

                    {props.resume.internshipFields && props.resume.internshipFields.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon}`}
                                src={WorkIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.internshipSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.internshipSectionTitle}</p>) : (<p className={classes.sectionTitle}>Internship</p>)}
                                {props.resume.internshipFields.map(internshipItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={internshipItem.id}>
                                        <p>{internshipItem.jobTitle}{(internshipItem.jobTitle && internshipItem.employer) ? ' at ' : ''}{internshipItem.employer}{((internshipItem.jobTitle || internshipItem.employer) && internshipItem.city ) ? ', ' : ''}{internshipItem.city}</p>
                                        <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MMM YYYY")}</p>
                                        {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}

                    {props.resume.coursesFields && props.resume.coursesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon} ${classes.educationIcon }`}
                                src={EducationIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.courseSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.courseSectionTitle}</p>) : (<p className={classes.sectionTitle}>Courses</p>)}
                                {props.resume.coursesFields.map(courseItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={courseItem.id}>
                                        <p>{courseItem.course}{(courseItem.course && courseItem.institution) ? ' at ' : ''}{courseItem.institution}</p>
                                        <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MMM YYYY")}</p>
                                        {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}

                    {props.resume.referencesFields && props.resume.referencesFields.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon}`}
                                src={ReferencesIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.referenceSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.referenceSectionTitle}</p>) : (<p className={classes.sectionTitle}>References</p>)}
                                {   !Boolean(Number(props.resume.referencesVisibility)) ? 
                                    props.resume.referencesFields.map(referenceItem => (
                                        <div className={`movable ${classes.repeatedItem}`} key={referenceItem.id}>
                                            <p>{referenceItem.referent}{(referenceItem.referent && referenceItem.company) ? ' from ' : ''}{referenceItem.company}</p>
                                            <p><a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}</p>
                                            {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>) : <></> }
                                        </div>
                                    )) : (<p>References available upon request</p>)}
                            </div>
                        </div>
                    ) : <></>}

                    {props.resume.customFields && props.resume.customFields.length ? (
                        <div className={classes.sectionWrap}>
                            <img
                                className={`movable ${classes.sectionIcon}`}
                                src={CustomIcon}
                                alt=""
                            />
                            <div className={classes.contentSection}>
                                {props.resume.customSectionTitle ? (<p className={classes.sectionTitle}>{props.resume.customSectionTitle}</p>) : (<></>)}
                            
                                {props.resume.customFields.map(customItem => (
                                    <div className={`movable ${classes.repeatedItem}`} key={customItem.id}>
                                        <p>{customItem.customItemTitle}{(customItem.customItemTitle && customItem.city) ? ', ' : ''}{customItem.city}</p>
                                        <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MMM YYYY")}</p>
                                        {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <></>}
                    
                </div>

                <div className={classes.detailsContentCell} /* fixed */>
                    <div className={`side-movable ${classes.sideItem}`}>
                        <p className={classes.sideTitle}>Details</p>
                        <p className={classes.contactLine}>
                            {generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}
                        </p>
                        {props.resume.phoneNumber ? (<p className={classes.phoneNumber}>{props.resume.phoneNumber}</p>) : <></>}
                        <a className={classes.link} href={`mailto:${props.resume.email}`}>{props.resume.email}</a>

                        {(props.resume.birthPlace || props.resume.birthDate) ? (
                            <>
                                <p className={classes.sideSubtitle}>{props.resume.birthDate ? 'Date' : ''}{props.resume.birthDate && props.resume.birthPlace ? ' / ' : ''}{props.resume.birthPlace ? 'Place' : ''} of birth</p>
                                {props.resume.birthDate ? (<p>{moment(props.resume.birthDate).format("DD/MM/yyyy")}</p>) : ''}
                                {props.resume.birthPlace ? (<p>{props.resume.birthPlace}</p>) : ''}
                            </>
                        ) : (<></>)}

                        {props.resume.nationality ? (
                            <>
                                <p className={classes.sideSubtitle}>Nationality</p>
                                <p>{props.resume.nationality}</p>
                            </>
                        ) : (<></>)}
                        
                        {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (
                            <>
                                <p className={classes.sideSubtitle}>Driving license</p>
                                <p>{props.resume.drivingLicenseType.join(', ')}</p>
                            </>
                        ) : (<></>)}
                    </div>

                    {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                        <div className={classes.sideItem}>
                            {props.resume.socialSectionTitle ? (<p className={`side-movable ${classes.sideTitle}`}>{props.resume.socialSectionTitle}</p>) : (<p className={classes.sideTitle}>Links</p>)}
                            {props.resume.websiteSocialFields.map(socialItem => (
                                (socialItem.link || socialItem.name) ? (
                                    <p key={socialItem.id} className={`side-movable ${classes.contactItem}`}><a className={classes.link} href={socialItem.link}>{socialItem.name}</a></p>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                        </div>
                    ) : <></>}

                    {props.resume.skillsFields && props.resume.skillsFields.length ? (
                        <div className={classes.sideItem}>                            
                            {props.resume.skillSectionTitle ? (<p className={`side-movable ${classes.sideTitle}`}>{props.resume.skillSectionTitle}</p>) : (<p className={classes.sideTitle}>Skills</p>)}
                            {props.resume.skillsFields.map(skillItem => 
                                (skillItem.skill) ? (<div className={`side-movable ${classes.sideRepeatedItem}`} key={skillItem.id}>
                                    <p>{skillItem.skill}</p>
                                    {skillItem.level && Boolean(Number(props.resume.skillsVisibility)) ? (
                                        <div className={classes.level}><BorderLinearProgress 
                                            variant="determinate" 
                                            value={skillsPercentages[skillItem.level]}
                                        /></div>
                                    ) : (<></>)}
                                </div>)  : (<></>)
                            )}
                        </div>
                    ) : <></>}
                    

                    {props.resume.languagesFields && props.resume.languagesFields.length ? (
                        <div className={classes.sideItem}>                            
                            {props.resume.languageSectionTitle ? (<p className={`side-movable ${classes.sideTitle}`}>{props.resume.languageSectionTitle}</p>) : (<p className={classes.sideTitle}>Languages</p>)}
                            {props.resume.languagesFields.map(languageItem => 
                                (languageItem.language) ? (<div className={`side-movable ${classes.sideRepeatedItem}`} key={languageItem.id}>
                                    <p>{languageItem.language}</p>
                                    {languageItem.level ? (
                                        <div className={classes.level}><BorderLinearProgress 
                                            variant="determinate" 
                                            value={languagesPercentages[languageItem.level]}
                                        /></div>) : null}
                                </div>)  : (<></>)
                            )}
                        </div>
                    ) : <></>} 

                </div>
                
            </div>
        </>
    )
}

export default Tokyo;