import React, {useState, useEffect, useRef} from 'react';

import styles from '../../BuilderToolbar/BuilderToolbar.module.scss';

import {Grow, Paper, Popper, ClickAwayListener, Link} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


function Dropdown(props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = (e) => {
        e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        /* console.log(selectRef.current);
        console.log(event.currentTarget)
        if (selectRef.current == event.target) {
            return;
        } */
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (props.typeDropdownClose) {
            setOpen(false);
        }
    }, [props.typeDropdownClose]);

    return (
        <>
            <Link
                href="#"
                ref={anchorRef}
                aria-controls={open ? props.id : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={`${styles.ToolbarItem} ${open ? styles.Active : ''}`}
            >
                {props.icon}<span>{props.title}</span>
                { props.showIcon && (open ? <ArrowDropUpIcon className={styles.ArrowIcon} /> : <ArrowDropDownIcon className={styles.ArrowIcon} />) }
            </Link>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div id={props.id} onKeyDown={handleListKeyDown} className={`${styles.DropdownItems} ${props.customClass}`}>
                                    {props.children}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>        
        </>
    )
}

export default Dropdown;