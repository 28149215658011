import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {OutlinedInput, FormControl, IconButton, InputAdornment }  from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        "& $notchedOutline": {
            borderColor: '#E3E8F1',
        },
        "&:hover $notchedOutline": {
            borderColor: '#8190AA',
            borderWidth: '1px',
        },
        "&$focused $notchedOutline": {
            borderColor: '#8190AA',
            borderWidth: '1px',
        }
    },
    input: {
        padding: '17px 14px',
    },
    focused: {},
    notchedOutline: {},
    error: {
        borderColor: '#F03153 !important',
        "&:hover $notchedOutline": {
            borderColor: '#F03153',
        },
        "&$focused $notchedOutline": {
            borderColor: '#F03153',
        }
    }
}));

const DatepickerInput = (props, ref) => {
    const outlinedInputClasses = useOutlinedInputStyles();

    return (
        <FormControl>
            <OutlinedInput
                classes={outlinedInputClasses}
                type="text"
                fullWidth
                placeholder="Pick a date"
                value={props.value}
                onClick={props.onClick}
                inputProps={{ 'data-lpignore': "true",}}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="show datepicker"
                            edge="end"
                            /* onClick={props.onClick} */
                        >
                            <TodayIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )

}

export default forwardRef(DatepickerInput);