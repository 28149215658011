import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image, View, Link, Canvas } from '@react-pdf/renderer';

import moment from 'moment';

import {toJSON} from '../../shared/domToJson';
import {JsonToPdfComponent} from '../../shared/jsonToPdf';
import {createFragment} from '../../shared/createFragment';
import { generateDateString , generateContactString} from '../../shared/utility';
import { addPdfPattern } from '../../shared/addPdfPattern';

//const PDFViewerWithNoSSR = dynamic(import('./PDFViewer'), { ssr: false });
    

// Create Document Component
const CleanBluePdf = (props) => {
    
    Font.registerHyphenationCallback(word => {
        // Return entire word as unique part
        return [word];
    });

    const mainColor = props.resumeConfiguration.primary_color || '#313B47';
    
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 34.5 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const sectionTitleSize = 21 * multiplier;
    const repeatedTitleSize = 19.5 * multiplier;
    const repeatedSubtitleSize = 18 * multiplier;
    const dateTextSize = 12 * multiplier;

    const resumeType = props.resumeConfiguration.resume_type || 'functional';
    
    const styles = StyleSheet.create({
        body: {
            fontFamily: fontFamily,
            paddingVertical: 20,
        },
        table: {
            fontSize: mainSize,
            lineHeight: 1.3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: '1 1 100%',
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "stretch",
        },
        header: {
            position: 'relative',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: 7,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 20,
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            backgroundColor: mainColor,
            color: '#fff',
            paddingVertical: 8,
            paddingLeft: 12,
            paddingRight: 20,
        },
        withoutAvatar: {
            marginRight: 20,
        },
        userImage: {
            width: '40mm',
            height: '40mm',
            objectFit: 'cover',
            flexGrow: 0,
            flexShrink: 0,
            borderRadius: '20mm',
            marginRight: 10,
        },
        title: {
            fontSize: headerTitleSize,
            lineHeight: 1.2,
            marginBottom: 0,
        },
        subtitle: {
            fontSize: headerSubtitleSize,
        },
        subtitleAfter: {
            marginTop: 5,
            width: 115,
            height: 1,
            backgroundColor: '#fff',
        },
        contentLink: {
            color: mainColor,
            textDecoration: 'none',
        },
        link: {
            //color: '#fff',
            color: 'inherit',
            textDecoration: 'underline',
        },
        contactLine: {
            padding: '7 20 0',
            display: 'flex',
            flexDirection: "row",
            flexWrap: 'wrap',
            alignItems: 'center',
            flex: '1 1 100%',
            lineHeight: 1.5,
        },
        contactItem: {
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            paddingRight: 4,
            marginVertical: 2,
        },
        contactLink: {
            color: 'inherit',
            textDecoration: 'none',
            alignSelf: 'center',
        },
        contactTitle: {
            color: mainColor,
            fontWeight: 700,
            marginRight: 6,
        },
        main: {
            paddingTop: 20,
            paddingHorizontal: 20,
        },
        sectionTitle: {
            color: mainColor,
            textTransform: 'uppercase',
            fontSize: sectionTitleSize,
            letterSpacing: 1,
            fontWeight: 700,
            marginBottom: 7,
            borderBottomWidth: 2,
            borderColor: mainColor,
        },
        skill: {
            flexGrow: 0,
            flexShrink: 0,
            borderRadius: 5,
            padding: '4 10',
            backgroundColor: mainColor,
            color: '#fff',
            marginVertical: 3,
            marginRight: 6,
        },
        sectionWrap: {
            marginBottom: 22,
        },
        repeatedItem: {
            marginBottom: 7,
        },
        repeatedItemTitle: {
            fontSize: repeatedTitleSize,
            lineHeight: 1.2,
            fontWeight: 700,
            color: mainColor,
        },
        repeatedItemSubtitle: {
            fontSize: repeatedSubtitleSize,
        },
        dateCityWrap: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontStyle: 'italic',
            fontSize: dateTextSize,
            color: mainColor,
        },
        flexRow: {
            display: 'flex',
            flexDirection: "row",
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        languagesItem: {
            flexGrow: 0,
            width: '25%',
        },
        languageLevel: {
            color: mainColor,
            fontStyle: 'italic',
        },
        svgIcon: {
            flexGrow: 0,
            flexShrink: 0,
            width: 24,
            height: 24,
            marginRight: 1,
        },
        description: {
            marginTop: 8,
            maxWidth: '98%',
        },
    });

    const generateTinyMceElements = content => {
        let contentWrapper = createFragment(content);
        if (contentWrapper.childNodes && contentWrapper.childNodes.length) {
            return Array.from(contentWrapper.childNodes).map(node => JsonToPdfComponent(toJSON(node), styles, mainColor, 'square'));
        }
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.facebook} style={styles.contactLink}>{showUrl(socials.facebook)}</Link></View>);
        }
        if (socials.linkedin) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.linkedin} style={styles.contactLink}>{showUrl(socials.linkedin)}</Link></View>);
        }
        if (socials.twitter) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.twitter} style={styles.contactLink}>{showUrl(socials.twitter)}</Link></View>);
        }
        if (socials.instagram) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.instagram} style={styles.contactLink}>{showUrl(socials.instagram)}</Link></View>);
        }
        if (socials.github) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.github} style={styles.contactLink}>{showUrl(socials.github)}</Link></View>);
        }
        if (socials.youtube) {
            socialsList.push(<View style={styles.contactItem}>
                <Canvas
                    paint={(painter) => {
                        painter.scale(.9, .9).fillColor(mainColor).path('M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z').fill();
                    }}
                    style={styles.svgIcon}
                />
                <Link href={socials.youtube} style={styles.contactLink}>{showUrl(socials.youtube)}</Link></View>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <View style={styles.sectionWrap}>
            {props.resume.skillSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.skillSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Skills</Text>)}
            <View style={styles.flexRow}>
                {props.resume.skillsFields.map((skillItem, index) => 
                    (skillItem.skill) ? (<Text wrap={false} key={index} style={styles.skill}>{skillItem.skill}</Text>) : <></>
                )}
            </View>
        </View>
    ) : <></>;

    return (
        <Document>
            <Page  wrap size="A4" style={styles.body}>
                {addPdfPattern(props.resumeConfiguration.pattern)}
                <View style={styles.table}>
                    <View style={[styles.row, styles.header]}>
                        {props.resume.userImage ? (
                            <Image 
                                style={styles.userImage} 
                                src={{uri:`https://resume-cors.herokuapp.com/${props.resume.userImage}`, method: 'GET', headers:{'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}}}
                            />
                        ) : <></>}
                    
                        <View style={[styles.headerText, !props.resume.userImage ? styles.withoutAvatar : '']}>
                            <Text style={styles.title}>{props.resume.firstName} {props.resume.lastName}</Text>
                            <Text style={styles.subtitle}>{props.resume.jobPosition}</Text>
                            {props.resume.jobPosition ? (<View style={styles.subtitleAfter}></View>) : <></>}
                            {props.resume.careerSummary ? (<View style={styles.description}>{generateTinyMceElements(props.resume.careerSummary, '#fff')}</View>) : <></>}
                        </View>
                    </View>

                    {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
                    props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
                    props.resume.city || props.resume.postalCode || props.resume.country || 
                    (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
                    <View style={styles.contactLine}>
                        {props.resume.email ? (<View style={styles.contactItem}>
                            <Canvas paint={(painter) => {
                                painter.scale(.9, .9).fillColor(mainColor).path('M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z').fill();
                            }} style={styles.svgIcon} />
                            <Link src={`mailto:${props.resume.email}`} style={styles.contactLink}>{props.resume.email}</Link>
                        </View>) : <></>}
                        {props.resume.phoneNumber ? (<View style={styles.contactItem}>
                            <Canvas paint={(painter) => {
                                    painter.scale(.9, .9).fillColor(mainColor).path('M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z').fill();
                                }} style={styles.svgIcon} />
                            <Text style={styles.contactValue}>{props.resume.phoneNumber}</Text>
                        </View>) : <></>}
                        {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) ? (<View style={styles.contactItem}>
                            <Canvas paint={(painter) => {
                                    painter.fillColor(mainColor).path('M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z').fill();
                                }} style={styles.svgIcon} />
                            <Text style={styles.contactValue}>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</Text>
                        </View>) : <></>}
                        {props.resume.nationality ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Nationality:</Text> {props.resume.nationality}</Text>) : <></>}
                        {props.resume.birthPlace ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Place:</Text> {props.resume.birthPlace}</Text>) : <></>}
                        {props.resume.birthDate ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Birth Date:</Text> {moment(props.resume.birthDate).format("DD/MM/yyyy")}</Text>) : <></>}
                        {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<Text style={styles.contactItem}><Text style={styles.contactTitle}>Driving license:</Text> {props.resume.drivingLicenseType.join(', ')}</Text>) : <></>}
                    </View>
                    ) : <></>}

                    {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                        <View style={styles.contactLine}>
                            {props.resume.websiteSocialFields.map((socialItem, index) => (
                                (socialItem.link || socialItem.name) ? (
                                    <View key={index} style={styles.contactItem}>
                                        <Canvas
                                            paint={(painter) => {
                                                painter.scale(.9, .9).fillColor(mainColor).path('M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z').fill();
                                            }}
                                            style={styles.svgIcon}
                                        />
                                        <Link href={socialItem.link} style={styles.contactLink}>{socialItem.name ? socialItem.name : socialItem.link}</Link>
                                    </View>
                                ) : (<></>)
                            ))}
                            {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                        </View>
                    ) : <></>}

                    <View style={styles.main}>

                        {resumeType === 'functional' ? skillsSection : <></>}

                        {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.experienceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.experienceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Work Experience</Text>)}

                                {props.resume.workExperienceItems.map((workItem, index) => (
                                    <View key={index} style={styles.repeatedItem}>
                                        {workItem.jobTitle ? (<Text style={styles.repeatedItemTitle}>{workItem.jobTitle}</Text>) : <></>}
                                        {workItem.company ? (<Text style={styles.repeatedItemSubtitle}>{workItem.company}</Text>) : <></>}
                                        {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                            <View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                                <Text>{workItem.city}</Text>
                                            </View>
                                        ) : <></>}
                                        {workItem.description ? (<View style={styles.description}>{generateTinyMceElements(workItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {resumeType === 'chronological' ? skillsSection : <></>}

                        {props.resume.educationFields && props.resume.educationFields.length  ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.educationSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.educationSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Education</Text>)}
                                {props.resume.educationFields.map((educationItem, index) => (
                                    <View key={index} style={styles.repeatedItem}>
                                        {educationItem.degree ? (<Text style={styles.repeatedItemTitle}>{educationItem.degree}</Text>) : <></>}
                                        {educationItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{educationItem.institution}</Text>) : <></>}
                                        {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                            <View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                                <Text>{educationItem.city}</Text>
                                            </View>
                                        ) : <></>}
                                        {educationItem.description ? (<View style={styles.description}>{generateTinyMceElements(educationItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}
                        
                        {props.resume.internshipFields && props.resume.internshipFields.length ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.internshipSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.internshipSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Internship</Text>)}
                                {props.resume.internshipFields.map((internshipItem, index) => (
                                    <View key={index} style={styles.repeatedItem}>
                                        {internshipItem.jobTitle ? (<Text style={styles.repeatedItemTitle}>{internshipItem.jobTitle}</Text>) : <></>}
                                        {internshipItem.employer ? (<Text style={styles.repeatedItemSubtitle}>{internshipItem.employer}</Text>) : <></>}     
                                        {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                            <View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                                <Text>{internshipItem.city}</Text>
                                            </View>
                                        ) : <></>}
                                        {internshipItem.description ? (<View style={styles.description}>{generateTinyMceElements(internshipItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                        {props.resume.coursesFields && props.resume.coursesFields.length ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.courseSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.courseSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Courses</Text>)}

                                {props.resume.coursesFields.map((courseItem, index) => (
                                    <View key={index} style={styles.repeatedItem}>
                                        {courseItem.course ? (<Text style={styles.repeatedItemTitle}>{courseItem.course}</Text>) : <></>}
                                        {courseItem.institution ? (<Text style={styles.repeatedItemSubtitle}>{courseItem.institution}</Text>) : <></>}
                                        {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                            <View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                            </View>
                                        ) : <></>}
                                        {courseItem.description ? (<View style={styles.description}>{generateTinyMceElements(courseItem.description)}</View>)/* (<Text>{courseItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}
                        
                        {props.resume.referencesFields && props.resume.referencesFields.length ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.referenceSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.referenceSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>References</Text>)}
                                {!Boolean(Number(props.resume.referencesVisibility)) ? 
                                    props.resume.referencesFields.map((referenceItem, index) => (
                                        <View key={index} style={styles.repeatedItem}>
                                            {referenceItem.referent ? (<Text style={styles.repeatedItemTitle}>{referenceItem.referent}</Text>) : <></>}
                                            {referenceItem.company ? (<Text style={styles.repeatedItemSubtitle}>{referenceItem.company}</Text>) : <></>}
                                            <Text>
                                                <Link style={styles.contentLink} src={`mailto:${referenceItem.email}`}>{referenceItem.email}</Link> {referenceItem.phone}
                                            </Text>
                                            {referenceItem.description ? (<View style={styles.description}>{generateTinyMceElements(referenceItem.description)}</View>)/* (<Text>{referenceItem.description.replace(/<[^>]+>/g, '')}</Text>) */ : <></> }
                                        </View>
                                    )) : (<Text>References available upon request</Text>)}
                            </View>
                        ) : <></>}
                        
                        {props.resume.languagesFields && props.resume.languagesFields.length ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.languageSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.languageSectionTitle}</Text>) : (<Text style={styles.sectionTitle}>Languages</Text>)}
                                <View style={[styles.flexRow, styles.languagesRow]}>
                                    {props.resume.languagesFields.map((languageItem, index) => 
                                        (languageItem.language || languageItem.languageLevel) ? (<View key={index} style={[styles.repeatedItem, styles.languagesItem]} wrap={false}>
                                            {languageItem.language ? (<Text>{languageItem.language}</Text>) : (<></>)}
                                            {languageItem.level ? (<Text style={styles.languageLevel}>{languageItem.level}</Text>) : (<></>)}
                                        </View>) : (<></>)
                                    )}
                                </View>
                            </View>
                        ) : <></>}

                        {props.resume.customFields && props.resume.customFields.length ? (
                            <View style={styles.sectionWrap}>
                                {props.resume.customSectionTitle ? (<Text style={styles.sectionTitle}>{props.resume.customSectionTitle}</Text>) : (<></>)}
                            
                                {props.resume.customFields.map((customItem, index) => (
                                    <View key={index} style={styles.repeatedItem}>
                                        {customItem.customItemTitle ? (<Text style={styles.repeatedItemTitle}>{customItem.customItemTitle}</Text>) : <></>}
                                        {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                            <View style={styles.dateCityWrap}>
                                                <Text style={styles.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY")}</Text>
                                                <Text>{customItem.city}</Text>
                                            </View>
                                        ) : <></>}
                                        {customItem.description ? (<View style={styles.description}>{generateTinyMceElements(customItem.description)}</View>) : <></> }
                                    </View>
                                ))}
                            </View>
                        ) : <></>}

                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default CleanBluePdf;