import React, { useState, useEffect } from 'react';
import axios from '../../axios-resumes';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import styles from './CoverLetterBuilder.module.scss';
import {CancelToken} from 'axios';

import {apiUrl} from '../../config';

function TemplatesSwitcher(props) {

    const [coverLetterTemplates, setCoverLetterTemplates] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const source = CancelToken.source();

        axios.get('/cover-letter/templates', { cancelToken: source.token })
        .then( response => {
            setCoverLetterTemplates(response.data.templates);
            setDataLoaded(true);
        })
        .catch( err => {
            console.log(err);
        });

        return () => {
            source.cancel();
        };
    }, []);

    const setActiveSlide = (templateId) => {
        props.onChange({ templateId: templateId});
    }

    const closeTemplateSwitcher = (e) => {
        e.preventDefault();
        props.onClose();
    }

    const templatesList = coverLetterTemplates.map((template, index) => {
        return (
          <div key={index} className={`${styles.TemplateItem} ${props.templateId == template.id ? styles.Current : ''}`}>
              <img src={`${apiUrl}/storage${template.preview_image}`} alt={template.title} onClick={() => setActiveSlide(template.id)} />
          </div>
        )
    });

    return dataLoaded && (
        <div className={`${styles.TemplatesSwitcher} ${props.open ? styles.Open : ''}`}>
            <a href="#" className={styles.Link} onClick={closeTemplateSwitcher}><KeyboardBackspaceIcon /> Back to editor</a>
            <h3>Select a template for your cover letter</h3>
            <div className={styles.TemplatesList}>
                {templatesList}
            </div>
        </div>
    )
}

export default TemplatesSwitcher;