import React, { useState, useEffect, useRef } from 'react';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editIcon from '../../assets/images/edit_icon.svg';

import AutosizeInput from 'react-input-autosize';

import SkillsItem from './SkillsItem';
import Switch from '../../components/UI/Switch/Switch';
import Tooltip from '../../components/UI/Tooltip/Tooltip';
import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';

import styles from './ResumeBuilder.module.scss';

function SkillsSection(props) {
    const [skillsPanelOpen, setSkillsPanelOpen] = useState(true);

    const [sectionTitle, setSectionTitle] = useState('Skills');

    const [skillsFields, setSkillsFields] = useState([
        { skill: '', level: '' }
    ]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.skillsFields && props.resume.skillsFields.length) {
                console.log('Skills Info Mounted');
                setSkillsFields(props.resume.skillsFields);
            }
            if (props.resume.skillSectionTitle) {
                setSectionTitle(props.resume.skillSectionTitle);
            }
            setLevelVisibility(props.resume.skillsVisibility);
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.skillsFields && props.resume.skillsFields.length) {
                console.log('Skills Info Reload');
                setSkillsFields(props.resume.skillsFields);
            }
            if (props.resume.skillSectionTitle) {
                setSectionTitle(props.resume.skillSectionTitle);
            }
            setLevelVisibility(props.resume.skillsVisibility);
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ skillsFields: skillsFields });
        }
    }, [skillsFields]);

/*     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({ skillsFields: skillsFields });
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, skillsFields]); */

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ skillSectionTitle: sectionTitle});
        }
    }, [dataLoaded, sectionTitle]);

    const handleAddSkillsFields = () => {
        const values = [...skillsFields];
        values.push({ skill: '', level: '' });
        setSkillsFields(values);
        setChanged(true);
    };

    const handleRemoveSkillsFields = index => {
        const values = [...skillsFields];
        values.splice(index, 1);
        setSkillsFields(values);
        handleCloseDeleteEntryDialog();
        setChanged(true);
    };

    const handleSkillsInputChange = (index, fieldKey) => (event) => {
        const values = [...skillsFields];
        values[index][fieldKey] = event.target.value;
        setSkillsFields(values);
        setChanged(true);
    };

    const [levelVisibility, setLevelVisibility] = React.useState(true);

    const handleLevelVisibilityChange = (event) => {
        setLevelVisibility(event.target.checked);
        setChanged(true);
    };
    
    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ skillsVisibility: levelVisibility});
        }
    }, [dataLoaded, levelVisibility]);

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setSkillsPanelOpen(!skillsPanelOpen)} className={styles.PanelToggle}>
                {skillsPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="skill-section-title"
                    value={sectionTitle}
                    placeholder="Skills"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
            </div>
            { skillsPanelOpen ? (
                <>
                    <p>Here you can add links to your portfolio, linkedin profile, personal websites or other relevant social media.</p>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <span>Level visibilty</span>                                        
                        <Switch
                            checked={Boolean(Number(levelVisibility))}
                            swichChangeHandler={handleLevelVisibilityChange}
                            name="levelVisibility"
                        />
                    </div>
                    {skillsFields.map((inputField, index) => (
                        <SkillsItem 
                            key={`${inputField}~${index}`} 
                            values={skillsFields[index]} 
                            changed={handleSkillsInputChange}
                            deleted={() => handleOpenDeleteEntryDialog(index)}
                            index={index}
                            levelVisibility={Boolean(Number(levelVisibility))}
                        />
                    ))}
                    <Link
                        component="a"
                        variant="body2"
                        className={styles.FormLink}
                        onClick={() => handleAddSkillsFields()}
                    >
                        <AddCircleIcon /> Add a new skill
                    </Link>
                </>
            ) : null }
            <Dialog 
                open={deleteEntryDialog.open}
                closed={handleCloseDeleteEntryDialog}
            >
                <h2>Delete Entry</h2>
                <p>Are you sure you want to delete this entry?</p>   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={() => handleRemoveSkillsFields(deleteEntryDialog.deletedEntry)}
                    className={styles.Button}
                >
                    Delete
                </Button>
                <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
            </Dialog>
        </Paper>     
    )
}

export default SkillsSection;