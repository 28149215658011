import React from 'react';
import {createUseStyles} from 'react-jss';

import parse from 'html-react-parser';

function DifferentColors (props) {

    const mainColor = props.coverLetterConfiguration.primary_color || '#c7ecc7';

    const fontFamily = props.coverLetterConfiguration.font_name || 'Open Sans';
    const fontSize = props.coverLetterConfiguration.font_size || 'small';

    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;

    const mainSize = 11.5 * multiplier;
    const headerTitleSize = 24 * multiplier;

    const fontSizes = { mainSize, headerTitleSize };

    const templateStyles = createUseStyles({
        header: {
            textAlign: 'center',
            padding: '40pt',
            marginBottom: '20pt',
            background: (prop) => prop.mainColor,
            fontSize: (prop) => `${prop.mainSize}pt`,
            fontFamily: (prop) => prop.fontFamily,
            zIndex: 1,
            '& p': {
                marginBottom: 0,
            },
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            wordWrap: 'break-word',
            fontWeight: 700,
        },
        main: {
            padding: '0 40pt 25pt',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 1,
            lineHeight: 1.3,
            '& a': {
                color: (prop) => prop.mainColor,
                textDecoration: 'none',
            },
            '& p': {
                marginBottom: 0,
            },
            '& $contactLink': {
                color: 'inherit',
            }
        },
        personalDetails: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        employerInfo: {
            marginTop: '20pt',
            marginBottom: '20pt',
        },
        jobPosition: {
            maxWidth: '48%',
            width: '48%',
        },
        contactInfo: {
            maxWidth: '48%',
            width: '48%',
            textAlign: 'right',
        },
        phoneNumber: {
            whiteSpace: 'nowrap',
        },
        contactLink: {
            color: 'inherit',
            textDecoration: 'none',
            wordBreak: 'break-word',
        }, 
        description: {
            '& ol': {
                margin: 0,
                paddingLeft: '14pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '14pt',
                    '&:before': {
                        content: '""',
                        display: 'inline-block',
                        position: 'absolute',
                        left: '0',
                        top: '6pt',
                        width: '8pt',
                        height: '1pt',
                        backgroundColor: (prop) => prop.mainColor,  
                        marginRight: '7pt',
                        minWidth: '8pt'
                    }
                },
            },
        },
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    return (
        <> 
            <div className={classes.header}>
                <p className={classes.headerTitle}>{props.coverLetter.firstName} {props.coverLetter.lastName}</p>
            </div>
            <div className={classes.main}>
                <div className={classes.personalDetails}>
                    <p className={classes.jobPosition}>{props.coverLetter.jobPosition}</p>
                    <div className={classes.contactInfo}>
                        {props.coverLetter.phoneNumber ? (<p className={classes.phoneNumber}>{props.coverLetter.phoneNumber}</p>) : <></>}
                        <a className={classes.contactLink} href={`mailto:${props.coverLetter.email}`}>{props.coverLetter.email}</a>
                    </div>
                </div>
                <div className={classes.employerInfo}>
                    {props.coverLetter.companyName ? (<p className={classes.companyName}>{props.coverLetter.companyName}</p>) : <></>}
                    {props.coverLetter.managerName ? (<p className={classes.managerName}>{props.coverLetter.managerName}</p>) : <></>}
                </div>
                {props.coverLetter.letterDetails ? (
                    <div className={classes.contentSection}>
                        <div className={classes.description}>{generateTinyMceElements(props.coverLetter.letterDetails)}</div>
                    </div>
                ) : <></>}
            </div>
        </>
    )
}

export default DifferentColors;