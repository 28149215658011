import React from 'react';

import {Grid, InputLabel, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';
import MonthYearPicker from '../../components/UI/MonthYearPicker/MonthYearPicker';
import styles from './ResumeBuilder.module.scss';

function InternshipeItem(props) {

    const handlePresentDateChange = (event) => {
        props.changed(props.index, 'presentDate', event.target.checked)();
    };

    const onFieldChange = (fieldKey, date) => {
        props.changed(props.index, fieldKey, date)();
    }

    return (
        <div className={styles.FormRepeatedItem} >
            <IconButton onClick={props.deleted} style={{float: 'right'}} className={styles.DeleteButton}>
                <DeleteIcon />
            </IconButton>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="jobTitle"
                        id="jobTitle"
                        type="text"
                        label="Job Title"
                        value={props.values.jobTitle}
                        changed={props.changed(props.index, 'jobTitle')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        variant="outlined"
                        key="employer"
                        id="employer"
                        type="text"
                        label="Employer"
                        value={props.values.employer}
                        changed={props.changed(props.index, 'employer')}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label="Start Date"
                        name="Start Date"
                        changed={(date) => onFieldChange('startDate', date)}
                        placeholder="mm/yyyy"
                        value={props.values.startDate}
                        monthHidden={props.values.startDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('startDateMonthHidden', isHidden)}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MonthYearPicker
                        label="End Date"
                        name="End Date"
                        changed={(date) => onFieldChange('endDate', date)}
                        placeholder="mm/yyyy"
                        showCurrentlySwitch={true}
                        presentDate={props.values.presentDate}
                        swichChangeHandler={handlePresentDateChange}
                        switchLabel="Currently work here"
                        value={props.values.endDate}
                        monthHidden={props.values.endDateMonthHidden}
                        changeMonthHiddenHandler={(isHidden) => onFieldChange('endDateMonthHidden', isHidden)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>                                        
                    <Input
                        variant="outlined"
                        key="city"
                        id="city"
                        type="text"
                        label="City"
                        value={props.values.city}
                        changed={props.changed(props.index, 'city')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={styles.Label}>Resposabilities & summary</InputLabel>
                    <TextEditor
                        value={props.values.description}
                        changed={(content) => onFieldChange('description', content)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default InternshipeItem;