import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    successMessage: null,
    userEmail: null,
    userFirstName: null,
    userLastName: null,
    resumeVisibility: 0,
    userDeleted: false,
};

const authInit = ( state, action ) => {
    return updateObject( state, { error: null, loading: false, successMessage: null} );
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true, successMessage: null} );
};

const getUserData = (state, action) => {
    return updateObject( state, {
        userEmail: action.email,
        userFirstName: action.firstName,
        userLastName: action.lastName
    } );    
}

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        userEmail: action.email,
        userFirstName: action.firstName,
        userLastName: action.lastName,
        error: null,
        loading: false,
        successMessage: 'auth',
    } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
        successMessage: 'not-auth',
    });
};

const userSuccess = (state, action) => {
    return updateObject( state, { 
        successMessage: action.successMessage,
        loading: false
    } );
};

const deleteUserSuccess = (state, action) => {
    return updateObject( state, { 
        successMessage: action.successMessage,
        loading: false,
        userDeleted: true,
    } );
};

const restoreUserSuccess = (state, action) => {
    return updateObject( state, { 
        successMessage: action.successMessage,
        loading: false,
        userDeleted: false,
    } );
};

const changeVisibilitySuccess = (state, action) => {
    return updateObject( state, { 
        successMessage: action.successMessage,
        resumeVisibility: action.resumeVisibility,
        loading: false,
    } );
};

const authLogout = (state, action) => {
    return updateObject(state, { 
        token: null, 
        userId: null,
        successMessage: 'not-auth', 
    });
};

const refreshTokenSuccess = (state, action) => {
    return updateObject(state, {token: action.token})
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_INIT: return authInit(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.USER_SUCCESS: return userSuccess(state, action);
        case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
        case actionTypes.RESTORE_USER_SUCCESS: return restoreUserSuccess(state, action);
        case actionTypes.CHANGE_VISIBILITY_SUCCESS: return changeVisibilitySuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.GET_USER_DATA: return getUserData(state, action);
        case actionTypes.TOKEN_REFRESH_SUCCESS: return refreshTokenSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;