import React from 'react';
import styles from './NavigationItems.module.scss';
import NavigationItem from '../NavigationItem/NavigationItem';
import { withRouter } from 'react-router-dom';

const NavigationItems = () => (
    <ul className={styles.NavigationItems}>
        <NavigationItem link="/choose-template" exact>Templates</NavigationItem>
        <NavigationItem link="https://www.resumeok.com/resume-samples/" external>Resume examples</NavigationItem>
        <NavigationItem link="https://www.resumeok.com/how-to-write-a-resume/" external>How to write your CV</NavigationItem>
    </ul>
);

export default withRouter(NavigationItems);