import React from 'react';
import { StyleSheet, Image } from '@react-pdf/renderer';

import bubblesBg from '../assets/images/resume_bg/bubbles-bg.png';
import cubesBg from '../assets/images/resume_bg/cubes-bg.png';
import dottedCirclesBg from '../assets/images/resume_bg/dotted-circles-bg.png';
import gradientDotsBg from '../assets/images/resume_bg/gradient-dots-bg.png';
import toysBg from '../assets/images/resume_bg/toys-bg.png';
import wavesBg from '../assets/images/resume_bg/waves-bg.png';

const patterns = {
    cubes: cubesBg,
    toys: toysBg,
    dottedCircles: dottedCirclesBg,
    bubbles: bubblesBg,
    gradientDots: gradientDotsBg,
    waves: wavesBg,
}

const styles = StyleSheet.create({
    topBackground: {
        position: 'absolute',
        top: 0,
        right: 0,
        minHeight: '11.69in',
        width: '8.27in',
        objectFit: 'contain',
        objectPositionX: '100%',
        display: 'block',
        //opacity: '0.2',
    },
});

export const addPdfPattern = (pattern, customStyles) => {
    return pattern ? (<Image src={patterns[pattern]} style={[styles.topBackground, customStyles]} fixed/>) : (<></>)
};