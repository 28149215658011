
import React from 'react';

import {Grid, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '../../components/UI/Select/Select';
import Autocomplete from '../../components/UI/Autocomplete/Autocomplete';

import styles from './ResumeBuilder.module.scss';

const levels = ['Native Speaker', 'Highly proficient', 'Very good command', 'Good working knowledge', 'Working knowledge', 'C2', 'C1', 'B2', 'B1', 'A2', 'A1'];

function LanguagesItem(props) {

    const onFieldChange = (fieldKey, content) => {
        props.changed(props.index, fieldKey, content)();
    }

    const [languageValue, setLanguageValue] = React.useState(props.values.language ? props.values.language : '');

    return (
        <div className={styles.FormRepeatedItem} >
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        freeSolo
                        value={languageValue} 
                        inputValue={props.values.language || ''} 
                        inputChanged={(event, newValue) => {
                            onFieldChange('language', newValue);
                        }}
                        changed={(event, newValue) => {
                            setLanguageValue(newValue);
                        }}
                        id="language"
                        options={props.languages} 
                        label="Language"
                    />
                </Grid>
                <Grid item xs={10} sm={5}>
                    <Select
                        id="language-level-select"
                        value={props.values.level}
                        changed={props.changed(props.index, 'level')}
                        placeholder="Select Level"
                        label="Level"
                        options={levels}
                    />
                </Grid>
                <Grid item xs={2} sm={1} className={styles.DeleteIconWrap}>            
                    <IconButton onClick={props.deleted} className={styles.DeleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default LanguagesItem;