import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './Layout.module.scss';
import Header from '../../components/Header/Header';
import MobileSide from '../../components/Navigation/MobileSide/MobileSide';
import { matchExact } from '../../shared/utility';

class Layout extends Component {
    state = {
        showMobileSide: false
    }

    mobileSideClosedHandler = () => {
        this.setState( { showMobileSide: false } );
    }

    mobileSideToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { showMobileSide: !prevState.showMobileSide };
        } );
    }

    render () {
        const { location} = this.props;
        let layoutClass = '';
        if (matchExact(/^\/sign-up$/, location.pathname)) {
            layoutClass = styles.SignUpLayout;
        } else if (matchExact(/^\/resume-builder/, location.pathname) || matchExact(/^\/sample/, location.pathname)) {
            layoutClass = styles.ResumeBuilderLayout;
        }

        if (matchExact(/^\/dashboard$/, location.pathname)) {
            document.body.classList.add('dashboard');
        } else {
            document.body.classList.remove('dashboard');
        }

        return (
            <>  
                <Header 
                    sideToggleClicked={this.mobileSideToggleHandler} 
                    showMobileMenu={this.state.showMobileSide} />
                <MobileSide
                    open={this.state.showMobileSide}
                    closed={this.mobileSideClosedHandler} />
                <main className={`${styles.Layout} ${layoutClass}`}>
                    {this.props.children}
                </main>
            </>
        )
    }
}

export default withRouter(Layout);