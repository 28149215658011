import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    coverLetters: [],
    loading: false,
    created: false,
    createdCoverLetterId: '',
    coverLetterConfiguration: {},
    coverLetterConfigurationId: null,
};

const authInit = ( state, action ) => {
    return updateObject( state, { createdCoverLetterId: '' } );
};

const createCoverLetterStart = ( state, action ) => {
    return updateObject( state, { loading: true, created: false, createdCoverLetterId: '' } );
};

const createCoverLetterSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        created: true,
        coverLetters: state.coverLetters.concat( action.coverLetterData ),
        createdCoverLetterId: action.coverLetterData.id
    } );
};

const createCoverLetterFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const deleteCoverLetterSuccess = ( state, action ) => {
    const updatedCoverLetters = state.coverLetters.filter((coverLetterItem) => coverLetterItem.id !== action.coverLetterId);
    return updateObject( state, {
        loading: false,
        created: true,
        coverLetters: updatedCoverLetters
    } );
};


const editCoverLetterSuccess = ( state, action ) => {
    const updatedCoverLetter = updateObject( action.coverLetterData );
    const updatedCoverLetters = state.coverLetters.map((coverLetterItem) => {
        if (coverLetterItem.id === action.coverLetterId) {
            coverLetterItem = updatedCoverLetter
        }
        return coverLetterItem;
    });

    return updateObject( state, {
        loading: false,
        created: true,
        coverLetters: updatedCoverLetters
    } );
};

const fetchCoverLettersStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchCoverLettersSuccess = ( state, action ) => {
    return updateObject( state, {
        coverLetters: action.coverLetters,
        loading: false
    } );
};

const fetchCoverLettersFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const getCoverLetterConfiguration = (state, action) => {
    return updateObject( state, { 
        coverLetterConfiguration: action.coverLetterConfiguration,
        coverLetterConfigurationId: action.coverLetterConfiguration.id,
    } );
};

const coverLetterConfigurationSuccess = (state, action) => {
    return updateObject( state, { 
        coverLetterConfiguration: action.coverLetterConfiguration,
    } );
};

const createCoverLetterConfiguration = (state, action) => {
    return updateObject( state, { 
        coverLetterConfigurationId: action.coverLetterConfigurationId,
        coverLetterConfiguration: action.coverLetterConfiguration,
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_INIT: return authInit(state, action);
        
        case actionTypes.CREATE_COVER_LETTER_START: return createCoverLetterStart( state, action );
        case actionTypes.CREATE_COVER_LETTER_SUCCESS: return createCoverLetterSuccess( state, action );
        case actionTypes.CREATE_COVER_LETTER_FAIL: return createCoverLetterFail( state, action );

        case actionTypes.DELETE_COVER_LETTER_SUCCESS: return deleteCoverLetterSuccess( state, action );
        case actionTypes.EDIT_COVER_LETTER_SUCCESS: return editCoverLetterSuccess( state, action );
        
        case actionTypes.FETCH_COVER_LETTERS_START: return fetchCoverLettersStart( state, action );
        case actionTypes.FETCH_COVER_LETTERS_SUCCESS: return fetchCoverLettersSuccess( state, action );
        case actionTypes.FETCH_COVER_LETTERS_FAIL: return fetchCoverLettersFail( state, action );

        
        case actionTypes.CREATE_COVER_LETTER_СONFIGURATION: return createCoverLetterConfiguration( state, action );
        case actionTypes.GET_COVER_LETTER_СONFIGURATION: return getCoverLetterConfiguration( state, action );
        case actionTypes.COVER_LETTER_СONFIGURATION_SUCCESS: return coverLetterConfigurationSuccess( state, action );

        default: return state;
    }
};

export default reducer;