import React, {useState, useEffect} from 'react';
import styles from './BuilderToolbar.module.scss';
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Box } from '@material-ui/core';

function CircularProgressWithLabel(props) {
    const [progressBarColor, setProgressBarColor] = useState('#eeeeee');

    useEffect(() => {
        switch (true) {
            case props.progressValue < 30:
                setProgressBarColor('#F03153');
                break;
        case props.progressValue >= 30 && props.progressValue < 70:
                setProgressBarColor('#FFA133');
                break;
        case props.progressValue >= 70:
            setProgressBarColor('#10b58c');
                break;
        default:
            break;
        }
    }, [props.progressValue] );

    const progressStyles = makeStyles(theme => ({
        circleDeterminate: {
            color: progressBarColor,
        },
    }));

    const classes = progressStyles();
    
    return (
        <Box position="relative" display="inline-flex" className={styles.CircularProgress}>
            <CircularProgress variant="determinate" value={props.progressValue} classes={{circleDeterminate: classes.circleDeterminate}} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <span>{`${Math.round(props.progressValue, 0)}%`}</span>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;