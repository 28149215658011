import React from 'react';
import {createUseStyles} from 'react-jss';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RoomIcon from '@material-ui/icons/Room';
import LinkIcon from '@material-ui/icons/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

function Madrid (props) {
    const mainColor = props.resumeConfiguration.primary_color || '#7222D4';
    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';

    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12 * multiplier;
    const headerTitleSize = 25 * multiplier;
    const headerSubtitleSize = 16 * multiplier;
    const dateTextSize = 12 * multiplier;
    const sectionTitleSize = 18.5 * multiplier;
    const repeatedItemSize = 18 * multiplier;
    const contactItemSize = 11 * multiplier;
    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, dateTextSize, sectionTitleSize, repeatedItemSize, contactItemSize};

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const templateStyles = createUseStyles({
        link: {
            color: (prop) => prop.mainColor,
        },
        header: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            position: 'relative',
            padding: '25px 35px 10px',
            borderBottom: '1px solid #fff',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 3,
        },
        headerText: {
            flex: '1 0 35%',
            paddingLeft: '15px',
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            lineHeight: '42px',
            workBreak: 'break-all',
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            lineHeight: '24px',
            color: (prop) => prop.mainColor,
            marginBottom: '10px',
        },
        userImage: {
            width: '150px',
            minHeight: '150px',
            maxWidth: '150px',
            borderRadius: '50% 50% 0 50%',
            objectFit: 'cover',
            flex: '1 0 auto',
            margin: '0 10px',
        },
        headerContact: {
            flex: '1 0 35%',
        },
        contactItem: {
            display: 'flex',
            flexDirection: 'row-reverse',
            lineHeight: 2,
            textAlign: 'right',
            fontSize: (prop) => `${prop.contactItemSize}pt`,
            '& a': {
                color: 'inherit',
                wordBreak: 'break-all',
            },
            '& svg': {
                marginLeft: '0.5rem',
                marginTop: '0.25rem',
            },
        },
        contactTitle: {
            color: (prop) => prop.mainColor,
            fontWeight: 700,
            marginLeft: '0.5rem',
        },
        contactLine: {
            fontFamily: (prop) => prop.fontFamily,
            backgroundColor: '#f3f3f3',
            padding: '10px 25px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'relative',
            zIndex: 5,
            '& $contactItem': {
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                lineHeight: 2,
                '&:not(:last-child)': {
                    paddingRight: '1.5rem',
                },
                '& a': {
                    color: 'inherit',
                },
                '& svg': {
                    marginRight: '0.5rem',
                },
            },
        },
        sectionTitle: {
            display: 'block',
            color: '#000',
            textTransform: 'uppercase',
            borderBottom: (prop) => `3px solid ${prop.mainColor}`,
            letterSpacing: 1.3,
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 'bold',
            marginBottom: '10px',
            padding: '0 25px',
        },
        dateCityWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontStyle: 'italic',
            fontSize: (prop) => `${prop.dateTextSize}pt`,
            lineHeight: '18px',
            color: (prop) => prop.mainColor,
        },
        description: {
            margin: '10px 0',
            '& ol': {
                margin: 0,
                paddingLeft: '14pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '14pt',
                    '&:before': {
                        content: '""',
                        display: 'inline-block',
                        position: 'absolute',
                        left: '0',
                        top: '6pt',
                        width: '8pt',
                        height: '1pt',
                        backgroundColor: (prop) => prop.mainColor,  
                        marginRight: '7pt',
                        minWidth: '8pt'
                    }
                },
            },
        },
        descriptionTitle: {
            color: (prop) => prop.mainColor,
            fontStyle: 'italic',
            position: 'absolute',
            top: '-8px',
            left: '15px',
        },
        main: {
            flex: '1 1 100%',
            zIndex: 1,
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            '& p': {
                lineHeight: 1.3,
            },
            '& a': {
                color: (prop) => prop.mainColor,
            }
        },
        skill: {
            display: 'inline-block',
            borderRadius: '6px',
            padding: '6px 14px',
            backgroundColor: (prop) => prop.mainColor,
            color: '#fff',
            marginBottom: '0.5rem',

            '&:not(:last-child)': {
                marginRight: '0.5rem',
            }
        },
        flexRow: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        languagesItem: {
            flexBasis: '23%',
            marginRight: '2%',
        },
        languageLevel: {
            color: (prop) => prop.mainColor,
            fontStyle: 'italic',
        },
        styledSection: {
            '& $repeatedItem': {
                position: 'relative',
                '&:before': {
                    content: '""',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    border: (prop) => `3px solid ${prop.mainColor}`,
                    position: 'absolute',
                    top: '3px',
                    left: '-25px',
                },
                '&:not(:last-child)::after': {
                    content: '""',
                    width: '2px',
                    height: '100%',
                    backgroundColor: (prop) => prop.mainColor,
                    position: 'absolute',
                    top: '19px',
                    left: '-19px',
                },
            },
            '& $sectionContent': {
                paddingLeft: '40px',
            },
        },
        sectionContent: {
            padding: '10px 25px',
        },
        repeatedItem: {
            marginBottom: '1rem',
            '& $description': {
                position: 'relative',
                padding: '0 10px',
                margin: '10px 0',
                '&$withTitle': {
                    paddingTop: '10px',
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '10px',
                    height: '100%',
                    background: '#fff',
                    borderLeft: (prop) => `2px solid ${prop.mainColor}`,
                    borderTop: (prop) => `2px solid ${prop.mainColor}`,
                    borderBottom: (prop) => `2px solid ${prop.mainColor}`,
                },
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '10px',
                    height: '100%',
                    background: '#fff',
                    borderRight: (prop) => `2px solid ${prop.mainColor}`,
                    borderTop: (prop) => `2px solid ${prop.mainColor}`,
                    borderBottom: (prop) => `2px solid ${prop.mainColor}`,
                }
            }
        },
        withTitle: {
        },
        repeatedItemTitle: {
            fontSize: (prop) => `${prop.repeatedItemSize}pt`,
            lineHeight: '26px',
            fontWeight: 700,
        },
        repeatedItemSubtitle: {
            fontSize: (prop) => `${prop.repeatedItemSize}pt`,
            lineHeight: '26px',
        }
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<span className={classes.contactItem}><FacebookIcon style={{ color: mainColor }} /> <a href={socials.facebook}>{showUrl(socials.facebook)}</a></span>);
        }
        if (socials.linkedin) {
            socialsList.push(<span className={classes.contactItem}><LinkedInIcon style={{ color: mainColor }} /> <a href={socials.linkedin}>{showUrl(socials.linkedin)}</a></span>);
        }
        if (socials.twitter) {
            socialsList.push(<span className={classes.contactItem}><TwitterIcon style={{ color: mainColor }} /> <a href={socials.twitter}>{showUrl(socials.twitter)}</a></span>);
        }
        if (socials.instagram) {
            socialsList.push(<span className={classes.contactItem}><InstagramIcon style={{ color: mainColor }} /> <a href={socials.instagram}>{showUrl(socials.instagram)}</a></span>);
        }
        if (socials.github) {
            socialsList.push(<span className={classes.contactItem}><GitHubIcon style={{ color: mainColor }} /> <a href={socials.github}>{showUrl(socials.github)}</a></span>);
        }
        if (socials.youtube) {
            socialsList.push(<span className={classes.contactItem}><YouTubeIcon style={{ color: mainColor }} /> <a href={socials.youtube}>{showUrl(socials.youtube)}</a></span>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <div className={`movable ${classes.sectionWrap}`}>
            <p className={classes.sectionTitle}><span>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</span></p>
            <div className={classes.sectionContent}>
                {props.resume.skillsFields.map((skillItem, index) => 
                    (skillItem.skill) ? (<span key={index} className={classes.skill}>{skillItem.skill}</span>) : <></>
                )}
            </div>
        </div>
    ) : <></>;

    return (
        <>
            <div className={classes.header}>
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}

                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                </div>
                
                <div className={classes.headerContact}>
                    {props.resume.email && (<span className={classes.contactItem}><EmailIcon style={{ color: mainColor }} /> <a href={`mailto:${props.resume.email}`}>{props.resume.email}</a></span>)}
                    {props.resume.phoneNumber && (<span className={classes.contactItem}><PhoneIphoneIcon style={{ color: mainColor }} /> <span>{props.resume.phoneNumber}</span></span>)}
                    {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={classes.contactItem}><RoomIcon style={{ color: mainColor }} /><span>{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span></span>)}
                    {props.resume.nationality && (<span className={classes.contactItem}><span className={classes.contactTitle}>Nationality</span> <span>{props.resume.nationality}</span></span>)}
                    {props.resume.birthPlace && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Place</span> <span>{props.resume.birthPlace}</span></span>)}
                    {props.resume.birthDate && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Date</span> <span>{moment(props.resume.birthDate).format("DD/MM/YYYY")}</span></span>)}
                    {(Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (<span className={classes.contactItem}><span className={classes.contactTitle}>Driving license</span> <span>{props.resume.drivingLicenseType.join(', ')}</span></span>) : (<></>)}
                    {(props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) ? props.resume.websiteSocialFields.map((socialItem, index) => (
                        (socialItem.link || socialItem.name) ? (
                            <span key={index} className={classes.contactItem}><LinkIcon style={{ color: mainColor }} /> <a href={socialItem.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                        ) : (<></>)
                    )) : (<></>)}
                </div>
            </div>

            <div className={classes.main}>

                {props.resume.socials ? (
                    <div className={classes.contactLine}>
                        {showSocials(props.resume.socials)}
                    </div>
                ) : <></>}

                {props.resume.careerSummary ? (
                    <div className={classes.sectionWrap}>
                        {props.resume.careerSummaryTitle ? (<p className={`movable ${classes.sectionTitle}`}><span>{props.resume.careerSummaryTitle}</span></p>) : <></>}
                        <div className={`movable ${classes.description} ${classes.sectionContent}`}>{generateTinyMceElements(props.resume.careerSummary)}</div>
                    </div>
                ) : <></>}
                
                {resumeType === 'functional' ? skillsSection : <></>}

                {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                    <div className={classes.styledSection}>                               
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Professional Experience'}</span></p>
                        <div className={classes.sectionContent}>
                            {props.resume.workExperienceItems.map((workItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {workItem.jobTitle && (<p className={classes.repeatedItemTitle}>{workItem.jobTitle}</p>)}
                                    {workItem.company && (<p className={classes.repeatedItemSubtitle}>{workItem.company}</p>)}
                                    {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{workItem.city}</p>
                                        </div>
                                    ) : <></>}
                                    {workItem.description ? (<div className={`${classes.description} ${classes.withTitle}`}><span className={classes.descriptionTitle}>Description</span>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}
                
                {props.resume.educationFields && props.resume.educationFields.length  ? (
                    <div className={classes.styledSection}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</span></p>
                        <div className={classes.sectionContent}>
                            {props.resume.educationFields.map((educationItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {educationItem.degree && (<p className={classes.repeatedItemTitle}>{educationItem.degree}</p>)}
                                    {educationItem.institution && (<p className={classes.repeatedItemSubtitle}>{educationItem.institution}</p>)}
                                    {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{educationItem.city}</p>
                                        </div>
                                    ) : <></>}
                                    {educationItem.description ? (<div className={`${classes.description} ${classes.withTitle}`}><span className={classes.descriptionTitle}>Description</span>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

                {resumeType === 'chronological' ? skillsSection : <></>}
                
                {props.resume.internshipFields && props.resume.internshipFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</span></p>
                        <div className={classes.sectionContent}>
                            {props.resume.internshipFields.map((internshipItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {internshipItem.jobTitle && (<p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}</p>)}
                                    {internshipItem.employer && (<p className={classes.repeatedItemSubtitle}>{internshipItem.employer}</p>)}
                                    {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        <p>{internshipItem.city}</p>
                                    </div>
                                    ) : <></>}
                                    {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.coursesFields && props.resume.coursesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</span></p>
                        <div className={classes.sectionContent}>
                            {props.resume.coursesFields.map((courseItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {courseItem.course && (<p className={classes.repeatedItemTitle}>{courseItem.course}</p>)}
                                    {courseItem.institution && (<p className={classes.repeatedItemSubtitle}>{courseItem.institution}</p>)}
                                    {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        </div>
                                    ) : <></> }
                                    {courseItem.description ?(<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>)/* (<p>{courseItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}
                
                {props.resume.referencesFields && props.resume.referencesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle}`}><span>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</span></p>
                        <div className={classes.sectionContent}>
                            {!Boolean(Number(props.resume.referencesVisibility)) ? 
                            props.resume.referencesFields.map((referenceItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {referenceItem.referent && (<p className={classes.repeatedItemTitle}>{referenceItem.referent}</p>)}
                                    {referenceItem.company && (<p className={classes.repeatedItemSubtitle}>{referenceItem.company}</p>)}
                                    <p>
                                        <a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}
                                    </p>
                                    {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>)/* (<p>{referenceItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            )) : (<p>References available upon request</p>)}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.languagesFields && props.resume.languagesFields.length ? (
                    <div className={`movable ${classes.sectionWrap}`}>
                        <p className={classes.sectionTitle}><span>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</span></p>
                        <div className={`${classes.flexRow} ${classes.sectionContent}`}>
                            {props.resume.languagesFields.map((languageItem, index) => 
                                (languageItem.language || languageItem.languageLevel) ? (<div key={index} className={`${classes.repeatedItem} ${classes.languagesItem}`}>
                                    {languageItem.language ? (<p>{languageItem.language}</p>) : (<></>)}
                                    {languageItem.level ? (<span className={classes.languageLevel}>{languageItem.level}</span>) : (<></>)}
                                </div>) : (<></>)
                            )}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.customFields && props.resume.customFields.length ? (
                    <div className={classes.sectionWrap}>
                        {props.resume.customSectionTitle ? (<p className={`movable ${classes.sectionTitle}`}><span>{props.resume.customSectionTitle}</span></p>) : (<></>)}
                        <div className={classes.sectionContent}>
                            {props.resume.customFields.map((customItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {customItem.customItemTitle && (<p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>)}
                                    {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                        <div className={classes.dateCityWrap}>
                                            <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY")}</p>
                                            <p>{customItem.city}</p>
                                        </div>
                                    ) : <></> }
                                    {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                                </div>
                            ))}
                        </div>
                    </div>
                ) : <></>}

            </div>
                    
        </>
    )
}

export default Madrid;