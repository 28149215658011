import React, { useState, useEffect } from 'react';
import axios from '../../axios-resumes';
import {CancelToken} from 'axios';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from '@material-ui/core';

import styles from './ResumeBuilder.module.scss';

import {apiUrl} from '../../config';

function TemplatesSwitcher(props) {

    const [resumeTemplates, setResumeTemplates] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const source = CancelToken.source();

        axios.get('/templates', { cancelToken: source.token })
        .then( response => {
            setResumeTemplates(response.data.templates);
            setDataLoaded(true);
        })
        .catch( err => {
            console.log(err);
        });

        return () => {
            source.cancel();
        };
    }, []);

    const setActiveSlide = (templateId) => {
        props.onChange({ templateId: templateId});
    }

    const closeTemplateSwitcher = (e) => {
        e.preventDefault();
        props.onClose();
    }

    const templatesList = resumeTemplates.map((template, index) => {
        return (
          <div key={index} className={`${styles.TemplateItem} ${props.templateId === template.id ? styles.Current : ''}`}>
              <img src={`${apiUrl}/storage${template.preview_image}`} alt={template.title} onClick={() => setActiveSlide(template.id)} />
          </div>
        )
    });

    return dataLoaded && (
        <div className={`${styles.TemplatesSwitcher} ${props.open ? styles.Open : ''}`}>
            <Link href="#" className={styles.Link} onClick={closeTemplateSwitcher}><KeyboardBackspaceIcon /> Back to editor</Link>
            <h3>Select a template for your resume</h3>
            <div className={styles.TemplatesList}>
                {templatesList}
            </div>
        </div>
    )
}

export default TemplatesSwitcher;