import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ResumeTypeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M4.86497 2.24266L2.7499 0.10117C2.61702 -0.0337234 2.31889 -0.0337234 2.18602 0.10117L0.0709424 2.24266C-0.102939 2.41833 0.0625997 2.67084 0.352879 2.67084H1.41043V7.75683C1.41043 7.90479 1.5681 8.02451 1.76294 8.02451H3.17298C3.36782 8.02451 3.52079 7.90479 3.52079 7.75683V2.67084H4.58301C4.87355 2.67084 5.03867 2.41815 4.86497 2.24266Z"/>
            <path d="M8.81353 6.46112H7.75128V1.3751C7.75128 1.22715 7.59832 1.10742 7.40347 1.10742H5.99341C5.79857 1.10742 5.6409 1.22715 5.6409 1.3751V6.4611H4.58335C4.29305 6.4611 4.12753 6.71361 4.30142 6.88928L6.41652 9.03076C6.55726 9.17363 6.83986 9.17342 6.98039 9.03076L9.09547 6.8893C9.26917 6.71381 9.10405 6.46112 8.81353 6.46112Z"/>
       </SvgIcon>
    );
}