import React from 'react';
import styles from './Header.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Logo from '../Logo/Logo';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import UserNavigationItems from '../Navigation/UserNavigationItems/UserNavigationItems';
import AccountNavigationItems from '../Navigation/AccountNavigationItems/AccountNavigationItems';
import MobileToggle from '../Navigation/MobileSide/MobileToggle/MobileToggle';

import { matchExact } from '../../shared/utility';
import { matchPath } from 'react-router';

const Header = (props) => {
    const { location } = props;
    if (matchExact(/^\/sign-up$/, location.pathname) || (!props.isAuthenticated && location.pathname.match(/sample/)) ) {
        return null;
    }

    if (matchPath(location.pathname, '/r/:idHash')) {
        return (
            <div className={`${styles.Logo} ${styles.LogoHeader}`}>
                <Logo />
            </div>
        )
    }

    const isBuilderPage = location.pathname.match(/resume-builder/) || location.pathname.match(/cover-letter-builder/);

    return (
        <header id="header" className={`${styles.Header} ${isBuilderPage ? styles.BuilderHeader : ''}`}>
            <div className={styles.Logo}>
                <Logo />
            </div>
            <nav className={`${styles.GeneralNavigation} ${styles.DesktopOnly}`}>
                {!isBuilderPage && <NavigationItems />}
            </nav>
            <nav className={`${styles.UserNavigation} ${styles.DesktopOnly}`}>
                { props.isAuthenticated ? <AccountNavigationItems /> : <UserNavigationItems /> }
            </nav>
            <MobileToggle clicked={props.sideToggleClicked} open={props.showMobileMenu} />
        </header>
        
    )
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default withRouter( connect( mapStateToProps, null )( Header ) );