import React from 'react';
import {createUseStyles} from 'react-jss';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RoomIcon from '@material-ui/icons/Room';
import LinkIcon from '@material-ui/icons/Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';

import moment from 'moment';
import parse from 'html-react-parser';
import { generateDateString, generateContactString } from '../../../shared/utility';

function Berlin (props) {
    const mainColor = props.resumeConfiguration.primary_color || '#00C8AA';

    const fontFamily = props.resumeConfiguration.font_name || 'Open Sans';
    
    const fontSize = props.resumeConfiguration.font_size || 'small';
    const multiplier = fontSize === 'large' ? 1.1 : fontSize === 'small' ? 0.8 :1;
    const mainSize = 12.5 * multiplier;
    const headerTitleSize = 34.5 * multiplier;
    const headerSubtitleSize = 18 * multiplier;
    const dateTextSize = 12 * multiplier;
    const sectionTitleSize = 21 * multiplier;
    const repeatedTitleSize = 19 * multiplier;
    const repeatedSubtitleSize = 18 * multiplier;
    const fontSizes = {mainSize, headerTitleSize, headerSubtitleSize, dateTextSize, sectionTitleSize, repeatedTitleSize, repeatedSubtitleSize};

    const resumeType = props.resumeConfiguration.resume_type || 'chronological';

    const templateStyles = createUseStyles({
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            position: 'relative',
            padding: '20pt',
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            zIndex: 3,
            lineHeight: 1.3,
            '& p': {
                lineHeight: 1.3,
            },
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            wordBreak: 'break-word',
        },
        headerTitle: {
            fontSize: (prop) => `${prop.headerTitleSize}pt`,
            lineHeight: 1.2,
            marginBottom: 0,
        },
        headerSubtitle: {
            fontSize: (prop) => `${prop.headerSubtitleSize}pt`,
            color: '#666766',
        },
        userImage: {
            width: '30mm',
            height: '30mm',
            objectFit: 'cover',
            borderRadius: '10px',
            flexGrow: 0,
            marginRight: '20px',
            border: '2px solid #d3d3d3',
        }, 
        contactLine: {
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            backgroundColor: '#f3f3f3',
            padding: '7pt 20pt',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'relative',
            zIndex: 5,
        },
        contactItem: {
            display: 'inline-flex',
            alignItems: 'center',
            lineHeight: 2,
            '&:not(:last-child)': {
                paddingRight: '1.5rem',
            },
            '& a': {
                color: 'inherit',
            },
            '& svg': {
                marginRight: '.5rem',
            },
        },
        sectionWrap: {
            marginBottom: '22pt',
        },
        sectionTitle: {
            color: (prop) => prop.mainColor,
            textTransform: 'uppercase',
            letterSpacing: '1.5px',
            fontSize: (prop) => `${prop.sectionTitleSize}pt`,
            lineHeight: 1,
            fontWeight: 700,
            marginBottom: '10pt',
        },
        main: {
            fontFamily: (prop) => prop.fontFamily,
            fontSize: (prop) => `${prop.mainSize}pt`,
            padding: '20pt',
            zIndex: 3,
            flexGrow: 1,
            lineheight: 1.3,
        },
        link: {
            color: (prop) => prop.mainColor,
        },
        contactTitle: {
            color: (prop) => prop.mainColor,
            fontWeight: 700,
            marginRight: '0.5rem',
        },
        languageLevel: {
            color: (prop) => prop.mainColor,
            fontStyle: 'italic',
        },
        description: {
            marginTop: '8pt',
            '& ol': {
                margin: 0,
                paddingLeft: '14pt',
                lineHeight: 1.3,
            },
            '& ul': {
                margin: 0,
                padding: 0,
                lineHeight: 1.3,
                listStyle: 'none',
                '& li': {
                    position: 'relative',
                    paddingLeft: '14pt',
                    '&:before': {
                        content: '""',
                        display: 'inline-block',
                        position: 'absolute',
                        left: '0',
                        top: '6pt',
                        width: '8pt',
                        height: '1pt',
                        backgroundColor: (prop) => prop.mainColor,  
                        marginRight: '7pt',
                        minWidth: '8pt'
                    }
                },
            },
            '& a': {
                color: (prop) => prop.mainColor,
                textDecoration: 'none',
            }
        },
        repeatedItem: {
            marginBottom: '1rem',
        },
        repeatedItemTitle: {
            fontSize: (prop) => `${prop.repeatedTitleSize}pt`,
            lineHeight: 1.2,
            fontWeight: 700,
        },
        repeatedItemSubtitle: {
            fontSize: (prop) => `${prop.repeatedSubtitleSize}pt`,
            fontWeight: 400,
            lineHeight: 1.2,
        },
        dateCityWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontStyle: 'italic',
            fontSize: (prop) => `${prop.dateTextSize}pt`,
            color: '#666766',
        },
        dateText: {},
        skill: {
            display: 'inline-block',
            borderRadius: '6px',
            padding: '6px 14px',
            color: '#fff',
            marginBottom: '0.5rem',
            backgroundColor: (prop) => prop.mainColor,
            '&:not(:last-child)': {
                marginRight: '0.5rem',
            },
        },
        flexRow: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        languagesItem: {
            flexBasis: '23%',
            marginRight: '2%',
        },
    });

    let classes = templateStyles({ mainColor, fontFamily, ...fontSizes });

    const generateTinyMceElements = (content) => {
        return parse(content);
    }

    const showUrl = (url) => {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    }

    const showSocials = (socials) => {
        let socialsList = [];
        
        if (socials.facebook) {
            socialsList.push(<span className={classes.contactItem}><FacebookIcon style={{ color: mainColor }} /> <a href={socials.facebook} className={classes.link}>{showUrl(socials.facebook)}</a></span>);
        }
        if (socials.linkedin) {
            socialsList.push(<span className={classes.contactItem}><LinkedInIcon style={{ color: mainColor }} /> <a href={socials.linkedin} className={classes.link}>{showUrl(socials.linkedin)}</a></span>);
        }
        if (socials.twitter) {
            socialsList.push(<span className={classes.contactItem}><TwitterIcon style={{ color: mainColor }} /> <a href={socials.twitter} className={classes.link}>{showUrl(socials.twitter)}</a></span>);
        }
        if (socials.instagram) {
            socialsList.push(<span className={classes.contactItem}><InstagramIcon style={{ color: mainColor }} /> <a href={socials.instagram} className={classes.link}>{showUrl(socials.instagram)}</a></span>);
        }
        if (socials.github) {
            socialsList.push(<span className={classes.contactItem}><GitHubIcon style={{ color: mainColor }} /> <a href={socials.github} className={classes.link}>{showUrl(socials.github)}</a></span>);
        }
        if (socials.youtube) {
            socialsList.push(<span className={classes.contactItem}><YouTubeIcon style={{ color: mainColor }} /> <a href={socials.youtube} className={classes.link}>{showUrl(socials.youtube)}</a></span>);
        }

        return socialsList;
    }

    const skillsSection = props.resume.skillsFields && props.resume.skillsFields.length ? (
        <div className={`movable ${classes.sectionWrap}`}>
            <p className={`${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.skillSectionTitle ? props.resume.skillSectionTitle : 'Skills'}</span></p>
            {props.resume.skillsFields.map((skillItem, index) => 
                (skillItem.skill) ? (<span key={index} className={`${classes.skill} ${classes.skill}`}>{skillItem.skill}</span>) : <></>
            )}
        </div>
    ) : <></>;

    return (
        <>
            <div className={classes.header}>
                {props.resume.userImage ? (
                    <img 
                        className={classes.userImage} 
                        src={props.resume.userImage}
                        alt="user avatar"
                    />
                ) : <></>}
            
                <div className={classes.headerText}>
                    <p className={classes.headerTitle}>{props.resume.firstName} {props.resume.lastName}</p>
                    <p className={classes.headerSubtitle}>{props.resume.jobPosition}</p>
                    {props.resume.careerSummary ? (<div className={classes.description}>{generateTinyMceElements(props.resume.careerSummary)}</div>) : null}
                </div>
            </div>

            {props.resume.email || props.resume.phoneNumber || props.resume.nationality || 
            props.resume.birthPlace || props.resume.birthDate || props.resume.adress || 
            props.resume.city || props.resume.postalCode || props.resume.country || 
            (Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length) ? (
            <div className={classes.contactLine}>
                {props.resume.email && (<span className={classes.contactItem}><EmailIcon style={{ color: mainColor }} /> <a href={`mailto:${props.resume.email}`} className={classes.link}>{props.resume.email}</a></span>)}
                {props.resume.phoneNumber && (<span className={classes.contactItem}><PhoneIphoneIcon style={{ color: mainColor }} /> {props.resume.phoneNumber}</span>)}
                {(props.resume.adress || props.resume.city || props.resume.postalCode || props.resume.country) && (<span className={classes.contactItem}><RoomIcon style={{ color: mainColor }} />{generateContactString(props.resume.adress, props.resume.city, props.resume.postalCode, props.resume.country)}</span>)}
                {props.resume.nationality && (<span className={classes.contactItem}><span className={classes.contactTitle}>Nationality:</span> {props.resume.nationality}</span>)}
                {props.resume.birthPlace && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Place:</span> {props.resume.birthPlace}</span>)}
                {props.resume.birthDate && (<span className={classes.contactItem}><span className={classes.contactTitle}>Birth Date:</span> {moment(props.resume.birthDate).format("DD/MM/YYYY")}</span>)}
                {Boolean(Number(props.resume.drivingLicense)) && props.resume.drivingLicenseType.length ? (<span className={classes.contactItem}><span className={classes.contactTitle}>Driving license:</span> {props.resume.drivingLicenseType.join(', ')}</span>) : (<></>)}
            </div>
            ) : <></>}

            {((props.resume.websiteSocialFields && props.resume.websiteSocialFields.length) || props.resume.socials) ? (
                <div className={classes.contactLine}>
                    {props.resume.websiteSocialFields.map((socialItem, index) => (
                        (socialItem.link || socialItem.name) ? (
                            <span key={index} className={classes.contactItem}><LinkIcon style={{ color: mainColor }} /> <a href={socialItem.link} className={classes.link}>{socialItem.name ? socialItem.name : socialItem.link}</a></span>
                        ) : (<></>)
                    ))}
                    
                    {props.resume.socials ? showSocials(props.resume.socials) : (<></>)}
                </div>
            ) : <></>}

            <div className={classes.main}>

                {resumeType === 'functional' ? skillsSection : <></>}

                {props.resume.workExperienceItems && props.resume.workExperienceItems.length  ? (
                    <div className={classes.sectionWrap}>                                
                        <p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.experienceSectionTitle ? props.resume.experienceSectionTitle : 'Work Experience'}</span></p>
                        {props.resume.workExperienceItems.map((workItem, index) => (
                            <div key={index} className={`movable ${classes.repeatedItem}`}>
                                {workItem.jobTitle && (<p className={classes.repeatedItemTitle}>{workItem.jobTitle}</p>)}
                                {workItem.company && (<p className={classes.repeatedItemSubtitle}>{workItem.company}</p>)}
                                {(workItem.startDate || workItem.endDate || workItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(workItem.startDate, workItem.endDate, workItem.presentDate, workItem.startDateMonthHidden, workItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        <p>{workItem.city}</p>
                                    </div>
                                ) : <></>}
                                {workItem.description ? (<div className={classes.description}>{generateTinyMceElements(workItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {props.resume.educationFields && props.resume.educationFields.length  ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.educationSectionTitle ? props.resume.educationSectionTitle : 'Education'}</span></p>
                        {props.resume.educationFields.map((educationItem, index) => (
                            <div key={index} className={`movable ${classes.repeatedItem}`}>
                                {educationItem.degree && (<p className={classes.repeatedItemTitle}>{educationItem.degree}</p>)}
                                {educationItem.institution && (<p className={classes.repeatedItemSubtitle}>{educationItem.institution}</p>)}
                                {(educationItem.startDate || educationItem.endDate || educationItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(educationItem.startDate, educationItem.endDate, educationItem.presentDate, educationItem.startDateMonthHidden, educationItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        <p>{educationItem.city}</p>
                                    </div>
                                ) : <></>}
                                {educationItem.description ? (<div className={classes.description}>{generateTinyMceElements(educationItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {resumeType === 'chronological' ? skillsSection : <></>}
                
                {props.resume.internshipFields && props.resume.internshipFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.internshipSectionTitle ? props.resume.internshipSectionTitle : 'Internship'}</span></p>
                        {props.resume.internshipFields.map((internshipItem, index) => (
                            <div key={index} className={`movable ${classes.repeatedItem}`}>
                                {internshipItem.jobTitle && (<p className={classes.repeatedItemTitle}>{internshipItem.jobTitle}</p>)}
                                {internshipItem.employer && (<p className={classes.repeatedItemSubtitle}>{internshipItem.employer}</p>)}
                                {(internshipItem.startDate || internshipItem.endDate || internshipItem.presentDate) ? (
                                <div className={classes.dateCityWrap}>
                                    <p className={classes.dateText}>{generateDateString(internshipItem.startDate, internshipItem.endDate, internshipItem.presentDate, internshipItem.startDateMonthHidden, internshipItem.endDateMonthHidden, "MM/YYYY")}</p>
                                    <p>{internshipItem.city}</p>
                                </div>
                                ) : <></>}
                                {internshipItem.description ? (<div className={classes.description}>{generateTinyMceElements(internshipItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

                {props.resume.coursesFields && props.resume.coursesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.courseSectionTitle ? props.resume.courseSectionTitle : 'Courses'}</span></p>
                        {props.resume.coursesFields.map((courseItem, index) => (
                            <div key={index} className={`movable ${classes.repeatedItem}`}>
                                {courseItem.course && (<p className={classes.repeatedItemTitle}>{courseItem.course}</p>)}
                                {courseItem.institution && (<p className={classes.repeatedItemSubtitle}>{courseItem.institution}</p>)}
                                {(courseItem.startDate || courseItem.endDate || courseItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(courseItem.startDate, courseItem.endDate, courseItem.presentDate, courseItem.startDateMonthHidden, courseItem.endDateMonthHidden, "MM/YYYY")}</p>
                                    </div>
                                ) : <></> }
                                {courseItem.description ? (<div className={classes.description}>{generateTinyMceElements(courseItem.description)}</div>)/* (<p>{courseItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}
                
                {props.resume.referencesFields && props.resume.referencesFields.length ? (
                    <div className={classes.sectionWrap}>
                        <p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.referenceSectionTitle ? props.resume.referenceSectionTitle : 'References'}</span></p>
                        {!Boolean(Number(props.resume.referencesVisibility)) ? 
                            props.resume.referencesFields.map((referenceItem, index) => (
                                <div key={index} className={`movable ${classes.repeatedItem}`}>
                                    {referenceItem.referent && (<p className={classes.repeatedItemTitle}>{referenceItem.referent}</p>)}
                                    {referenceItem.company && (<p className={classes.repeatedItemSubtitle}>{referenceItem.company}</p>)}
                                    <p>
                                        <a className={classes.link} href={`mailto:${referenceItem.email}`}>{referenceItem.email}</a> {referenceItem.phone}
                                    </p>
                                    {referenceItem.description ? (<div className={classes.description}>{generateTinyMceElements(referenceItem.description)}</div>)/* (<p>{referenceItem.description.replace(/<[^>]+>/g, '')}</p>) */ : <></> }
                                </div>
                            )) : (<p>References available upon request</p>)}
                    </div>
                ) : <></>}

                {props.resume.languagesFields && props.resume.languagesFields.length ? (
                    <div className={`movable ${classes.sectionWrap}`}>                            
                        <p className={`${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.languageSectionTitle ? props.resume.languageSectionTitle : 'Languages'}</span></p>
                        <div className={classes.flexRow}>
                            {props.resume.languagesFields.map((languageItem, index) => 
                                (languageItem.language || languageItem.languageLevel) ? (<div key={index} className={`${classes.repeatedItem} ${classes.languagesItem}`}>
                                    {languageItem.language ? (<p>{languageItem.language}</p>) : (<></>)}
                                    {languageItem.level ? (<span className={classes.languageLevel}>{languageItem.level}</span>) : (<></>)}
                                </div>) : (<></>)
                            )}
                        </div>
                    </div>
                ) : <></>}

                {props.resume.customFields && props.resume.customFields.length ? (
                    <div className={classes.sectionWrap}>
                        {props.resume.customSectionTitle ? (<p className={`movable ${classes.sectionTitle} ${classes.sectionTitle}`}><span>{props.resume.customSectionTitle}</span></p>) : (<></>)}
                        {props.resume.customFields.map((customItem, index) => (
                            <div key={index} className={`movable ${classes.repeatedItem}`}>
                                {customItem.customItemTitle && (<p className={classes.repeatedItemTitle}>{customItem.customItemTitle}</p>)}
                                {(customItem.startDate || customItem.endDate || customItem.presentDate) ? (
                                    <div className={classes.dateCityWrap}>
                                        <p className={classes.dateText}>{generateDateString(customItem.startDate, customItem.endDate, customItem.presentDate, customItem.startDateMonthHidden, customItem.endDateMonthHidden, "MM/YYYY")}</p>
                                        <p>{customItem.city}</p>
                                    </div>
                                ) : <></> }
                                {customItem.description ? (<div className={classes.description}>{generateTinyMceElements(customItem.description)}</div>) : <></> }
                            </div>
                        ))}
                    </div>
                ) : <></>}

            </div>
                    
        </>
    )
}

export default Berlin;