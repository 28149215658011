import React from 'react';
import {Switch} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const switchStyles = makeStyles(theme => ({
    root: {
        margin: '0 0 0 1rem',
    },
    switchBase: {
        color: '#fff',
        left: 'auto',
        right: '0',
        '& + $track': {
            backgroundColor: '#F03153',
            opacity: '1',
            padding: '8px',
            borderRadius: '10px'
        },
        '&$checked': {
          color: '#fff',
          transform: 'translateX(-20px)',
        },
        '&$checked + $track': {
          backgroundColor: '#24D39F',
          opacity: '1',
        },
    },
    checked: {},
    track: {},
}));

function UiSwitch(props) {
    const classes = switchStyles();

    return (
        <Switch
            checked={props.checked}
            onChange={props.swichChangeHandler}
            name={props.name}
            color="primary"
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
        />
    );
}

export default UiSwitch;