import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import { Paper, IconButton, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AutosizeInput from 'react-input-autosize';

import Dialog from '../../components/UI/Dialog/Dialog';
import Button from '../../components/UI/Button/Button';
import CustomItem from './CustomItem';
import editIcon from '../../assets/images/edit_icon.svg';

import styles from './ResumeBuilder.module.scss';

function CustomSection(props) {
    const [customPanelOpen, setCustomPanelOpen] = useState(true);

    const [customFields, setCustomFields] = useState([
        { customItemTitle: '', city: '', startDate: null, endDate: null, presentDate: false, description: '' }
    ]);

    const [sectionTitle, setSectionTitle] = useState('Untitled');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    const [deleteEntryDialog, setDeleteEntryDialog] = React.useState({open: false, deletedEntry: ''});

    const handleOpenDeleteEntryDialog = (entryKey) => {
        setDeleteEntryDialog({...deleteEntryDialog, open: true, deletedEntry: entryKey});
    };
    
    const handleCloseDeleteEntryDialog = () => {
        setDeleteEntryDialog({...deleteEntryDialog, open: false, deletedEntry: ''});
    };

    useEffect(() => {
        if (props.resume) {
            if (props.resume.customFields && props.resume.customFields.length) {
                console.log('Custom Info Mounted');
                setCustomFields(props.resume.customFields);
            }
            if (props.resume.customSectionTitle) {
                setSectionTitle(props.resume.customSectionTitle);
            }
            setDataLoaded(true);
        }
    }, [] );

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            if (props.resume.customFields && props.resume.customFields.length) {
                console.log('Custom Info Reload');
                setCustomFields(props.resume.customFields);
            }
            if (props.resume.customSectionTitle) {
                setSectionTitle(props.resume.customSectionTitle);
            }
        }
    }, [props.exampleLoaded] );

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ customFields: customFields});
        }
    }, [customFields]);

/*     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({customFields: customFields});
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, customFields]);
 */
    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ customSectionTitle: sectionTitle});
        }
    }, [dataLoaded, sectionTitle]);

    const handleAddCustomFields = () => {
        const values = [...customFields];
        values.push({ customItemTitle: '', city: '', startDate: null, endDate: null, presentDate: false, description: '' });
        setCustomFields(values);
        setChanged(true);
    };

    const handleRemoveCustomFields = index => {
        const values = [...customFields];
        values.splice(index, 1);
        setCustomFields(values);
        handleCloseDeleteEntryDialog();
        setChanged(true);
    };

    const handleCustomInputChange = (index, fieldKey, content) => (event) => {
        const values = [...customFields];
        if ((fieldKey === 'description') || (fieldKey === 'presentDate') || (fieldKey === 'startDateMonthHidden') || (fieldKey === 'endDateMonthHidden')) {
            values[index][fieldKey] = content;
        } else if ((fieldKey === 'startDate') || (fieldKey === 'endDate')) {
            //Fix One day off JS issue
            if (moment(content).isValid()) {
                const date = new Date(content.getTime() - (content.getTimezoneOffset() * 60000));
                values[index][fieldKey] = date;
            } else {
                values[index][fieldKey] = content;
            }
        } else {
            values[index][fieldKey] = event.target.value;
        }
        setCustomFields(values);
        setChanged(true);
    };

    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setCustomPanelOpen(!customPanelOpen)} className={styles.PanelToggle}>
                {customPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="custom-section-title"
                    value={sectionTitle}
                    placeholder="Untitled"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                    
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle}/>
                <IconButton onClick={props.deleted} style={{float: 'right'}}>
                    <DeleteIcon />
                </IconButton>  
            </div>
            { customPanelOpen ? (
                <>
                    {customFields.map((inputField, index) => (
                        <CustomItem 
                            key={`${inputField}~${index}`} 
                            values={customFields[index]} 
                            changed={handleCustomInputChange}
                            deleted={() => handleOpenDeleteEntryDialog(index)}
                            index={index}
                        />
                    ))}
                    <Link
                        component="a"
                        variant="body2"
                        className={styles.FormLink}
                        onClick={() => handleAddCustomFields()}
                    >
                        <AddCircleIcon /> Add item
                    </Link>
                </>
            ) : null }
            <Dialog 
                open={deleteEntryDialog.open}
                closed={handleCloseDeleteEntryDialog}
            >
                <h2>Delete Entry</h2>
                <p>Are you sure you want to delete this entry?</p>   
                <Button
                    variant="contained"
                    color="primary"
                    clicked={() => handleRemoveCustomFields(deleteEntryDialog.deletedEntry)}
                    className={styles.Button}
                >
                    Delete
                </Button>
                <Link onClick={handleCloseDeleteEntryDialog}>Cancel</Link>
            </Dialog>
        </Paper>       
    )
}

export default CustomSection;