import React from 'react';

import templateImage1 from '../../assets/images/resume-template1.png';
import templateImage2 from '../../assets/images/resume-template2.png';
import templateImage3 from '../../assets/images/resume-template3.png';

import {apiUrl} from '../../config';
import styles from './ResumeCard.module.scss';

const templateImages = {
    1: templateImage1,
    2: templateImage2,
    3: templateImage3,
    4: templateImage1,
    5: templateImage2,
    6: templateImage3,
};

const ResumeCard = (props) => (
/*     <a href="/" className={styles.Logo}>
        <img src={logoImage} alt="ResumeOK" />
    </a> */
    <>
        <div style={{backgroundImage: props.cardImage ? `url(${apiUrl}/storage${props.cardImage})` : `url(${templateImages[props.templateId]})`}} className={styles.ResumeCard}></div>
        {/* <img src={props.cardImage ? `http://resumeapi.justfortest-staging1.com/storage${props.cardImage}` : templateImages[props.templateId]} alt={"template " + props.templateId } className={styles.ResumeCard}/> */}
    </>
);

export default ResumeCard;