import React from 'react';
import { IconButton, Dialog, Fade } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import styles from './Dialog.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function UiDialog(props) {
    return (
        <Dialog 
            onClose={props.closed} 
            aria-labelledby="resume-dialog-title" 
            open={props.open}
            disableBackdropClick={false}
            maxWidth={props.maxWidth ? props.maxWidth : false}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: props.dialogBg || '#fff',
                    margin: '1rem',
                    padding: props.dialogPadding || '1rem 1.5rem',
                    boxShadow: 'none',
                },
            }}
            BackdropProps= {{
                style: {
                    backgroundColor: '#17181B',
                    opacity: '.75',
                },
            }}
            className={`${styles.Dialog} ${props.className}`}
        >
            <>
                <IconButton aria-label="close" className={`${styles.CloseDialogButton} ${props.classNameCloseBtn}`} onClick={props.closed}>
                    <CloseIcon />
                </IconButton>
                {props.title}
                {props.children}
            </>
        </Dialog>
    )
}

export default UiDialog;