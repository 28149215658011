import React from 'react';

import styles from './BuilderToolbar.module.scss';

import FontItem from './FontItem';
import ColorItem from './ColorItem';
import DesignItem from './DesignItem';
import ShareItem from './ShareItem';
import ComplectionItem from './ComplectionItem';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import FontIcon from '../UI/Icons/FontIcon';
import DesignIcon from '../UI/Icons/DesignIcon';
import SwitchTemplateIcon from '../UI/Icons/SwitchTemplateIcon';
import DownloadIcon from '../UI/Icons/DownloadIcon';
//import LinkedinIcon from '../UI/Icons/LinkedinIcon';
import SamplesIcon from '../UI/Icons/SamplesIcon';
import ResumeTypeIcon from '../UI/Icons/ResumeTypeIcon';
import ShareIcon from '../UI/Icons/ShareIcon';

import Dropdown from '../UI/Dropdown/Dropdown';

function DesktopToolbar(props) {
    /* const CLIENT_ID = '78h5vupnsyb6ae';
    const CLIENT_SECRET = 'zmuSsUOfUDkXaMyh';
    const REDIRECT_URI = 'http://localhost:3000';
    useEffect(() => {
        axios.get(`https://resume-cors.herokuapp.com/https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`)
        .then( response => {
            console.log('authorizationCode');
            console.log(response);
        } )
        .catch( err => {
            console.log(err);
        } );

        /* axios.get(`https://resume-cors.herokuapp.com/https://www.linkedin.com/oauth/v2/accessToken?grant_type=client_credentials&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`)
        .then( response => {
            console.log('authorizationCode');
            console.log(response);
        } )
        .catch( err => {
            console.log(err);
        } ); */
        
        /* axios.get( 'https://resume-cors.herokuapp.com/https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code='
        + authorizationCode + '&redirect_uri=' + REDIRECT_URI + '&client_id='
        + CLIENT_ID + '&client_secret=' + CLIENT_SECRET)
        .then( response => {
            console.log(response);
        } )
        .catch( err => {
            console.log(err);
        } );


        axios.get( 'https://resume-cors.herokuapp.com/https://api.linkedin.com/v2/people/(id:bloodyowl)')
        .then( response => {
            console.log(response);
        } )
        .catch( err => {
            console.log(err);
        } ); */
    /* }, []); */


    return (
        <nav className={styles.BuilderToolbar}>
            <ul className={styles.ResumeTools}>
                <li className={styles.Button}>
                    <Dropdown 
                        id="resume-font-list" 
                        title="Font" 
                        icon={<FontIcon viewBox="0 0 14 14" />} 
                        showIcon={true}
                    >
                        <FontItem
                            fontFamily={props.fontFamily}
                            onFontFamilyChange={props.handleFontFamilyChange}
                            fontSize={props.fontSize}
                            onFontSizeChange={props.handleFontSizeChange}
                        />
                    </Dropdown>
                </li>
                <li className={styles.Button}>
                    <Dropdown 
                        id="resume-color-list" 
                        title="Colors" 
                        icon={(<span className={styles.ColorIcon} style={{backgroundColor: props.primaryColor}}></span>)}
                        showIcon={true} 
                        customClass={styles.ColorDropdown}
                    >
                        <ColorItem 
                            primaryColor={props.primaryColor}
                            secondaryColor={props.secondaryColor}
                            onPrimaryColorChange={props.handlePrimaryColorChange}
                            onSecondaryColorChange={props.handleSecondaryColorChange}
                        />
                    </Dropdown>
                </li>
                <li className={styles.Button}>
                    <Dropdown 
                        id="resume-design-list" 
                        title="Design" 
                        icon={<DesignIcon  viewBox="0 0 13 12" />} 
                        showIcon={true}
                    >
                        <DesignItem 
                            pattern={props.pattern}
                            onPatternChange={props.handlePatternChange}
                        />
                    </Dropdown>
                </li>                
                <li onClick={props.showTemplatesHandler} className={styles.Button}>
                    <SwitchTemplateIcon viewBox="0 0 13 12" /><span>Template</span>
                </li>
                
                {!props.isCoverLetter && (<>
                    <li className={styles.Button}>
                        <a href="http://www.resumeok.com/resume-samples/" target="_blank" rel="noopener noreferrer" className={styles.Button}><SamplesIcon viewBox="0 0 11 11" /><span>Samples</span></a>
                    </li>           
                    <li className={styles.Button}>
                        <Dropdown 
                            id="resume-type-list" 
                            title={props.activeType} 
                            icon={<ResumeTypeIcon viewBox="0 0 10 10" />} 
                            showIcon={true}
                            typeDropdownClose={props.typeDropdownClose}
                        >
                            <>{Object.keys(props.resumeTypes).map(type => <li onClick={() => props.handleChooseType(type)} key={type}>{props.resumeTypes[type]}</li>)}</>
                        </Dropdown>
                    </li>
                </>)}
            </ul>
            <ul className={styles.ResumeActions}>
                {!props.isCoverLetter && (
                    <li className={styles.Button}>
                        <Dropdown 
                            id="resume-complection-list"
                            title="Progress" 
                            icon={<CircularProgressWithLabel progressValue={props.progressValue} />} 
                            showIcon={false} 
                            customClass={styles.ComplectionDropdown}
                        >
                            <ComplectionItem resume={props.document} scrollToProgressHandler={props.scrollToProgressHandler} onProgressValueChange={props.handleProgressValue} />
                        </Dropdown>
                    </li>
                )}
                {/* <li className={styles.Button}>
                    <LinkedinIcon viewBox="0 0 11 11" /><span>Import from Linkedin</span>
                </li> */}
                <li className={styles.Button}>
                    <Dropdown 
                        id="resume-share-list" 
                        title="Share" 
                        icon={<ShareIcon viewBox="0 0 19 19" />} 
                        showIcon={false}
                        customClass={styles.ShareDropdown}
                    >
                        <ShareItem documentId={props.documentId} isCoverLetter={props.isCoverLetter} />
                    </Dropdown>
                </li>
                <li className={styles.Button}>
                    <Dropdown 
                        id="resume-share-list" 
                        title="Download" 
                        icon={<DownloadIcon viewBox="0 0 19 19" />} 
                        showIcon={false}
                        customClass={styles.DownloadDropdown}
                    >
                        <span onClick={props.generatePdf} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}>Download PDF</span>
                        <span onClick={props.generateScreenshot} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}>Download PNG</span>
                    </Dropdown>
                </li>          
                {/* <li>
                    <span onClick={props.generatePdf} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}><DownloadIcon viewBox="0 0 19 19" /><span>Download</span></span>
                </li>
                <li>
                    <span onClick={props.generateScreenshot} className={`${styles.Button} ${!props.downloadBtnActive ? styles.Disabled : ''}`}><DownloadIcon viewBox="0 0 19 19" /><span>PNG</span></span>
                </li> */
                }
                {/* <a href={props.pdfUrl} download={`${props.resume.firstName} ${props.resume.lastName} resume.pdf`} type="application/pdf" className={styles.Button}>
                        <DownloadIcon viewBox="0 0 19 19" /><span>Download</span>
                    </a> */}
            </ul>
            {!props.isCoverLetter && (<ComplectionItem resume={props.document} scrollToProgressHandler={props.scrollToProgressHandler} onProgressValueChange={props.handleProgressValue} hidden/>)}

        </nav>
    )
}

export default DesktopToolbar;