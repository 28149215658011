import React from 'react';

import {Grid, InputLabel, IconButton, Collapse} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import Input from '../../components/UI/Input/Input';
import TextEditor from '../../components/UI/TextEditor/TextEditor';
import MonthYearPicker from '../../components/UI/MonthYearPicker/MonthYearPicker';

import { Draggable } from 'react-beautiful-dnd';

import { generateDateString } from '../../shared/utility';
import styles from './ResumeBuilder.module.scss';

function EducationItem(props) {
    const handlePresentDateChange = (event) => {
        props.changed(props.index, 'presentDate', event.target.checked)();
    };

    const onFieldChange = (fieldKey, date) => {
        props.changed(props.index, fieldKey, date)();
    }

    let workDatesString = generateDateString(props.values.startDate, props.values.endDate, props.values.presentDate, props.values.startDateMonthHidden, props.values.endDateMonthHidden, "MMM YYYY");

    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided) => (
                <div 
                    className={styles.FormRepeatedItem} 
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                <Grid container spacing={1} alignItems="center" justify="space-between" wrap='nowrap'>
                    <Grid item onClick={props.toggled} className={styles.ItemTitle} {...provided.dragHandleProps}>
                        <h4 className={styles.ResumeItemTitle}>{props.values.degree} {(props.values.degree && props.values.institution) ? 'at' : ''} {props.values.institution}</h4>
                        {workDatesString ? (<p className={styles.ResumeItemDates}>{workDatesString}</p>) : ''}
                    </Grid>
                    <Grid item className={styles.ItemControls}>
                        <div className={styles.DragButton} {...provided.dragHandleProps}><DragIndicatorIcon /></div>
                        <IconButton onClick={props.toggled} className={styles.PanelToggle}>
                            {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                        </IconButton>
                        <IconButton onClick={props.deleted} style={{float: 'right', padding: '.15rem'}} className={styles.DeleteButton}>
                            <DeleteIcon style={{fontSize: '1.3rem'}}/>
                        </IconButton>                 
                    </Grid>
                </Grid>
                <Collapse in={props.open}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Input
                                variant="outlined"
                                key="degree"
                                id="degree"
                                type="text"
                                label="Degree"
                                value={props.values.degree}
                                changed={props.changed(props.index, 'degree')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                variant="outlined"
                                key="institution"
                                id="institution"
                                type="text"
                                label="Education Institution"
                                value={props.values.institution}
                                changed={props.changed(props.index, 'institution')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <MonthYearPicker
                                label="Start Date"
                                name="Start Date"
                                changed={(date) => onFieldChange('startDate', date)}
                                placeholder="mm/yyyy"
                                value={props.values.startDate}
                                monthHidden={props.values.startDateMonthHidden}
                                changeMonthHiddenHandler={(isHidden) => onFieldChange('startDateMonthHidden', isHidden)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <MonthYearPicker
                                label="End Date"
                                name="End Date"
                                changed={(date) => onFieldChange('endDate', date)}
                                placeholder="mm/yyyy"
                                showCurrentlySwitch={true}
                                presentDate={props.values.presentDate}
                                swichChangeHandler={handlePresentDateChange}
                                switchLabel="Currently study here"
                                value={props.values.endDate}
                                monthHidden={props.values.endDateMonthHidden}
                                changeMonthHiddenHandler={(isHidden) => onFieldChange('endDateMonthHidden', isHidden)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                                        
                            <Input
                                variant="outlined"
                                key="city"
                                id="city"
                                type="text"
                                label="City"
                                value={props.values.city}
                                changed={props.changed(props.index, 'city')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={styles.Label}>Summary and achievements</InputLabel>
                            <TextEditor
                                value={props.values.description}
                                changed={(content) => onFieldChange('description', content)}
                                key={props.id}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </div>
            )}
        </Draggable>
    )
}

export default EducationItem;