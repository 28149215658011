import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Paper, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AutosizeInput from 'react-input-autosize';

import Tooltip from '../../components/UI/Tooltip/Tooltip';
import TextEditor from '../../components/UI/TextEditor/TextEditor';

import editIcon from '../../assets/images/edit_icon.svg';
import styles from './ResumeBuilder.module.scss';

function CareerSummarySection(props) {
    const [sectionTitle, setSectionTitle] = useState('Career Summary & Objective');
    const [careerSummaryPanelOpen, setCareerSummaryPanelOpen] = useState(true);
    const [careerSummary, setCareerSummary] = useState('');    
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changed, setChanged] = useState(false);

    let timer = null;

    useEffect(() => {
        if (props.resume) {
            console.log('Career Summary mounted');
            setCareerSummary(props.resume.careerSummary);
        }
        if (props.resume.careerSummaryTitle) {
            setSectionTitle(props.resume.careerSummaryTitle);
        }
        setDataLoaded(true);
    }, []);

    useEffect(() => {
        if (props.resume && props.exampleLoaded) {
            console.log('Career Summary Reload');
            setCareerSummary(props.resume.careerSummary);
        }
        if (props.resume.careerSummaryTitle) {
            setSectionTitle(props.resume.careerSummaryTitle);
        }
    }, [props.exampleLoaded]);

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({careerSummary: careerSummary});
        }
    }, [dataLoaded, changed, careerSummary]);

    useEffect(() => {
        if (dataLoaded && changed) {
            props.onChange({ careerSummaryTitle: sectionTitle});
        }
    }, [sectionTitle]);

    /* useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (dataLoaded && changed) {
                props.onChange({careerSummary: careerSummary});
            }
        }, 3000);
        
        return () => clearTimeout(delayDebounceFn);
    }, [dataLoaded, changed, careerSummary]); */

    const handleEditorChange = (content) => {
        //clearTimeout(timer);
        setCareerSummary(content);
        setChanged(true);
    }

    const handleSectionTitleChange = (event) => {
        setSectionTitle(event.target.value);
        setChanged(true);
    }

    const sectionTitleInput = useRef(null); 

    const editCustomSectionTitle = () => {
        sectionTitleInput.current.focus();
        sectionTitleInput.current.select();
    }

    return (                       
        <Paper className={`${styles.FormSection}`}>
            <IconButton onClick={() => setCareerSummaryPanelOpen(!careerSummaryPanelOpen)} className={styles.PanelToggle}>
                {careerSummaryPanelOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </IconButton>

            <div className={styles.PanelTitle}>
                <AutosizeInput
                    name="summary-section-title"
                    value={sectionTitle}
                    placeholder="Career Summary & Objective"
                    onChange={handleSectionTitleChange}
                    className={`${styles.TitleInput} ${styles.CustomSectionTitle}`}
                    ref={sectionTitleInput}
                    data-lpignore="true"
                />
                <img src={editIcon} alt="edit icon" onClick={editCustomSectionTitle} style={{marginRight: '.5rem'}} />
                <Tooltip tooltipContent="Include a short career objective that summarizes your current career and your long term expectations. Keep it short and to the point." />
            </div>
            { careerSummaryPanelOpen ? (
                <>
                    <p>Write a few sentences about your experience and career goals</p>
                    <TextEditor
                        value={careerSummary}
                        changed={(content) => handleEditorChange(content)}
                    />
                </>
            ) : null }
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        created: state.resume.created,
        resumes: state.resume.resumes
    };
};

export default connect( mapStateToProps )( CareerSummarySection );